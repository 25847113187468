import { SVG } from 'assets/imagePath';
import * as React from 'react';
import { OnProgressProps } from 'react-player/base';

export interface IRangeProps {
  color?: 'primary' | 'black';
  icon?: string;
  duration?: number;
  progress?: number;
  defaultValue?: number;
  setRange?: (val: number) => void;
}

function Range({
  icon,
  color = 'primary',
  duration,
  progress,
  defaultValue = 0,
  setRange,
}: IRangeProps) {
  const [value, setValue] = React.useState(defaultValue);
  const handleChangeValue = (e) => {
    setValue(e.target.value);
    if (progress) {
      const seekVal = (e.target.value * duration) / 100;
      setRange(seekVal);
    } else {
      setRange(e.target.value);
    }
  };
  const colorProgress = {
    bgColor: `${color === 'primary' ? '#BC9A3B50' : '#38383830'}`,
    dotColor: `${color === 'primary' ? 'progress-primary' : 'progress-black'}`,
  };

  React.useEffect(() => {
    if (!duration && !progress) {
      return;
    } else {
      const loadedVal = (progress / duration) * 100;
      setValue(Math.floor(loadedVal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return (
    <div className="flex items-center gap-x-2.5">
      <img src={icon} alt="icon-control-range" />
      <input
        className={`progress w-full rounded-full ${colorProgress.dotColor}`}
        style={{
          background: `linear-gradient(to right, ${colorProgress.bgColor} 0%, ${colorProgress.bgColor} ${value}%, rgba(56, 56, 56, 0.1) ${value}%, rgba(56, 56, 56, 0.1) 100%)`,
          border: '1px solid #38383850',
        }}
        value={value}
        onChange={handleChangeValue}
        type={'range'}
        min={0}
        max={100}
      />
      <p className="w-[30px]">{value}%</p>
    </div>
  );
}

export default React.memo(Range);
