import { FC, Fragment, memo, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import cn from 'classnames';
import { ISelectOption } from 'types';
import { SVG } from 'assets/imagePath';

type Props = {
  className?: string;
  label: string;
  value?: ISelectOption[];
  error?: boolean;
  options: ISelectOption[];
  defaultValue?: string;
  onChangeValue?: (value: ISelectOption[]) => void;
};

const SelectedChip = ({ selectedItem, className = null, removeSelect }) => {
  return (
    <div
      className={cn(
        'w-fit inline-block p-[5px] pl-2.5 bg-[#f3f3f3] border boder-nav font-inter text-xs leading-[15px] rounded-[2.5px]   hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out',
        className
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="w-full h-full flex flex-row gap-5">
        <p>{selectedItem?.label}</p>
        <img
          className="w-[15px] h-[15px] object-contain"
          src={SVG.close}
          onClick={(e) => {
            e.stopPropagation();
            removeSelect(selectedItem);
          }}
        />
      </div>
    </div>
  );
};

const SelectMulti: FC<Props> = ({
  className,
  label,
  value,
  error,
  options,
  onChangeValue,
  defaultValue,
  ...props
}) => {
  const [valuesSelected, setValuesSelected] = useState([]);
  const removeSelect = (removeItem) => {
    const _valuesSelected = valuesSelected.filter(
      (item) => item.value !== removeItem.value
    );
    setValuesSelected(_valuesSelected);
  };

  useEffect(() => {
    onChangeValue && onChangeValue(valuesSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesSelected]);

  return (
    <Listbox
      as="div"
      className="relative inline-block w-full text-left"
      value={valuesSelected}
      onChange={setValuesSelected}
      multiple
    >
      <div>
        {label && valuesSelected && (
          <p className="absolute text-[10px] leading-[10px] left-[10px] px-[5px] top-[-5px] bg-white">
            {label}
          </p>
        )}
        <Listbox.Button
          className={cn(
            'inline-flex w-full justify-between bg-white bg-clip-padding px-2.5 py-3 m-0 ring-1 ring-nav rounded-[5px]',
            error ? 'ring-1 ring-red-500 focus:ring-1 focus:ring-red-500' : '',
            className
          )}
        >
          <div
            className={cn(
              'text-sm text-input font-inter flex flex-row gap-[5px] w-full overflow-hidden',
              valuesSelected ? 'text-input' : 'text-slate-400'
            )}
          >
            {valuesSelected.map((val) => (
              <SelectedChip selectedItem={val} removeSelect={removeSelect} />
            ))}
          </div>
          <img
            src={SVG.chevron_down}
            className="-mr-1 ml-2 my-auto h-5 w-5"
            aria-hidden="true"
            alt="icon"
          />
        </Listbox.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Listbox.Options className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options?.map((item) => (
              <Listbox.Option key={item.value} value={item}>
                {({ active, selected }) => (
                  <a
                    // href="#"
                    className={cn(
                      selected ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm font-inter cursor-pointer'
                    )}
                  >
                    {item.label}
                  </a>
                )}
              </Listbox.Option>
            ))}
          </div>
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
};

SelectMulti.displayName = 'Select.Multi';

export default memo(SelectMulti);
