import * as React from 'react';
import { useMemo, useState } from 'react';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  PAGE_SIZE_OPTIONS,
} from '../../../constant';
import { Text } from '../../../ui-molecules';
import { Dropdown } from '../../../ui-atoms';
import { SVG } from '../../../assets/imagePath';
import { Box } from '../../../ui-organisms';
import { ITableColumn } from '../../../types';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';

type Props = {
  toggle: boolean;
};

const ListFilterPanel = [
  { name: 'Users' },
  { name: 'User Status' },
  { name: 'Security Control' },
  { name: 'Time Zones' },
  { name: 'Prefix' },
  { name: 'Suffix' },
  { name: 'Org Service Level' },
];

const ListUserColumn: ITableColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'role',
    headerName: 'Role',
    headerStyle: { textAlign: 'left' },
  },
];

const UserRows = {
  data: [
    {
      name: 'User Name',
      role: 'Document Specialist',
    },
  ],
};

const ListUSColumn: ITableColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'status',
    headerName: 'Status',
    headerStyle: { textAlign: 'left' },
  },
];

const USRows = {
  data: [
    {
      name: 'User Name',
      status: 'Document Specialist',
    },
  ],
};

const ListSCColumn: ITableColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
];

const SCRows = {
  data: [
    {
      name: 'User Name',
      value: 'Organización',
    },
  ],
};

const ListTimeColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'utc',
    headerName: 'UTC Offset',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'func',
    headerName: '',
    headerStyle: { textAlign: 'left' },
  },
];

const TimeRows = {
  data: [
    {
      id: '1',
      value: 'UTC',
      description: 'Coordinated Universal Time',
      utc: '+0',
      func: '1',
    },
    {
      id: '1',
      value: 'GMT',
      description: 'Greenwich Mean Time',
      utc: '+0',
      func: '1',
    },
    {
      id: '1',
      value: 'EST',
      description: 'Eastern Standard Time',
      utc: '-5',
      func: '1',
    },
    {
      id: '1',
      value: 'CST',
      description: 'Central Standard Time',
      utc: '-6',
      func: '1',
    },
    {
      id: '1',
      value: 'MST',
      description: 'Mountain Standard Time',
      utc: '-7',
      func: '1',
    },
    {
      id: '1',
      value: 'PST',
      description: 'Pacific Standard Time',
      utc: '-8',
      func: '1',
    },
    {
      id: '1',
      value: 'AKST',
      description: 'Alaska Standard Time',
      utc: '-9',
      func: '1',
    },
    {
      id: '1',
      value: 'HAST',
      description: 'Hawaii-Aleutian Standard Time',
      utc: '-10',
      func: '1',
    },
    {
      id: '1',
      value: 'WET',
      description: 'Western European Time',
      utc: '+0',
      func: '1',
    },
    {
      id: '1',
      value: 'CET',
      description: 'Central European Time',
      utc: '+1',
      func: '1',
    },
    {
      id: '1',
      value: 'EET',
      description: 'Eastern European Time',
      utc: '+2',
      func: '1',
    },
    {
      id: '1',
      value: 'IST',
      description: 'Indian Standard Time',
      utc: '+5:30',
      func: '1',
    },
    {
      id: '1',
      value: 'JST',
      description: 'Japan Standard Time',
      utc: '+9',
      func: '1',
    },
    {
      id: '1',
      value: 'CST',
      description: 'China Standard Time',
      utc: '+8',
      func: '1',
    },
    {
      id: '1',
      value: 'KST',
      description: 'Korea Standard Time',
      utc: '+9',
      func: '1',
    },
    {
      id: '1',
      value: 'AEST',
      description: 'Australian Eastern Standard Time',
      utc: '+10',
      func: '1',
    },
    {
      id: '1',
      value: 'ACST',
      description: 'Australian Central Standard Time',
      utc: '+9:30',
      func: '1',
    },
    {
      id: '1',
      value: 'AWST',
      description: 'Australian Western Standard Time',
      utc: '+8',
      func: '1',
    },
    {
      id: '1',
      value: 'NZST',
      description: 'New Zealand Standard Time',
      utc: '+12',
      func: '1',
    },
  ],
};

const ListPrefixColumn: ITableColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
];

const PrefixRows = {
  data: [
    {
      name: 'Mr.',
      description: 'Mister',
    },
    {
      name: 'Mrs.',
      description: 'Mistress (pronounced missiz)',
    },
    {
      name: 'Miss',
      description: 'Used for unmarried women',
    },
    {
      name: 'Ms.',
      description: 'Used for women regardless of marital status',
    },
    {
      name: 'Dr.',
      description: 'Doctor',
    },
    {
      name: 'Prof.',
      description: 'Professor',
    },
    {
      name: 'Rev.',
      description: 'Reverend',
    },
    {
      name: 'Capt.',
      description: 'Captain',
    },
    {
      name: 'Sgt.',
      description: 'Sergeant',
    },
    {
      name: 'Col.',
      description: 'Colonel',
    },
    {
      name: 'Maj.',
      description: 'Major',
    },
    {
      name: 'Sir',
      description: 'Used as a respectful form of address',
    },
    {
      name: 'Madam',
      description: 'Used as a polite form of address for a woman',
    },
    {
      name: 'Lord',
      description: 'Title of nobility',
    },
    {
      name: 'Lady',
      description: 'Title of nobility or courtesy',
    },
  ],
};

const ListSuffixColumn: ITableColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
];

const SuffixRows = {
  data: [
    {
      name: 'Jr.',
      description: 'Junior',
    },
    {
      name: 'Sr.',
      description: 'Senior',
    },
    {
      name: 'II',
      description: 'the Second',
    },
    {
      name: 'III',
      description: 'the Third',
    },
    {
      name: 'IV',
      description: 'the Fourth',
    },
    {
      name: 'Esq.',
      description: 'Esquire',
    },
    {
      name: 'Ph.D.',
      description: 'Doctor of Philosophy',
    },
    {
      name: 'M.D.',
      description: 'Medical Doctor',
    },
    {
      name: 'D.D.S.',
      description: 'Doctor of Dental Surgery',
    },
    {
      name: 'D.V.M.',
      description: 'Doctor of Veterinary Medicine',
    },
    {
      name: 'CPA',
      description: 'Certified Public Accountant',
    },
    {
      name: 'RN',
      description: 'Registered Nurse',
    },
    {
      name: 'J.D.',
      description: 'Juris Doctor',
    },
    {
      name: 'Rev.',
      description: 'Reverend',
    },
    {
      name: 'Prof.',
      description: 'Professor',
    },
    {
      name: 'Col.',
      description: 'Colonel',
    },
    {
      name: 'Sgt.',
      description: 'Sergeant',
    },
    {
      name: 'Gov.',
      description: 'Governor',
    },
    {
      name: 'Rep.',
      description: 'Representative',
    },
    {
      name: 'Sen.',
      description: 'Senator',
    },
    {
      name: 'Amb.',
      description: 'Ambassador',
    },
    {
      name: 'Mr.',
      description: 'Mister',
    },
    {
      name: 'Mrs.',
      description: 'Missus',
    },
    {
      name: 'Ms.',
      description: 'Miz',
    },
    {
      name: 'Dr.',
      description: 'Doctor',
    },
    {
      name: 'Capt.',
      description: 'Captain',
    },
    {
      name: 'Sir',
      description: 'Formal title of respect for a man',
    },
    {
      name: 'Madam',
      description: 'Formal title of respect for a woman',
    },
    {
      name: 'Lord',
      description: 'Formal title for a nobleman',
    },
    {
      name: 'Lady',
      description: 'Formal title for a noblewoman',
    },
  ],
};

const ListOSLColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'func',
    headerName: '',
    headerStyle: { textAlign: 'left' },
  },
];

const OSLRows = {
  data: [
    {
      id: '1',
      value: 'Full Service',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'Coding Only',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'Transcription Only',
      description: 'Description',
      func: '1',
    },
  ],
};

export const UserAndControl = ({ toggle }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LIMIT);
  const [selectPanel, setSelectPanel] = useState<string>(
    ListFilterPanel[0].name
  );

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const tableData = useMemo(() => {
    switch (true) {
      case selectPanel === ListFilterPanel[0].name:
        return {
          columns: ListUserColumn,
          rows: UserRows,
        };
      case selectPanel === ListFilterPanel[1].name:
        return {
          columns: ListUSColumn,
          rows: USRows,
        };

      case selectPanel === ListFilterPanel[2].name:
        return {
          columns: ListSCColumn,
          rows: SCRows,
        };

      case selectPanel === ListFilterPanel[3].name:
        return {
          columns: ListTimeColumn,
          rows: TimeRows,
        };

      case selectPanel === ListFilterPanel[4].name:
        return {
          columns: ListPrefixColumn,
          rows: PrefixRows,
        };

      case selectPanel === ListFilterPanel[5].name:
        return {
          columns: ListSuffixColumn,
          rows: SuffixRows,
        };

      case selectPanel === ListFilterPanel[6].name:
        return {
          columns: ListOSLColumn,
          rows: OSLRows,
        };

      default:
        return {
          columns: [],
          rows: [],
        };
    }
  }, [selectPanel]);

  const renderTableHead = (columns) => {
    return (
      <tr>
        {columns.map((column) => {
          return (
            !column?.hideCol && (
              <th
                key={column.field}
                className="p-table text-center border font-[600] whitespace-nowrap min-w-fit"
                style={column?.headerStyle}
              >
                <p>{column.headerName}</p>
              </th>
            )
          );
        })}
      </tr>
    );
  };

  const renderTableBody = (rows) => {
    return rows?.data?.map((row) => {
      return (
        <tr className="border border-t-0">
          {row?.id && <td className="w-fit"></td>}
          {row?.value && (
            <td className="w-fit">
              <p className={cn('text-left p-table')}>{row.value}</p>
            </td>
          )}
          {row?.name && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.name}</p>
            </td>
          )}
          {row?.role && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.role}</p>
            </td>
          )}
          {row?.status && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.status}</p>
            </td>
          )}
          {row?.description && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.description}</p>
            </td>
          )}

          {row?.utc && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.utc}</p>
            </td>
          )}
          {row?.func && (
            <td className="w-fit text-center">
              <Dropdown.Standard
                CustomButton={() => (
                  <div className="flex gap-2.5 items-center bg-white !w-fit">
                    <img
                      src={SVG.more_horizontal}
                      className="m-auto"
                      alt="more"
                    />
                  </div>
                )}
                options={[
                  {
                    label: 'Duplicate',
                    value: 'Duplicate',
                    // onClick: () => handleDuplicateRow(row),
                  },
                  {
                    label: 'Delete',
                    value: 'Delete',
                    // onClick: () => handleDeleteRow(row),
                  },
                ]}
              />
            </td>
          )}
        </tr>
      );
    });
  };
  return (
    <div className="flex flex-col flex-1 p-2.5 gap-2.5 mb-[50px]">
      <div className="flex items-center justify-between">
        <Text>1 Records</Text>
        <Dropdown.Standard
          CustomButton={(item) => (
            <div className="flex gap-2.5 items-center bg-white hover:bg-white p-5-10 border border-nav rounded-[5px]">
              <div className="">
                <p className="text-xs">{item.label} Records per page</p>
              </div>
              <img
                className="icon-app"
                src={SVG.chevron_down}
                alt="chevron_down"
              />
            </div>
          )}
          options={PAGE_SIZE_OPTIONS}
          onChangeValue={(item: any) => {
            handleChangePageSize(item?.value);
          }}
        />
      </div>
      <div className="flex flex-1 gap-2.5">
        <Box.Standard
          className={`max-w-[180px] h-auto w-full transition-all ${
            toggle ? '' : '-translate-x-[150%] fixed'
          }`}
        >
          <div className="flex flex-col gap-y-2.5 px-2.5">
            <h3 className={'font-bold text-xs py-1'}>User and Control</h3>
            <div className="flex flex-col text-xs gap-[5px]">
              {ListFilterPanel.map((item) => {
                return (
                  <p
                    className={cn(
                      selectPanel === item.name
                        ? 'text-[#BC9A3B] font-bold'
                        : 'hover:cursor-pointer hover:text-pink-700 hover:underline hover:font-bold'
                    )}
                    key={item.name}
                    onClick={() => setSelectPanel(item.name)}
                  >
                    {item.name}
                  </p>
                );
              })}
            </div>
          </div>
        </Box.Standard>
        <Box.Standard>
          <table className={'table-auto w-full z-0'}>
            <thead>{renderTableHead(tableData.columns)}</thead>
            <tbody>{renderTableBody(tableData.rows)}</tbody>
          </table>
        </Box.Standard>
      </div>
    </div>
  );
};
