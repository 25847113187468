import React, { Fragment } from 'react';

interface InlineHtmlProps {
  html: string;
}

function InlineHtml({ html }: InlineHtmlProps) {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

export default InlineHtml;
