import { SVG } from 'assets/imagePath';
import { Box } from 'ui-organisms';
import { Filter, Form } from 'ui-molecules';
import { memo } from 'react';

export interface IInquiriesFilterProps {
  onSubmitFilter?: () => void;
  onClearFilter?: () => void;
  onClearSearchBox?: () => void;
}

function InquiriesFilter({
  onClearFilter,
  onSubmitFilter,
  onClearSearchBox,
}: IInquiriesFilterProps) {
  return (
    <Box.Standard className="w-full h-full">
      <div className="p-2.5 flex flex-col">
        <h4 className="font-bold text-xs flex justify-between items-center">
          <span>Filter Inquiries by</span>
          <img src={SVG.un_filter} />
        </h4>
        <div className="my-2.5 -mx-2.5">
          <Form.SearchBox
            name="search_box"
            className="w-ful rounded-full border border-nav border-solid  bg-transparent px-2.5 py-[5px]"
            clearInput={onClearSearchBox}
            inputProps={{
              placeholder: 'Search',
              style: { borderStyle: 'solid' },
            }}
          />
        </div>
        <div className="flex flex-col gap-2.5">
          <Filter.FilterSystem onSubmit={onSubmitFilter} />
        </div>
      </div>
    </Box.Standard>
  );
}

InquiriesFilter.displayName = 'Inquiries.Filter';

export default memo(InquiriesFilter);
