import { yupResolver as FormResolver } from '@hookform/resolvers/yup';
import { SVG } from 'assets/imagePath';
import { Button, GeoSuggest } from 'ui-atoms';
import { Form, Auth } from 'ui-molecules';
import { SET_REGISTER_FORM } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import {
  useApiCall,
  useNavigateSearch,
  useSelectBillToSuggestion,
} from 'hooks';
import { useRegisterStep1Schema } from 'pages/auth/register/register.schema';
import { FC, memo, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import cn from 'classnames';
import { IRegisterStep1FormValues } from 'types';
import { capitalizeName } from 'utils/text';
import { checkCorporateRecordAPI } from 'services';
import { Suggest } from 'react-geosuggest';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';

const RegisterStep1: FC = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(GlobalContext);
  const form = useForm<IRegisterStep1FormValues>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    resolver: FormResolver(useRegisterStep1Schema()),
  });
  const navigateSearch = useNavigateSearch();
  const [checkCorporateRecord] = useApiCall(checkCorporateRecordAPI);
  const [customInputValue, setCustomInputValue] = useState('');
  const userBillingAddress = useSelectBillToSuggestion(
    form.watch('streetAddress')?.label
  );

  const onSubmit = async (values: IRegisterStep1FormValues) => {
    try {
      const res = await checkCorporateRecord({
        adminEmail: values.email.toLowerCase().trim(),
      });
      dispatch({
        type: SET_REGISTER_FORM,
        payload: {
          username: values.email.toLowerCase().trim(),
          userBillingAddress: userBillingAddress,
          phoneNumber: values.phoneNumber,
          email: values.email.toLowerCase().trim(),
          attributes: {
            'custom:userRole': res?.userRole,
            'custom:organizationId': res?.organizationId,
            'custom:firstName': values.firstName,
            'custom:lastName': values.lastName,
            'custom:middleName': values.firstName,
            'custom:initials': capitalizeName(
              values.firstName,
              values.lastName
            ),
            'custom:organizationName': values.organizationName,
            'custom:title': 'Admin',
            email: values.email.toLowerCase().trim(),
            // locale: 'miami'
          },
        },
      });
      navigateSearch('/register', { step: '2' });
    } catch (err) {
      console.log(err);
    }
  };

  const formatStreet = (suggestion: Suggest) => {
    form.setValue('streetAddress', suggestion);
    if (suggestion && suggestion.gmaps) {
      const zipCode = suggestion.gmaps.address_components.find((component) =>
        component.types.includes('postal_code')
      );

      const city = suggestion.gmaps.address_components.find((component) =>
        component.types.includes('locality')
      );

      form.setValue('zipCode', zipCode ? zipCode?.long_name : '');
      form.setValue('city', city ? city.long_name : '');
    }
    if (suggestion) {
      const [inputValue] = suggestion?.label.split(',');

      setCustomInputValue(inputValue.trim());
      // form.setValue('apartment', apartment.length ? apartment.join(',') : '');
    }
  };

  return (
    <div className="max-w-[280px] w-full h-full  mx-auto grid place-items-center ">
      <div className="flex flex-col justify-center items-center py-[30px]">
        <Auth.Header icon={SVG.register_account} title="Join us!" subtitle="" />

        <FormProvider {...form}>
          <form>
            <Form.EmailInput
              className="mt-[30px]"
              name="email"
              inputProps={{
                id: 'email',
                placeholder: 'E-mail Address',
              }}
            />
            <div className="flex mt-5">
              <Form.TextInput
                className="flex-1 mr-5"
                name="firstName"
                inputProps={{
                  id: 'firstName',
                  placeholder: 'First Name',
                  className: 'ring-1',
                }}
              />

              <Form.TextInput
                className="flex-1"
                name="lastName"
                inputProps={{
                  id: 'lastName',
                  placeholder: 'Last Name',
                  className: 'ring-1',
                }}
              />
            </div>
            <Form.TextInput
              className="mt-5 "
              name="organizationName"
              inputProps={{
                id: 'organizationName',
                placeholder: 'Organization Name',
                className: 'ring-1',
              }}
            />
            {/* <Input
              className="my-2.5"
              name="streetAddress"
              inputProps={{ id: 'streetAddress', placeholder: 'Street Address' }}
            /> */}
            <GeoSuggest
              placeholder="Street Address"
              inputClassName={cn(
                'w-full ring-none  !shadow-none px-4 h-10 text-sm font-inter text-input placeholder:text-slate-400 bg-white bg-clip-padding transition ease-in-out m-0 focus:text-input focus:bg-white  !focus:border-primary border border-nav rounded-[5px]'
              )}
              onSuggestSelect={(suggestion) => formatStreet(suggestion)}
              initialValue={customInputValue}
              // location={new google.maps.LatLng(53.558572, 9.9278215)}
              // radius="20"
              name="streetAddress"
            />

            <Form.TextInput
              className="mt-5"
              name="apartment"
              inputProps={{
                id: 'suiteOrApartment',
                placeholder: 'Suite or Apartment',
                className: 'ring-1',
              }}
            />
            <div className="flex mt-5">
              <Form.TextInput
                className="flex-1 mr-5"
                name="city"
                inputProps={{
                  id: 'city',
                  placeholder: 'City',
                  className: 'ring-1',
                }}
              />

              <Form.TextInput
                className={`flex-1  ${
                  !!form.getValues()?.zipCode ? 'opacity-50' : ''
                }`}
                name="zipCode"
                inputProps={{
                  id: 'zipCode',
                  placeholder: 'Zip Code',
                  disabled: !!form.getValues()?.zipCode,
                  className: 'ring-1',
                }}
              />
            </div>
            <Form.PhoneInputV2
              className="mt-5 h-10"
              name="phoneNumber"
              inputProps={{
                id: 'phoneNumber',
                placeholder: 'Phone Number',
              }}
            />

            <div className="flex items-center gap-x-2 mt-[30px]">
              <Button.White
                className="flex-1"
                label="Back"
                onClick={() => navigate(RouterPath.LOGIN)}
              />
              <Button.Primary
                className="flex-1"
                label="Continue"
                onClick={form.handleSubmit(onSubmit)}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default memo(RegisterStep1);
