import React from 'react';

interface TitleCoverProps {}

function TitleCover({}: TitleCoverProps) {
  return (
    <h1 className="text-white text-4xl mt-8 font-bold font-inter inline-block text-primary">
      CodedNote
    </h1>
  );
}

export default TitleCover;
