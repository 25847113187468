import cn from 'classnames';
import { ChangeEvent, FC, memo, useCallback } from 'react';

type Props = {
  className?: string;
  error?: boolean;
  value: string;
  onChange: (value: string) => void;
};

const PasswordInput: FC<Props> = ({ className, error, onChange, ...rest }) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target?.value);
    },
    [onChange]
  );

  return (
    <input
      type="password"
      className={cn(
        'w-full border-none  px-4 py-2 text-sm font-inter text-input placeholder:text-slate-400 bg-white bg-clip-padding transition ease-in-out m-0 focus:text-input focus:bg-white ring-1 ring-nav rounded-[5px]',
        error ? 'ring-1 ring-red-500 focus:ring-1 focus:ring-red-500' : '',
        className
      )}
      onChange={handleChange}
      {...rest}
    />
  );
};

PasswordInput.displayName = 'Input.Password';

export default memo(PasswordInput);
