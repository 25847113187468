import Geosuggest, { GeosuggestProps } from 'react-geosuggest';
import './styles.css';
import * as React from 'react';

export interface IGeoSuggestProps {}

export default function GeoSuggest({
  ...props
}: IGeoSuggestProps & GeosuggestProps) {
  return <Geosuggest {...props} />;
}
