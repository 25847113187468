import * as React from 'react';
import { Box } from 'ui-organisms';
import { Footer, HeaderApp, Form } from 'ui-molecules';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import { APP_ROLE, SET_LOADING_MODAL, TIMEZONE_OPTIONS } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import { useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getListOrganizationsAPI, inviteUserAPI } from 'services';
import { IClinicianInviteFormValues } from 'types';
import { useApiCall } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useClinicianInviteSchema } from './clinician-invite.schema';
import { capitalizeName } from '../../utils/text';
import isEmpty from 'lodash/isEmpty';
import { useAuth } from '../../hooks/useAuth';
import { RouterPath } from '../../routes/router.constant';

interface IClinicianInvite {}

export default function ClinicianInvite({}: IClinicianInvite) {
  const navigate = useNavigate();
  const { dispatch } = useContext(GlobalContext);
  const { CURRENT_ROLE } = useAuth();
  const [fetchOrganization, loadingOrganization, listOrganization] = useApiCall(
    getListOrganizationsAPI
  );
  const [inviteUser] = useApiCall(inviteUserAPI);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loadingOrganization,
    });
  }, [dispatch, loadingOrganization]);

  const allowAccess = useMemo(() => {
    return (
      CURRENT_ROLE === APP_ROLE.OW ||
      CURRENT_ROLE === APP_ROLE.SA ||
      CURRENT_ROLE === APP_ROLE.MGR ||
      CURRENT_ROLE === APP_ROLE.DS
    );
  }, [CURRENT_ROLE]);

  useEffect(() => {
    if (!allowAccess) {
      return navigate(RouterPath.CLINICIANS);
    }
    fetchOrganization();
  }, [allowAccess, fetchOrganization, navigate]);

  const organizationOption = useMemo(() => {
    return listOrganization?.map((orgItem) => ({
      label: orgItem?.organizationName,
      value: orgItem?.organizationId,
    }));
  }, [listOrganization]);

  const form = useForm<IClinicianInviteFormValues>({
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      priority: '',
      docSpecialist: '',
      cellPhone: '',
      email: '',
      timeZone: '',
      coder: '',
      degree: '',
      specialty: '',
      serviceLevel: '',
      servicePhone: '',
      manager: '',
    },
    resolver: FormResolver(useClinicianInviteSchema()),
  });
  const titleOpts = [{ label: 'Invite - Clinician', value: 'title-1' }];
  const actionOpts = [
    {
      label: 'Edit',
      value: 'edit',
      onClick: () => {},
    },
  ];

  const handleSubmitFunc = async (data) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });

      await inviteUser({
        initials: capitalizeName(
          data?.firstName,
          data?.lastName,
          data?.middleName
        ),
        firstName: data?.firstName,
        lastName: data?.lastName,
        middleName: data?.middleName,
        organizationId: data?.organization?.value,
        organizationName: data?.organization?.label,
        email: data?.email,
        cellPhone: data?.cellPhone,
        userRole: APP_ROLE.CL,
        timeZone: data?.timeZone,
        dateStarted: data?.dateStarted,
        degree: data?.degree,
        specialty: data?.specialty,
        serviceLevel: data?.serviceLevel,
        servicePhone: data?.servicePhone,
        priority: data?.priority,
        title: data?.title,
      });

      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
      navigate(`/clinicians`);
    } catch (err) {
      console.log('error', err);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
    }
  };

  const renderVerticalRow = (headerName: string, cell: any) => {
    return (
      <div className=" w-full flex flex-col gap-y-4 my-4 border border-white-light border-b-0">
        <div className="flex items-center gap-x-2 border-b border-white-light h-[60px]">
          <div className="w-2/5 justify-end border-r border-white-light">
            <p className="text-xs font-bold text-center">{headerName}</p>
          </div>
          <div className="w-full flex-1 items-center justify-between">
            {cell}
          </div>
        </div>
      </div>
    );
  };

  const renderPhone = (phone: string) => {
    return isEmpty(phone)
      ? '-'
      : `(${phone?.slice(0, 3)})-${phone?.slice(3, 6)}-${phone?.slice(
          6,
          phone?.length
        )}`;
  };

  return (
    <DefaultLayout>
      <HeaderApp.Edit
        title={{ options: titleOpts }}
        actions={{ options: actionOpts }}
        onSave={form.handleSubmit(handleSubmitFunc)}
        viewName="Clinician Details"
      />
      <Box.Standard className="flex-1 my-2.5">
        <FormProvider {...form}>
          <form className="grid grid-cols-3 gap-[10px]">
            <div>
              {renderVerticalRow(
                '*First Name (Goes By)',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full  !px-2 text-right h-[30px]',
                    placeholder: '?',
                  }}
                  className="w-full"
                  name="firstName"
                />
              )}
              {renderVerticalRow(
                '*Middle Name',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full  !px-2 text-right h-[30px]',
                    placeholder: '?',
                  }}
                  className="w-full"
                  name="middleName"
                />
              )}
              {renderVerticalRow(
                '*Last Name',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full  !px-2 text-right h-[30px]',
                    placeholder: '?',
                  }}
                  className="w-full"
                  name="lastName"
                />
              )}
              {renderVerticalRow(
                '*Organization',
                <Form.SelectInputV2
                  options={organizationOption}
                  name="organization"
                  text
                  className="w-full flex-1 h-[30px] !rounded-[5px]"
                />
              )}
              {renderVerticalRow(
                '*Priority',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full  !px-2 text-right h-[30px]',
                    placeholder: '?',
                  }}
                  className="w-full"
                  name="priority"
                />
              )}
              {renderVerticalRow(
                'Doc Specialist',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full  !px-2 text-right h-[30px]',
                    placeholder: '?',
                  }}
                  className="w-full"
                  name="docSpecialist"
                />
              )}
            </div>
            <div>
              {renderVerticalRow(
                '*Cell phone',
                <Form.PhoneInputV2
                  className=""
                  name="cellPhone"
                  inputProps={{
                    id: 'cellPhone',
                    placeholder: '?',
                    value: renderPhone(form.getValues('cellPhone')),
                  }}
                />
              )}
              {renderVerticalRow(
                '*Email',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full  !px-2 text-right h-[30px] ',
                    placeholder: '?',
                  }}
                  className="w-full"
                  name="email"
                />
              )}
              {renderVerticalRow(
                '*Time Zone',
                <Form.SelectInput
                  options={TIMEZONE_OPTIONS}
                  name="timeZone"
                  text
                  className="w-full flex-1 h-[30px] !rounded-[5px]"
                />
              )}
              {renderVerticalRow(
                '*Date Started',
                <Form.DatePicker
                  name="dateStarted"
                  inputProps={{ id: 'dateStarted', placeholder: '' }}
                  date={form?.watch('dateStarted')}
                  onSelect={(value: Date) => {
                    form.setValue('dateStarted', value);
                  }}
                  className="text-right h-[30px]"
                />
              )}
              {renderVerticalRow(
                'Coder',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full  !px-2 text-right h-[30px]',
                    placeholder: '?',
                  }}
                  className="w-full"
                  name="coder"
                />
              )}
            </div>
            <div>
              {renderVerticalRow(
                '*Degree',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full  !px-2 text-right h-[30px]',
                    placeholder: '?',
                  }}
                  className="w-full"
                  name="degree"
                />
              )}
              {renderVerticalRow(
                '*Specialty',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full  !px-2 text-right h-[30px]',
                    placeholder: '?',
                  }}
                  className="w-full"
                  name="specialty"
                />
              )}
              {renderVerticalRow(
                '*Service Level',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full  !px-2 text-right h-[30px]',
                    placeholder: '?',
                  }}
                  className="w-full"
                  name="serviceLevel"
                />
              )}
              {renderVerticalRow(
                '*Service Phone #',
                <Form.PhoneInputV2
                  className=""
                  name="servicePhone"
                  inputProps={{
                    id: 'servicePhone',
                    placeholder: '?',
                    value: renderPhone(form.getValues('servicePhone')),
                  }}
                />
              )}
              {renderVerticalRow(
                'Manager',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full  !px-2 text-right h-[30px]',
                    placeholder: '?',
                  }}
                  className="w-full"
                  name="manager"
                />
              )}
              {renderVerticalRow(
                '*Title',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full  !px-2 text-right h-[30px]',
                    placeholder: '?',
                  }}
                  className="w-full"
                  name="title"
                />
              )}
            </div>
          </form>
        </FormProvider>
      </Box.Standard>
      <Footer />
    </DefaultLayout>
  );
}
