import * as React from 'react';
import { ITableColumn } from '../../../types';
import { Box } from 'ui-organisms';
import { Text } from '../../../ui-molecules';
import { Dropdown } from '../../../ui-atoms';
import { SVG } from '../../../assets/imagePath';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  PAGE_SIZE_OPTIONS,
} from '../../../constant';
import { useMemo, useState } from 'react';
import cn from 'classnames';

type Props = {
  toggle: boolean;
};

const ListFilterPanel = [
  { name: 'Personal Settings' },
  { name: 'Organization Details' },
  { name: 'Service Phone' },
  { name: 'Localisation' },
  { name: 'Support Tickets' },
];

const ListPSColumn: ITableColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
];

const PSRows = {
  data: [
    {
      name: 'Setting Name',
      value: 'Organization',
    },
  ],
};

const ListSPColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'func',
    headerName: '',
    headerStyle: { textAlign: 'left' },
  },
];

const SPRows = {
  data: [
    {
      id: '1',
      value: '1234567890',
      description: 'Description',
      func: '1',
    },
  ],
};

const ListLocaColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'localization',
    headerName: 'Localization',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'abbr',
    headerName: 'Abbr.',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'func',
    headerName: '',
    headerStyle: { textAlign: 'left' },
  },
];

const LocaRows = {
  data: [
    {
      id: '1',
      value: 'Organization',
      localization: 'organization',
      abbr: 'ORG',
      func: '1',
    },
  ],
};

const ListSTColumn: ITableColumn[] = [
  {
    field: 'type',
    headerName: 'Type',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'full_name',
    headerName: 'Full Name',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'email',
    headerName: 'Email',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'phone',
    headerName: 'Phone Number',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'department',
    headerName: 'Department',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'subject',
    headerName: 'Subject',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'message',
    headerName: 'Message',
    headerStyle: { textAlign: 'left' },
  },
];

const STRows = {
  data: [
    {
      type: 'Informational',
      full_name: 'Full Name',
      email: 'Email',
      phone: 'Phone Number',
      department: 'Department',
      subject: 'Subject',
      message: 'Message',
    },
    {
      type: 'Low',
      full_name: 'Full Name',
      email: 'Email',
      phone: 'Phone Number',
      department: 'Department',
      subject: 'Subject',
      message: 'Message',
    },
    {
      type: 'Medium',
      full_name: 'Full Name',
      email: 'Email',
      phone: 'Phone Number',
      department: 'Department',
      subject: 'Subject',
      message: 'Message',
    },
    {
      type: 'High',
      full_name: 'Full Name',
      email: 'Email',
      phone: 'Phone Number',
      department: 'Department',
      subject: 'Subject',
      message: 'Message',
    },
    {
      type: 'Critical',
      full_name: 'Full Name',
      email: 'Email',
      phone: 'Phone Number',
      department: 'Department',
      subject: 'Subject',
      message: 'Message',
    },
  ],
};
export const General = ({ toggle }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LIMIT);
  const [selectPanel, setSelectPanel] = useState<string>(
    ListFilterPanel[0].name
  );

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const tableData = useMemo(() => {
    switch (true) {
      case selectPanel === ListFilterPanel[0].name:
        return {
          columns: ListPSColumn,
          rows: PSRows,
        };
      case selectPanel === ListFilterPanel[2].name:
        return {
          columns: ListSPColumn,
          rows: SPRows,
        };

      case selectPanel === ListFilterPanel[3].name:
        return {
          columns: ListLocaColumn,
          rows: LocaRows,
        };

      case selectPanel === ListFilterPanel[4].name:
        return {
          columns: ListSTColumn,
          rows: STRows,
        };

      default:
        return {
          columns: [],
          rows: [],
        };
    }
  }, [selectPanel]);

  const renderTableHead = (columns) => {
    return (
      <tr>
        {columns.map((column) => {
          return (
            !column?.hideCol && (
              <th
                key={column.field}
                className="p-table text-center border font-[600] whitespace-nowrap min-w-fit"
                style={column?.headerStyle}
              >
                <p>{column.headerName}</p>
              </th>
            )
          );
        })}
      </tr>
    );
  };

  const renderTableBody = (rows) => {
    return rows?.data?.map((row) => {
      return (
        <tr className="border border-t-0">
          {row?.id && <td className="w-fit"></td>}
          {row?.name && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.name}</p>
            </td>
          )}
          {row?.value && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.value}</p>
            </td>
          )}
          {row?.description && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.description}</p>
            </td>
          )}
          {row?.localization && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.localization}</p>
            </td>
          )}
          {row?.abbr && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.abbr}</p>
            </td>
          )}
          {row?.type && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.type}</p>
            </td>
          )}
          {row?.full_name && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.full_name}</p>
            </td>
          )}
          {row?.email && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.email}</p>
            </td>
          )}
          {row?.phone && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.phone}</p>
            </td>
          )}
          {row?.department && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.department}</p>
            </td>
          )}
          {row?.subject && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.subject}</p>
            </td>
          )}
          {row?.email && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.email}</p>
            </td>
          )}
          {row?.func && (
            <td className="w-fit text-center">
              <Dropdown.Standard
                CustomButton={() => (
                  <div className="flex gap-2.5 items-center bg-white !w-fit">
                    <img
                      src={SVG.more_horizontal}
                      className="m-auto"
                      alt="more"
                    />
                  </div>
                )}
                options={[
                  {
                    label: 'Duplicate',
                    value: 'Duplicate',
                    // onClick: () => handleDuplicateRow(row),
                  },
                  {
                    label: 'Delete',
                    value: 'Delete',
                    // onClick: () => handleDeleteRow(row),
                  },
                ]}
              />
            </td>
          )}
        </tr>
      );
    });
  };

  return (
    <div className="flex flex-col flex-1 p-2.5 gap-2.5 mb-[50px]">
      <div className="flex items-center justify-between">
        <Text>1 Records</Text>
        <Dropdown.Standard
          CustomButton={(item) => (
            <div className="flex gap-2.5 items-center bg-white hover:bg-white p-5-10 border border-nav rounded-[5px]">
              <div className="">
                <p className="text-xs">{item.label} Records per page</p>
              </div>
              <img
                className="icon-app"
                src={SVG.chevron_down}
                alt="chevron_down"
              />
            </div>
          )}
          options={PAGE_SIZE_OPTIONS}
          onChangeValue={(item: any) => {
            handleChangePageSize(item?.value);
          }}
        />
      </div>
      <div className="flex flex-1 gap-2.5">
        <Box.Standard
          className={`max-w-[180px] h-auto w-full transition-all ${
            toggle ? '' : '-translate-x-[150%] fixed'
          }`}
        >
          <div className="flex flex-col gap-y-2.5 px-2.5">
            <h3 className={'font-bold text-xs py-1'}>General</h3>
            <div className="flex flex-col text-xs gap-[5px]">
              {ListFilterPanel.map((item) => {
                return (
                  <p
                    className={cn(
                      selectPanel === item.name
                        ? 'text-[#BC9A3B] font-bold'
                        : 'hover:cursor-pointer hover:text-pink-700 hover:underline hover:font-bold'
                    )}
                    key={item.name}
                    onClick={() => setSelectPanel(item.name)}
                  >
                    {item.name}
                  </p>
                );
              })}
            </div>
          </div>
        </Box.Standard>
        <Box.Standard>
          <table className={'table-auto w-full z-0'}>
            <thead>{renderTableHead(tableData.columns)}</thead>
            <tbody>{renderTableBody(tableData.rows)}</tbody>
          </table>
        </Box.Standard>
      </div>
    </div>
  );
};
