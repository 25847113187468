import { SVG } from 'assets/imagePath';
import { Button } from 'ui-atoms';
import { ButtonIconProps } from 'ui-atoms/button/ButtonIcon';
import * as React from 'react';

export interface INextPrevProps {
  event: 'prevTime' | 'nextTime' | 'skipPrev' | 'skipNext';
  onClick?: () => void;
  buttonStyle?: ButtonIconProps;
}

export default function NextPrev({
  event,
  onClick = () => {},
  buttonStyle,
}: INextPrevProps) {
  const ic = () => {
    switch (event) {
      case 'nextTime':
        return SVG.next_time;
      case 'prevTime':
        return SVG.prev_time;
      case 'skipPrev':
        return SVG.skip_prev;
      case 'skipNext':
        return SVG.skip_next;

      default:
        return SVG.arrow_next;
    }
  };

  return (
    <Button.Icon
      variant="outlined"
      classNameIcon="w-[15px] h-[15px]"
      className="!py-[5px] !px-[12.5px]  hover:bg-grey"
      icon={ic()}
      {...buttonStyle}
      onClick={onClick}
    />
  );
}
