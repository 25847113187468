import * as React from 'react';
import { ILocationsTable, ITableColumn } from '../../../types';
import { Dropdown, GeoSuggest, Input } from '../../../ui-atoms';
import { SVG } from '../../../assets/imagePath';
import { v4 as uuidv4 } from 'uuid';
import { Suggest } from 'react-geosuggest';
import { isUndefined } from 'lodash';

type Props = {
  rows: ILocationsTable[];
  setRows: (rows: ILocationsTable[]) => void;
  updateValue: (id: number, row: ILocationsTable) => void;
  fontSize: number;
};

const LocationsColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'locationName',
    headerName: 'Location Name',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'address',
    headerName: 'Address',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'phone',
    headerName: 'Phone',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'administrator',
    headerName: 'Administrator',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'func',
    headerName: '',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
];
export const Locations = ({ rows, setRows, updateValue, fontSize }: Props) => {
  const formatStreet = (suggestion: Suggest) => {
    if (suggestion) {
      const [inputValue] = suggestion?.label?.split(',');
      return inputValue?.trim();
    }
  };
  const handleChangeValue = (field: string, value: string | Suggest, row) => {
    if (field === 'locationName') {
      return updateValue(row?.id, {
        ...row,
        locationName: value,
      });
    }
    if (field === 'email') {
      return updateValue(row?.id, {
        ...row,
        email: value,
      });
    }
    if (field === 'address') {
      return updateValue(row?.id, {
        ...row,
        address: value,
      });
    }
    if (field === 'phone') {
      return updateValue(row?.id, {
        ...row,
        phone: value,
      });
    }
    if (field === 'administrator') {
      return updateValue(row?.id, {
        ...row,
        administrator: value,
      });
    }
  };

  const onAdd = () => {
    let rowData = [];
    rowData.push(...rows, {
      id: uuidv4(),
      locationName: '',
      address: null,
      email: '',
      phone: '',
      administrator: '',
      func: '',
    });
    setRows(rowData);
  };

  const handleDuplicateRow = (rowsData) => {
    const locationNameData = rowsData?.locationName ?? rowsData?.locationName;
    const addressData = rowsData?.streetAddress ?? rowsData?.address;
    const emailData = rowsData?.locationAdmin?.email ?? rowsData?.email;
    const phoneData = rowsData?.locationAdmin?.phone ?? rowsData?.phone;
    const administratorData =
      rowsData?.locationAdmin?.firstName || rowsData?.locationAdmin?.lastName
        ? `${rowsData?.locationAdmin?.firstName ?? ''} ${
            rowsData?.locationAdmin?.lastName ?? ''
          }`
        : rowsData?.administrator;

    let rowData = [];
    rowData.push(...rows, {
      id: uuidv4(),
      locationName: locationNameData ?? '',
      address: addressData ?? '',
      email: emailData ?? '',
      phone: phoneData ?? '',
      administrator: administratorData ?? '',
      func: '',
    });
    setRows(rowData);
  };

  const handleDeleteRow = (row) => {
    const filterRows = rows.filter((item) => item.id !== row?.id);
    setRows(filterRows);
  };

  const renderRow = (row) => {
    const locationNameData = row?.locationName ?? row?.locationName;
    const addressData = row?.streetAddress ?? row?.address?.label?.trim();
    const emailData = row?.locationAdmin?.email ?? row?.email;
    const phoneData = row?.locationAdmin?.phone ?? row?.phone;
    const administratorData =
      row?.locationAdmin?.firstName || row?.locationAdmin?.lastName
        ? `${row?.locationAdmin?.firstName ?? ''} ${
            row?.locationAdmin?.lastName ?? ''
          }`
        : row?.administrator;
    return (
      <tr key={row?.id} className="border border-t-0 hover:bg-gray-300">
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          />
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-left h-[30px]"
              onChange={(e) => handleChangeValue('locationName', e, row)}
              value={locationNameData ?? ''}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <GeoSuggest
              placeholder=""
              inputClassName="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-left h-[30px]"
              onSuggestSelect={(suggestion) =>
                handleChangeValue('address', suggestion, row)
              }
              initialValue={addressData ?? ''}
              name="address"
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Email
              className="outlined-none !ring-0 !p-1 border-none w-full rounded-[0px] !px-2 text-left h-[30px]"
              onChange={(e) => handleChangeValue('email', e, row)}
              value={emailData ?? ''}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'w-full bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.PhoneV2
              className="!w-full rounded-[0px] text-left h-[30px]"
              onChange={(e) => handleChangeValue('phone', e, row)}
              value={phoneData ?? ''}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-left h-[30px]"
              onChange={(e) => handleChangeValue('administrator', e, row)}
              value={administratorData ?? ''}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Dropdown.Standard
              CustomButton={() => (
                <div className="flex gap-2.5 items-center bg-white">
                  <img
                    src={SVG.more_horizontal}
                    className="m-auto"
                    alt="more"
                  />
                </div>
              )}
              options={
                isUndefined(row.addressLine2)
                  ? [
                      {
                        label: 'Duplicate',
                        value: 'Duplicate',
                        onClick: () => handleDuplicateRow(row),
                      },
                      {
                        label: 'Delete',
                        value: 'Delete',
                        onClick: () => handleDeleteRow(row),
                      },
                    ]
                  : [
                      {
                        label: 'Delete',
                        value: 'Delete',
                        onClick: () => handleDeleteRow(row),
                      },
                    ]
              }
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <table className={'table-auto w-full h-full z-0'}>
        <thead>
          <tr>
            {LocationsColumn.map((column) => {
              return (
                !column?.hideCol && (
                  <th
                    key={column.field}
                    className="p-table text-center border font-[600] whitespace-nowrap !min-w-fit"
                    style={column?.headerStyle}
                  >
                    <p style={{ fontSize: `${fontSize}px` }}>
                      {column.headerName}
                    </p>
                  </th>
                )
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row) => {
            return renderRow(row);
          })}
          <tr
            className="border border-t-0 hover:cursor-pointer"
            onClick={onAdd}
          >
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
