import { IMAGES, SVG } from 'assets/imagePath';
import { Auth as AuthLayout, Form } from 'ui-molecules';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup';
import { IForgotPasswordFormValues } from 'types';
import { useForgotPasswordSchema } from './forgotPassword.schema';
import { Button } from 'ui-atoms';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { GlobalContext } from 'context/GlobalContext';
import { SET_LOADING_MODAL } from 'constant';
import { Auth } from 'aws-amplify';
import { RouterPath } from 'routes/router.constant';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  const { dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();

  const form = useForm<IForgotPasswordFormValues>({
    defaultValues: {
      email: '',
    },
    resolver: FormResolver(useForgotPasswordSchema()),
  });

  const onSubmit = async (values: IForgotPasswordFormValues) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });
      const response = await Auth.forgotPassword(
        values?.email.toLowerCase().trim()
      );
      if (!response) return;
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
      navigate(RouterPath.SETTINGS);
    } catch (err) {
      toast.error(err);
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
    }
  };

  return (
    <div className="flex w-full h-screen">
      <AuthLayout.Left
        image={IMAGES.side_auth_image}
        title={<AuthLayout.TitleCover />}
        subtitle="Restoring The Joy of Medicine"
      />
      <div className="flex flex-col items-center justify-center flex-1 p-5">
        <div className="flex flex-col flex-1 justify-center items-center max-w-[280px] w-full">
          <AuthLayout.Header
            icon={SVG.login_enter}
            title="Forgot Password"
            subtitle=""
          />
          <FormProvider {...form}>
            <form className="w-full">
              <Form.EmailInput
                className="w-full mt-[30px]"
                name="email"
                inputProps={{
                  id: 'email',
                  placeholder: 'E-mail',
                }}
              />

              <Button.Primary
                className="mt-[30px] py-3"
                label="Submit"
                onClick={form.handleSubmit(onSubmit)}
              />
            </form>
          </FormProvider>
          {/* <div className="flex mt-3">
          <Link
            to="/login"
            className="ml-1 font-bold transition duration-200 ease-in-out text-primary hover:text-primary hover:opacity-75 focus:text-primary"
          >
            Sign In
          </Link>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
