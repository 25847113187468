import { GridTable } from 'ui-molecules';
import { ITableColumn } from 'types';

export default function ClinicianDetailTable() {
  const AttachmentsColumn: ITableColumn[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'format',
      headerName: 'Format',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'author',
      headerName: 'Author',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'comment',
      headerName: 'Comment',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
  ];

  const AttachmentsRow = [
    {
      name: 'Attachments 1',
      format: 'PDF',
      author: 'Marcus Welby',
      comment: 'Contact sheet',
    },
    {
      name: '+',
      format: '+',
      author: '+',
      comment: '+',
    },
  ];

  const CommentsColumn: ITableColumn[] = [
    {
      field: 'date',
      headerName: 'Date',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'full_name',
      headerName: 'Full Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'comment',
      headerName: 'Comment',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
  ];

  const CommentsRow = [
    {
      date: '03/18/2023',
      full_name: 'Julie Medley',
      role: 'DS',
      comment: 'Betty MacDonald',
    },
    {
      date: '+',
      full_name: '+',
      role: '+',
      comment: '+',
    },
  ];

  return (
    <div className="flex flex-col gap-4 items-center w-full">
      <div className={'w-full'}>
        <p className={'text-xs font-bold text-center py-1'}>Attachments</p>
        <div className="!overflow-auto  w-full max-h-full z-0">
          <GridTable
            column={AttachmentsColumn}
            row={AttachmentsRow}
            checkboxes={false}
          />
        </div>
      </div>
      <div className={'w-full'}>
        <p className={'text-xs font-bold text-center py-1'}>Comments</p>
        <div className="!overflow-auto  w-full max-h-full z-0">
          <GridTable
            column={CommentsColumn}
            row={CommentsRow}
            checkboxes={false}
          />
        </div>
      </div>
    </div>
  );
}
