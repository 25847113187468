import { SVG } from 'assets/imagePath';
import { Button } from 'ui-atoms';
import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GlobalContext } from 'context/GlobalContext';
import SelectedButton from './components/SelectedButton';
import { IDropdown } from 'types';
import { Text } from '../index';
import { RouterPath } from '../../routes/router.constant';

export interface IHederEditProps {
  title: {
    options: IDropdown[];
  };
  actions: {
    options: IDropdown[];
  };
  onSave: () => void;
  saveDisabled?: boolean;
  viewName?: string;
}

export default function HeaderEdit({
  title,
  actions,
  onSave,
  saveDisabled,
  viewName,
}: IHederEditProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useContext(GlobalContext);
  const { userInfo, isHaveRecordsOrganization } = state;
  return (
    <div className="flex justify-between">
      <ul className="flex max-w-[400px] w-full items-center list-none  text-center gap-2.5">
        <li>
          <Button.Icon
            classNameIcon="!icon-app"
            icon={SVG.arrow_prev}
            variant="outlined"
            className="bg-[#f3f3f3]"
            // onClick={() => {}}
            onClick={() => navigate(-1)}
          />
        </li>
        <li className="flex">
          <div>
            <SelectedButton
              propsButton={{
                variant: 'outlined',
                color: 'secondary',
                className: 'px-[15px]',
              }}
              options={title.options}
            />
          </div>
        </li>
      </ul>
      <div className="hidden lg:flex flex-1 justify-center items-center text-xs text-black font-bold">
        <Text size="16" weight="700">
          {viewName || ''}
        </Text>
        <Button.Text
          className="border border-silver rounded-md w-fit p-1 mx-2"
          label={`${userInfo?.['custom:initials'] || ''}`}
          onClick={() => {}}
        />
      </div>

      <ul className="flex  max-w-[400px] w-full items-center justify-end list-none text-center gap-2.5">
        <li>
          <Button.Icon
            classNameIcon="!icon-app"
            icon={SVG.zoom_in}
            variant="outlined"
            className="bg-[#f3f3f3]"
            onClick={() => {}}
          />
        </li>
        <li>
          <Button.Icon
            classNameIcon="!icon-app"
            icon={SVG.zoom_out}
            variant="outlined"
            className="bg-[#f3f3f3]"
            onClick={() => {}}
          />
        </li>

        <li>
          <Button.Common onClick={onSave}>
            {!isHaveRecordsOrganization &&
            location.pathname === RouterPath.ORGANIZATION_NEW
              ? 'Invite'
              : 'Save'}
          </Button.Common>
        </li>

        <li>
          <SelectedButton
            propsButton={{
              variant: 'contained',
              color: 'whiteLight',
              className: 'px-[15px]',
              textClass: '!text-black',
            }}
            options={actions.options}
          />
        </li>
        <li>
          <Button.Icon
            className="!p-[7.5px]"
            icon={SVG.more_horizontal}
            variant="outlined"
            onClick={() => {}}
          />
        </li>
      </ul>
    </div>
  );
}
