import { SVG } from 'assets/imagePath';
import { Player } from 'ui-molecules';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Storage } from 'aws-amplify';
import useKeypress from 'react-use-keypress';

export enum Key {
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ENTER = 'Enter',
  SPACE = ' ',
  SHIFT = 'Shift',
}

const { PlayPause, Record, NextPrev, Range } = Player.Controls;

export interface IBuilderPlayerProps {
  toggle: boolean;
  mediaUrl: string;
  identityId: string;
  isFocus: boolean;
}

function BuilderPlayer({
  toggle,
  mediaUrl,
  identityId,
  isFocus,
}: IBuilderPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [volume, setVolume] = useState<number>(50);
  const [audioUrl, setAudioUrl] = useState('');
  const audioRef = useRef(null);
  const duration = audioRef.current?.getDuration();

  useKeypress(['ArrowLeft', 'ArrowRight', ' ', 'Shift'], (event) => {
    if (isFocus && event.key === Key.ARROW_LEFT) {
      handleSetTimeVal('back');
    }
    if (isFocus && event.key === Key.ARROW_RIGHT) {
      handleSetTimeVal('next');
    }
    if (isFocus && event.key === Key.SPACE) {
      setIsPlaying(!isPlaying);
    }
  });

  // useEffect(() => {
  //   const onKeyDown = (e) => {
  //     if (e.shiftKey && e.key === Key.ARROW_LEFT) {
  //       handleSetTimeVal('skipPrev');
  //     }
  //     if (e.shiftKey && e.key === Key.ARROW_RIGHT) {
  //       handleSetTimeVal('skipNext');
  //     }
  //   };
  //   const onKeyUp = (e) => {
  //     // on purpose (only one key in condition)
  //     if (e.key === Key.ARROW_LEFT) {
  //       e.preventDefault();
  //     }
  //     if (e.key === Key.ARROW_RIGHT) {
  //       e.preventDefault();
  //     }
  //   };
  //   document.addEventListener('keydown', onKeyDown);
  //   document.addEventListener('keyup', onKeyUp);
  //   return () => {
  //     document.removeEventListener('keydown', onKeyDown);
  //     document.removeEventListener('keyup', onKeyUp);
  //   };
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    const audioElem = audioRef.current
      ?.getElementsByTagName('audio')
      ?.item(0) as HTMLAudioElement;
    audioElem?.focus();
  }, []);

  const getRecord = useCallback(async () => {
    try {
      if (mediaUrl?.length && identityId?.length) {
        const signedUrl = await Storage.get(mediaUrl, {
          level: 'protected',
          identityId: identityId,
        });
        setAudioUrl(signedUrl);
      }
    } catch (err) {
      console.log('error', err);
    }
  }, [identityId, mediaUrl]);

  useEffect(() => {
    getRecord();
  }, [identityId, mediaUrl]);

  const handleSetTimeVal = (type = null, time = 1) => {
    //Seconds
    const flag = type === 'next' ? 1 : -1;
    let timeSeek = progress + flag * time;
    if (timeSeek <= 0) {
      timeSeek = 0;
    } else if (timeSeek >= duration) {
      timeSeek = duration;
    }
    setProgress(timeSeek);
    audioRef.current?.seekTo(timeSeek, 'seconds');
  };

  return (
    <>
      {audioUrl ? (
        <div
          className={`flex gap-2.5 flex-col ${
            toggle ? 'sm:flex-row' : '2xl:flex-row'
          } items-center `}
        >
          <ReactPlayer
            url={audioUrl}
            width={0}
            height={0}
            ref={audioRef}
            volume={volume / 100}
            playing={isPlaying}
            controls
            muted={!isPlaying}
            onReady={() => setIsPlaying(true)}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onProgress={(e) => setProgress(e.playedSeconds)}
            loop={false}
          />

          <div className="flex  gap-x-2.5 items-center">
            <PlayPause
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              isPlaying={isPlaying}
            />
            <Record />
            <NextPrev event="skipPrev" />
            <NextPrev
              event="prevTime"
              onClick={() => handleSetTimeVal('back')}
            />
            <NextPrev
              event="nextTime"
              onClick={() => handleSetTimeVal('next')}
            />
            <NextPrev event="skipNext" />
          </div>
          <div className="2xl:w-[2px] 2xl:h-[20px] bg-[#727272]" />
          <div className="flex flex-1 gap-x-2.5 items-center">
            <Range
              icon={SVG.sound_black}
              color="black"
              defaultValue={volume}
              setRange={(vol) => setVolume(vol)}
            />
            <Range
              icon={SVG.audio_range}
              duration={duration}
              progress={progress}
              setRange={(val: number) =>
                audioRef.current?.seekTo(val, 'seconds')
              }
            />
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
}

export default BuilderPlayer;
