import React from 'react';
import RegisterSetup from './RegisterSetup';
import RegisterSetupEXAM from './RegisterSetupEXAM';
import RegisterSetupHPI from './RegisterSetupHPI';
import RegisterSetupROS from './RegisterSetupROS';
import RegisterStep1 from './RegisterStep1';
import RegisterStep2 from './RegisterStep2';
import RegisterStep3 from './RegisterStep3';
import RegisterStep4 from './RegisterStep4';

const Register = () => {
  return <React.Fragment />;
};

Register.Step1 = RegisterStep1;

Register.Step2 = RegisterStep2;

Register.Step3 = RegisterStep3;

Register.Step4 = RegisterStep4;

Register.Setup = RegisterSetup;

Register.SetupHPI = RegisterSetupHPI;

Register.SetupROS = RegisterSetupROS;

Register.SetupEXAM = RegisterSetupEXAM;

export default Register;
