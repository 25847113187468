import * as React from 'react';
import { SVG } from 'assets/imagePath';
import { Drawer, Text } from 'ui-molecules';
import { AppPopover } from 'ui-atoms';
import { BREAKPOINT } from 'constant/breakpoints';
import { useViewport } from 'context/ViewportContext';
import { useNavigate } from 'react-router-dom';
import { IDrawerState } from 'types';
import NavBarIcon from './NavBarIcon';
import NavSearch from './NavSearch';
import NavToolsMenu from './NavToolMenu';

export interface INavToolsProps {}

const NavToolsDesktop = ({ setDrawer }) => {
  const navigate = useNavigate();
  return (
    <>
      <AppPopover
        ButtonNode={<NavBarIcon src={SVG.plus_white} />}
        Panel={() => (
          <div className="text-black flex justify-between items-center">
            <ul>
              <li className="hover:bg-pearl p-2.5 cursor-pointer">
                Organization
              </li>
              <li className="hover:bg-pearl p-2.5 cursor-pointer">Clinician</li>
              <li className="hover:bg-pearl p-2.5 cursor-pointer">
                Doc Specialist
              </li>
              <li className="hover:bg-pearl p-2.5 cursor-pointer">Coder</li>
              <li className="hover:bg-pearl p-2.5 cursor-pointer">Manager</li>
            </ul>
          </div>
        )}
        optionsPopover={{ placement: 'bottom-end' }}
        className="w-max"
      />
      <NavSearch inputProps={{ placeholder: 'Search' }} />
      <NavBarIcon
        src={SVG.user_icon}
        onClick={() =>
          setDrawer(() => ({
            Component: <NavToolsMenu />,
            open: true,
            position: 'right',
          }))
        }
      />
      <Text
        Component="button"
        size="12"
        weight="700"
        color="white"
        className={'px-[15px]'}
        onClick={() => navigate('#')}
      >
        Inquires (3)
      </Text>
    </>
  );
};
const NavToolsMobile = ({ setDrawer }) => {
  return (
    <>
      <NavSearch inputProps={{ placeholder: 'Search' }} />
      <NavBarIcon src={SVG.search} />
      <NavBarIcon
        src={SVG.menu}
        onClick={() =>
          setDrawer(() => ({
            Component: <NavToolsMenu />,
            open: true,
            position: 'right',
          }))
        }
      />
    </>
  );
};

export default function NavTools({ ...props }: INavToolsProps) {
  const { width } = useViewport();

  const [drawer, setDrawer] = React.useState<IDrawerState>({
    Component: <></>,
    position: 'left',
    open: false,
  });

  const renderNavTools = () => {
    if (width < BREAKPOINT['2xl']) {
      return <NavToolsMobile setDrawer={setDrawer} {...props} />;
    } else {
      return <NavToolsDesktop setDrawer={setDrawer} {...props} />;
    }
  };

  return (
    <>
      {renderNavTools()}
      <Drawer
        handleClose={() => setDrawer((prev) => ({ ...prev, open: false }))}
        open={drawer.open}
        position={drawer.position}
        DrawerContent={drawer.Component}
      />
    </>
  );
}
