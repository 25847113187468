import { IAppRole } from 'types';

export const APP_ROLE: IAppRole = {
  OW: 'Owner',
  SA: 'System_Admin',
  MGR: 'System_Manager',
  OA: 'Office_Assistant',
  CO: 'Medical_Coder',
  DS: 'Document_Specialist',
  CL: 'Clinician',
  ORA: 'Organization_Admin',
};

export const ALL_ROLE_DROPDOWN = [
  { value: APP_ROLE.OW, label: 'OW' },
  { value: APP_ROLE.SA, label: 'SA' },
  { value: APP_ROLE.MGR, label: 'MGR' },
  { value: APP_ROLE.OA, label: 'OA' },
  { value: APP_ROLE.CO, label: 'CO' },
  { value: APP_ROLE.DS, label: 'DS' },
  { value: APP_ROLE.CL, label: 'CL' },
];

export const STORAGE = {
  APP_AUTH: 'MEDICAL_AUTH',
};
