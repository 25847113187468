export const TIMEZONE_OPTIONS = [
  { label: 'Atlantic (AST)', value: 'Atlantic (AST)' },
  { label: 'Eastern (EST)', value: 'Eastern (EST)' },
  { label: 'Central (CST)', value: 'Central (CST)' },
  { label: 'Mountain (MST)', value: 'Mountain (MST)' },
  { label: 'Pacific (PST)', value: 'Pacific (PST)' },
  { label: 'Alaskan (AKST)', value: 'Alaskan (AKST)' },
  { label: 'Hawaii-Aleutian (HST)', value: 'Hawaii-Aleutian (HST)' },
  { label: 'Samoa (UTC-11)', value: 'Samoa (UTC-11)' },
  { label: 'Chamorro (UTC+10)', value: 'Chamorro (UTC+10)' },
];
