// @flow
import * as React from 'react';
import { ICommentsTable, ITableColumn } from '../../../types';
import { Input } from '../../../ui-atoms';

type Props = {
  rows: ICommentsTable[];
  setRows: (rows: ICommentsTable[]) => void;
  updateValue: (id: number, row: ICommentsTable) => void;
  fontSize: number;
};

const CommentsColumn: ITableColumn[] = [
  {
    field: 'date',
    headerName: 'Date',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'full_name',
    headerName: 'Full Name',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'role',
    headerName: 'Role',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'comment',
    headerName: 'Comment',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
];
export const Comments = ({ rows, setRows, updateValue, fontSize }: Props) => {
  const handleChangeValue = (field: string, value: string, row) => {
    if (field === 'date') {
      return updateValue(row.id, {
        ...row,
        date: value,
      });
    }
    if (field === 'full_name') {
      return updateValue(row.id, {
        ...row,
        full_name: value,
      });
    }
    if (field === 'role') {
      return updateValue(row.id, {
        ...row,
        role: value,
      });
    }
    if (field === 'comment') {
      return updateValue(row.id, {
        ...row,
        comment: value,
      });
    }
  };

  const onAdd = () => {
    let rowData = [];
    rowData.push(...rows, {
      id: rows.length,
      date: '',
      full_name: '',
      role: '',
      comment: '',
    });
    setRows(rowData);
  };

  const renderRow = (row) => {
    return (
      <tr key={row.id} className="border border-t-0 hover:bg-gray-300">
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-right h-[30px]"
              onChange={(e) => handleChangeValue('date', e, row)}
              value={row.date}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-right h-[30px]"
              onChange={(e) => handleChangeValue('full_name', e, row)}
              value={row.full_name}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-right h-[30px]"
              onChange={(e) => handleChangeValue('role', e, row)}
              value={row.role}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-right h-[30px]"
              onChange={(e) => handleChangeValue('comment', e, row)}
              value={row.comment}
            />
          </div>
        </td>
      </tr>
    );
  };
  return (
    <>
      <table className={'table-auto w-full h-full z-0'}>
        <thead>
          <tr>
            {CommentsColumn.map((column) => {
              return (
                !column?.hideCol && (
                  <th
                    key={column.field}
                    className="p-table text-center border font-[600] whitespace-nowrap  min-w-fit"
                    style={column?.headerStyle}
                  >
                    <p style={{ fontSize: `${fontSize}px` }}>
                      {column.headerName}
                    </p>
                  </th>
                )
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row) => {
            return renderRow(row);
          })}
          <tr
            className="border border-t-0 hover:cursor-pointer"
            onClick={onAdd}
          >
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
