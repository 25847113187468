import { DETAIL_INFO } from 'ui-molecules/detail/data';
import { DetailBox } from 'ui-molecules';

export interface INoteDocsProps {
  notesData;
}

export default function NoteDocs({ notesData }: INoteDocsProps) {
  return (
    <div className="w-full flex gap-x-[30px]">
      <div className="flex w-full flex-1 flex-col justify-between">
        <DetailBox
          title="Organization"
          detailRows={DETAIL_INFO.organization(notesData)}
        />
        <DetailBox
          title="Status DS"
          detailRows={DETAIL_INFO.status_ds(notesData)}
        />
        <DetailBox
          title="Stats DS"
          detailRows={DETAIL_INFO.stats_ds_docs(notesData)}
        />
      </div>
      <div className="flex flex-1 flex-col justify-between">
        <div className="flex w-full flex-col mb-[25px]">
          <DetailBox
            title="Clinician Messages"
            detailRows={DETAIL_INFO.clinician_message(notesData)}
          />
          <DetailBox
            title="Other Note Info"
            detailRows={DETAIL_INFO.other_note_info(notesData)}
          />
          <DetailBox
            title="Note Specifics"
            detailRows={DETAIL_INFO.note_specifics(notesData)}
          />
        </div>
        <DetailBox
          title="Clinician Specifics"
          detailRows={DETAIL_INFO.clinician_specific(notesData)}
        />
      </div>
    </div>
  );
}
