import { IMAGES } from 'assets/imagePath';
import { FC, Fragment, memo } from 'react';
import cn from 'classnames';

type Props = {
  src?: string;
  onClick?: () => void;
  text?: string;
  className?: string;
  classNameIcon?: string;
};

const NavBarAvatar: FC<Props> = ({
  src,
  onClick,
  text,
  className,
  classNameIcon,
}) => {
  return (
    <Fragment>
      <li className="h-12">
        <button
          className={cn(
            'h-full px-4 flex items-center hover:bg-input gap-[5px]',
            className
          )}
          onClick={onClick}
        >
          <img
            src={src || IMAGES.avatar}
            className={cn('icon-app', classNameIcon)}
            alt="avatar"
          />
          {text && <p className="text-sm">{text}</p>}
        </button>
      </li>
    </Fragment>
  );
};

NavBarAvatar.displayName = 'Nav.BarAvatar';

export default memo(NavBarAvatar);
