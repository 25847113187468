import RootRoutes from 'routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { LoadingModal } from 'ui-molecules';

const App = () => {
  return (
    <>
      <RootRoutes />
      <ToastContainer />
      <LoadingModal />
    </>
  );
};

export default App;
