export * from './auth';
export * from './breadcrumbs';
export * from './breakpoints';
export * from './navbar';
export * from './pagination';
export * from './timezoneOption';
export * from './organization';

export const SET_REGISTER_FORM = 'SET_REGISTER_FORM';
export const SET_LOADING_MODAL = 'SET_LOADING_MODAL';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_CONFIRM_INFO = 'SET_CONFIRM_INFO';
export const SET_IS_HAVE_RECORDS_ORGANIZATION =
  'SET_IS_HAVE_RECORDS_ORGANIZATION';
export const SET_USER_RECORD_ID = 'SET_USER_RECORD_ID';
export const SET_PRIMARY_ACCOUNT_ID = 'SET_PRIMARY_ACCOUNT_ID';
export const CLEAR_USER_RECORD_PRIMARY_ACCOUNT =
  'CLEAR_USER_RECORD_PRIMARY_ACCOUNT';
export const SET_ACTIVE_FORM = 'SET_ACTIVE_FORM';
