import {
  ActiveUserStep1,
  ActiveUserStep2,
  ActiveUserStep3,
  ActiveUserStep4,
} from 'ui-organisms/activeNewUser';
import { useState } from 'react';
import Settings from '../../settings/SettingsPage';

export const ActiveNewUserPage = () => {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const renderContent = () => {
    switch (true) {
      case step === 1: {
        return <ActiveUserStep2 nextStep={nextStep} />;
      }
      case step === 2: {
        return <ActiveUserStep1 nextStep={nextStep} />;
      }
      case step === 3: {
        return <ActiveUserStep3 nextStep={nextStep} />;
      }
      case step === 4: {
        return <ActiveUserStep4 />;
      }
      default:
        return <Settings />;
    }
  };
  return (
    <div className="flex min-h-screen">
      <div className="w-full flex-1 h-screen p-[15px]">{renderContent()}</div>
    </div>
  );
};
