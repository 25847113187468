import { ISettingBox } from '../../../types/setting.interface';
import { Settings, Text } from '../../../ui-molecules';
import { RouterPath } from '../../../routes/router.constant';
import { ITableColumn } from '../../../types';
import { useState } from 'react';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  PAGE_SIZE_OPTIONS,
} from '../../../constant';
import { Dropdown } from '../../../ui-atoms';
import { SVG } from '../../../assets/imagePath';
import { Box } from '../../../ui-organisms';
import * as React from 'react';
import cn from 'classnames';

type Props = {
  toggle: boolean;
};

const ListFilterPanel = [{ name: 'Clarify Questions' }];

const columns: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
  },
  {
    field: 'question',
    headerName: 'Question',
    headerStyle: { textAlign: 'left' },
  },

  {
    field: 'func',
    headerName: '',
  },
];

const rows = {
  data: [
    {
      id: '1',
      question: 'Question',
      func: '1',
    },
  ],
};

export const Manager = ({ toggle }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LIMIT);
  const [selectPanel, setSelectPanel] = useState<string>(
    ListFilterPanel[0].name
  );
  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const renderTableHead = (columns) => {
    return (
      <tr>
        {columns.map((column) => {
          return (
            !column?.hideCol && (
              <th
                key={column.field}
                className="p-table text-center border font-[600] whitespace-nowrap min-w-fit"
                style={column?.headerStyle}
              >
                <p>{column.headerName}</p>
              </th>
            )
          );
        })}
      </tr>
    );
  };

  const renderTableBody = (rows) => {
    return rows?.data?.map((row) => {
      return (
        <tr className="border border-t-0">
          {row?.id && <td className="w-fit"></td>}
          {row?.question && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.question}</p>
            </td>
          )}
          {row?.func && (
            <td className="w-fit text-center">
              <Dropdown.Standard
                CustomButton={() => (
                  <div className="flex gap-2.5 items-center bg-white !w-fit">
                    <img
                      src={SVG.more_horizontal}
                      className="m-auto"
                      alt="more"
                    />
                  </div>
                )}
                options={[
                  {
                    label: 'Duplicate',
                    value: 'Duplicate',
                    // onClick: () => handleDuplicateRow(row),
                  },
                  {
                    label: 'Delete',
                    value: 'Delete',
                    // onClick: () => handleDeleteRow(row),
                  },
                ]}
              />
            </td>
          )}
        </tr>
      );
    });
  };

  return (
    <div className="flex flex-col flex-1 p-2.5 gap-2.5 mb-[50px]">
      <div className="flex items-center justify-between">
        <Text>1 Records</Text>
        <Dropdown.Standard
          CustomButton={(item) => (
            <div className="flex gap-2.5 items-center bg-white hover:bg-white p-5-10 border border-nav rounded-[5px]">
              <div className="">
                <p className="text-xs">{item.label} Records per page</p>
              </div>
              <img
                className="icon-app"
                src={SVG.chevron_down}
                alt="chevron_down"
              />
            </div>
          )}
          options={PAGE_SIZE_OPTIONS}
          onChangeValue={(item: any) => {
            handleChangePageSize(item?.value);
          }}
        />
      </div>
      <div className="flex flex-1 gap-2.5">
        <Box.Standard
          className={`max-w-[180px] h-auto w-full transition-all ${
            toggle ? '' : '-translate-x-[150%] fixed'
          }`}
        >
          <div className="flex flex-col gap-y-2.5 px-2.5">
            <h3 className={'font-bold text-xs py-1'}>Manager</h3>
            <div className="flex flex-col text-xs gap-[5px]">
              {ListFilterPanel.map((item) => {
                return (
                  <p
                    className={cn(
                      selectPanel === item.name
                        ? 'text-[#BC9A3B] font-bold'
                        : 'hover:cursor-pointer hover:text-pink-700 hover:underline hover:font-bold'
                    )}
                    key={item.name}
                    onClick={() => setSelectPanel(item.name)}
                  >
                    {item.name}
                  </p>
                );
              })}
            </div>
          </div>
        </Box.Standard>
        <Box.Standard>
          <table className={'table-auto w-full z-0'}>
            <thead>{renderTableHead(columns)}</thead>
            <tbody>{renderTableBody(rows)}</tbody>
          </table>
        </Box.Standard>
      </div>
    </div>
  );
};
