import * as React from 'react';
import {
  PaginationItemArrow,
  PaginationItemJumper,
  PaginationItemPage,
} from '../ui-molecules/pagination-item';

type UseRenderPaginationItemReturn = {
  itemRender: (
    current: number,
    type: string,
    element: React.ReactNode
  ) => React.ReactNode;
};
export const PAGE_PREV = 'prev';
export const PAGE_NEXT = 'next';
export const PAGE_JUMP_PREV = 'jump-prev';
export const PAGE_JUMP_NEXT = 'jump-next';

export function usePaginationItemRender(
  isFirstPage: boolean,
  isLastPage: boolean
): UseRenderPaginationItemReturn {
  const itemRender = React.useCallback(
    (
      current: number,
      type: string,
      element: React.ReactNode
    ): React.ReactNode => {
      if (type === 'page') {
        return <PaginationItemPage page={current} id={'page-items'} />;
      }
      if (type === PAGE_NEXT || type === PAGE_PREV) {
        return (
          <PaginationItemArrow
            type={type}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
          />
        );
      }
      if (type === PAGE_JUMP_PREV || type === PAGE_JUMP_NEXT) {
        return <PaginationItemJumper />;
      }
      return element;
    },
    [isLastPage, isFirstPage]
  );
  return { itemRender };
}
