import { Button } from 'ui-atoms';
import * as React from 'react';
import { useEffect } from 'react';

export interface IAcceptStepProps {
  step: number;
  onClick: {
    onAccept: (step) => void;
    onDecline: () => void;
  };
}

export default function AcceptStep({ step, onClick }: IAcceptStepProps) {
  const { onAccept, onDecline } = onClick;

  useEffect(() => {}, []);

  return (
    <div className="max-w-[280px] w-full h-auto">
      <div className="w-full h-full flex flex-col  gap-[30px]">
        <h2 className="text-center text-xl font-bold">Accept Invitation</h2>
        <div className="w-full h-auto grid  grid-flow-row">
          <div className="h-[30px] flex flex-row items-center">
            <div className="text-xs flex-1 p-[5px]">Organization</div>
            <div className="text-xs font-bold text-right p-[5px]">
              Welby Clinic
            </div>
          </div>
          <div className="h-[30px] flex flex-row items-center">
            <div className="text-xs flex-1 p-[5px]">Manager</div>
            <div className="text-xs font-bold text-right p-[5px]">
              Consuela Diaz
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-5">
          <Button.White
            color="white"
            className="flex-1"
            label="Decline"
            onClick={onDecline}
          />

          <Button.Primary
            className="flex-1"
            label={'Accept'}
            onClick={() => onAccept(step + 1)}
          />
        </div>
      </div>
    </div>
  );
}
