import * as React from 'react';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import { Form } from 'ui-molecules';
import { useActiveStep2Schema } from '../../pages/auth/activeNewUser/active.schema';
import { Button } from '../../ui-atoms';
import { ActiveNewUserStep2 } from 'types';
import { GlobalContext } from '../../context/GlobalContext';
import { SET_ACTIVE_FORM, SET_LOADING_MODAL } from '../../constant';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { SVG } from '../../assets/imagePath';

type Props = {
  nextStep: () => void;
};
export const ActiveUserStep2 = ({ nextStep }: Props) => {
  const { dispatch } = useContext(GlobalContext);
  const { email } = useParams();

  const navigate = useNavigate();

  const form = useForm<ActiveNewUserStep2>({
    defaultValues: {
      confirmCode: '',
    },
    mode: 'onChange',
    resolver: FormResolver(useActiveStep2Schema()),
  });

  const resendCode = async () => {
    await Auth.resendSignUp(email)
      .then()
      .catch((err) => {
        console.log(err);
        toast(err.message, {
          hideProgressBar: true,
          autoClose: 3000,
          type: 'error',
          theme: 'colored',
        });
      });
  };

  const onSubmit = async (values: ActiveNewUserStep2) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });
      dispatch({
        type: SET_ACTIVE_FORM,
        payload: {
          email: email?.trim(),
        },
      });
      const confirmRes = await Auth.confirmSignUp(
        email?.trim(),
        values?.confirmCode?.trim()
      );
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
      nextStep();
    } catch (err) {
      console.log(err);
      toast(err.message, {
        hideProgressBar: true,
        autoClose: 3000,
        type: 'error',
        theme: 'colored',
      });
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
      navigate('/login');
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col gap-y-5 items-center justify-center">
      <img src={SVG.avatar_circle} width={110} alt="logo" className="mb-10" />
      <h1 className="text-xl font-bold">Verify Account</h1>
      <p className="flex items-baseline text-center text-[#1D2533]">
        Please check your email for the <br />
        code or verification link.
      </p>

      <FormProvider {...form}>
        <form className="flex flex-col gap-y-5">
          <Form.TextInput
            className="w-full border rounded-[5px]"
            name="confirmCode"
            inputProps={{
              id: 'confirmCode',
              placeholder: 'Confirmation Code',
            }}
          />

          <Button.Primary
            className="py-[12px]"
            label="Accept"
            onClick={form.handleSubmit(onSubmit)}
          />

          <Button.Text underline label="Resend Code" onClick={resendCode} />
        </form>
      </FormProvider>
    </div>
  );
};
