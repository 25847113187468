import React from 'react';
import DocAssistantDetail from './DocAssistantDetail';
import DocAssistantSidebar from './DocAssistantSidebar';
import DocAssistantSummary from './DocAssistantSummary';

const DocAssistant = () => {
  return <React.Fragment />;
};

DocAssistant.Summary = DocAssistantSummary;
DocAssistant.SideBar = DocAssistantSidebar;
DocAssistant.Detail = DocAssistantDetail;
export default DocAssistant;
