import { CLINICIAN_DETAILS } from 'ui-molecules/detail/data';
import { DetailBox } from 'ui-molecules';
import { memo, useContext, useEffect } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { useApiCall } from '../../hooks';
import { getOrganizationAPI } from '../../services';
import { SET_LOADING_MODAL } from '../../constant';
import { useNavigate } from 'react-router-dom';

const ClinicianDetail = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { userRecordId: clinicianId, primaryAccountId: organizationId } = state;
  const navigate = useNavigate();

  const [fetchOrg, loading, orgData] = useApiCall(getOrganizationAPI);
  useEffect(() => {
    if (!clinicianId || !organizationId) return navigate('/');
    fetchOrg({
      userRecordId: clinicianId,
      organizationId: organizationId,
    });
  }, [fetchOrg, clinicianId, organizationId]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading,
    });
  }, [loading]);

  return (
    <div className="grid h-full grid-cols-2 gap-x-2.5">
      <div className="col-span-1 h-full flex flex-col justify-between">
        <DetailBox
          title="Name Info"
          detailRows={CLINICIAN_DETAILS.name_info(orgData)}
        />
        <DetailBox
          title="Education"
          detailRows={CLINICIAN_DETAILS.education(orgData)}
        />
        <DetailBox
          title="Identification"
          detailRows={CLINICIAN_DETAILS.identification(orgData)}
        />
      </div>
      <div className="col-span-1 h-full flex flex-col justify-between gap-y-2.5">
        <DetailBox
          title="Key Info"
          detailRows={CLINICIAN_DETAILS.key_info(orgData)}
        />
        <DetailBox
          title="Organization"
          detailRows={CLINICIAN_DETAILS.organization(orgData)}
        />
        <DetailBox
          title="Preferences"
          detailRows={CLINICIAN_DETAILS.preferences(orgData)}
        />
      </div>
    </div>
  );
};

export default memo(ClinicianDetail);
