import * as React from 'react';
import { Form } from 'ui-molecules';
import { FormProvider, useForm } from 'react-hook-form';
import { IOrganizationEditFormValues } from '../../types';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import { useOrganizationEditSchema } from '../../pages/organization/organization-edit.schema';

export default function OrganizationSummary() {
  const form = useForm<IOrganizationEditFormValues>({
    resolver: FormResolver(useOrganizationEditSchema()),
    reValidateMode: 'onChange',
  });

  const renderVerticalRow = (headerName: string, cell: any) => {
    return (
      <div className="flex flex-col gap-y-4 py-4">
        <div className="flex items-center">
          <div className="title flex-1 px-2.5 justify-end">
            <p className="text-xs font-bold text-right">{headerName}</p>
          </div>
          <div className="title flex-1 flex items-center px-2.5 justify-end">
            {cell}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <FormProvider {...form}>
        <form className="grid grid-cols-2 gap-[10px] mt-4">
          {renderVerticalRow(
            'Organization',
            <Form.TextInput
              name="organization"
              className="flex-1 bg-white"
              inputProps={{
                placeholder: '',
                value: form.getValues('organization'),
                className:
                  'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
              }}
            />
          )}
          <div className="flex flex-col">
            {renderVerticalRow(
              'Important',
              <Form.TextInput
                name="important"
                className="flex-1 bg-white"
                inputProps={{
                  placeholder: '',
                  value: form.getValues('important'),
                  className:
                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold !text-error',
                }}
              />
            )}
            {renderVerticalRow(
              'Organization Time Zone',
              <Form.TextInput
                name="organizationTimeZone"
                className="flex-1 bg-white"
                inputProps={{
                  placeholder: '',
                  value: form.getValues('organizationTimeZone'),
                  className:
                    'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !text-error',
                }}
              />
            )}
          </div>
        </form>
      </FormProvider>
    </>
  );
}
