import { IMAGES } from 'assets/imagePath';
import { Text } from 'ui-molecules';
import * as React from 'react';
import { Button } from '../../ui-atoms';

export interface FooterProps {}

export default function Footer(props: FooterProps) {
  const [isShowPopup, setIsShowPopup] = React.useState(false);
  const handleOpenPopup = () => {
    setIsShowPopup(true);
  };

  const renderPolicyPopup = () => {
    return (
      <div className="w-full h-full fixed top-0 left-0 bg-[#828282] bg-opacity-50 z-50 flex items-center justify-center overflow-hidden">
        <div className="m-auto w-[80%] h-[80%] overflow-y-auto rounded-lg bg-white p-4 flex flex-col gap-4">
          <p># Privacy Policy</p>
          <p>Last updated: November 22, 2023</p>
          <p>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
          <p>Interpretation and Definitions</p>
          <p>Interpretation</p>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <p>Definitions</p>
          <p>For the purposes of this Privacy Policy:</p>
          <p>
            1. Account means a unique account created for You to access our
            Service or parts of our Service.
          </p>
          <p>
            2. Company (referred to as either "the Company", "We", "Us" or "Our"
            in this Agreement) refers to International Council for Quality Care,
            Inc. (ICQC).
          </p>
          <p>
            3. Cookies are small files that are placed on Your computer, mobile
            device or any other device by a website, containing the details of
            Your browsing history on that website among its many uses.
          </p>
          <p>4. Country refers to: United States of America</p>
          <p>
            5. Device means any device that can access the Service such as a
            computer, a cellphone or a digital tablet.
          </p>
          <p>
            6. Personal Data is any information that relates to an identified or
            identifiable individual.
          </p>
          <p>7. Service refers to the Website.</p>
          <p>
            8. Service Provider means any natural or legal person who processes
            the data on behalf of the Company. It refers to third-party
            companies or individuals employed by the Company to facilitate the
            Service, to provide the Service on behalf of the Company, to perform
            services related to the Service or to assist the Company in
            analyzing how the Service is used.
          </p>
          <p>
            9. Usage Data refers to data collected automatically, either
            generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </p>
          <p>
            10. Website refers to ICQC, accessible from
            [https://icqc.ai](https://icqc.ai/)
          </p>
          <p>
            11. You means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </p>
          <p>Collecting and Using Your Personal Data</p>
          <p>Types of Data Collected</p>
          <p>Personal Data</p>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <p>- Email address</p>
          <p>- First name and last name</p>
          <p>- Phone number</p>
          <p>- Usage Data</p>
          <p>Usage Data</p>
          <p>
            1. Usage Data is collected automatically when using the Service.
          </p>
          <p>
            2. Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p>
            3. When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p>
            4. We may also collect information that Your browser sends whenever
            You visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <p>Tracking Technologies and Cookies</p>
          <p>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </p>
          <p>
            - Cookies or Browser Cookies. A cookie is a small file placed on
            Your Device. You can instruct Your browser to refuse all Cookies or
            to indicate when a Cookie is being sent. However, if You do not
            accept Cookies, You may not be able to use some parts of our
            Service. Unless you have adjusted Your browser setting so that it
            will refuse Cookies, our Service may use Cookies.
          </p>
          <p>
            - Flash Cookies. Certain features of our Service may use local
            stored objects (or Flash Cookies) to collect and store information
            about Your preferences or Your activity on our Service. Flash
            Cookies are not managed by the same browser settings as those used
            for Browser Cookies.
          </p>
          <p>
            - Web Beacons. Certain sections of our Service and our emails may
            contain small electronic files known as web beacons (also referred
            to as clear gifs, pixel tags, and single-pixel gifs) that permit the
            Company, for example, to count users who have visited those pages or
            opened an email and for other related website statistics (for
            example, recording the popularity of a certain section and verifying
            system and server integrity).
          </p>
          <p>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser.
          </p>
          <p>
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <p>
            - Necessary / Essential Cookies Type: Session Cookies Administered
            by: Us Purpose: These Cookies are essential to provide You with
            services available through the Website and to enable You to use some
            of its features. They help to authenticate users and prevent
            fraudulent use of user accounts. Without these Cookies, the services
            that You have asked for cannot be provided, and We only use these
            Cookies to provide You with those services.
          </p>
          <p>
            - Cookies Policy / Notice Acceptance Cookies Type: Persistent
            Cookies Administered by: Us Purpose: These Cookies identify if users
            have accepted the use of cookies on the Website.
          </p>
          <p>
            - Functionality Cookies Type: Persistent Cookies Administered by: Us
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </p>
          <p>
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </p>
          <p>Use of Your Personal Data</p>
          <p>The Company may use Personal Data for the following purposes:</p>
          <p>
            1. To provide and maintain our Service, including to monitor the
            usage of our Service.
          </p>
          <p>
            2. To manage Your Account: to manage Your registration as a user of
            the Service. The Personal Data You provide can give You access to
            different functionalities of the Service that are available to You
            as a registered user.
          </p>
          <p>
            3. For the performance of a contract: the development, compliance
            and undertaking of the purchase contract for the products, items or
            services You have purchased or of any other contract with Us through
            the Service.
          </p>
          <p>
            4. To contact You: To contact You by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application's push notifications regarding updates or informative
            communications related to the functionalities, products or
            contracted services, including the security updates, when necessary
            or reasonable for their implementation.
          </p>
          <p>
            5. To provide You with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless You have opted not to receive such information.
          </p>
          <p>
            6. To manage Your requests: To attend and manage Your requests to
            Us.
          </p>
          <p>
            7. For business transfers: We may use Your information to evaluate
            or conduct a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which Personal Data held by Us about our
            Service users is among the assets transferred.
          </p>
          <p>
            8. For other purposes: We may use Your information for other
            purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
          </p>
          <p>
            We may share Your personal information in the following situations:
          </p>
          <p>
            1. With Service Providers: We may share Your personal information
            with Service Providers to monitor and analyze the use of our
            Service, to contact You.
          </p>
          <p>
            2. For business transfers: We may share or transfer Your personal
            information in connection with, or during negotiations of, any
            merger, sale of Company assets, financing, or acquisition of all or
            a portion of Our business to another company.
          </p>
          <p>
            3. With Affiliates: We may share Your information with Our
            affiliates, in which case we will require those affiliates to honor
            this Privacy Policy. Affiliates include Our parent company and any
            other subsidiaries, joint venture partners or other companies that
            We control or that are under common control with Us.
          </p>
          <p>
            4. With business partners: We may share Your information with Our
            business partners to offer You certain products, services or
            promotions.
          </p>
          <p>
            5. With other users: when You share personal information or
            otherwise interact in the public areas with other users, such
            information may be viewed by all users and may be publicly
            distributed outside.
          </p>
          <p>
            6. With Your consent: We may disclose Your personal information for
            any other purpose with Your consent.
          </p>
          <p>Retention of Your Personal Data</p>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
          <p>Transfer of Your Personal Data</p>
          <p>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
          <p>Disclosure of Your Personal Data</p>
          <p>Business Transactions</p>
          <p>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <p>Law enforcement</p>
          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <p>Other legal requirements</p>
          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <p>- Comply with a legal obligation</p>
          <p>- Protect and defend the rights or property of the Company</p>
          <p>
            - Prevent or investigate possible wrongdoing in connection with the
            Service
          </p>
          <p>
            - Protect the personal safety of Users of the Service or the public
          </p>
          <p>- Protect against legal liability</p>
          <p>Security of Your Personal Data</p>
          <p>
            The security of Your Personal Data is important to Us but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security. ICQC:
          </p>
          <p>
            - maintains SOC 2 and HIPAA compliance programs that validate the
            security controls.
          </p>
          <p>
            - implements security practices and monitoring procedures to ensure
            the security of data.
          </p>
          <p>
            - protects the security of your data by implementing encryption
            protocols to safeguard it during storage (at rest) and transmission.
          </p>
          <p>Children's Privacy</p>
          <p>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
          <p>Links to Other Websites</p>
          <p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <p>Changes to this Privacy Policy</p>
          <p>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <p>
            Our Use of Your Personal Data and Other Information: The Company
            uses the Personal Data you provide in a manner that is consistent
            with this Privacy Policy. If you provide Personal Data for a certain
            reason, we may use the Personal Data in connection with the reason
            for which it was provided. For instance, if you contact us by email,
            we will use the Personal Data you provide to answer your question or
            resolve your problem. Also, if you provide Personal Data in order to
            obtain access to the Services, we will use your Personal Data to
            provide you with access to such services and to monitor your use of
            such services. The Company and its subsidiaries and affiliates (the
            "Related Companies") may also use your Personal Data and other
            personally non-identifiable information collected through the
            Services to help us improve the content and functionality of the
            Services, to better understand our users and to improve the
            Services. Specifically, the Company uses Personal Data to:\*
            Authenticate access to the Account and provide access to the
            Services;\* Provide, operate, maintain and improve the Services;\*
            Send technical notices, updates, security alerts and support and
            administrative messages;\* Provide and deliver the Services and
            features you request, process and complete transactions, and send
            you related information, including confirmations and invoices;\*
            Respond to comments, questions, and requests and provide customer
            service and support;\* Communicate with you about services,
            features, surveys, newsletters, offers, promotions and events, and
            provide other news or information about us and our select
            partners;\* Investigate and prevent fraudulent transactions,
            unauthorized access to the Services, and other illegal activities;\*
            Personalize and improve the Services, and provide content, features,
            and/or advertisements that match your interests and preferences or
            otherwise customize your experience on the Services;\* Monitor and
            analyze trends, usage, and activities in connection with the
            Services and for marketing or advertising purposes; and\* For other
            purposes about which we will notify you and seek your consent, or
            which may be reflected in our services agreement with you. The
            Company and its affiliates may use this information to contact you
            in the future to tell you about services we believe will be of
            interest to you. If we do so, each marketing communication we send
            you will contain instructions permitting you to "opt-out" of
            receiving future marketing communications. In addition, if at any
            time you wish not to receive any future marketing communications or
            you wish to have your name deleted from our mailing lists, please
            contact us as indicated below. If the Company intends on using any
            Personal Data in any manner that is not consistent with this Privacy
            Policy, you will be informed of such anticipated use prior to or at
            the time at which the Personal Data is collected.
          </p>
          <p>
            Our Disclosure of Your Personal Data and Other Information: The
            Company is not in the business of selling your information. We
            consider this information to be a vital part of our relationship
            with you. There are, however, certain circumstances in which we may
            share your Personal Data with certain third parties without further
            notice to you, as set forth below:
          </p>
          <p>
            Processing of Medical Practice Data: As a service provider to our
            Practice Users we collect and analyze data related to our medical
            practice customers on their behalf. In this role, the Company is
            processing data upon instruction from such Customer, consistent with
            our service agreement and Business Associate Agreement with such
            Customer.
          </p>
          <p>
            Some of the Personal Data received by the Company in connection with
            the Services may be provided by health care providers that are
            subject to laws and regulations, such as rules issued under the
            Health Insurance Portability and Accountability Act of 1996 (HIPAA)
            and Health Information Technology for Economic and Clinical Health
            Act of 2009 (HITECH), that govern providers' use and disclosure of
            certain individually identifiable health-related Personal Data
            ("Protected Health Information"). When we receive Protected Health
            Information, we may do so as a "Business Associate" of our health
            care provider customers under an agreement that, among other things,
            prohibits us from using or disclosing the Protected Health
            Information in ways that are not permissible by the health care
            provider itself, and requires us to implement certain measures to
            safeguard the confidentiality, integrity, and availability of the
            Protected Health Information. When we act as a Business Associate,
            we may be subject to certain laws and regulations, including certain
            HIPAA rules that govern our use and disclosure of Protected Health
            Information and that may be more restrictive than otherwise provided
            in this Privacy Policy.
          </p>
          <p>
            Service Providers and Business Partners: We may from time to time
            employ third parties to perform tasks on our behalf and we may need
            to share Personal Data with them to provide certain services. Unless
            we tell you differently, such third parties do not have any right to
            use the Personal Data we share with them beyond what is necessary
            for them to provide the tasks and services on our behalf. We
            currently engage third party companies and individuals employed by
            us to facilitate our Services, including the provision of
            maintenance services, database management, Web analytics and general
            improvement of the Services, and businesses who engage our Services
            (to the extent provided for above).
          </p>
          <p>
            Business Transfers: As we develop our business, we might sell or buy
            businesses or assets. In the event of a corporate sale, merger,
            reorganization, dissolution or similar event, Personal Data may be
            part of the transferred assets.
          </p>
          <p>
            Related Companies: We may also share your Personal Data with our
            Related Companies for purposes consistent with this Privacy Policy.
          </p>
          <p>
            Agents, Consultants and Related Third Parties: The Company, like
            many businesses, sometimes hires other companies to perform certain
            business-related functions. Examples of such functions include
            mailing information, maintaining databases and processing payments.
            When we employ another entity to perform a function of this nature,
            we only provide them with the information that they need to perform
            their specific function.
          </p>
          <p>
            Legal Requirements: The Company may disclose your Personal Data if
            required to do so by law or in the good faith belief that such
            action is necessary to (i) comply with a legal obligation, (ii)
            protect and defend the rights or property of the Company, (iii) act
            in urgent circumstances to protect the personal safety of users of
            the Services or the public, or (iv) protect against legal liability.
            The above may include exchanging information with other companies
            and organizations for fraud protection and spam/malware prevention.
          </p>
          <p>Contact Us</p>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <p>- By email: [admin@icqc.ai](mailto:admin@icqc.ai)</p>
          <div>
            <Button.Primary
              className=""
              label="Accept Terms"
              onClick={() => setIsShowPopup(false)}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <footer className="w-full bg-black py-[60px] px-2.5">
        <div className="w-full pb-[30px] flex items-center gap-x-4 justify-center text-center">
          <p
            className="w-fit text-white hover:text-green-300 hover:cursor-pointer hover:underline text-lg"
            onClick={handleOpenPopup}
          >
            Privacy Policy
          </p>
          <a
            href="https://github.com/InternationalCouncilForQualityCare/external-docs"
            className="w-fit text-white hover:text-green-300 hover:cursor-pointer hover:underline text-lg"
            target="_blank"
          >
            Documentation
          </a>
        </div>
        <div className="max-w-[1280px] mx-auto w-full grid grid-flow-row gap-[30px] md:gap-[60px]">
          <div className="w-full grid grid-flow-row md:grid-cols-[1fr_1.5fr_1fr] gap-[30px] md:gap-[84px] items-center justify-items-center">
            <img
              src={IMAGES.landing_logo_light}
              className="max-w-[320px] md:max-w-[240px] w-full h-auto"
            />
            <Text className="text-center" color="white" size="14" weight="300">
              *We gathered data from actual users to reflect their experiences
              and opinions, but we cannot guarantee that all users will achieve
              similar results. Individual factors may affect outcomes. Our
              statements do not constitute an offer or contract.
            </Text>
          </div>
          <div className="max-w-[640px] mx-auto flex flex-col sm:flex-row items-center w-full gap-[15px]">
            <img
              src={IMAGES.app_store}
              className="max-w-[135px] w-full h-auto"
            />
            <Text
              className="text-center sm:text-start"
              color="white"
              size="14"
              weight="300"
            >
              Apple logo, iPhone 13, MacBook Air and App Store are trademarks of
              Apple Inc., registered in the U.S. and other countries.
            </Text>
          </div>
          <Text className="text-center" color="white" size="14" weight="300">
            Copyright ©
            <Text Component={'span'} color="white" size="14" weight="700">
              Coded<span className="text-primary">Note</span>®
            </Text>
            2023
          </Text>
        </div>
        {isShowPopup && renderPolicyPopup()}
      </footer>
    </>
  );
}
