import { Footer, Header, Support } from '../../../ui-organisms/landing';

export default function SupportPage() {
  return (
    <section className="flex flex-col">
      <Header />
      <div className="flex-1 h-full max-w-[1300px] w-full px-2.5 mx-auto">
        <Support />
      </div>
      <Footer />
    </section>
  );
}
