import { SVG } from 'assets/imagePath';
import { Button } from 'ui-atoms';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'ui-molecules';
import { RouterPath } from '../../routes/router.constant';

const RegisterStep4: FC = () => {
  const navigate = useNavigate();

  const onConfirm = () => {
    navigate(RouterPath.NOTES);
  };

  return (
    <div className="max-w-[280px] w-full h-full  mx-auto flex flex-col justify-center items-center ">
      <Auth.Header
        icon={SVG.register_smart_texts}
        title="Setup CodedNote!"
        subtitle=""
      />
      <div className="w-full flex items-center mt-5">
        <Button.Primary label="Confirm" onClick={onConfirm} />
      </div>
    </div>
  );
};

export default memo(RegisterStep4);
