import { NAV_LINKS } from 'constant/navbar';
import { useViewport } from 'context/ViewportContext';
import { FC, Fragment, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBarLink from './NavBarLink';
import NavBarMore from './NavBarMore';
import NavNavigation from './NavNavigation';
import NavTools from './NavTools';

type Props = {
  className?: string;
};

const NavBar: FC<Props> = ({ className }) => {
  const navigate = useNavigate();

  const { width } = useViewport();
  return (
    <Fragment>
      <nav className="bg-label h-12 absolute top-0 left-0 right-0 z-50">
        <div className="flex justify-between items-center gap-[15px]">
          <ul className="flex items-center list-none text-center">
            <NavNavigation />
          </ul>
          <ul className="flex items-center list-none text-center flex-1 justify-end">
            <NavTools />
          </ul>
        </div>
      </nav>
    </Fragment>
  );
};

NavBar.displayName = 'Nav.Bar';

export default memo(NavBar);
