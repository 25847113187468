import * as React from 'react';
import { useMemo, useState } from 'react';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  PAGE_SIZE_OPTIONS,
} from '../../../constant';
import { Text } from '../../../ui-molecules';
import { Dropdown } from '../../../ui-atoms';
import { SVG } from '../../../assets/imagePath';
import { Box } from '../../../ui-organisms';
import { ITableColumn } from '../../../types';
import cn from 'classnames';

type Props = {
  toggle: boolean;
};

const ListFilterPanel = [
  { name: 'Degrees' },
  { name: 'Other Degrees' },
  { name: 'Specialties' },
  { name: 'Fellowships' },
  { name: 'Rankings' },
  { name: 'Clinician Record Status' },
  { name: 'Clin Service Levels' },
  { name: 'Priority' },
  { name: 'Predictability' },
];

const ListDegreesColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'func',
    headerName: '',
  },
];

const DegreesRows = {
  data: [
    {
      id: '1',
      value: 'MD',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'DO',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'DPM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'DPT',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'APRN',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'ACP',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'PA-C',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'OD',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'PHD',
      description: 'Description',
      func: '1',
    },
  ],
};

const ListODegreesColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'func',
    headerName: '',
  },
];

const ODegreesRows = {
  data: [
    {
      id: '1',
      value: 'PHD',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'MBA',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'MPH',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'Pharmd',
      description: 'Description',
      func: '1',
    },
  ],
};

const ListSpecialtiesColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
  },
  {
    field: 'abbr',
    headerName: 'Abbreviation',
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'department',
    headerName: 'Department',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'func',
    headerName: '',
  },
];

const SpecialtiesRows = {
  data: [
    {
      id: '1',
      abbr: 'FP',
      value: 'Family Medicine',
      department: 'PC',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'FPS',
      value: 'FP Sports',
      department: 'PC',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'FPA',
      value: 'FP Addiction',
      department: 'PC',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'IM',
      value: 'Internal Medicine',
      department: 'PC',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'IMP',
      value: 'IM Pediatrics',
      department: 'PC',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'OB/GYN',
      value: 'OB/GYN',
      department: 'PC',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Pain',
      value: 'Pain',
      department: 'PC',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'FP',
      value: 'Pediatrics',
      department: 'PC',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'FP',
      value: 'Pediatrics',
      department: 'PC',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'PM&R',
      value: 'Rehabilitation',
      department: 'PC',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Urgent Care',
      value: 'Urgent Care',
      department: 'PC',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Allergy',
      value: 'Allergy',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Cardio',
      value: 'Cardio',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Derm',
      value: 'Dermatology',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Endo',
      value: 'Endocrinology',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'GI',
      value: 'Gastroenterology',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Geriatrics',
      value: 'Geriatrics',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Hem/Onc',
      value: 'Hematology Oncology',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Immune',
      value: 'Immunologist',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'ID',
      value: 'Infectious Disease',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Medical - Genetics',
      value: 'Medical - Genetics',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Neph',
      value: 'Nephrology',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Neuro',
      value: 'Neurology',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Onc',
      value: 'Oncology',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Palli',
      value: 'Palliative Care',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Pulm',
      value: 'Pulmonology',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Rheum',
      value: 'Rheumatology',
      department: 'IM',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Colo',
      value: 'Colon Rectal',
      department: 'Sx',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Gen',
      value: 'General',
      department: 'Sx',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Orthop',
      value: 'Orthopedic',
      department: 'Sx',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'ENT',
      value: 'Otolaryngology',
      department: 'Sx',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'OPH',
      value: 'Ophthalmology',
      department: 'Sx',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Uro',
      value: 'Urology',
      department: 'Sx',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Vasc',
      value: 'Vascular',
      department: 'Sx',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Plastic',
      value: 'Plastic',
      department: 'Sx',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Pod',
      value: 'Podiatry',
      department: 'Sx',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Anesthesiology',
      value: 'Anesthesiology',
      department: 'FAH',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Emergency Medicine',
      value: 'Emergency Medicine',
      department: 'FAH',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'ICU',
      value: 'Intensivist',
      department: 'FAH',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Hosp',
      value: 'Hospitalist',
      department: 'FAH',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Path',
      value: 'Pathology',
      department: 'FAH',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Radiologist',
      value: 'Radiologist',
      department: 'FAH',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Rad Onc',
      value: 'Radiation Oncology',
      department: 'FAH',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'PM&R',
      value: 'Rehabilitation',
      department: 'FAH',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Physical Therapy',
      value: 'Physical Therapy',
      department: 'Oth',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Audiology',
      value: 'Audiology',
      department: 'Oth',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'OCC 65',
      value: 'Occupational Health',
      department: 'Oth',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      abbr: 'Psychiatry',
      value: 'Psychiatry',
      department: 'Oth',
      description: 'Description',
      func: '1',
    },
  ],
};

const ListFellowshipsColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
  },
  {
    field: 'abbr',
    headerName: 'Abbr.',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'func',
    headerName: '',
  },
];

const Fellowships = {
  data: [
    { id: '1', abbr: 'SM', description: 'Sports Med', func: '1' },
    {
      id: '2',
      abbr: 'OB',
      description: 'Orthobiologics',
      func: '1',
    },
  ],
};

const ListCRColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'func',
    headerName: '',
  },
];

const CRRows = {
  data: [
    {
      id: '1',
      value: '1%',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: '5%',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: '10%',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: '20%',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: '30%',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: '40%',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: '50%',
      description: 'Description',
      func: '1',
    },
  ],
};

const ListCRSColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'func',
    headerName: '',
  },
];

const CRSRows = {
  data: [
    {
      id: '1',
      value: 'Active - Default',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'Inactive',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'All',
      description: 'Description',
      func: '1',
    },
  ],
};

const ListSLColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'func',
    headerName: '',
  },
];

const SLRows = {
  data: [
    {
      id: '1',
      value: 'Full Service',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'Coding Only',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'Transcription Only',
      description: 'Description',
      func: '1',
    },
  ],
};
const ListPrioritiesColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'func',
    headerName: '',
  },
];

const PrioritiesRows = {
  data: [
    {
      id: '1',
      value: 'Neutral - Default',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'Influencer',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'Innovator',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'Important',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'Negative Sorter',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'Political',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: 'Promoter',
      description: 'Description',
      func: '1',
    },
  ],
};

const ListPredictabilityColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
  },
  {
    field: 'value',
    headerName: 'Value',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'description',
    headerName: 'Description',
    headerStyle: { textAlign: 'left' },
  },
  {
    field: 'func',
    headerName: '',
  },
];

const PredictabilityRows = {
  data: [
    {
      id: '1',
      value: '1%',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: '5%',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: '10%',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: '20%',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: '30%',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: '40%',
      description: 'Description',
      func: '1',
    },
    {
      id: '1',
      value: '50%',
      description: 'Description',
      func: '1',
    },
  ],
};
export const Clinician = ({ toggle }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LIMIT);
  const [selectPanel, setSelectPanel] = useState<string>(
    ListFilterPanel[0].name
  );
  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const tableData = useMemo(() => {
    switch (true) {
      case selectPanel === ListFilterPanel[0].name:
        return {
          column: ListDegreesColumn,
          rows: DegreesRows,
        };
      case selectPanel === ListFilterPanel[1].name:
        return {
          column: ListODegreesColumn,
          rows: ODegreesRows,
        };

      case selectPanel === ListFilterPanel[2].name:
        return {
          column: ListSpecialtiesColumn,
          rows: SpecialtiesRows,
        };

      case selectPanel === ListFilterPanel[3].name:
        return {
          column: ListFellowshipsColumn,
          rows: Fellowships,
        };

      case selectPanel === ListFilterPanel[4].name:
        return {
          column: ListCRColumn,
          rows: CRRows,
        };

      case selectPanel === ListFilterPanel[5].name:
        return {
          column: ListCRSColumn,
          rows: CRSRows,
        };

      case selectPanel === ListFilterPanel[6].name:
        return {
          column: ListSLColumn,
          rows: SLRows,
        };
      case selectPanel === ListFilterPanel[7].name:
        return {
          column: ListPrioritiesColumn,
          rows: PrioritiesRows,
        };
      case selectPanel === ListFilterPanel[8].name:
        return {
          column: ListPredictabilityColumn,
          rows: PredictabilityRows,
        };

      default:
        return {
          column: [],
          rows: [],
        };
    }
  }, [selectPanel]);

  const renderTableHead = (columns) => {
    return (
      <tr>
        {columns.map((column) => {
          return (
            !column?.hideCol && (
              <th
                key={column.field}
                className="p-table text-center border font-[600] whitespace-nowrap min-w-fit"
                style={column?.headerStyle}
              >
                <p>{column.headerName}</p>
              </th>
            )
          );
        })}
      </tr>
    );
  };

  const renderTableBody = (rows) => {
    return rows?.data?.map((row) => {
      return (
        <tr className="border border-t-0">
          {row?.id && <td className="w-fit"></td>}
          {row?.abbr && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.abbr}</p>
            </td>
          )}
          {row?.value && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.value}</p>
            </td>
          )}
          {row?.name && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.name}</p>
            </td>
          )}
          {row?.description && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.description}</p>
            </td>
          )}

          {row?.utc && (
            <td className="w-fit">
              <p className={'text-left p-table'}>{row.utc}</p>
            </td>
          )}
          {row?.func && (
            <td className="w-fit text-center">
              <Dropdown.Standard
                CustomButton={() => (
                  <div className="flex gap-2.5 items-center bg-white !w-fit">
                    <img
                      src={SVG.more_horizontal}
                      className="m-auto"
                      alt="more"
                    />
                  </div>
                )}
                options={[
                  {
                    label: 'Duplicate',
                    value: 'Duplicate',
                    // onClick: () => handleDuplicateRow(row),
                  },
                  {
                    label: 'Delete',
                    value: 'Delete',
                    // onClick: () => handleDeleteRow(row),
                  },
                ]}
              />
            </td>
          )}
        </tr>
      );
    });
  };

  return (
    <div className="flex flex-col flex-1 p-2.5 gap-2.5 mb-[50px]">
      <div className="flex items-center justify-between">
        <Text>1 Records</Text>
        <Dropdown.Standard
          CustomButton={(item) => (
            <div className="flex gap-2.5 items-center bg-white hover:bg-white p-5-10 border border-nav rounded-[5px]">
              <div className="">
                <p className="text-xs">{item.label} Records per page</p>
              </div>
              <img
                className="icon-app"
                src={SVG.chevron_down}
                alt="chevron_down"
              />
            </div>
          )}
          options={PAGE_SIZE_OPTIONS}
          onChangeValue={(item: any) => {
            handleChangePageSize(item?.value);
          }}
        />
      </div>
      <div className="flex flex-1 gap-2.5">
        <Box.Standard
          className={`max-w-[180px] h-auto w-full transition-all ${
            toggle ? '' : '-translate-x-[150%] fixed'
          }`}
        >
          <div className="flex flex-col gap-y-2.5 px-2.5">
            <h3 className={'font-bold text-xs py-1'}>Clinician</h3>
            <div className="flex flex-col text-xs gap-[5px]">
              {ListFilterPanel.map((item) => {
                return (
                  <p
                    className={cn(
                      selectPanel === item.name
                        ? 'text-[#BC9A3B] font-bold'
                        : 'hover:cursor-pointer hover:text-pink-700 hover:underline hover:font-bold'
                    )}
                    key={item.name}
                    onClick={() => setSelectPanel(item.name)}
                  >
                    {item.name}
                  </p>
                );
              })}
            </div>
          </div>
        </Box.Standard>
        <Box.Standard>
          <table className={'table-auto w-full z-0'}>
            <thead>{renderTableHead(tableData.column)}</thead>
            <tbody>{renderTableBody(tableData.rows)}</tbody>
          </table>
        </Box.Standard>
      </div>
    </div>
  );
};
