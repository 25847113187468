import { config } from 'config';
import { useEffect, useState } from 'react';

/**
 *  @function regexAddress
 *
 *  This functions splits the selected suggestion
 *  into parts to return the street address line
 *  1 data.
 *
 *  @param suggestion
 *
 *  @return [streetAddress, city, state]
 *
 */
const regexAddress = (suggestion) => {
  if (suggestion.length > 0) {
    const streetAddress = suggestion.split(', ')[0];
    const city = suggestion.split(', ')[1];
    const state = suggestion.split(', ')[2];
    return [streetAddress, city, state];
  } else {
    throw Error('New Errors');
  }
};

/**
 *
 * @function zipCodeSearch
 *
 */
const zipCodeSearch = async (geoCodeURL) => {
  let formattedAddress;
  let url = geoCodeURL;

  await fetch(url)
    .then((response) => response.json())
    .then(
      (data) =>
        (formattedAddress = data.results[0].formatted_address.split(', '))
    );

  let city = formattedAddress[formattedAddress.length - 3];
  let stateOf = formattedAddress[formattedAddress.length - 2].split(' ')[0];
  let zipCode = formattedAddress[formattedAddress.length - 2].split(' ')[1];
  let country = formattedAddress[formattedAddress.length - 1];

  return {
    city: city,
    stateOf: stateOf,
    zipCode: zipCode,
    country: country,
  };
  // 711 East Okeechobee Road, Hialeah, FL 33010, USA
};

export const useSelectBillToSuggestion = (suggestion) => {
  const [userBillingAddress, setUserBillingAddress] = useState({
    streetAddress: '',
    streetAddressLine2: '',
    city: '',
    stateOf: '',
    zipCode: '',
    country: '',
    suggestion: null,
  });

  const getBillingAddress = async (suggestion) => {
    if (!suggestion) {
      return;
    }
    try {
      let streetAddress = suggestion;

      const apiKey = config.PLACES_API_KEY; //
      const reg = regexAddress(streetAddress);

      streetAddress = reg[0];

      let url = 'https://maps.googleapis.com/maps/api/geocode/json?address=';

      const address = streetAddress.split(' ');

      let query = '';

      for (let i = 0; i < address.length; i++) {
        if (i === address.length - 1) {
          query = query + address[i];
        } else {
          query = query + address[i] + '+';
        }
      }

      url += query + '+' + reg[1] + '+' + reg[2] + '&key=' + apiKey;

      const { city, stateOf, zipCode, country } = await zipCodeSearch(url);

      const billToAddress = {
        suggestion: suggestion,
        streetAddress: streetAddress,
        streetAddressLine2: '',
        city: city,
        stateOf: stateOf,
        zipCode: zipCode,
        country: country,
      };

      setUserBillingAddress(billToAddress);
    } catch (err) {
      console.log('SELECT SUGGESTION ADDRESS CLEARED: ', err);
    }
  };

  useEffect(() => {
    getBillingAddress(suggestion);
  }, [suggestion]);

  return userBillingAddress;
};
