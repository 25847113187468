const dev = {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'icqc-uploads-api-dev-codednoteattachmentsbucket-jjlbacyyyi7e',
  },

  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api.icqc.ai/dev',
  },

  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_wRKWj83hp',
    APP_CLIENT_ID: '4p6tom1sppa09h1u9uugae7vcv',
    IDENTITY_POOL_ID: 'us-east-1:797e9062-701c-40b8-9613-f0e3f2f161b4',
  },

  PLACES_API_KEY: 'AIzaSyCf9gHej_w8002o5tBNJ9jaKO90kDRLM8c',
  API_NAME: 'coded-note',
};

const prod = {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'icqc-uploads-api-prod-codednoteattachmentsbucket-l996fvylwkt9',
  },

  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api.icqc.ai/prod',
  },

  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_3m7c5oiSm',
    APP_CLIENT_ID: '2bg85lvd9frv31h4enb797tnvc',
    IDENTITY_POOL_ID: 'us-east-1:d1b31720-ea65-47c3-aa64-b8e2b1500a40',
  },

  PLACES_API_KEY: 'AIzaSyCf9gHej_w8002o5tBNJ9jaKO90kDRLM8c',
  API_NAME: 'coded-note',
};

// Extract the subdomain from the URL
const hostnameParts = window.location.hostname.split('.');
const stage = hostnameParts.length >= 3 ? hostnameParts[0] : 'prod'; // Default to 'prod' if no subdomain

// Select the appropriate configuration based on the subdomain
// export const config = stage === 'prod' ? prod : dev;
// Use this config in local
export const config =
  hostnameParts.toString() === 'localhost' || stage?.toLowerCase() === 'dev'
    ? dev
    : prod;
