import * as React from 'react';
import * as PopperJS from '@popperjs/core';

import { Popover } from '@headlessui/react';
import cn from 'classnames';
import { usePopper } from 'react-popper';

interface IPanelProps {
  onClosePopper?: () => void;
}
export interface IPopoverProps {
  ButtonNode: React.ReactNode;
  Panel: (props: IPanelProps) => JSX.Element;
  arrow?: 'topRight' | 'topLeft' | 'bottomLeft' | 'bottomRight';
  className?: string;
  optionsPopover?: Omit<Partial<PopperJS.Options>, 'modifiers'> & {
    createPopper?: typeof PopperJS.createPopper;
  };
  customStyle?: any;
}

export default function AppPopover({
  ButtonNode,
  Panel,
  arrow,
  className,
  optionsPopover,
  customStyle,
}: IPopoverProps) {
  const positionArrow = {
    topRight:
      "after:content-[''] after:absolute after:right-0 after:-top-[10px] after:border-[10px]  after:border-r-white after:border-b-white after:border-t-transparent after:border-l-transparent",
    topLeft:
      "after:content-[''] after:absolute after:left-0 after:-top-[10px] after:border-[10px]  after:border-l-white after:border-b-white after:border-t-transparent after:border-r-transparent",
    bottomRight:
      "after:content-[''] after:absolute after:right-0 after:-bottom-[10px] after:border-[10px]  after:border-r-white after:border-t-white after:border-b-transparent after:border-l-transparent",
    bottomLeft:
      "after:content-[''] after:absolute after:left-0 after:-bottom-[10px] after:border-[10px]  after:border-l-white after:border-t-white after:border-b-transparent after:border-r-transparent",
  };

  const [referenceElement, setReferenceElement] = React.useState<any>(null);
  const [popperElement, setPopperElement] = React.useState<any>(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    optionsPopover
  );

  return (
    <Popover ref={setReferenceElement} className="relative">
      <Popover.Button className={'relative focus:outline-none'}>
        {ButtonNode}
      </Popover.Button>

      <Popover.Panel
        ref={setPopperElement}
        style={{
          ...styles.popper,
          ...customStyle,
        }}
        {...attributes.popper}
        className={`absolute z-10 focus:outline-none`}
      >
        {({ close }) => (
          <div
            className={cn(
              'w-full  bg-white rounded-[10px] text-center text-white text-sm',
              'drop-shadow-[0px_5px_25px_rgba(0,0,0,0.15)]',
              'min-w-max z-50 mt-2 w-max rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
              className,
              arrow ? positionArrow[arrow] : ''
            )}
          >
            <Panel onClosePopper={close} />
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
}
