import * as React from 'react';

export interface IDocAssistantSidebarProps {
  title: string;
}

export default function DocAssistantSidebar({
  title,
}: IDocAssistantSidebarProps) {
  return (
    <div>
      <div className="p-2.5">
        <h3 className="font-bold text-xs mb-[5px]">{title}</h3>
        <div className="flex flex-col text-xs gap-[5px]">
          <p className="font-bold text-[#BC9A3B]">Details</p>
          <p>Contract Info</p>
          <p>Schedule</p>
          <p>Comments</p>
          <p>Attachments</p>
        </div>
      </div>
    </div>
  );
}
