import * as Yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const requiredText = 'This is required field';

export const useClinicianEditSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string().nullable().required(requiredText),
    lastName: Yup.string().nullable().required(requiredText),
    middleName: Yup.string().nullable(),
    initials: Yup.string().nullable(),
    sortName: Yup.string().nullable(),
    givenName: Yup.string().nullable().optional(),
    prefix: Yup.string().nullable().optional(),
    suffix: Yup.string().nullable().optional(),
    degree: Yup.string().nullable().required(requiredText),
    otherDegree: Yup.string().nullable().optional(),
    specialties: Yup.string().nullable().required(requiredText),
    fellowships: Yup.string().nullable().required(requiredText),
    recordStatus: Yup.string().nullable().optional(),
    startDate: Yup.date().nullable().required(requiredText),
    organizationId: Yup.string().nullable().required(requiredText),
    serviceLevel: Yup.string().nullable().optional(),
    priority: Yup.string().nullable().optional(),
    ranking: Yup.string().nullable().optional(),
    predictability: Yup.string().nullable().optional(),
    important: Yup.string().nullable().optional(),
    organizationTimeZone: Yup.string().nullable().optional(),
    organization: Yup.object().required(requiredText),
    orgShort: Yup.string().nullable().required(requiredText),
    orgInitials: Yup.string().nullable().required(requiredText),
    location: Yup.string().nullable().optional(),
    department: Yup.string().nullable().optional(),
    site: Yup.string().nullable().optional(),
    preferences: Yup.string().nullable().optional(),
    siteManagerCell: Yup.string().nullable().optional(),
    principalNurse: Yup.string().nullable().optional(),
    nurseDirectPhone: Yup.string().nullable().optional(),
    primaryDocumentSpecialist: Yup.string().nullable().optional(),
    primaryAuditor: Yup.string().nullable().optional(),
    turnaroundTime: Yup.string().nullable().optional(),
  });
};
