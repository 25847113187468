import { SVG } from 'assets/imagePath';
import { Box, Note } from 'ui-organisms';
import { Footer, HeaderApp, Staff } from 'ui-molecules';
import { useAuth } from 'hooks/useAuth';
import DefaultLayout from 'layouts/DefaultLayout';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';
import { APP_ROLE, SET_LOADING_MODAL } from '../../constant';
import { GlobalContext } from '../../context/GlobalContext';

const StaffDetail = () => {
  const { dispatch } = useContext(GlobalContext);
  const { CURRENT_ROLE } = useAuth();
  const navigate = useNavigate();

  const { staffId } = useParams();

  const [staff, setStaff] = useState();

  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }

  // const noteDetail = fakeRow.find((item) => item.id === +noteId);

  return (
    <DefaultLayout>
      <HeaderApp.Detail
        detailProps={{
          detailName: 'First Name Last Name - CO',
          addLabel: 'Start',
          labelDetail: 'CO Details - SA',
        }}
        viewName="Staff Detail"
      />

      <div className="w-full flex flex-col flex-1 justify-center items-center p-2.5">
        <div className="w-full flex justify-between">
          <div
            className="flex items-center text-xs text-black font-bold px-2.5 gap-x-[5px] cursor-pointer"
            onClick={() => navigate(RouterPath.STAFFS, { replace: true })}
          >
            <img src={SVG.chevron_left} className="icon-app" alt="icon-back" />
            <p>{'Marcus Welby Jr. MD'}</p>
          </div>
          <p className="text-right self-end text-xs">
            Last Update: 23 hrs – 40 Min Ago
          </p>
        </div>
        <div className="flex items-stretch flex-1 gap-2.5 w-full h-full mt-2.5">
          <div className="w-[225px]">
            <Box.Standard>
              <Note.SideBar />
            </Box.Standard>
          </div>
          <div className="flex flex-col w-full  flex-1 h-auto gap-2.5">
            <Box.Standard className="px-[60px] py-[30px]">
              <Staff.Detail />
            </Box.Standard>
          </div>
        </div>
      </div>
      <Footer />
    </DefaultLayout>
  );
};

export default StaffDetail;
