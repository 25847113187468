import { yupResolver as FormResolver } from '@hookform/resolvers/yup';
import { Button } from 'ui-atoms';
import { Form } from 'ui-molecules';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useChangePassword } from './invitation.schema';
import { SET_LOADING_MODAL } from 'constant';
import { useContext } from 'react';
import { GlobalContext } from 'context/GlobalContext';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';
import { toast } from 'react-toastify';

export default function ChangePassword() {
  const { dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: FormResolver(useChangePassword()),
  });

  useEffect(() => {}, []);

  const onSubmit = async (values: any) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });

      const currentUser = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(
        currentUser,
        values?.oldPassword,
        values?.newPassword
      );

      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);

      navigate(RouterPath.SETTINGS);
    } catch (err) {
      toast.error(err);
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
    }
  };

  return (
    <div className="max-h-screen h-full w-full flex flex-col relative">
      <div className="h-full w-full flex-1 flex justify-center items-center p-2.5 overflow-auto">
        <div className="max-w-[280px] w-full h-auto">
          <div className="w-full h-full flex flex-col  gap-[30px]">
            <h2 className="text-center text-xl font-bold">Create Password</h2>
            <FormProvider {...form}>
              <form className="w-full h-auto grid  grid-flow-row gap-y-5">
                <Form.PasswordInput
                  name="oldPassword"
                  inputProps={{
                    placeholder: 'Temporary Password',
                  }}
                />
                <Form.PasswordInput
                  name="newPassword"
                  inputProps={{ placeholder: 'New Password' }}
                />
                <Form.PasswordInput
                  name="confirmPassword"
                  inputProps={{
                    placeholder: 'Confirm New Password',
                  }}
                />
              </form>
            </FormProvider>
            <div className="flex flex-row gap-5">
              <Button.Primary
                className="flex-1 py-3"
                label={'Create'}
                onClick={form.handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
