import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { SVG } from 'assets/imagePath';
import { Box } from 'ui-organisms';
import { Footer, Form, HeaderApp } from 'ui-molecules';
import DefaultLayout from 'layouts/DefaultLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';
import cn from 'classnames';
import { useApiCall } from '../../hooks';
import {
  getListOrganizationsAPI,
  getTreatmentRecordsAPI,
  updateTreatmentRecordAPI,
} from '../../services';
import { GlobalContext } from '../../context/GlobalContext';
import { APP_ROLE, SET_LOADING_MODAL } from '../../constant';
import { Attachments, Comments } from './table';
import { IAttachmentsTable, ICommentsTable } from '../../types';
import { v4 as uuidv4 } from 'uuid';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ObjectUtils } from '../../utils';
import isEmpty from 'lodash/isEmpty';
import { useAuth } from '../../hooks/useAuth';

const ListFilterPanel = [
  { name: 'Details' },
  { name: 'Comments' },
  { name: 'Attachments' },
];

const statusOptions = [
  { label: 'New', value: 'New', color: '#DBDBDB' },
  { label: 'DS question', value: 'DS question', color: '#A895E2' },
  { label: 'Schedule', value: 'Schedule', color: '#8B8B8B' },
  { label: 'Transcribe', value: 'Transcribe', color: '#E48BB5' },
  { label: 'DS review QI', value: 'DS review QI', color: '#FECB6F' },
  { label: 'Transfer', value: 'Transfer', color: '#FF8C00' },
  { label: 'To code', value: 'To code', color: '#55ABE5' },
  { label: 'Coding', value: 'Coding', color: '#C0C0C0' },
  { label: 'CO question', value: 'CO question', color: '#33BAB1' },
  { label: 'CO review QI', value: 'CO review QI', color: '#F07D88' },
  { label: 'Tasked EMR', value: 'Tasked EMR', color: '#FFF100' },
  { label: 'Submitted', value: 'Submitted', color: '#5FBE7D' },
];

const NoteDetailPage = () => {
  const { CURRENT_ROLE } = useAuth();

  const navigate = useNavigate();
  const { userRecordId, treatmentRecordId } = useParams();
  const { dispatch, state } = useContext(GlobalContext);
  const [getTreatmentRecords, loading, treatmentData] = useApiCall(
    getTreatmentRecordsAPI
  );
  const [fetchOrganization, loadingOrganization, listOrganization] = useApiCall(
    getListOrganizationsAPI
  );
  const [updateTreatmentRecord] = useApiCall(updateTreatmentRecordAPI);
  const form = useForm({
    defaultValues: {
      organization: {
        label: '',
        value: '',
        short: '',
        initials: '',
      },
      organizationName: '',
      organizationId: '',
      orgShort: '',
      orgInitials: '',
      location: '',
      speciality: '',
      dsDeadline: '',
      dsClarifyQuestions: '',
      dsClinicianAnswers: '',
      nurseCreateAppt: '',
      characters: '',
      smartPhrases: '',
      dsDateTimeDone: '',
      duration: '',
      patientInitials: '',
      note: '',
      audioFile: '',
      arrived: '',
      slaFinish: '',
      noteMessage: '',
      clinicianTimeZone: '',
      coDeadline: '',
      coClarifyQuestions: '',
      coClinicianAnswers: '',
      serviceLocations: '',
      coDateTimeDone: '',
      serviceLevel: '',
      clinicianRecordStatus: '',
      manager: '',
      ranking: '',
      priority: '',
      noteStatus: '',
      documentSpecialist: '',
      clinician: '',
      msgImportant: '',
      msgNote: '',
      msgTimeZone: '',
      medicalCoder: '',
      dsCreateAppointment: '',
      coServiceLocation: '',
      audioFileAttachment: '',
      treatmentLog: '',
      patient: '',
      dateOfService: '',
    },
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    // resolver: FormResolver(useClinicianEditSchema()),
  });

  useEffect(() => {
    if (!isEmpty(form?.formState?.errors)) {
      toast.error('Missing some fields. Please check them', {
        hideProgressBar: true,
        autoClose: 3000,
        type: 'error',
        theme: 'colored',
      });
    }
  }, [form?.formState?.errors]);

  const [toggle, setToggle] = React.useState(false);
  const [fontSize, setFontSize] = useState(12);

  const [selectPanel, setSelectPanel] = useState<string>(
    ListFilterPanel[0].name
  );

  const [attachmentRows, setAttachmentRows] = React.useState<
    IAttachmentsTable[]
  >([
    {
      id: uuidv4(),
      name: 'Attachments 1',
      format: 'PDF',
      author: 'Marcus Welby',
      comment: 'Contact sheet',
    },
  ]);

  const [commentRows, setCommentRows] = React.useState<ICommentsTable[]>([
    {
      id: uuidv4(),
      date: '03/18/2023',
      full_name: 'Julie Medley',
      role: 'DS',
      comment: 'Betty MacDonald',
    },
  ]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading || loadingOrganization,
    });
  }, [dispatch, loading, loadingOrganization]);

  useEffect(() => {
    if (!userRecordId || !treatmentRecordId) return navigate('/');
    getTreatmentRecords({
      userRecordId,
      treatmentRecordId,
    });
    fetchOrganization();
  }, [treatmentRecordId, userRecordId]);

  useEffect(() => {
    let data: any = {};
    if (ObjectUtils.isJson(treatmentData?.[0]?.clinician))
      data = JSON.parse(treatmentData?.[0]?.clinician || '');
    form.setValue('organizationName', treatmentData?.[0]?.organizationName);
    form.setValue('organizationId', treatmentData?.[0]?.organizationId);
    form.setValue('location', treatmentData?.[0]?.location);
    form.setValue('speciality', treatmentData?.[0]?.speciality);
    form.setValue('dsDeadline', treatmentData?.[0]?.dsDeadline);
    form.setValue('dsClarifyQuestions', treatmentData?.[0]?.dsClarifyQuestions);
    form.setValue('dsClinicianAnswers', treatmentData?.[0]?.dsClinicianAnswers);
    form.setValue('nurseCreateAppt', treatmentData?.[0]?.nurseCreateAppt);
    form.setValue('characters', treatmentData?.[0]?.characters);
    form.setValue('smartPhrases', treatmentData?.[0]?.smartPhrases);
    form.setValue('dsDateTimeDone', treatmentData?.[0]?.dsDateTimeDone);
    form.setValue('duration', treatmentData?.[0]?.duration);
    form.setValue('patientInitials', treatmentData?.[0]?.patientInitials);
    form.setValue('note', treatmentData?.[0]?.note);
    form.setValue('audioFile', treatmentData?.[0]?.audioFile);
    form.setValue('arrived', treatmentData?.[0]?.arrived);
    form.setValue('slaFinish', treatmentData?.[0]?.slaFinish);
    form.setValue('noteMessage', treatmentData?.[0]?.msgNote);
    form.setValue('clinicianTimeZone', treatmentData?.[0]?.msgTimeZone);
    form.setValue('coDeadline', treatmentData?.[0]?.coDeadline);
    form.setValue('coClarifyQuestions', treatmentData?.[0]?.coClarifyQuestions);
    form.setValue('coClinicianAnswers', treatmentData?.[0]?.coClinicianAnswers);
    form.setValue('serviceLocations', treatmentData?.[0]?.serviceLocations);
    form.setValue('coDateTimeDone', treatmentData?.[0]?.coDateTimeDone);
    form.setValue('serviceLevel', treatmentData?.[0]?.serviceLevel);
    form.setValue(
      'clinicianRecordStatus',
      treatmentData?.[0]?.clinicianRecordStatus
    );
    form.setValue('manager', treatmentData?.[0]?.manager);
    form.setValue('ranking', treatmentData?.[0]?.ranking);
    form.setValue('priority', treatmentData?.[0]?.priority);
    form.setValue('noteStatus', treatmentData?.[0]?.noteStatus);
    form.setValue('documentSpecialist', treatmentData?.[0]?.documentSpecialist);
    form.setValue(
      'clinician',
      `${data?.firstName} ${data?.middleName} ${data?.lastName}`
    );
    form.setValue(
      'dateOfService',
      treatmentData?.[0]?.dateOfService.slice(0, 10)
    );
    form.setValue('msgImportant', treatmentData?.[0]?.msgImportant);
    form.setValue('msgNote', treatmentData?.[0]?.msgNote);
    form.setValue('msgTimeZone', treatmentData?.[0]?.msgTimeZone);
    form.setValue('medicalCoder', treatmentData?.[0]?.medicalCoder);
    form.setValue(
      'dsCreateAppointment',
      treatmentData?.[0]?.dsCreateAppointment
    );
    form.setValue('coServiceLocation', treatmentData?.[0]?.coServiceLocation);
    form.setValue(
      'audioFileAttachment',
      treatmentData?.[0]?.audioFileAttachment
    );
    form.setValue('treatmentLog', treatmentData?.[0]?.treatmentLog);
    treatmentData?.[0]?.attachments &&
      setAttachmentRows(treatmentData?.[0]?.attachments);
    treatmentData?.[0]?.comments &&
      setCommentRows(treatmentData?.[0]?.comments);
  }, [form, treatmentData]);

  const renderPart = (title: string, element: any, isMl?: boolean) => {
    return (
      <div className="w-full flex items-start gap-x-10">
        {title && (
          <div
            className={cn('text-xs font-bold py-4 w-1/4', isMl ? 'ml-9' : '')}
            style={{ fontSize: `${fontSize}px` }}
          >
            {title}
          </div>
        )}
        <div className="w-full mt-6">{element}</div>
      </div>
    );
  };

  const renderVerticalRow = (headerName: string, cell: any) => {
    return (
      <div className=" w-full flex flex-col gap-y-4 py-4">
        <div className="flex items-center gap-x-2">
          <div className="w-2/5 justify-end">
            <p
              className="text-xs font-bold text-right"
              style={{ fontSize: `${fontSize}px` }}
            >
              {headerName}
            </p>
          </div>
          <div
            className="w-full flex flex-1 items-center justify-end"
            style={{ fontSize: `${fontSize}px` }}
          >
            {cell}
          </div>
        </div>
      </div>
    );
  };

  const renderTablePart = (title: string, element: any) => {
    return (
      <div
        className="flex flex-col gap-4 items-center w-full"
        style={{ fontSize: `${fontSize}px` }}
      >
        <p className="font-bold text-center py-1">{title}</p>
        <div className="!overflow-auto  w-full max-h-full z-0">{element}</div>
      </div>
    );
  };

  const updateAttachmentRows = (id: string, row: IAttachmentsTable) => {
    const newRow = attachmentRows?.map((it) => {
      if (it.id === id) {
        return {
          ...it,
          ...row,
        };
      }
      return it;
    });

    setAttachmentRows(newRow);
  };

  const updateCommentRows = (id: number, row: ICommentsTable) => {
    const newRow = commentRows?.map((it) => {
      if (it.id === id) {
        return {
          ...it,
          ...row,
        };
      }
      return it;
    });

    setCommentRows(newRow);
  };

  const organizationOption = useMemo(() => {
    return listOrganization?.map((orgItem) => ({
      label: orgItem?.organizationName,
      value: orgItem?.organizationId,
      short: orgItem?.orgShort,
      initials: orgItem?.orgInitials,
    }));
  }, [listOrganization]);

  const organizationData = form.watch('organization');
  useEffect(() => {
    form.setValue('organizationName', organizationData.label);
    form.setValue('organizationId', organizationData.value);
    form.setValue('orgShort', organizationData.short);
    form.setValue('orgInitials', organizationData.initials);
  }, [form, organizationData]);

  const renderContent = React.useMemo(() => {
    switch (true) {
      case selectPanel === ListFilterPanel[1].name: {
        return (
          <>
            {renderTablePart(
              'Comments',
              <Comments
                fontSize={fontSize}
                rows={commentRows}
                setRows={setCommentRows}
                updateValue={updateCommentRows}
              />
            )}
          </>
        );
      }
      case selectPanel === ListFilterPanel[2].name: {
        return (
          <>
            {renderTablePart(
              'Attachments',
              <Attachments
                fontSize={fontSize}
                rows={attachmentRows}
                setRows={setAttachmentRows}
                updateValue={updateAttachmentRows}
              />
            )}
          </>
        );
      }
      default: {
        return (
          <FormProvider {...form}>
            <form className="flex flex-col w-full flex-1 gap-2.5">
              <Box.Standard className="h-auto">
                <div className="grid grid-cols-2 gap-[10px] mt-4">
                  <div className="flex flex-col">
                    {renderVerticalRow(
                      'Clinician',
                      <Form.TextInput
                        name="clinician"
                        className="flex-1 bg-white"
                        inputProps={{
                          placeholder: '',
                          value: form.getValues('clinician'),
                          className:
                            'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                        }}
                      />
                    )}
                    {renderVerticalRow(
                      'Status',
                      <Form.SelectInput
                        name="noteStatus"
                        className="flex-1 bg-white"
                        options={statusOptions}
                        defaultValue={statusOptions?.find(
                          (status) =>
                            status?.value === treatmentData?.[0]?.noteStatus
                        )}
                      />
                      // <Dropdown.Standard
                      //   CustomButton={() => (
                      //     <div className="flex items-center gap-x-2.5 w-full relative">
                      //       <div className="py-[2px] rounded-full w-full">
                      //         <p className="text-xs uppercase w-full">
                      //           {form.getValues('noteStatus')}
                      //         </p>
                      //       </div>
                      //
                      //       <img
                      //         className="icon-app"
                      //         src={SVG.chevron_down}
                      //         alt="chevron_down"
                      //       />
                      //     </div>
                      //   )}
                      //   options={statusOptions}
                      //   onChangeValue={(value) =>
                      //     form.setValue('noteStatus', value.value.toUpperCase())
                      //   }
                      // />
                    )}
                  </div>
                  <div className="flex flex-col">
                    {renderVerticalRow(
                      'Patient',
                      <Form.TextInput
                        name="patientInitials"
                        className="flex-1 bg-white"
                        inputProps={{
                          placeholder: '',
                          value: form.getValues('patientInitials'),
                          className:
                            'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                        }}
                      />
                    )}
                    {renderVerticalRow(
                      'Date of Service',
                      <Form.TextInput
                        name="dateOfService"
                        className="flex-1 bg-white"
                        inputProps={{
                          placeholder: '',
                          value: form.getValues('dateOfService'),
                          className:
                            'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                        }}
                      />
                    )}
                  </div>
                </div>
              </Box.Standard>
              <Box.Standard className="h-full p-[15px]">
                <div className="grid grid-cols-2 gap-10 mt-4 w-full">
                  <div className="flex w-full flex-col gap-y-[20px]">
                    {renderPart(
                      'Organization',
                      <>
                        {renderVerticalRow(
                          'Organization',
                          <Form.SelectInputV2
                            name="organization"
                            className="flex-1 bg-white"
                            defaultValue={organizationOption?.find(
                              (org) =>
                                org?.value ===
                                treatmentData?.[0]?.organizationId
                            )}
                            options={organizationOption}
                          />
                        )}
                        {renderVerticalRow(
                          'Location',
                          <Form.TextInput
                            name="location"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('location'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Speciality',
                          <Form.TextInput
                            name="speciality"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('speciality'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Doc Specialist',
                          <Form.SelectInput
                            name="documentSpecialist"
                            className="flex-1 bg-white"
                            options={[]}
                            defaultValue={{
                              label: form.getValues('documentSpecialist'),
                              value: form.getValues('documentSpecialist'),
                            }}
                          />
                        )}
                      </>,
                      true
                    )}
                    {renderPart(
                      'Status DS',
                      <>
                        {renderVerticalRow(
                          'DS - Deadline',
                          <Form.TextInput
                            name="dsDeadline"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('dsDeadline'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'DS - Clarify Questions',
                          <Form.TextInput
                            name="dsClarifyQuestions"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('dsClarifyQuestions'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'DS - Clinician Answers',
                          <Form.TextInput
                            name="dsClinicianAnswers"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('dsClinicianAnswers'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Nurse Create Appt',
                          <Form.TextInput
                            name="nurseCreateAppt"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('nurseCreateAppt'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                      </>,
                      true
                    )}
                    {renderPart(
                      'Stats DS',
                      <>
                        {renderVerticalRow(
                          'Characters',
                          <Form.TextInput
                            name="characters"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('characters'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Smart Phrases',
                          <Form.TextInput
                            name="smartPhrases"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('smartPhrases'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Requests',
                          <Form.SelectInput
                            name="requests"
                            className="flex-1 bg-white"
                            options={[
                              {
                                label: '6',
                                value: '6',
                              },
                            ]}
                            defaultValue={{
                              label: '6',
                              value: '6',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'QA Check',
                          <Form.SelectInput
                            name="qaCheck"
                            className="flex-1 bg-white"
                            options={[
                              {
                                label: '1',
                                value: '1',
                              },
                            ]}
                            defaultValue={{
                              label: '1',
                              value: '1',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'EMR Transfer',
                          <Form.SelectInput
                            name="emrTransfer"
                            className="flex-1 bg-white"
                            options={[
                              {
                                label: '1',
                                value: '1',
                              },
                            ]}
                            defaultValue={{
                              label: '1',
                              value: '1',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'DS Date - Time Done',
                          <Form.TextInput
                            name="dsDateTimeDone"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('dsDateTimeDone'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                      </>,
                      true
                    )}
                    {renderPart(
                      'Note Specifics',
                      <>
                        {renderVerticalRow(
                          'Duration',
                          <Form.TextInput
                            name="duration"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('duration'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Patient Initials',
                          <Form.TextInput
                            name="patientInitials"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('patientInitials'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Note #',
                          <Form.TextInput
                            name="note"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('note'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Audio File',
                          <Form.TextInput
                            name="audioFile"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('audioFile'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Arrived',
                          <Form.TextInput
                            name="arrived"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('arrived'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'SLA - Finish',
                          <Form.TextInput
                            name="slaFinish"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('slaFinish'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                      </>,
                      true
                    )}
                  </div>
                  <div className="flex w-full flex-col gap-y-[20px]">
                    {renderPart(
                      'Clinician Messages',
                      <>
                        {renderVerticalRow(
                          'Important',
                          <Form.TextInput
                            name="msgImportant"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('msgImportant'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Note Message',
                          <Form.TextInput
                            name="msgNote"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('msgNote'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right text-red h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Clinician Time Zone',
                          <Form.TextInput
                            name="clinicianTimeZone"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('clinicianTimeZone'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Coder',
                          <Form.SelectInput
                            name="medicalCoder"
                            className="flex-1 bg-white"
                            options={[]}
                            defaultValue={{
                              label: form.getValues('medicalCoder'),
                              value: form.getValues('medicalCoder'),
                            }}
                          />
                        )}
                      </>,
                      true
                    )}
                    {renderPart(
                      'Status CO',
                      <>
                        {renderVerticalRow(
                          'CO - Deadline',
                          <Form.TextInput
                            name="coDeadline"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('coDeadline'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'CO - Clarify Questions',
                          <Form.TextInput
                            name="coClarifyQuestions"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('coClarifyQuestions'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'CO - Clinician Answers',
                          <Form.TextInput
                            name="coClinicianAnswers"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('coClinicianAnswers'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Service Locations',
                          <Form.TextInput
                            name="serviceLocations"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('serviceLocations'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                      </>,
                      true
                    )}
                    {renderPart(
                      'Stats CO',
                      <>
                        {renderVerticalRow(
                          'E&M Level',
                          <Form.SelectInput
                            name="emLevel"
                            className="flex-1 bg-white"
                            options={[
                              {
                                label: '3',
                                value: '3',
                              },
                            ]}
                            defaultValue={{
                              label: '3',
                              value: '3',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'ICD 10',
                          <Form.SelectInput
                            name="icd10"
                            className="flex-1 bg-white"
                            options={[
                              {
                                label: '112.3',
                                value: '112.3',
                              },
                            ]}
                            defaultValue={{
                              label: '112.3',
                              value: '112.3',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Clinician Pat. Time Spent',
                          <Form.SelectInput
                            name="clinicianPatTimeSpent"
                            className="flex-1 bg-white"
                            options={[
                              {
                                label: '50 Min',
                                value: '50 Min',
                              },
                            ]}
                            defaultValue={{
                              label: '50 Min',
                              value: '50 Min',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Place of Service',
                          <Form.SelectInput
                            name="placeOfService"
                            className="flex-1 bg-white"
                            options={[
                              {
                                label: 'Office',
                                value: 'Office',
                              },
                            ]}
                            defaultValue={{
                              label: 'Office',
                              value: 'Office',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'CO Date - Time Done',
                          <Form.TextInput
                            name="coDateTimeDone"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('coDateTimeDone'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow('', <></>)}
                        {renderVerticalRow('', <></>)}
                        {renderVerticalRow('', <></>)}
                        {renderVerticalRow('', <></>)}
                      </>,
                      true
                    )}
                    {renderPart(
                      'Clinician Specifics',
                      <>
                        {renderVerticalRow(
                          'Service Level',
                          <Form.TextInput
                            name="serviceLevel"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('serviceLevel'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Clinician Record Status',
                          <Form.TextInput
                            name="clinicianRecordStatus"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('clinicianRecordStatus'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Manager',
                          <Form.TextInput
                            name="manager"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('manager'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Ranking',
                          <Form.TextInput
                            name="ranking"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('ranking'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Priority',
                          <Form.TextInput
                            name="priority"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('priority'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                      </>,
                      true
                    )}
                  </div>
                </div>
              </Box.Standard>
              <Box.Standard>
                {renderTablePart(
                  'Comments',
                  <Comments
                    fontSize={fontSize}
                    rows={commentRows}
                    setRows={setCommentRows}
                    updateValue={updateCommentRows}
                  />
                )}
              </Box.Standard>
              <Box.Standard>
                {renderTablePart(
                  'Attachments',
                  <Attachments
                    fontSize={fontSize}
                    rows={attachmentRows}
                    setRows={setAttachmentRows}
                    updateValue={updateAttachmentRows}
                  />
                )}
              </Box.Standard>
            </form>
          </FormProvider>
        );
      }
    }
  }, [selectPanel]);

  const handleSubmitFunc = async (data) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });

      const request = {
        ...treatmentData?.[0],
        organizationName: data?.organizationName,
        organizationId: data?.organizationId,
        orgShort: data?.orgShort,
        orgInitials: data?.orgInitials,
        location: data?.location,
        speciality: data?.speciality,
        dsDeadline: data?.dsDeadline,
        dsClarifyQuestions: data?.dsClarifyQuestions,
        dsClinicianAnswers: data?.dsClinicianAnswers,
        nurseCreateAppt: data?.nurseCreateAppt,
        characters: data?.characters,
        smartPhrases: data?.smartPhrases,
        dsDateTimeDone: data?.dsDateTimeDone,
        duration: data?.duration,
        patientInitials: data?.patientInitials,
        note: data?.note,
        audioFile: data?.audioFile,
        arrived: data?.arrived,
        slaFinish: data?.slaFinish,
        noteMessage: data?.noteMessage,
        clinicianTimeZone: data?.clinicianTimeZone,
        coDeadline: data?.coDeadline,
        coClarifyQuestions: data?.coClarifyQuestions,
        coClinicianAnswers: data?.coClinicianAnswers,
        serviceLocations: data?.serviceLocations,
        coDateTimeDone: data?.coDateTimeDone,
        serviceLevel: data?.serviceLevel,
        clinicianRecordStatus: data?.clinicianRecordStatus,
        manager: data?.manager,
        ranking: data?.ranking,
        priority: data?.priority,
        noteStatus: data?.noteStatus,
        documentSpecialist: data?.documentSpecialist,
        msgImportant: data?.msgImportant,
        msgNote: data?.msgNote,
        msgTimeZone: data?.msgTimeZone,
        medicalCoder: data?.medicalCoder,
        dsCreateAppointment: data?.dsCreateAppointment,
        coServiceLocation: data?.coServiceLocation,
        audioFileAttachment: data?.audioFileAttachment,
        treatmentLog: data?.treatmentLog,
        patient: data?.patient,
        dateOfService: data?.dateOfService,
      };

      await updateTreatmentRecord({
        organizationId: treatmentData?.[0]?.organizationId,
        treatmentRecordId: treatmentData?.[0]?.treatmentRecordId,
        body: request,
      });
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
    } catch (err) {
      console.log('error', err);
      setTimeout(() => {
        toast.error(err.message, {
          hideProgressBar: true,
          autoClose: 3000,
          type: 'error',
          theme: 'colored',
        });
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
    }
  };

  const actionOpts = [
    {
      label: 'Listen & Transcribe',
      value: 'Listen & Transcribe',
      onClick: () =>
        navigate(`/note/edit/${userRecordId}/${treatmentRecordId}`),
    },
  ];

  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }

  return (
    <DefaultLayout>
      <HeaderApp.Detail
        toggle={toggle}
        setToggle={setToggle}
        detailProps={{
          detailName: 'Note Detail',
          addLabel: 'Save',
          labelDetail: 'Notes List - MGR',
          setFontSize: setFontSize,
        }}
        viewName="Note Detail"
        onAdd={form.handleSubmit(handleSubmitFunc)}
        actions={{ options: actionOpts }}
      />

      <div className="fixed w-full top-[107px] right-0 left-0 bottom-[50px] flex flex-col flex-1 justify-center items-center p-2.5 overflow-y-auto">
        <div className="w-full flex justify-between pt-4">
          <div
            className="flex items-center text-xs text-black font-bold px-2.5 gap-x-[5px] cursor-pointer"
            onClick={() => navigate(RouterPath.NOTES, { replace: true })}
          >
            <img src={SVG.chevron_left} className="icon-app" alt="icon-back" />
          </div>
          <p className="text-right self-end text-xs">
            Last Update: 23 hrs – 40 Min Ago
          </p>
        </div>
        <div className="flex items-stretch flex-1 gap-2.5 w-full h-full mt-2.5">
          <Box.Standard
            className={`max-w-[180px] h-auto w-full transition-all ${
              toggle ? '' : '-translate-x-[150%] fixed'
            }`}
          >
            <h3 className={'font-bold text-xs py-1'}>Note Information</h3>
            <div className="flex flex-col text-xs gap-[5px]">
              {ListFilterPanel.map((item) => {
                return (
                  <p
                    className={cn(
                      selectPanel === item.name
                        ? 'text-[#BC9A3B] font-bold'
                        : 'hover:cursor-pointer hover:text-pink-700 hover:underline hover:font-bold'
                    )}
                    key={item.name}
                    onClick={() => setSelectPanel(item.name)}
                  >
                    {item.name}
                  </p>
                );
              })}
            </div>
          </Box.Standard>
          <div
            className={cn(
              'flex flex-col w-full h-fit gap-2.5',
              selectPanel !== ListFilterPanel[0].name && 'bg-white'
            )}
          >
            {renderContent}
          </div>
        </div>
      </div>
      <Footer />
    </DefaultLayout>
  );
};

export default NoteDetailPage;
