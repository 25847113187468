import * as React from 'react';
import cn from 'classnames';

export interface IClinicianFilterProps {
  listPage: string[];
  selectPage: string;
  setPage: (page) => void;
}

function ClinicianFilter({
  listPage,
  selectPage,
  setPage,
}: IClinicianFilterProps) {
  return (
    <div className="flex flex-col gap-y-2.5">
      <div className="px-2.5">
        <h3 className="font-bold text-xs py-[5px]">Clinician information</h3>
        <div className="flex flex-col text-xs gap-y-[5px]">
          {listPage?.map((page, index) => (
            <p
              key={`${page}-${index}`}
              className={cn(
                selectPage === page
                  ? 'text-[#BC9A3B] font-bold'
                  : 'hover:underline hover:text-primary'
              )}
              onClick={() => setPage(page)}
            >
              {page}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ClinicianFilter);
