import * as yup from 'yup';
import * as Yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const requiredText = 'This is required field';

export const useOrganizationNewSchema = () => {
  return yup.object().shape({
    orgFullName: yup.string().nullable().required(requiredText),
    orgShortName: yup.string().nullable().required(requiredText),
    orgInitials: yup.string().nullable().required(requiredText),
    department: yup.string().nullable(),
    SlaTiming: yup.string().nullable(),
    orgLocation: yup.string().nullable(),
    primaryLocation: yup.string().nullable(),
    billing_address: yup.object().nullable(),
    adminFirstName: yup.string().nullable(),
    adminLastName: yup.string().nullable(),
    adminPhone: Yup.string()
      .nullable()
      .trim('Cell Phone is required')
      .matches(phoneRegExp, {
        message: 'Cell Phone is not valid',
        excludeEmptyString: true,
      }),
    adminEmail: yup.string().nullable().required(requiredText),
    otherOrgName: yup.string().nullable(),
    otherDepartment: yup.string().nullable(),
    emrSystem: yup.string().nullable(),
    servicePhone: Yup.string()
      .nullable()
      .trim('Cell Phone is required')
      .required('Cell Phone is required')
      .matches(phoneRegExp, {
        message: 'Cell Phone is not valid',
        excludeEmptyString: true,
      }),
    timeZone: yup.string().nullable().required(requiredText),
    attach_func: yup.string().nullable(),
  });
};
