import { yupResolver as FormResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import { Button } from 'ui-atoms';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'ui-molecules';
import { useAcceptSchema } from './invitation.schema';
import { SET_LOADING_MODAL } from 'constant';
import { useContext } from 'react';
import { GlobalContext } from 'context/GlobalContext';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RouterPath } from 'routes/router.constant';

export default function AcceptStep() {
  const navigate = useNavigate();
  const { dispatch } = useContext(GlobalContext);
  const { email } = useParams();
  const form = useForm({
    defaultValues: {
      password: '',
      email: email,
      code: '',
    },
    resolver: FormResolver(useAcceptSchema()),
  });

  const onSubmit = async (values: any) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });

      navigate(RouterPath.INVITATION_CONFIRM_DETAIL);

      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
    } catch (err) {
      toast.error(err);
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
    }
  };

  const handleResendRequest = async () => {
    await Auth.resendSignUp(email)
      .then()
      .catch((err) => console.log(err));
  };

  return (
    <div className="max-h-screen h-full w-full flex flex-col relative">
      <div className="h-full w-full flex-1 flex justify-center items-center p-2.5 overflow-auto">
        <div className="max-w-[280px] w-full h-auto">
          <div className="w-full h-full flex flex-col  gap-[30px]">
            <div>
              <h2 className="text-center text-xl font-bold mb-2">
                Accept Invitation
              </h2>
              <p className="text-center px-7 py-2">
                Please check your email for the code or verification link.
              </p>
            </div>
            <div className="w-full h-auto grid  grid-flow-row">
              <div className="flex flex-row items-center">
                <FormProvider {...form}>
                  <form className="w-full">
                    <Form.PasswordInput
                      className="w-full"
                      name="password"
                      inputProps={{
                        id: 'password',
                        placeholder: 'Temporary Password',
                      }}
                    />
                    <Form.EmailInput
                      className="w-full mt-5"
                      name="email"
                      inputProps={{
                        id: 'email',
                        placeholder: 'Email',
                      }}
                    />

                    <Form.PhoneInput
                      className="mt-5 w-full"
                      name="code"
                      inputProps={{
                        id: 'code',
                        placeholder: 'Confirmation Code',
                      }}
                    />
                  </form>
                </FormProvider>
              </div>
            </div>
            <div className="flex flex-col gap-3 items-center">
              <Button.Primary
                className="flex-1"
                label={'Accept'}
                onClick={form.handleSubmit(onSubmit)}
              />
              <p
                className="w-fit cursor-pointer"
                onClick={() => handleResendRequest()}
              >
                Resend code
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
