import { ErrorMessage } from '@hookform/error-message';
import { Search } from 'ui-molecules';
import { SearchStandardProps } from 'ui-molecules/search/SearchStandard';
import React, { memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

export interface IFormSearchProps extends SearchStandardProps {
  classNameFormBox?: string;
  name: string;
  onChangeCustom?: (e: string) => void;
}

function FormSearch({
  name,
  classNameFormBox,
  onChangeCustom,
  ...rest
}: IFormSearchProps) {
  const formContext = useFormContext();
  const { field, formState } = useController({
    name,
    control: formContext?.control,
    defaultValue: '',
  });

  return (
    <div className={classNameFormBox}>
      <Search.Standard
        {...rest}
        value={field?.value}
        onChange={(e) => {
          field?.onChange(e);
          onChangeCustom && onChangeCustom(e);
        }}
      />
      <ErrorMessage
        errors={formState?.errors}
        name={name}
        render={({ message }) => (
          <p className="text-error text-xs font-inter my-1">{message}</p>
        )}
      />
    </div>
  );
}

FormSearch.displayName = 'Form.Search';

export default memo(FormSearch);
