export const styleSize: { [key: string]: string } = {
  '10': 'text-xxs',
  '12': 'text-xs',
  '14': 'text-sm',
  '16': 'text-base',
  '18': 'text-lg',
  '20': 'text-xl',
  '22': 'text-2xl',
  '24': 'text-3xl',
};

export const sizeResponsive: { [key: string]: string } = {
  '10': 'text-xxs',
  '12': 'text-xs',
  '14': 'text-sm',
  '16': 'text-xxs xs:text-xs sm:text-sm md:text-base',
  '18': 'text-lg',
  '20': 'text-xl',
  '22': 'text-2xl',
  '24': 'text-3xl',
};

export const weightClasses = {
  200: 'font-extralight',
  300: 'font-light',
  400: 'font-normal',
  500: 'font-medium',
  600: 'font-semibold',
  700: 'font-bold',
  800: 'font-extrabold',
};

export const colorClasses = {
  default: 'text-default',
  secondary: 'text-gray-active',
  primary: 'text-primary',
  white: 'text-white',
  blue: 'text-dark-blue',
  success: 'text-success',
  error: 'text-error',
  warning: 'text-warning',
};
