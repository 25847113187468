import * as Yup from 'yup';

export const useForgotPasswordSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .nullable()
      .email('Email is invalid')
      .trim('Email is required')
      .required('Email is required'),
  });
};
