import { SVG } from 'assets/imagePath';
import { Form } from 'ui-molecules';
import * as React from 'react';
import Collapse from './Collapse';

export interface ICollapseListProps {
  collapseList: any[];
  LabelComp?: (item) => React.ReactNode;
  onChangeCustom?: any;
}

export default function CollapseList({
  collapseList,
  onChangeCustom,
  LabelComp,
}: ICollapseListProps) {
  return (
    <ul className="flex flex-col">
      {collapseList.map((it, idx) =>
        it.children ? (
          <Collapse.Text
            defaultOpen={it.defaultOpen}
            button={(open) => (
              <Collapse.Button
                open={open}
                label={it.label}
                icon={SVG.arrow_down}
                className="!justify-center text-center py-[4.5px]  text-black"
                classNameIcon="h-[15px] w-[15px] ml-[5px]"
              />
            )}
            collapseContent={
              <Collapse.List
                collapseList={it.children}
                LabelComp={(item) => {
                  return (
                    <div className="flex justify-between items-center">
                      <Form.CheckboxSingle
                        name={
                          item?.name ||
                          `${item.label.split(' ').join('_')}_${it.label
                            .split(' ')
                            .join('_')}_${idx}`
                        }
                        label={item?.label}
                        onChangeCustom={
                          onChangeCustom ? onChangeCustom : undefined
                        }
                        classNameText="!text-xs"
                        // onChange={() => {}}
                      />

                      <div className="flex gap-x-[5px]">
                        <img
                          src={SVG.minus}
                          alt="minus"
                          className="w-[15px] h-[15px]"
                        />
                        <img
                          src={SVG.plus}
                          alt="plus"
                          className="w-[15px] h-[15px]"
                        />
                      </div>
                    </div>
                  );
                }}
              />
            }
          />
        ) : (
          <li className="text-xs py-[3.5px]" style={{}}>
            {LabelComp ? LabelComp(it) : it.label}
          </li>
        )
      )}
    </ul>
  );
}
