export const fakeColStaff = [
  {
    name: 'Smith, Judy',
    staff_title: 'MGR',
    staff_degree: 'CO',
    email: 'WC',
    cell: '?',
    department: 'Surgery',
    locations: 'Pana',
    organization: 'Welby Clinic',
    staff_assign: '?',
    comment: '?',
    attachments: '?',
  },
  {
    name: 'Smith, Judy',
    staff_title: 'MGR',
    staff_degree: 'CO',
    email: 'WC',
    cell: '?',
    department: 'Surgery',
    locations: 'Pana',
    organization: 'Welby Clinic',
    staff_assign: '?',
    comment: '?',
    attachments: '?',
  },
  {
    name: 'Smith, Judy',
    staff_title: 'MGR',
    staff_degree: 'CO',
    email: 'WC',
    cell: '?',
    department: 'Surgery',
    locations: 'Pana',
    organization: 'Welby Clinic',
    staff_assign: '?',
    comment: '?',
    attachments: '?',
  },
  {
    name: 'Smith, Judy',
    staff_title: 'MGR',
    staff_degree: 'CO',
    email: 'WC',
    cell: '?',
    department: 'Surgery',
    locations: 'Pana',
    organization: 'Welby Clinic',
    staff_assign: '?',
    comment: '?',
    attachments: '?',
  },
  {
    name: 'Smith, Judy',
    staff_title: 'MGR',
    staff_degree: 'CO',
    email: 'WC',
    cell: '?',
    department: 'Surgery',
    locations: 'Pana',
    organization: 'Welby Clinic',
    staff_assign: '?',
    comment: '?',
    attachments: '?',
  },
  {
    name: 'Smith, Judy',
    staff_title: 'MGR',
    staff_degree: 'CO',
    email: 'WC',
    cell: '?',
    department: 'Surgery',
    locations: 'Pana',
    organization: 'Welby Clinic',
    staff_assign: '?',
    comment: '?',
    attachments: '?',
  },
  {
    name: 'Smith, Judy',
    staff_title: 'MGR',
    staff_degree: 'CO',
    email: 'WC',
    cell: '?',
    department: 'Surgery',
    locations: 'Pana',
    organization: 'Welby Clinic',
    staff_assign: '?',
    comment: '?',
    attachments: '?',
  },
  {
    name: 'Smith, Judy',
    staff_title: 'MGR',
    staff_degree: 'CO',
    email: 'WC',
    cell: '?',
    department: 'Surgery',
    locations: 'Pana',
    organization: 'Welby Clinic',
    staff_assign: '?',
    comment: '?',
    attachments: '?',
  },
  {
    name: 'Smith, Judy',
    staff_title: 'MGR',
    staff_degree: 'CO',
    email: 'WC',
    cell: '?',
    department: 'Surgery',
    locations: 'Pana',
    organization: 'Welby Clinic',
    staff_assign: '?',
    comment: '?',
    attachments: '?',
  },
  {
    name: 'Smith, Judy',
    staff_title: 'MGR',
    staff_degree: 'CO',
    email: 'WC',
    cell: '?',
    department: 'Surgery',
    locations: 'Pana',
    organization: 'Welby Clinic',
    staff_assign: '?',
    comment: '?',
    attachments: '?',
  },
  {
    name: 'Smith, Judy',
    staff_title: 'MGR',
    staff_degree: 'CO',
    email: 'WC',
    cell: '?',
    department: 'Surgery',
    locations: 'Pana',
    organization: 'Welby Clinic',
    staff_assign: '?',
    comment: '?',
    attachments: '?',
  },
];
