import DefaultLayout from '../../layouts/DefaultLayout';
import { Support } from '../../ui-organisms/landing';
import { Footer } from '../../ui-molecules';
import { useAuth } from '../../hooks/useAuth';
import { APP_ROLE, SET_LOADING_MODAL } from '../../constant';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from '../../routes/router.constant';
import { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';

export default function SupportPageAuth() {
  const { dispatch } = useContext(GlobalContext);
  const { CURRENT_ROLE } = useAuth();
  const navigate = useNavigate();
  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }
  return (
    <DefaultLayout>
      <section className="flex flex-col">
        <div className="flex-1 h-full max-w-[1300px] w-full px-2.5 mx-auto">
          <Support />
        </div>
      </section>
      <Footer />
    </DefaultLayout>
  );
}
