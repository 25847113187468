import cn from 'classnames';
import { ChangeEvent, FC, memo, useCallback } from 'react';

type Props = {
  className?: string;
  error?: boolean;
  value: string;
  onChange: (value: any) => void;
  label?: string;
  disabled?: boolean;
};

const UploadFile: FC<Props> = ({
  className,
  error,
  onChange,
  label,
  disabled,
  ...rest
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target?.value);
    },
    [onChange]
  );

  if (label) {
    return (
      <div className="w-full relative bg-inherit">
        <p className="absolute text-[10px] leading-[10px] left-[10px] px-[5px] top-[-5px] bg-pearl">
          {label}
        </p>
        <input id="dropzone-file" type="file" className="hidden" />
      </div>
    );
  }

  return (
    <div className="w-full relative bg-inherit">
      <div className="flex items-center w-full">
        <label
          htmlFor="dropzone-file"
          className="flex items-center w-full border border-gray-300 rounded-lg cursor-pointer"
        >
          <div className="flex items-center p-[12.5px] gap-x-2">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.01622 2.74089C8.59082 2.17976 9.36185 1.86807 10.1635 1.87512C10.9652 1.88216 11.7307 2.20734 12.2955 2.7784C12.8601 3.34925 13.1791 4.12004 13.186 4.9244C13.1929 5.72875 12.8872 6.50497 12.3326 7.08556L12.3308 7.08744L8.44545 11.1212C8.44509 11.1216 8.44474 11.122 8.44439 11.1224C8.27549 11.2989 8.07319 11.4401 7.84905 11.5374C7.62444 11.635 7.38259 11.6865 7.13769 11.6886C6.89279 11.6908 6.65007 11.6436 6.42378 11.55C6.1975 11.4563 5.99239 11.3183 5.82022 11.1442C5.64807 10.9702 5.51229 10.7636 5.42048 10.5366C5.32866 10.3098 5.28255 10.0669 5.28465 9.82213C5.28675 9.57737 5.33704 9.33533 5.43275 9.11005C5.52769 8.88659 5.66553 8.68386 5.83867 8.51367L9.82664 4.48145C10.0694 4.23603 10.4651 4.23385 10.7105 4.47658C10.9559 4.7193 10.9581 5.11503 10.7154 5.36045L6.71635 9.40385C6.65997 9.45891 6.61455 9.52508 6.58322 9.59883C6.55189 9.67259 6.5353 9.75217 6.5346 9.83287C6.53391 9.91357 6.54913 9.99345 6.57921 10.0678C6.60928 10.1421 6.65351 10.2092 6.70897 10.2652C6.76441 10.3213 6.82996 10.3652 6.9016 10.3949C6.97323 10.4245 7.04973 10.4393 7.12671 10.4387C7.20368 10.438 7.27994 10.4218 7.35108 10.3909C7.42223 10.36 7.48705 10.3149 7.54156 10.2578L7.54335 10.256L11.4287 6.22215C11.429 6.22186 11.4293 6.22157 11.4295 6.22128C11.7576 5.87741 11.9402 5.41571 11.9361 4.93514C11.9319 4.45418 11.7411 3.99545 11.4068 3.6574C11.0726 3.31957 10.6221 3.12919 10.1526 3.12507C9.68593 3.12097 9.23497 3.30111 8.89587 3.62905L5.00954 7.66265C4.44398 8.23448 4.125 9.01146 4.125 9.82309C4.125 10.6347 4.44394 11.4117 5.0095 11.9836C5.57482 12.5552 6.34008 12.875 7.13656 12.875C7.93304 12.875 8.6983 12.5552 9.26362 11.9836L13.0561 8.15756C13.2991 7.91241 13.6948 7.91067 13.94 8.15367C14.1851 8.39667 14.1869 8.7924 13.9439 9.03755L10.1524 12.8626C9.35376 13.6698 8.2688 14.125 7.13656 14.125C6.00408 14.125 4.9194 13.6701 4.12075 12.8626C3.32233 12.0553 2.875 10.9618 2.875 9.82309C2.875 8.68578 3.32121 7.59363 4.11777 6.78664L8.0028 2.75439L8.01622 2.74089Z"
                fill="black"
              />
            </svg>

            <p className="text-sm text-black text-opacity-50">
              Upload Screenshot
            </p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            onChange={onChange}
          />
        </label>
      </div>
    </div>
  );
};

UploadFile.displayName = 'Input.UploadFile';

export default memo(UploadFile);
