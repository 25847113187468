import * as yup from 'yup';
import * as Yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const requiredText = 'This is required field';

export const useOrganizationEditSchema = () => {
  return yup.object().shape({
    organization: yup.string().nullable().required(requiredText),
    important: yup.string().nullable().required(requiredText),
    organizationTimeZone: yup.string().nullable().required(requiredText),
    orgShort: yup.string().nullable().required(requiredText),
    orgInitials: yup.string().nullable().required(requiredText),
    siteName: yup.string().nullable().required(requiredText),
    startDate: yup.date().nullable().required(requiredText),
    orgId: yup.string().nullable().required(requiredText),
    firstName: yup.string().nullable().required(requiredText),
    lastName: yup.string().nullable().required(requiredText),
    middleName: yup.string().nullable().required(requiredText),
    initials: yup.string().nullable().required(requiredText),
    sortName: yup.string().nullable().required(requiredText),
    givenName: yup.string().nullable().required(requiredText),
    prefixName: yup.string().nullable().optional(),
    suffixName: yup.string().nullable().optional(),
    servicePhone: Yup.string()
      .nullable()
      .trim('Cell Phone is required')
      .optional()
      .matches(phoneRegExp, {
        message: 'Cell Phone is not valid',
        excludeEmptyString: true,
      }),
    street: yup.object().nullable().optional(),
    unit: yup.string().nullable().optional(),
    city: yup.string().nullable().optional(),
    state: yup.string().nullable().optional(),
    postCode: yup.string().nullable().optional(),
  });
};
