import { Footer, HeaderApp } from 'ui-molecules';
import DefaultLayout from 'layouts/DefaultLayout';
import { useContext, useState } from 'react';
import {
  Clinician,
  DocumentSpecialists,
  General,
  Manager,
  Note,
  SettingMgr,
  SystemAdmin,
  UserAndControl,
} from './page';
import { useAuth } from '../../hooks/useAuth';
import { GlobalContext } from '../../context/GlobalContext';
import { APP_ROLE, SET_LOADING_MODAL } from '../../constant';
import { RouterPath } from '../../routes/router.constant';
import { useNavigate } from 'react-router-dom';

export interface ISettingsPageProps {}

export default function SettingsPage({}: ISettingsPageProps) {
  const { CURRENT_ROLE } = useAuth();
  const { dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(true);
  const [page, setPage] = useState('Settings');

  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }

  const handleOnChange = (val: any) => {
    setPage(val.label);
  };

  const renderPage = () => {
    switch (page) {
      case 'Settings': {
        return <SettingMgr />;
      }

      case 'General': {
        return <General toggle={toggle} />;
      }

      case 'Users and Control': {
        return <UserAndControl toggle={toggle} />;
      }

      case 'Clinicians': {
        return <Clinician toggle={toggle} />;
      }

      case 'Note': {
        return <Note toggle={toggle} />;
      }

      case 'Document Specialists': {
        return <DocumentSpecialists toggle={toggle} />;
      }

      case 'Manager': {
        return <Manager toggle={toggle} />;
      }

      case 'System Admin': {
        return <SystemAdmin toggle={toggle} />;
      }

      default:
        break;
    }
  };
  return (
    <DefaultLayout>
      <HeaderApp.Settings
        toggle={toggle}
        setToggle={setToggle}
        onChange={handleOnChange}
        selectPage={page}
      />
      {renderPage()}
      <Footer />
    </DefaultLayout>
  );
}
