import { IMAGES } from 'assets/imagePath';
import { Button } from 'ui-atoms';
import { Text } from 'ui-molecules';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';

export interface HeaderProps {}

export default function Header({}: HeaderProps) {
  const navigate = useNavigate();
  return (
    <header
      id="header"
      className="w-full  my-5 xs:mt-[30px] sm:mt-[60px] xs:mb-[30px]"
    >
      <div className="max-w-[1280px] mx-auto px-2.5 flex max-sm:flex-col items-center justify-between w-full gap-5">
        <Link to={RouterPath.HOME}>
          <img
            src={IMAGES.landing_logo}
            className="max-w-[180px] xs:max-w-[200px] md:max-w-[220px] w-full h-auto"
          />
        </Link>
        <div className="flex items-center gap-x-2">
          <Button.Common
            className="w-fit rounded-full px-5 h-10"
            onClick={() => {
              navigate(RouterPath.LOGIN);
            }}
          >
            <Text
              responsive
              className="whitespace-nowrap"
              weight="400"
              size="16"
              color="white"
            >
              Login
            </Text>
          </Button.Common>
          <Button.White
            label={'Support'}
            className="w-fit h-[32px] xs:h-[40px] md:h-[48px] rounded-full px-5 md:px-[30px] text-base"
            onClick={() => {
              navigate(RouterPath.SUPPORT);
            }}
          />
        </div>
      </div>
    </header>
  );
}
