import React from 'react';
import FormCheckboxSingle from './FormCheckboxSingle';
import FormDatePicker from './FormDatePicker';
import FormEmailInput from './FormEmailInput';
import FormPasswordInput from './FormPasswordInput';
import FormPhoneInput from './FormPhoneInput';
import FormSearch from './FormSearch';
import FormSelectInput from './FormSelectInput';
import FormSelectMulti from './FormSelectMulti';
import FormTextInput from './FormTextInput';
import FormTextArea from './FormTextArea';
import FormUploadFile from './FormUploadFile';
import FormPhoneInputV2 from './FormPhoneInputV2';
import FormSelectInputV2 from './FormSelectInputV2';

const Form = () => {
  return <React.Fragment />;
};

Form.TextInput = FormTextInput;

Form.EmailInput = FormEmailInput;

Form.PhoneInput = FormPhoneInput;

Form.PhoneInputV2 = FormPhoneInputV2;

Form.PasswordInput = FormPasswordInput;

Form.SelectInput = FormSelectInput;

Form.SelectInputV2 = FormSelectInputV2;

Form.SelectMulti = FormSelectMulti;

Form.CheckboxSingle = FormCheckboxSingle;

Form.SearchBox = FormSearch;

Form.DatePicker = FormDatePicker;

Form.TextArea = FormTextArea;

Form.UploadFile = FormUploadFile;

export default Form;
