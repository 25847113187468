import { SVG } from 'assets/imagePath';
import { Form, Text } from 'ui-molecules';
import { Button } from 'ui-atoms';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import FilterCheckList, { IFilterCheckListProps } from './FilterCheckList';

export interface IFilterFormProps {
  title: {
    className?: string;
    text: string;
  };

  formProps?: {
    search?: {
      name?: string;
      inputProps?: DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >;
    };
  };
  filters: IFilterCheckListProps[];
  onSubmitFilter?: (data: any) => void;
  onClearFilter?: () => void;
  onClearSearchBox?: () => void;
}

const defaultSearch = {
  name: 'search_box',
  inputProps: {
    placeholder: 'Search',
    style: { borderStyle: 'solid' },
  },
};

// Please pass this component into a <FormProvider />
export default function FilterForm({
  formProps = {
    search: defaultSearch,
  },
  filters,
  title,
  onClearFilter,
  onSubmitFilter,
  onClearSearchBox,
}: IFilterFormProps) {
  const form = useFormContext();

  return (
    <>
      <form className="flex flex-col gap-2.5">
        <div className="px-2.5 py-[5px] flex items-center justify-between">
          <Text className={title.className} Component="h4" weight="700">
            {title.text}
          </Text>
          <img src={SVG.un_filter} />
        </div>
        <Form.SearchBox
          name={formProps?.search?.name}
          className="w-ful rounded-full border border-nav border-solid  bg-transparent px-2.5 py-[5px]"
          clearInput={() => form?.resetField(formProps?.search?.name)}
          inputProps={formProps?.search?.inputProps}
        />
        {filters?.map((filter, idx) => (
          <div className="px-2.5" key={idx}>
            <FilterCheckList
              title={filter?.title}
              checkList={filter?.checkList}
              {...filter}
            />
          </div>
        ))}
      </form>
      <div className="w-full mt-10">
        <div className="flex w-full flex-row gap-2.5">
          <Button.Primary
            className="flex-1 !p-default"
            label="Apply"
            onClick={form?.handleSubmit(onSubmitFilter)}
          />
          <Button.White
            className="flex-1 !p-default"
            color="white"
            label="Clear"
            onClick={onClearFilter}
          />
        </div>
      </div>
    </>
  );
}
