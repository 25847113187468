import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Auth } from 'ui-molecules';
import { Register } from 'ui-organisms';
import { IMAGES } from 'assets/imagePath';

const RegisterPage: FC = () => {
  const [searchParams] = useSearchParams();
  const isSetup =
    searchParams.get('step') === '4' && searchParams.get('setup') === 'true';

  return (
    <div className="flex min-h-screen">
      {!isSetup && (
        <Auth.Left
          image={IMAGES.side_auth_image}
          title={<Auth.TitleCover />}
          subtitle="Restoring The Joy of Medicine"
        />
      )}

      <div className="w-full flex-1 h-screen p-[15px]">
        {searchParams.get('step') === '1' && <Register.Step1 />}

        {searchParams.get('step') === '2' && <Register.Step2 />}

        {searchParams.get('step') === '3' && <Register.Step3 />}

        {searchParams.get('step') === '4' && !isSetup && <Register.Step4 />}

        {/* {searchParams.get('step') === '4' && searchParams.get('setup') === 'true' && (
          <Register.Setup />
        )} */}
      </div>
    </div>
  );
};

export default RegisterPage;
