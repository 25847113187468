import { FC, Fragment, memo } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { RouterPath } from '../../routes/router.constant';

type Props = {
  to: string;
  label: string;
};

const NavBarLink: FC<Props> = ({ to, label }) => {
  const isMatchUrl = (labelName: string) => {
    switch (labelName) {
      case 'Notes': {
        return (
          window.location.pathname.includes('/notes/') ||
          window.location.pathname.includes('/note/')
        );
      }
      case 'Organizations': {
        return (
          window.location.pathname.includes('/organizations/') ||
          window.location.pathname.includes('/organization/')
        );
      }
      case 'Clinicians': {
        return (
          window.location.pathname.includes('/clinicians/') ||
          window.location.pathname.includes('/clinician/')
        );
      }
      case 'Doc Specialists': {
        return (
          window.location.pathname.includes('/doc-assistants/') ||
          window.location.pathname.includes('/doc-assistant/')
        );
      }
      case 'Coders': {
        return (
          window.location.pathname.includes('/coders/') ||
          window.location.pathname.includes('/coder/')
        );
      }
      case 'Managers': {
        return (
          window.location.pathname.includes('/managers/') ||
          window.location.pathname.includes('/manager/')
        );
      }
      default:
        return false;
    }
  };

  return (
    <Fragment>
      <li className="h-12">
        <NavLink
          to={to}
          className={({ isActive }) =>
            cn(
              'h-full px-4 flex items-center text-nav text-xs font-semibold font-inter hover:bg-gray-active',
              isActive || isMatchUrl(label) ? 'bg-gray-active' : ''
            )
          }
        >
          {label}
        </NavLink>
      </li>
    </Fragment>
  );
};

NavBarLink.displayName = 'Nav.BarLink';

export default memo(NavBarLink);
