import { yupResolver as FormResolver } from '@hookform/resolvers/yup';
import { SVG } from 'assets/imagePath';
import { Auth } from 'aws-amplify';
import { Button } from 'ui-atoms';
import { Form, Auth as AuthLayout } from 'ui-molecules';
import { SET_LOADING_MODAL, SET_REGISTER_FORM } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import { useApiCall, useNavigateSearch } from 'hooks';
import { useRegisterStep2Schema } from 'pages/auth/register/register.schema';
import { FC, memo, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IRegisterStep2FormValues } from 'types';
import { postSignatureAPI, postUserAPI } from 'services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const RegisterStep2: FC = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { registerForm } = state;
  const navigate = useNavigate();
  const navigateSearch = useNavigateSearch();
  const [postSignature]: any = useApiCall(postSignatureAPI);
  const [postUser]: any = useApiCall(postUserAPI);

  useEffect(() => {
    if (!registerForm) {
      navigateSearch('/register', { step: '1' });
    }
  }, []);

  const form = useForm<IRegisterStep2FormValues>({
    defaultValues: {
      password: '',
      confirmPassword: '',
      acceptTermsAndConditions: false,
    },
    resolver: FormResolver(useRegisterStep2Schema()),
  });

  const onSubmit = async (values: IRegisterStep2FormValues) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });
      let payload = {
        ...registerForm,
        password: values.password,
      };
      const authUser = await Auth.signUp(payload);

      const security = await postSignature(authUser?.userSub);
      // const industry = registerForm?.industry?.map((item: any) => item?.value) || [];

      payload = {
        // primaryAccountId: primaryAccountId,
        userRecordId: authUser?.userSub,
        organizationId: registerForm?.attributes?.['custom:organizationId'],
        organizationName: registerForm?.attributes?.['custom:organizationName'],
        firstName: registerForm?.attributes?.['custom:firstName'],
        lastName: registerForm?.attributes?.['custom:lastName'],
        jobTitle: registerForm?.attributes?.['custom:title'],
        userRole: registerForm?.attributes?.['custom:userRole'],
        email: registerForm?.email,
        phone: registerForm?.phoneNumber,
        streetAddress: registerForm?.userBillingAddress?.streetAddress,
        addressLine2: registerForm?.userBillingAddress?.addressLine2,
        city: registerForm?.userBillingAddress?.city,
        stateOf: registerForm?.userBillingAddress?.stateOf,
        zipCode: registerForm?.userBillingAddress?.zipCode,
        country: registerForm?.userBillingAddress?.country,
        accountStatus: 'REGISTERED',
        // orgAdminContact: 'empty_flag',
        // adminPhone: registerForm?.phoneNumber,
        // adminEmail: registerForm?.phoneNumber,
        // industry
      };

      await postUser({
        body: payload,
        signature: security,
      });
      dispatch({
        type: SET_REGISTER_FORM,
        payload: {
          ...registerForm,
          password: values?.password,
          userRecordId: authUser?.userSub,
        },
      });

      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });

      navigateSearch('/register', { step: '3' });
    } catch (err) {
      console.log('show error', err.message);
      toast(err.message, {
        hideProgressBar: true,
        autoClose: 3000,
        type: 'error',
        theme: 'colored',
      });
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
      navigate('/login');
    }
  };

  return (
    <div className="max-w-[280px] w-full h-full   mx-auto flex flex-col justify-center items-center ">
      <AuthLayout.Header
        icon={SVG.register_security}
        title="Complete registration!"
        subtitle="Password must consist of at least eight characters, including at least one number & use of both lower and uppercase letters, and special characters, for example #, ?, !."
        canGoBack
      />

      <FormProvider {...form}>
        <form className="flex flex-col gap-[20px] w-full mt-[30px]">
          <Form.PasswordInput
            className="w-full"
            name="password"
            inputProps={{ id: 'password', placeholder: 'Password' }}
          />

          <Form.PasswordInput
            className="w-full"
            name="confirmPassword"
            inputProps={{
              id: 'confirmPassword',
              placeholder: 'Confirm Password',
            }}
          />

          <div className="w-full">
            <Form.CheckboxSingle
              name="acceptTermsAndConditions"
              classNameBox="justify-start mx-2.5"
              label="Accept Terms & Conditions"
            />
          </div>

          <Button.Primary
            className="mt-2.5 py-3"
            label="Register"
            onClick={form.handleSubmit(onSubmit)}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default memo(RegisterStep2);
