import { Auth } from 'aws-amplify';
import { APP_ROLE, SET_LOADING_MODAL, SET_USER_INFO } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { RouterPath } from './router.constant';
import { useAuth } from '../hooks/useAuth';

export const RequiredAuth: React.FC = () => {
  const { dispatch } = useContext(GlobalContext);
  const { CURRENT_ROLE } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);

  const checkAuth = useCallback(async () => {
    try {
      setIsLoading(true);
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      await Auth.currentSession();
      if (authenticatedUser) {
        setIsAuth(true);
        dispatch({
          type: SET_USER_INFO,
          payload: authenticatedUser?.attributes,
        });
        localStorage.setItem(
          'CODED_NOTE_userData',
          JSON.stringify(authenticatedUser?.attributes)
        );
      } else {
        setIsAuth(false);
      }
    } catch (err) {
      console.log('error', err);
      setIsAuth(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: isLoading,
    });
  }, [dispatch, isLoading]);

  const isAllowAccess = useMemo(() => {
    return (
      CURRENT_ROLE === APP_ROLE.OW ||
      CURRENT_ROLE === APP_ROLE.SA ||
      CURRENT_ROLE === APP_ROLE.MGR ||
      CURRENT_ROLE === APP_ROLE.DS ||
      CURRENT_ROLE === APP_ROLE.CO ||
      CURRENT_ROLE === APP_ROLE.CL
    );
  }, [CURRENT_ROLE]);

  if (isLoading) {
    return <></>;
  }

  if (isAuth && isAllowAccess) {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return <Navigate to={RouterPath.LOGIN} />;
};
