import { Button } from 'ui-atoms';
import { Form } from 'ui-molecules';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export interface IVerifyAccount {
  step: number;
  onClick: {
    onAccept: (step) => void;
    onDecline: () => void;
  };
}

export default function VerifyAccount({ step, onClick }: IVerifyAccount) {
  const { onAccept, onDecline } = onClick;

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {}, []);

  return (
    <div className="max-w-[280px] w-full h-auto">
      <div className="w-full h-full flex flex-col  gap-[30px]">
        <div className="title__box">
          <h2 className="text-center text-xl font-bold">Create Password</h2>
          <p className="text-sm text-center mt-5">
            Please check your email for the <br /> code or verification link.
          </p>
        </div>
        <FormProvider {...form}>
          <form className="w-full h-auto grid  grid-flow-row gap-y-5">
            <Form.TextInput
              name="confirm_code"
              inputProps={{
                placeholder: 'Confirm Code',
                className: 'ring-1',
              }}
            />
          </form>
        </FormProvider>
        <div className="flex flex-row gap-5">
          <Button.Primary
            className="flex-1 py-3"
            label={'Verify'}
            onClick={form.handleSubmit((data) => {
              console.log(data);
              onAccept(step + 1);
            })}
          />
        </div>
        <Button.Text
          className="flex-1"
          underline
          label={'Resend code'}
          onClick={() => {}}
        />
      </div>
    </div>
  );
}
