import * as React from 'react';
import FilterCheckList from './FilterCheckList';

export interface IFilterSystemProps {
  onSubmit?: () => void;
}

export default function FilterSystem({ onSubmit }: IFilterSystemProps) {
  const checkList = [
    {
      label: 'Oldest First',
      value: 'odest_frist',
    },
    {
      label: 'Order of Arrival',
      value: 'order_arrival',
    },
    {
      label: 'Priority',
      value: 'priority',
    },
    {
      label: 'Untouched Records',
      value: 'untouch_record',
    },
    {
      label: 'Touched Records',
      value: 'touch_record',
    },
  ];

  return <FilterCheckList title={'System Filters'} checkList={checkList} />;
}
