import * as React from 'react';
import { ObjectUtils } from 'utils';
import cn from 'classnames';

export interface IDetailRow {
  subTitle: string;
  content: React.ReactNode;
  colorContent?: 'success' | 'error';
  isHide?: boolean;
  importantRow?: boolean;
  className?: {
    classNameContent?: string;
    cn?: string;
  };
  renderContent?: (row) => React.ReactNode;
}

export interface IDetailBoxProps {
  title?: string;
  detailRows: IDetailRow[];
}

function DetailBox({ title, detailRows }: IDetailBoxProps) {
  return (
    <div className="w-full grid grid-cols-2">
      {title && (
        <div className="text-xs font-bold col-span-2 p-[5px] px-2.5">
          {title}
        </div>
      )}
      {ObjectUtils.isArray(detailRows) &&
        detailRows.map(
          (row, idx) =>
            !row?.isHide && (
              <React.Fragment key={idx}>
                <div
                  className={cn(
                    'text-right text-black whitespace-pre-wrap text-xs p-[5px]',
                    row?.importantRow ? 'font-bold' : '',
                    row?.className?.cn || ''
                  )}
                >
                  {row?.subTitle}
                </div>
                {row?.renderContent ? (
                  row?.renderContent(row)
                ) : (
                  <div
                    className={cn(
                      `text-right text-xs font-normal break-words p-[5px]`,

                      row?.colorContent === 'success'
                        ? 'text-success'
                        : row?.colorContent === 'error'
                        ? 'text-error'
                        : '',
                      row?.className?.classNameContent || ''
                    )}
                  >
                    {row?.content}
                  </div>
                )}
              </React.Fragment>
            )
        )}
    </div>
  );
}
export default React.memo(DetailBox);
