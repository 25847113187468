import { FC, memo, ReactNode } from 'react';
import cn from 'classnames';

type Props = {
  Component?: any;
  className?: string;
  children?: ReactNode;
  [rest: string]: any;
};

const BoxStandard: FC<Props> = ({
  Component = 'div',
  className,
  children,
  ...rest
}) => {
  return (
    <Component
      className={cn(
        'p-[10px]',
        'w-full',
        'bg-white',
        'rounded-[10px]',
        'border-nav border border-solid',
        className
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};

BoxStandard.displayName = 'Box.Standard';

export default memo(BoxStandard);
