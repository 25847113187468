import * as React from 'react';
import { ITableColumn } from '../../../types';
import { FiPlus } from 'react-icons/fi';

type Props = {
  rows: any[];
  fontSize: number;
  handleNavigateOrganizationDetail: (row) => void;
};

const ListOrganizationColumn: ITableColumn[] = [
  {
    field: 'organizationName',
    headerName: 'Organization',
  },
  {
    field: 'orgInitials',
    headerName: 'Org Initials',
  },
  {
    field: 'orgShort',
    headerName: 'Org Short Name',
  },
  {
    field: 'adminFirstName',
    headerName: 'Admin name',
  },
  {
    field: 'servicePhone',
    headerName: 'Admin Phone',
    headerStyle: { textAlign: 'center' },
  },
  {
    field: 'adminEmail',
    headerName: 'Admin Email',
  },
  {
    field: 'func',
    headerName: '',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
];

export const ListOrganization = ({
  rows,
  fontSize,
  handleNavigateOrganizationDetail,
}: Props) => {
  const renderRow = (row) => {
    return (
      <tr className="border border-t-0">
        <td className="h-full text-left p-table whitespace-nowrap min-w-fit justify-center">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            {row.organizationName}
          </div>
        </td>
        <td className="h-full text-left p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            {row.orgInitials}
          </div>
        </td>
        <td className="h-full text-left p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            {row.orgShort}
          </div>
        </td>
        <td className="h-full text-left p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            {row.adminFirstName}
          </div>
        </td>
        <td className="h-full text-left p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            {row.servicePhone}
          </div>
        </td>
        <td className="h-full text-left p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            {row.adminEmail}
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <FiPlus
              className="text-gray-active w-4 h-4 cursor-pointer"
              onClick={() => handleNavigateOrganizationDetail(row)}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <table className={'table-auto w-full h-full z-0'}>
      <thead>
        <tr>
          {ListOrganizationColumn.map((column) => {
            return (
              !column?.hideCol && (
                <th
                  key={column.field}
                  className="p-table text-center border font-[600] whitespace-nowrap min-w-fit"
                >
                  <p style={{ fontSize: `${fontSize}px` }}>
                    {column.headerName}
                  </p>
                </th>
              )
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows?.map((row) => {
          return renderRow(row);
        })}
      </tbody>
    </table>
  );
};
