import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, DocAssistant } from 'ui-organisms';
import { Footer, Form, HeaderApp } from 'ui-molecules';
import DefaultLayout from 'layouts/DefaultLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import { IAttachmentsTable, ICommentsTable } from '../../types';
import { useCoderEditSchema } from './coder-edit.schema';
import cn from 'classnames';
import { Attachments, Comments } from '../organization/tables';
import { APP_ROLE, SET_LOADING_MODAL, TIMEZONE_OPTIONS } from '../../constant';
import { GlobalContext } from '../../context/GlobalContext';
import { useApiCall } from '../../hooks';
import {
  getListOrganizationsAPI,
  getUserAPI,
  updateUserAPI,
} from '../../services';
import { RouterPath } from '../../routes/router.constant';
import { SVG } from '../../assets/imagePath';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const CoderDetail = () => {
  const form = useForm({
    defaultValues: {
      organization: {
        label: '',
        value: '',
        short: '',
        initials: '',
      },
      staffName: '',
      role: '',
      organizationName: '',
      cellPhone: '',
      email: '',
      timeZone: '',
      specialty: '',
      ranking: '',
      firstName: '',
      middleName: '',
      lastName: '',
      initials: '',
      sortName: '',
      givenName: '',
      prefix: '',
      suffix: '',
      degree: '',
      staffRecordStatus: '',
      orgShort: '',
      orgInitials: '',
      locations: '',
      site: '',
      important: '',
      staffTimeZone: '',
      organizationId: '',
      serviceLevel: '',
      priority: '',
      startDate: new Date(),
    },
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: FormResolver(useCoderEditSchema()),
  });
  const { state, dispatch } = useContext(GlobalContext);
  const { CURRENT_ROLE } = useAuth();
  const navigate = useNavigate();
  const { userRecordId: clinicianId, primaryAccountId: organizationId } = state;
  const [fetchCoder, loading, coderDetail] = useApiCall(getUserAPI);
  const [updateCoder] = useApiCall(updateUserAPI);
  const [fetchOrganization, loadingOrganization, listOrganization] = useApiCall(
    getListOrganizationsAPI
  );
  const [toggle, setToggle] = React.useState(false);
  const [fontSize, setFontSize] = useState(12);
  const [attachmentRows, setAttachmentRows] = React.useState<
    IAttachmentsTable[]
  >([
    {
      id: uuidv4(),
      name: 'Attachments 1',
      format: 'PDF',
      author: 'Marcus Welby',
      comment: 'Contact sheet',
    },
  ]);

  const [commentRows, setCommentRows] = React.useState<ICommentsTable[]>([
    {
      id: uuidv4(),
      date: '03/18/2023',
      full_name: 'Julie Medley',
      role: 'DS',
      comment: 'Betty MacDonald',
    },
  ]);

  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }

  useEffect(() => {
    fetchCoder({ primaryAccountId: organizationId, userRecordId: clinicianId });
    fetchOrganization();
  }, [clinicianId, organizationId]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading | loadingOrganization,
    });
  }, [dispatch, loading, loadingOrganization]);

  useEffect(() => {
    coderDetail?.userRole && form.setValue('role', coderDetail?.userRole);
    coderDetail?.cellPhone &&
      form.setValue('cellPhone', coderDetail?.cellPhone);
    coderDetail?.degree && form.setValue('degree', coderDetail?.degree);
    coderDetail?.email && form.setValue('email', coderDetail?.email);
    coderDetail?.firstName &&
      form.setValue('firstName', coderDetail?.firstName);
    coderDetail?.initials && form.setValue('initials', coderDetail?.initials);
    coderDetail?.lastName && form.setValue('lastName', coderDetail?.lastName);
    coderDetail?.locations &&
      form.setValue('locations', coderDetail?.locations);
    coderDetail?.middleName &&
      form.setValue('middleName', coderDetail?.middleName);
    coderDetail?.organizationId &&
      form.setValue('organizationId', coderDetail?.organizationId);
    coderDetail?.organizationName &&
      form.setValue('organizationName', coderDetail?.organizationName);
    coderDetail?.specialty &&
      form.setValue('specialty', coderDetail?.specialty);
    coderDetail?.timeZone && form.setValue('timeZone', coderDetail?.timeZone);
  }, [
    coderDetail?.cellPhone,
    coderDetail?.degree,
    coderDetail?.email,
    coderDetail?.firstName,
    coderDetail?.initials,
    coderDetail?.lastName,
    coderDetail?.locations,
    coderDetail?.middleName,
    coderDetail?.organizationId,
    coderDetail?.organizationName,
    coderDetail?.specialty,
    coderDetail?.timeZone,
    coderDetail?.userRole,
    form,
  ]);

  const organizationOption = useMemo(() => {
    return listOrganization?.map((orgItem) => ({
      label: orgItem?.organizationName,
      value: orgItem?.organizationId,
      short: orgItem?.orgShort,
      initials: orgItem?.orgInitials,
    }));
  }, [listOrganization]);

  const organizationData = form.watch('organization');
  useEffect(() => {
    form.setValue('organizationName', organizationData.label);
    form.setValue('organizationId', organizationData.value);
    form.setValue('orgShort', organizationData.short);
    form.setValue('orgInitials', organizationData.initials);
  }, [form, organizationData]);
  const updateAttachmentRows = (id: string, row: IAttachmentsTable) => {
    const newRow = attachmentRows?.map((it) => {
      if (it.id === id) {
        return {
          ...it,
          ...row,
        };
      }
      return it;
    });

    setAttachmentRows(newRow);
  };

  const updateCommentRows = (id: number, row: ICommentsTable) => {
    const newRow = commentRows?.map((it) => {
      if (it.id === id) {
        return {
          ...it,
          ...row,
        };
      }
      return it;
    });

    setCommentRows(newRow);
  };

  const renderPart = (title: string, element: any, isMl?: boolean) => {
    return (
      <div className="w-full flex items-start gap-x-10">
        {title && (
          <div
            className={cn('text-xs font-bold py-4 w-1/4', isMl ? 'ml-9' : '')}
            style={{ fontSize: `${fontSize}px` }}
          >
            {title}
          </div>
        )}
        <div className="w-full mt-6">{element}</div>
      </div>
    );
  };

  const renderVerticalRow = (headerName: string, cell: any) => {
    return (
      <div className=" w-full flex flex-col gap-y-4 py-4">
        <div className="flex items-center gap-x-2">
          <div className="w-2/5 justify-end">
            <p
              className="text-xs text-right"
              style={{ fontSize: `${fontSize}px` }}
            >
              {headerName}
            </p>
          </div>
          <div
            className="w-full flex flex-1 items-center justify-end"
            style={{ fontSize: `${fontSize}px` }}
          >
            {cell}
          </div>
        </div>
      </div>
    );
  };

  const renderTablePart = (title: string, element: any) => {
    return (
      <div
        className="flex flex-col gap-4 items-center w-full"
        style={{ fontSize: `${fontSize}px` }}
      >
        <p className={'font-bold text-center py-1'}>{title}</p>
        <div className="!overflow-auto  w-full max-h-full z-0">{element}</div>
      </div>
    );
  };

  const handleSubmitFunc = async (data) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });
      await updateCoder({
        body: {
          ...coderDetail,
          staffName: data.staffName,
          role: data.role,
          organizationName: data.organizationName,
          cellPhone: data.cellPhone,
          email: data.email,
          timeZone: data.timeZone,
          specialty: data.specialty,
          ranking: data.ranking,
          firstName: data.firstName,
          middleName: data?.middleName,
          lastName: data?.lastName,
          initials: data.initials,
          sortName: data.sortName,
          givenName: data.givenName,
          prefix: data.prefix,
          suffix: data.suffix,
          degree: data.degree,
          staffRecordStatus: data.staffRecordStatus,
          orgShort: data.orgShort,
          orgInitials: data.orgInitials,
          locations: data.locations,
          site: data.site,
          important: data.important,
          staffTimeZone: data.staffTimeZone,
          startDate: data.startDate,
          organizationId: data.organizationId,
          serviceLevel: data.serviceLevel,
          priority: data.priority,
          attachments: attachmentRows,
          comments: commentRows,
        },
      });
    } catch (err) {
      console.log('error', err);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
    }
    setTimeout(() => {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
    }, 100);
  };

  return (
    <DefaultLayout>
      <HeaderApp.Detail
        toggle={toggle}
        setToggle={setToggle}
        detailProps={{
          detailName: ` - Details`,
          labelDetail: `DC Details - `,
          addLabel: 'Save',
        }}
        viewName="Coder Detail"
        setFontSize={setFontSize}
        onAdd={form.handleSubmit(handleSubmitFunc)}
      />
      <div className="w-full  flex flex-col flex-1 justify-center items-center p-2.5 mb-[50px]">
        <div
          className=" w-full flex items-center text-left text-xs text-black font-bold px-2.5 gap-x-[5px] cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <img src={SVG.chevron_left} className="icon-app" alt="icon-back" />
        </div>
        <div className="flex items-stretch flex-1 gap-2.5 w-full h-full mt-2.5">
          <Box.Standard
            className={`max-w-[180px] h-auto w-full transition-all ${
              toggle ? '' : '-translate-x-[150%] fixed'
            }`}
          >
            <DocAssistant.SideBar title="CO Information" />
          </Box.Standard>
          <FormProvider {...form}>
            <form className="gap-2.5 items-stretch h-full w-full flex-1">
              <Box.Standard className="w-full grid grid-cols-2 gap-[10px]">
                <div>
                  {renderVerticalRow(
                    '*Staff Name',
                    <Form.TextInput
                      name="staffName"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('staffName'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                  {renderVerticalRow(
                    '*Role',
                    <Form.TextInput
                      name="role"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('role'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                  {renderVerticalRow(
                    '*Organization',
                    <Form.TextInput
                      name="organizationName"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('organizationName'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                  {renderVerticalRow(
                    '*Cell Phone',
                    <Form.TextInput
                      name="cellPhone"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('cellPhone'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                </div>
                <div>
                  {renderVerticalRow(
                    '*Email',
                    <Form.TextInput
                      name="email"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('email'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                  {renderVerticalRow(
                    'Time Zone',
                    <Form.TextInput
                      name="timeZone"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('timeZone'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                  {renderVerticalRow(
                    '*Specialties',
                    <Form.TextInput
                      name="specialties"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('specialty'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                  {renderVerticalRow(
                    'Ranking',
                    <Form.TextInput
                      name="ranking"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('ranking'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                </div>
              </Box.Standard>
              <Box.Standard className="w-full grid grid-cols-2 gap-[10px] mt-2.5">
                <div>
                  {renderPart(
                    'Name Info',
                    <>
                      {renderVerticalRow(
                        '*First Name (Goes by)',
                        <Form.TextInput
                          name="firstName"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('firstName'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        '*Last Name',
                        <Form.TextInput
                          name="lastName"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('lastName'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        '*Middle Name',
                        <Form.TextInput
                          name="middleName"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('middleName'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        '*Initials',
                        <Form.TextInput
                          name="initials"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('initials'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        '*Sort Name - Clin',
                        <Form.TextInput
                          name="sortName"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('sortName'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Given Name (First)',
                        <Form.TextInput
                          name="givenName"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('givenName'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Prefix',
                        <Form.SelectInput
                          name="prefix"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Sir',
                              value: 'Sir',
                            },
                          ]}
                          defaultValue={{
                            label: 'Sir',
                            value: 'Sir',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Suffix',
                        <Form.SelectInput
                          name="suffix"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Jr',
                              value: 'Jr',
                            },
                          ]}
                          defaultValue={{
                            label: 'Jr',
                            value: 'Jr',
                          }}
                        />
                      )}
                      {renderVerticalRow('', <></>)}
                      {renderVerticalRow('', <></>)}
                      {renderVerticalRow('', <></>)}
                    </>,
                    true
                  )}
                  {renderPart(
                    'Education',
                    <>
                      {renderVerticalRow(
                        'Degrees',
                        <Form.SelectInput
                          name="degree"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'MBA, MPH',
                              value: 'MBA, MPH',
                            },
                          ]}
                          defaultValue={{
                            label: 'MBA, MPH',
                            value: 'MBA, MPH',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        '*Specialties',
                        <Form.SelectInput
                          name="specialties"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'PC - FP, UC',
                              value: 'PC - FP, UC',
                            },
                          ]}
                          defaultValue={{
                            label: 'PC - FP, UC',
                            value: 'PC - FP, UC',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Staff Record Status',
                        <Form.SelectInput
                          name="staffRecordStatus"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Active',
                              value: 'Active',
                            },
                          ]}
                          defaultValue={{
                            label: 'Active',
                            value: 'Active',
                          }}
                        />
                      )}
                    </>,
                    true
                  )}
                </div>
                <div>
                  {renderPart(
                    'Organization',
                    <>
                      {renderVerticalRow(
                        '*Organization',
                        <Form.SelectInputV2
                          name="organization"
                          className="flex-1 bg-white"
                          defaultValue={organizationOption?.find(
                            (org) => org?.value === coderDetail?.organizationId
                          )}
                          options={organizationOption}
                        />
                      )}
                      {renderVerticalRow(
                        '*Org Short',
                        <Form.TextInput
                          name="orgShort"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('orgShort'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                          disable
                        />
                      )}
                      {renderVerticalRow(
                        '*Org. Initials',
                        <Form.TextInput
                          name="orgInitials"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('orgInitials'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                          disable
                        />
                      )}
                      {renderVerticalRow(
                        'Location',
                        <Form.SelectInput
                          name="locations"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Smithville',
                              value: 'Smithville',
                            },
                          ]}
                          defaultValue={{
                            label: 'Smithville',
                            value: 'Smithville',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Site',
                        <Form.SelectInput
                          name="site"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'The Smith Building',
                              value: 'The Smith Building',
                            },
                          ]}
                          defaultValue={{
                            label: 'The Smith Building',
                            value: 'The Smith Building',
                          }}
                        />
                      )}
                    </>
                  )}
                  {renderPart(
                    'Key Info',
                    <>
                      {renderVerticalRow(
                        'Important',
                        <Form.SelectInput
                          name="important"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Text - Do not Call',
                              value: 'Text - Do not Call',
                            },
                          ]}
                        />
                      )}
                      {renderVerticalRow(
                        'Staff Time Zone',
                        <Form.SelectInput
                          name="staffTimeZone"
                          className="flex-1 bg-white"
                          options={TIMEZONE_OPTIONS}
                        />
                      )}
                    </>,
                    true
                  )}
                  {renderPart(
                    'Identification',
                    <>
                      {renderVerticalRow(
                        '*Start Date',
                        <Form.DatePicker
                          name="startDate"
                          inputProps={{ id: 'startDate', placeholder: '' }}
                          date={form?.watch('startDate')}
                          onSelect={(value: Date) => {
                            form.setValue('startDate', value);
                          }}
                          className="text-right"
                        />
                      )}
                      {renderVerticalRow(
                        '*Organization ID',
                        <Form.TextInput
                          name="organizationId"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('organizationId'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] ',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Service Level',
                        <Form.SelectInput
                          name="serviceLevel"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Full Service',
                              value: 'Full Service',
                            },
                          ]}
                          defaultValue={{
                            label: 'Full Service',
                            value: 'Full Service',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Priority',
                        <Form.SelectInput
                          name="priority"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Neutral',
                              value: 'Neutral',
                            },
                          ]}
                          defaultValue={{
                            label: 'Neutral',
                            value: 'Neutral',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Ranking',
                        <Form.SelectInput
                          name="ranking"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Top 10%',
                              value: 'Top 10%',
                            },
                          ]}
                          defaultValue={{
                            label: 'Top 10%',
                            value: 'Top 10%',
                          }}
                        />
                      )}
                    </>,
                    true
                  )}
                </div>
              </Box.Standard>
              <Box.Standard className="mt-2.5">
                {renderTablePart(
                  'Attachments',
                  <Attachments
                    fontSize={fontSize}
                    rows={attachmentRows}
                    setRows={setAttachmentRows}
                    updateValue={updateAttachmentRows}
                  />
                )}
              </Box.Standard>
              <Box.Standard className="mt-2.5">
                {renderTablePart(
                  'Comments',
                  <Comments
                    fontSize={fontSize}
                    rows={commentRows}
                    setRows={setCommentRows}
                    updateValue={updateCommentRows}
                  />
                )}
              </Box.Standard>
            </form>
          </FormProvider>
        </div>
      </div>
      <Footer />
    </DefaultLayout>
  );
};

export default CoderDetail;
