import { API } from 'aws-amplify';
import { config } from 'config';

export const checkCorporateRecordAPI = async (user) => {
  return await API.post(config.API_NAME, '/corporate-record-check', {
    body: user,
  });
};

export const postSignatureAPI = async (userRecordId: string) => {
  return await API.post(
    config.API_NAME,
    `/user-security?sign=true&userRecordId=${userRecordId}`,
    {
      body: {},
    }
  );
};

export const postCorporateAPI = async (payload: any) => {
  return await API.post(config.API_NAME, `/corporate-record`, {
    body: payload.body,
    headers: {
      'x-ecom-request': payload.signature,
    },
  });
};

export const postUserAPI = async (payload: any) => {
  return await API.post(config.API_NAME, `/users/add`, {
    body: payload.body,
    headers: {
      'x-ecom-request': payload.signature,
    },
  });
};

export const getRedemptionCode = async (userRecordId: string) => {
  return await API.get(
    config.API_NAME,
    `/get-redemption-code/${userRecordId}`,
    {

    }
  );
};
