import { yupResolver as FormResolver } from '@hookform/resolvers/yup';
import { IMAGES, SVG } from 'assets/imagePath';
import { Auth } from 'aws-amplify';
import { Auth as AuthLayout, Form } from 'ui-molecules';
import { Button, Checkbox } from 'ui-atoms';
import { SET_LOADING_MODAL } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import { FC, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';
import { ILoginFormValues } from 'types';
import { useLoginSchema } from './login.schema';
import { toast } from 'react-toastify';
import { useNavigateSearch } from '../../../hooks';

const LoginPage: FC = () => {
  const { dispatch } = useContext(GlobalContext);
  const [checked, setChecked] = useState(false);

  const form = useForm<ILoginFormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: FormResolver(useLoginSchema()),
  });
  const navigate = useNavigate();
  const navigateSearch = useNavigateSearch();

  const onSubmit = async (values: ILoginFormValues) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });
      const response = await Auth.signIn(
        values?.email.toLowerCase().trim(),
        values?.password
      );
      if (!response) return;
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
      navigate(RouterPath.NOTES);
    } catch (err) {
      console.log('Login fail', err);
      toast(err.message, {
        hideProgressBar: true,
        autoClose: 3000,
        type: 'error',
        theme: 'colored',
      });
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
      if (err.message === 'User does not exist.') {
        return navigate('/register?step=1');
      }
      if (err.message === 'User is not confirmed.') {
        return navigateSearch('/register', { step: '3' });
      }
    }
  };

  useEffect(() => {
    const inputPassword = document.getElementById('password');
    inputPassword.addEventListener('keypress', function (event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        document.getElementById('submit').click();
      }
    });
  }, []);

  return (
    <div className="flex w-full h-screen">
      <AuthLayout.Left
        image={IMAGES.side_auth_image}
        title={<AuthLayout.TitleCover />}
        subtitle="Restoring The Joy of Medicine"
      />
      <div className="flex flex-col items-center justify-center flex-1 p-5">
        <div className="flex flex-col flex-1 justify-center items-center max-w-[280px] w-full">
          <AuthLayout.Header
            icon={SVG.login_enter}
            title="Hello Again!"
            subtitle=""
          />
          <FormProvider {...form}>
            <form className="w-full">
              <Form.EmailInput
                className="w-full mt-[30px]"
                name="email"
                inputProps={{
                  id: 'email',
                  placeholder: 'E-mail',
                }}
              />

              <Form.PasswordInput
                className="w-full mt-5"
                name="password"
                inputProps={{
                  id: 'password',
                  placeholder: 'Password',
                }}
              />

              <div className="flex flex-wrap justify-between items-center mt-5 gap-[5px]">
                <Checkbox.Single
                  className="ml-2"
                  label="Remember me"
                  value={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />

                <Link
                  to={RouterPath?.FORGOT_PASSWORD}
                  className="mx-2 text-sm underline text-input md:mx-0"
                >
                  Recovery Password
                </Link>
              </div>

              <Button.Primary
                id="submit"
                className="mt-[30px] py-3"
                label="Login"
                onClick={form.handleSubmit(onSubmit)}
              />
            </form>
          </FormProvider>
        </div>
        <p className="text-sm text-input font-inter mb-7">
          Don’t have an account yet?
          <Link
            to="/register?step=1"
            className="ml-1 font-bold transition duration-200 ease-in-out text-primary hover:text-primary hover:opacity-75 focus:text-primary"
          >
            Sign Up
          </Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
