import { Form } from 'ui-molecules';
import { SearchStandardProps } from 'ui-molecules/search/SearchStandard';
import * as React from 'react';

export interface IFilterSearchProps {
  onClearSearchBox?: () => void;
  title: React.ReactNode;
}

export default function FilterSearch({
  title,
  onClearSearchBox,
  ...rest
}: IFilterSearchProps & SearchStandardProps) {
  return (
    <Form.SearchBox
      name="search_box"
      className="w-ful rounded-full border border-nav border-solid  bg-transparent px-2.5 py-[5px]"
      clearInput={onClearSearchBox}
      {...rest}
    />
  );
}
