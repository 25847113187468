import * as React from 'react';
import { IMAGES, SVG } from '../../assets/imagePath';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useApiCall } from '../../hooks';
import { getRedemptionCode } from '../../services';
import { GlobalContext } from '../../context/GlobalContext';
import { SET_LOADING_MODAL } from '../../constant';
import { toast } from 'react-toastify';

export const ActiveUserStep4 = () => {
  const { dispatch } = useContext(GlobalContext);

  const [getRedem, loading, redemData] = useApiCall(getRedemptionCode);

  const [code, setCode] = useState('');
  const [url, setUrl] = useState('');

  React.useEffect(() => {
    setTimeout(() => {
      getInfo();
    }, 100);
  }, []);

  const getInfo = useCallback(async () => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });
      const currentUser = await Auth.currentAuthenticatedUser();
      await getRedem(currentUser.username);
    } catch (err) {
      toast(err.message, {
        hideProgressBar: true,
        autoClose: 3000,
        type: 'error',
        theme: 'colored',
      });
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
    }
  }, [dispatch, getRedem]);

  useEffect(() => {
    if (redemData) {
      setCode(redemData?.redemptionCode);
      setUrl(redemData?.redemptionURL);
    }
  }, [redemData]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading,
    });
  }, [dispatch, loading]);

  const navigateAppStore = () => {
    window.open(url);
  };

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <img src={SVG.avatar_circle} width={110} alt="logo" className="mb-10" />
      <h1 className="text-xl font-bold mb-5">Get Started on iOS</h1>
      <p className="xs:text-center mb-12">
        Below you will find a quick start up guide to start using icqc.ai
      </p>
      <h1 className="text-md font-bold mb-12">
        This is your Redemption Code that you MUST use <br />
        when downloading the application to your phone:
      </h1>

      <h1 className="xs:text-xl sm:text-2xl text-3xl font-bold text-red-500 mb-12">
        {code}
      </h1>

      <h1 className="text-md font-bold mb-12">DOWNLOAD CodedNote Here:</h1>

      <img
        src={IMAGES.app_store}
        className="max-w-[210px] w-full h-auto mb-20"
        alt="ios-store"
        onClick={navigateAppStore}
      />
      <div className="px-2 md:px-0 text-left">
        <p className="mb-6">CodedNote, v1.0.8</p>

        <p>
          <span className="underline">
            ICQC.ai End User Terms and Conditions:
          </span>
          Code is redeemable only on the App Store for the United States.
          <br /> Requires an iTunes account, subject to prior acceptance of
          license and usage terms. To open an account you
          <br /> must be above the age of 13 and in United States. Compatible
          software and hardware, and internet access
          <br /> (fees may apply) required. Not for resale. Full terms apply;
          see
          <a href="www.apple.com/legal/itunes/ww/">
            www.apple.com/legal/itunes/ww/
          </a>
          . For more
          <br /> information, see
          <a href="www.apple.com/support/">www.apple.com/support/</a>.
        </p>

        <p className="my-6">Regards,</p>
        <p>The Volume Purchase Program team</p>
      </div>
    </div>
  );
};
