import * as Yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const allowDepartment = [
  'Technical Support',
  'Customer Support',
  'Billing & Accounting',
  'Fraud & Security',
];

export const useSupportSchema = () => {
  return Yup.object().shape({
    fullName: Yup.string()
      .nullable()
      .optional()
      .when('department', {
        is: (value: string) => allowDepartment.includes(value),
        then: Yup.string()
          .transform((subject: string) => subject.trim())
          .required('Full Name is required'),
      }),
    email: Yup.string()
      .nullable()
      .optional()
      .email('Email is invalid')
      .when('department', {
        is: (value: string) => allowDepartment.includes(value),
        then: Yup.string()
          .transform((subject: string) => subject.trim())
          .required('Email is required'),
      }),
    phone: Yup.string()
      .nullable()
      .optional()
      .matches(phoneRegExp, {
        message: 'Cell Phone is not valid',
        excludeEmptyString: true,
      })
      .when('department', {
        is: (value: string) => allowDepartment.includes(value),
        then: Yup.string()
          .transform((subject: string) => subject.trim())
          .required('Phone is required'),
      }),
    department: Yup.string().required('Department is required'),
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required'),
  });
};
