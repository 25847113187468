import { RouterPath } from 'routes/router.constant';
import { INavLink } from 'types';

export const NAV_LINKS: INavLink[] = [
  {
    label: 'Notes',
    to: RouterPath.NOTES,
    // authPermission: [APP_ROLE.SA, APP_ROLE.CO, APP_ROLE.DS, APP_ROLE.OW]
  },

  {
    label: 'Organizations',
    to: RouterPath.ORGANIZATIONS,
    // authPermission: [APP_ROLE.SA]
  },
  {
    label: 'Clinicians',
    to: RouterPath.CLINICIANS,
    // authPermission: [APP_ROLE.SA]
  },
  {
    label: 'Doc Specialists',
    to: RouterPath.DOC_ASSISTANTS,
    // authPermission: [APP_ROLE.SA, APP_ROLE.OW]
  },
  {
    label: 'Coders',
    to: RouterPath.CODERS,
    // authPermission: [APP_ROLE.SA, APP_ROLE.OW]
  },
  {
    label: 'Managers',
    to: RouterPath.MANAGERS,
    // authPermission: [APP_ROLE.SA]
  },
  // {
  //   label: 'System Admins',
  //   to: RouterPath.SYSTEM_ADMINS,
  //   authPermission: [APP_ROLE.SA]
  // },
  {
    label: 'Settings',
    to: RouterPath.SETTINGS,
    // authPermission: [APP_ROLE.SA]
  },
];
