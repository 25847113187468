import * as React from 'react';
import { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, DocAssistant } from 'ui-organisms';
import { Footer, Form, HeaderApp } from 'ui-molecules';
import DefaultLayout from 'layouts/DefaultLayout';
import { IAttachmentsTable, ICommentsTable } from 'types';
import { APP_ROLE, SET_LOADING_MODAL, TIMEZONE_OPTIONS } from '../../constant';
import { Attachments, Comments } from '../organization/tables';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import cn from 'classnames';
import { useManagerEditSchema } from './manager-edit.schema';
import { RouterPath } from '../../routes/router.constant';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalContext';

const ManagerDetail = () => {
  const { dispatch } = useContext(GlobalContext);
  const { CURRENT_ROLE } = useAuth();

  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      staffName: '',
      role: '',
      organization: '',
      cellPhone: '',
      email: '',
      timeZone: '',
      specialties: '',
      ranking: '',
      firstName: '',
      middleName: '',
      lastName: '',
      initials: '',
      sortName: '',
      givenName: '',
      prefix: '',
      suffix: '',
      degrees: '',
      staffRecordStatus: '',
      orgShort: '',
      orgInitials: '',
      location: '',
      site: '',
      department: '',
      important: '',
      staffTimeZone: '',
      orgId: '',
      serviceLevel: '',
      priority: '',
      startDate: new Date(),
    },
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: FormResolver(useManagerEditSchema()),
  });
  const [toggle, setToggle] = React.useState(false);
  const [fontSize, setFontSize] = useState(12);
  const [attachmentRows, setAttachmentRows] = React.useState<
    IAttachmentsTable[]
  >([
    {
      id: uuidv4(),
      name: 'Attachments 1',
      format: 'PDF',
      author: 'Marcus Welby',
      comment: 'Contact sheet',
    },
  ]);

  const [commentRows, setCommentRows] = React.useState<ICommentsTable[]>([
    {
      id: uuidv4(),
      date: '03/18/2023',
      full_name: 'Julie Medley',
      role: 'DS',
      comment: 'Betty MacDonald',
    },
  ]);

  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }

  const updateAttachmentRows = (id: string, row: IAttachmentsTable) => {
    const newRow = attachmentRows?.map((it) => {
      if (it.id === id) {
        return {
          ...it,
          ...row,
        };
      }
      return it;
    });

    setAttachmentRows(newRow);
  };

  const updateCommentRows = (id: number, row: ICommentsTable) => {
    const newRow = commentRows?.map((it) => {
      if (it.id === id) {
        return {
          ...it,
          ...row,
        };
      }
      return it;
    });

    setCommentRows(newRow);
  };

  const renderPart = (title: string, element: any, isMl?: boolean) => {
    return (
      <div className="w-full flex items-start gap-x-10">
        {title && (
          <div
            className={cn('text-xs font-bold py-4 w-1/4', isMl ? 'ml-9' : '')}
            style={{ fontSize: `${fontSize}px` }}
          >
            {title}
          </div>
        )}
        <div className="w-full mt-6">{element}</div>
      </div>
    );
  };

  const renderVerticalRow = (headerName: string, cell: any) => {
    return (
      <div className=" w-full flex flex-col gap-y-4 py-4">
        <div className="flex items-center gap-x-2">
          <div className="w-2/5 justify-end">
            <p
              className="text-xs text-right"
              style={{ fontSize: `${fontSize}px` }}
            >
              {headerName}
            </p>
          </div>
          <div
            className="w-full flex flex-1 items-center justify-end"
            style={{ fontSize: `${fontSize}px` }}
          >
            {cell}
          </div>
        </div>
      </div>
    );
  };

  const renderTablePart = (title: string, element: any) => {
    return (
      <div
        className="flex flex-col gap-4 items-center w-full"
        style={{ fontSize: `${fontSize}px` }}
      >
        <p className={'font-bold text-center py-1'}>{title}</p>
        <div className="!overflow-auto  w-full max-h-full z-0">{element}</div>
      </div>
    );
  };

  return (
    <DefaultLayout>
      <HeaderApp.Detail
        toggle={toggle}
        setToggle={setToggle}
        detailProps={{
          detailName: ` - Details`,
          labelDetail: `Manager Details - `,
        }}
        viewName="Manager Details"
        setFontSize={setFontSize}
      />
      <div className="w-full flex flex-col flex-1 justify-center items-center mb-[50px] p-2.5">
        <div className="flex items-stretch flex-1 gap-2.5 w-full h-full mt-2.5">
          <Box.Standard
            className={`max-w-[180px] h-auto w-full transition-all ${
              toggle ? '' : '-translate-x-[150%] fixed'
            }`}
          >
            <DocAssistant.SideBar title="MGR Information" />
          </Box.Standard>

          <FormProvider {...form}>
            <form className="gap-2.5 items-stretch h-full w-full mt-2.5 flex-1">
              <Box.Standard className="w-full grid grid-cols-2 gap-[10px]">
                <div>
                  {renderVerticalRow(
                    '*Staff Name',
                    <Form.TextInput
                      name="staffName"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('staffName'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                  {renderVerticalRow(
                    '*Role',
                    <Form.TextInput
                      name="role"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('role'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                  {renderVerticalRow(
                    '*Organization',
                    <Form.TextInput
                      name="organization"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('organization'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                  {renderVerticalRow(
                    '*Cell Phone',
                    <Form.TextInput
                      name="cellPhone"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('cellPhone'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                </div>
                <div>
                  {renderVerticalRow(
                    '*Email',
                    <Form.TextInput
                      name="email"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('email'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                  {renderVerticalRow(
                    'Time Zone',
                    <Form.TextInput
                      name="timeZone"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('timeZone'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                  {renderVerticalRow(
                    '*Specialties',
                    <Form.TextInput
                      name="specialties"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('specialties'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                  {renderVerticalRow(
                    'Ranking',
                    <Form.TextInput
                      name="ranking"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('ranking'),
                        className:
                          'outlined-none !p-1 w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                    />
                  )}
                </div>
              </Box.Standard>
              <Box.Standard className="w-full grid grid-cols-2 gap-[10px] mt-2.5">
                <div>
                  {renderPart(
                    'Name Info',
                    <>
                      {renderVerticalRow(
                        '*First Name (Goes by)',
                        <Form.TextInput
                          name="firstName"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('firstName'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        '*Last Name',
                        <Form.TextInput
                          name="lastName"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('lastName'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        '*Middle Name',
                        <Form.TextInput
                          name="middleName"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('middleName'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        '*Initials',
                        <Form.TextInput
                          name="initials"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('initials'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        '*Sort Name - Clin',
                        <Form.TextInput
                          name="sortName"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('sortName'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Given Name (First)',
                        <Form.TextInput
                          name="givenName"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('givenName'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Prefix',
                        <Form.SelectInput
                          name="prefix"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Sir',
                              value: 'Sir',
                            },
                          ]}
                          defaultValue={{
                            label: 'Sir',
                            value: 'Sir',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Suffix',
                        <Form.SelectInput
                          name="suffix"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Jr',
                              value: 'Jr',
                            },
                          ]}
                          defaultValue={{
                            label: 'Jr',
                            value: 'Jr',
                          }}
                        />
                      )}
                      {renderVerticalRow('', <></>)}
                      {renderVerticalRow('', <></>)}
                      {renderVerticalRow('', <></>)}
                      {renderVerticalRow('', <></>)}
                      {renderVerticalRow('', <></>)}
                    </>,
                    true
                  )}
                  {renderPart(
                    'Education',
                    <>
                      {renderVerticalRow(
                        'Degrees',
                        <Form.SelectInput
                          name="degree"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'MBA, MPH',
                              value: 'MBA, MPH',
                            },
                          ]}
                          defaultValue={{
                            label: 'MBA, MPH',
                            value: 'MBA, MPH',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        '*Specialties',
                        <Form.SelectInput
                          name="specialties"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'PC - FP, UC',
                              value: 'PC - FP, UC',
                            },
                          ]}
                          defaultValue={{
                            label: 'PC - FP, UC',
                            value: 'PC - FP, UC',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Staff Record Status',
                        <Form.SelectInput
                          name="staffRecordStatus"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Active',
                              value: 'Active',
                            },
                          ]}
                          defaultValue={{
                            label: 'Active',
                            value: 'Active',
                          }}
                        />
                      )}
                    </>,
                    true
                  )}
                </div>
                <div>
                  {renderPart(
                    'Organization',
                    <>
                      {renderVerticalRow(
                        '*Organization',
                        <Form.SelectInputV2
                          name="organization"
                          className="flex-1 bg-white"
                          options={[]}
                        />
                      )}
                      {renderVerticalRow(
                        '*Org Short',
                        <Form.TextInput
                          name="orgShort"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('orgShort'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                          disable
                        />
                      )}
                      {renderVerticalRow(
                        '*Org. Initials',
                        <Form.TextInput
                          name="orgInitials"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('orgInitials'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                          }}
                          disable
                        />
                      )}
                      {renderVerticalRow(
                        'Location',
                        <Form.SelectInput
                          name="location"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Smithville',
                              value: 'Smithville',
                            },
                          ]}
                          defaultValue={{
                            label: 'Smithville',
                            value: 'Smithville',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Site',
                        <Form.SelectInput
                          name="site"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'The Smith Building',
                              value: 'The Smith Building',
                            },
                          ]}
                          defaultValue={{
                            label: 'The Smith Building',
                            value: 'The Smith Building',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Department',
                        <Form.SelectInput
                          name="department"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Family Medicine',
                              value: 'Family Medicine',
                            },
                          ]}
                          defaultValue={{
                            label: 'Family Medicine',
                            value: 'Family Medicine',
                          }}
                        />
                      )}
                    </>
                  )}
                  {renderPart(
                    'Key Info',
                    <>
                      {renderVerticalRow(
                        'Important',
                        <Form.SelectInput
                          name="important"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Text - Do not Call',
                              value: 'Text - Do not Call',
                            },
                          ]}
                        />
                      )}
                      {renderVerticalRow(
                        'Staff Time Zone',
                        <Form.SelectInput
                          name="staffTimeZone"
                          className="flex-1 bg-white"
                          options={TIMEZONE_OPTIONS}
                        />
                      )}
                    </>,
                    true
                  )}
                  {renderPart(
                    'Identification',
                    <>
                      {renderVerticalRow(
                        '*Start Date',
                        <Form.DatePicker
                          name="startDate"
                          inputProps={{ id: 'startDate', placeholder: '' }}
                          date={form?.watch('startDate')}
                          onSelect={(value: Date) => {
                            form.setValue('startDate', value);
                          }}
                          className="text-right"
                        />
                      )}
                      {renderVerticalRow(
                        '*Organization ID',
                        <Form.TextInput
                          name="orgId"
                          className="flex-1 bg-white"
                          inputProps={{
                            placeholder: '',
                            value: form.getValues('orgId'),
                            className:
                              'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] ',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Service Level',
                        <Form.SelectInput
                          name="serviceLevel"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Full Service',
                              value: 'Full Service',
                            },
                          ]}
                          defaultValue={{
                            label: 'Full Service',
                            value: 'Full Service',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Priority',
                        <Form.SelectInput
                          name="priority"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Neutral',
                              value: 'Neutral',
                            },
                          ]}
                          defaultValue={{
                            label: 'Neutral',
                            value: 'Neutral',
                          }}
                        />
                      )}
                      {renderVerticalRow(
                        'Ranking',
                        <Form.SelectInput
                          name="ranking"
                          className="flex-1 bg-white"
                          options={[
                            {
                              label: 'Top 10%',
                              value: 'Top 10%',
                            },
                          ]}
                          defaultValue={{
                            label: 'Top 10%',
                            value: 'Top 10%',
                          }}
                        />
                      )}
                    </>,
                    true
                  )}
                </div>
              </Box.Standard>
              <Box.Standard className="mt-2.5">
                {renderTablePart(
                  'Attachments',
                  <Attachments
                    fontSize={fontSize}
                    rows={attachmentRows}
                    setRows={setAttachmentRows}
                    updateValue={updateAttachmentRows}
                  />
                )}
              </Box.Standard>
              <Box.Standard className="mt-2.5">
                {renderTablePart(
                  'Comments',
                  <Comments
                    fontSize={fontSize}
                    rows={commentRows}
                    setRows={setCommentRows}
                    updateValue={updateCommentRows}
                  />
                )}
              </Box.Standard>
            </form>
          </FormProvider>
        </div>
      </div>
      <Footer />
    </DefaultLayout>
  );
};

export default ManagerDetail;
