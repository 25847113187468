import { SVG } from 'assets/imagePath';
import { Box } from 'ui-organisms';
import { Button } from 'ui-atoms';
import { Filter, Form } from 'ui-molecules';
import { memo } from 'react';

export interface IStaffFilterProps {
  onSubmitFilter?: () => void;
  onClearFilter?: () => void;
  onClearSearchBox?: () => void;
}

const checkListStaff = [
  { value: 'transcriptionist', label: 'Transcriptionist' },
  { value: 'coder', label: 'Coder' },
  { value: 'admin_assist', label: 'Admin Assist' },
  { value: 'qa_transcription', label: 'QA Transcription' },
  { value: 'qa_coding', label: 'QA Coding' },
  { value: 'doc_lead', label: 'Documentation Lead' },
  { value: 'director_operation', label: 'Director of Operations' },
];

function StaffFilter({
  onClearFilter,
  onSubmitFilter,
  onClearSearchBox,
}: IStaffFilterProps) {
  return (
    <Box.Standard className="flex flex-col w-full ">
      <div className="p-2.5 flex-1 flex flex-col">
        <h4 className="flex items-center justify-between text-sm font-bold">
          <span>Filter Staff By</span>
          <img src={SVG.un_filter} />
        </h4>
        <div className="my-2.5 -mx-2.5">
          <Form.SearchBox
            name="search_box"
            className="w-ful rounded-full border border-nav border-solid  bg-transparent px-2.5 py-[5px]"
            clearInput={onClearSearchBox}
            inputProps={{
              placeholder: 'Search',
              style: { borderStyle: 'solid' },
            }}
          />
        </div>
        <div className="flex flex-col gap-2.5">
          <Filter.FilterCheckList
            title="Staff Title"
            checkList={checkListStaff}
          />
        </div>
      </div>
      <div className="w-full mt-10">
        <div className="flex w-full flex-row gap-2.5">
          <Button.Primary
            className="flex-1 !p-default"
            label="Apply"
            onClick={onSubmitFilter}
          />
          <Button.White
            className="flex-1 !p-default"
            color="white"
            label="Clear"
            onClick={onClearFilter}
          />
        </div>
      </div>
    </Box.Standard>
  );
}

StaffFilter.displayName = 'Staff.Filter';

export default memo(StaffFilter);
