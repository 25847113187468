import * as React from 'react';
import FilterCheckList from './FilterCheckList';

export interface IFilterLocationProps {}

export default function FilterLocation(props: IFilterLocationProps) {
  const checkList = [
    {
      label: 'Springfield',
      value: 'spring_field',
    },
    {
      label: 'Blessing',
      value: 'blessing',
    },
    {
      label: 'Pana',
      value: 'pana',
    },
  ];

  return (
    <FilterCheckList isCollapse title={'Location'} checkList={checkList} />
  );
}
