import * as React from 'react';
import CollapseButton from './CollapseButton';
import CollapseList from './CollapseList';
import CollapseText from './CollapseText';

export interface ICollapseProps {}

function Collapse(props: ICollapseProps) {
  return <React.Fragment />;
}

Collapse.Text = CollapseText;
Collapse.Button = CollapseButton;
Collapse.List = CollapseList;

export default Collapse;
