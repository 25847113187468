export const fakeInquiresData = [
  {
    first_name: 'Marcus',
    last_name: 'Welby',
    email: 'Marcus.Welby@WelbyClinic.com',
  },
  {
    first_name: 'Marcus',
    last_name: 'Welby',
    email: 'Marcus.Welby@WelbyClinic.com',
  },
  {
    first_name: 'Marcus',
    last_name: 'Welby',
    email: 'Marcus.Welby@WelbyClinic.com',
  },
  {
    first_name: 'Marcus',
    last_name: 'Welby',
    email: 'Marcus.Welby@WelbyClinic.com',
  },
  {
    first_name: 'Marcus',
    last_name: 'Welby',
    email: 'Marcus.Welby@WelbyClinic.com',
  },
  {
    first_name: 'Marcus',
    last_name: 'Welby',
    email: 'Marcus.Welby@WelbyClinic.com',
  },
  {
    first_name: 'Marcus',
    last_name: 'Welby',
    email: 'Marcus.Welby@WelbyClinic.com',
  },
  {
    first_name: 'Marcus',
    last_name: 'Welby',
    email: 'Marcus.Welby@WelbyClinic.com',
  },
  {
    first_name: 'Marcus',
    last_name: 'Welby',
    email: 'Marcus.Welby@WelbyClinic.com',
  },
  {
    first_name: 'Marcus',
    last_name: 'Welby',
    email: 'Marcus.Welby@WelbyClinic.com',
  },
  {
    first_name: 'Marcus',
    last_name: 'Welby',
    email: 'Marcus.Welby@WelbyClinic.com',
  },
  {
    first_name: 'Marcus',
    last_name: 'Welby',
    email: 'Marcus.Welby@WelbyClinic.com',
  },
];
