import {
  SET_LOADING_MODAL,
  SET_REGISTER_FORM,
  SET_USER_INFO,
  SET_CONFIRM_INFO,
  SET_IS_HAVE_RECORDS_ORGANIZATION,
  SET_USER_RECORD_ID,
  SET_PRIMARY_ACCOUNT_ID,
  CLEAR_USER_RECORD_PRIMARY_ACCOUNT,
  SET_ACTIVE_FORM,
} from 'constant';
import { useReducer, createContext } from 'react';

const initialState: any = {
  userInfo: null,
  registerForm: null,
  loadingModalOpen: false,
  isHaveRecordsOrganization: false,
  userRecordId: '',
  primaryAccountId: '',
  activeForm: null,
};

export const GlobalContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_REGISTER_FORM:
      return {
        ...state,
        registerForm: action?.payload,
      };
    case SET_LOADING_MODAL:
      return {
        ...state,
        loadingModalOpen: action?.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action?.payload,
      };
    case SET_CONFIRM_INFO:
      return {
        ...state,
        confirmInfo: action?.payload,
      };
    case SET_IS_HAVE_RECORDS_ORGANIZATION: {
      return {
        ...state,
        isHaveRecordsOrganization: action?.payload,
      };
    }
    case SET_USER_RECORD_ID: {
      return {
        ...state,
        userRecordId: action?.payload,
      };
    }
    case SET_PRIMARY_ACCOUNT_ID: {
      return {
        ...state,
        primaryAccountId: action?.payload,
      };
    }
    case CLEAR_USER_RECORD_PRIMARY_ACCOUNT: {
      return {
        ...state,
        userRecordId: '',
        primaryAccountId: '',
      };
    }
    case SET_ACTIVE_FORM:
      return {
        ...state,
        activeForm: action?.payload,
      };

    default:
      return {
        state,
      };
  }
};

export const GlobalContextProvider = (props: any) => {
  const [state, dispatch] = useReducer<any>(reducer, initialState);
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
