import Dashboard from 'pages/dashboard';
import Login from 'pages/auth/login';
import Register from 'pages/auth/register';
import { ForgotPassword } from 'pages/auth/forgotPassword';
import { Route, Routes } from 'react-router-dom';
import { NoteList, NoteDetailPage } from 'pages/notes';
import {
  CoderList,
  CoderDetail,
  CoderEditPage,
  CoderInvite,
} from 'pages/coders';
import {
  DocAssistantList,
  DocAssistantDetail,
  DocAssistantEditPage,
  DocAssistantInvite,
} from 'pages/docAssistants';
import Builder from 'pages/builder';
import Settings from 'pages/settings';
import EmptyLayout from 'layouts/EmptyLayout';
import ClinicianInvitationPage from 'pages/clinician/ClinicianInvitationPage';
import { Clinician, ClinicianList, ClinicianInvite } from 'pages/clinician';
import { RouterPath } from './router.constant';
import {
  Organization,
  OrganizationDetail,
  OrganizationNew,
} from 'pages/organization';
import { Inquiries } from 'pages/inquiries';
import { Attachments } from 'pages/attachments';
import { StandardQnA } from 'pages/standardQnA';
import { Staffs } from 'pages/staff';
import StaffDetail from 'pages/staff/StaffDetail';
import { SmartTexts } from 'pages/smartTexts';
import { RequiredAuth } from './RequiredAuth';
import { PublicRoute } from './PublicRoute';
import { Landing } from 'pages/public';
import {
  ManagerDetail,
  ManagerEditPage,
  ManagerList,
  ManagerInvite,
} from 'pages/manager';
import Invitation from 'pages/invitation';
import SupportPage from '../pages/public/SupportPage/SupportPage';
import ContactPage from '../pages/public/ContactPage/ContactPage';
import SupportPageAuth from '../pages/supportPageAuth/SupportPageAuth';
import { ActiveNewUserPage } from '../pages/auth/activeNewUser';
import { ActiveNewCodePage } from 'pages/auth/activeNewUser/ActiveNewCodePage';
import { ActiveUserStep4 } from '../ui-organisms/activeNewUser';

export * from './router.constant';

const RootRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path={RouterPath.LOGIN} element={<Login />} />
        <Route path={RouterPath.REGISTER} element={<Register />} />
        <Route path={RouterPath.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={RouterPath.HOME} element={<Landing />} />
        <Route path={RouterPath.SUPPORT} element={<SupportPage />} />
        <Route path={RouterPath.CONTACT_US} element={<ContactPage />} />
        <Route path={RouterPath.NEW_USER} element={<ActiveNewUserPage />} />
      </Route>
      <Route
        path={RouterPath.INVITATION_ACCEPT}
        element={
          <EmptyLayout>
            <Invitation.AcceptStep />
          </EmptyLayout>
        }
      />
      <Route path="/" element={<RequiredAuth />}>
        <Route
          path={RouterPath.CLINICIAN_INVITATION}
          element={
            <EmptyLayout>
              <ClinicianInvitationPage />
            </EmptyLayout>
          }
        />

        <Route
          path={RouterPath.NEW_USER_CODE}
          element={<ActiveNewCodePage />}
        />

        <Route element={<Dashboard />}>
          <Route path={RouterPath.SUPPORT_PAGE} element={<SupportPageAuth />} />
          <Route path={RouterPath.NOTES} element={<NoteList />} />
          <Route path={RouterPath.NOTE} element={<NoteDetailPage />} />
          <Route path={RouterPath.BUILDER} element={<Builder />} />

          <Route path={RouterPath.ORGANIZATIONS} element={<Organization />} />
          <Route
            path={RouterPath.ORGANIZATION}
            element={<OrganizationDetail />}
          />
          <Route
            path={RouterPath.ORGANIZATION_NEW}
            element={<OrganizationNew />}
          />

          <Route path={RouterPath.INQUIRIES} element={<Inquiries />} />
          <Route path={RouterPath.ATTACHMENTS} element={<Attachments />} />
          <Route path={RouterPath.STANDARD_QNA} element={<StandardQnA />} />
          <Route path={RouterPath.STAFFS} element={<Staffs />} />
          <Route path={RouterPath.STAFF_DETAIL} element={<StaffDetail />} />

          <Route
            path={RouterPath.DOC_ASSISTANTS}
            element={<DocAssistantList />}
          />
          <Route
            path={RouterPath.DOC_ASSISTANT}
            element={<DocAssistantDetail />}
          />
          <Route
            path={RouterPath.DOC_ASSISTANT_EDIT}
            element={<DocAssistantEditPage />}
          />
          <Route
            path={RouterPath.DOC_ASSISTANT_INVITE}
            element={<DocAssistantInvite />}
          />

          <Route path={RouterPath.CODERS} element={<CoderList />} />
          <Route path={RouterPath.CODER} element={<CoderDetail />} />
          <Route path={RouterPath.CODER_EDIT} element={<CoderEditPage />} />
          <Route path={RouterPath.CODER_INVITE} element={<CoderInvite />} />

          <Route path={RouterPath.MANAGERS} element={<ManagerList />} />
          <Route path={RouterPath.MANAGER} element={<ManagerDetail />} />
          <Route path={RouterPath.MANAGER_EDIT} element={<ManagerEditPage />} />
          <Route path={RouterPath.MANAGER_INVITE} element={<ManagerInvite />} />

          <Route path={RouterPath.SMART_TEXTS} element={<SmartTexts />} />
          <Route path={RouterPath.CLINICIANS} element={<ClinicianList />} />
          <Route path={RouterPath.CLINICIAN} element={<Clinician />} />
          <Route
            path={RouterPath.CLINICIAN_INVITE}
            element={<ClinicianInvite />}
          />
          <Route path={RouterPath.SETTINGS} element={<Settings />} />
          <Route
            path={RouterPath.REDEMPTION_CODE}
            element={<ActiveUserStep4 />}
          />
        </Route>
        <Route
          path={RouterPath.INVITATION_CONFIRM_DETAIL}
          element={
            <EmptyLayout>
              <Invitation.ConfirmDetails />
            </EmptyLayout>
          }
        />
        <Route
          path={RouterPath.INVITATION_CHANGE_PASSWORD}
          element={
            <EmptyLayout>
              <Invitation.ChangePassword />
            </EmptyLayout>
          }
        />
      </Route>
    </Routes>
  );
};

export default RootRoutes;
