import * as React from 'react';
import classes from './pagination-item.module.scss';

type Props = {
  page: number;
  id: string;
};

export function PaginationItemPage({ page, id }: Props): React.ReactElement {
  return (
    <div className={classes['page']} id={id}>
      {page}
    </div>
  );
}
