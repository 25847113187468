import * as React from 'react';
import { Form, Text } from 'ui-molecules';
import { FormProvider, useForm } from 'react-hook-form';
import { ISupportFormValues } from '../../types';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import { useSupportSchema } from '../../pages/public/SupportPage/support.schema';
import cn from 'classnames';
import { Button } from '../../ui-atoms';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';
import { useApiCall } from '../../hooks';
import { submitSupportFormAPI } from '../../services';

export default function Support() {
  const navigate = useNavigate();
  const [submitForm, loading, submitData, submitError] =
    useApiCall(submitSupportFormAPI);
  const form = useForm<ISupportFormValues>({
    defaultValues: {
      fullName: '',
      email: '',
      phone: '',
      department: '',
      subject: '',
      message: '',
    },
    resolver: FormResolver(useSupportSchema()),
  });

  const optionsDepartment = [
    {
      label: 'Technical Support',
      value: 'Technical Support',
    },
    {
      label: 'Customer Support',
      value: 'Customer Support',
    },
    {
      label: 'Billing & Accounting',
      value: 'Billing & Accounting',
    },
    {
      label: 'Fraud & Security',
      value: 'Fraud & Security',
    },
    {
      label: 'Whistleblower',
      value: 'Whistleblower',
    },
  ];

  const onSubmit = async (values: ISupportFormValues) => {
    try {
      await submitForm({
        fullName: values.department === 'Whistleblower' ? '' : values.fullName,
        email: values.department === 'Whistleblower' ? '' : values.email,
        phone: values.department === 'Whistleblower' ? '' : values.phone,
        department: values.department,
        subject: values.subject,
        message: values.message,
      });

      if (
        submitData.message === '[SUCCESS] - USER has been emailed successfully.'
      ) {
        navigate(RouterPath.HOME);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="p-[30px] bg-white-light md:px-5 my-2.5 rounded-[30px]">
      <div className="flex flex-col gap-y-5">
        <Text size="20" weight="700" className="text-center">
          Ticketing & Support
        </Text>
        <FormProvider {...form}>
          <form className="flex flex-col gap-y-5">
            {form.watch('department') !== 'Whistleblower' && (
              <div className="flex items-center gap-x-5">
                <Form.TextInput
                  name="fullName"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: 'Full Name',
                    className: 'ring-1',
                  }}
                />
                <Form.EmailInput
                  name="email"
                  className="flex-1"
                  inputProps={{
                    placeholder: 'E-mail',
                  }}
                />
              </div>
            )}

            <div className="flex items-center gap-x-5">
              {form.watch('department') !== 'Whistleblower' && (
                <Form.PhoneInput
                  className="flex-1"
                  name="phone"
                  inputProps={{
                    placeholder: 'Phone Number',
                  }}
                />
              )}
              <Form.SelectInput
                className="flex-1 text-left"
                options={optionsDepartment}
                name="department"
                label="Department"
              />
            </div>

            <Form.TextInput
              name="subject"
              className="flex-1 bg-white"
              inputProps={{
                placeholder: 'Subject',
                className: 'ring-1',
              }}
            />

            <Form.TextArea
              name="message"
              className="flex-1 bg-white"
              inputProps={{
                placeholder: 'Message',
                className: 'ring-1 h-[200px] flex-start items-start',
              }}
            />

            <Form.UploadFile
              name="screenShot"
              className="flex-1 bg-white"
              inputProps={{
                placeholder: 'Upload Screenshot',
                className: 'ring-1 flex-start items-start',
              }}
            />
            <div className="flex justify-center mt-4">
              <Button.Common
                className={cn(
                  'w-[180px] h-[32px] xs:h-[40px] md:h-[48px] rounded-full px-5 md:px-[30px]'
                )}
                onClick={form.handleSubmit(onSubmit)}
              >
                <Text
                  responsive
                  className="whitespace-nowrap"
                  weight="400"
                  size="16"
                  color="white"
                >
                  Submit
                </Text>
              </Button.Common>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
