// @flow
import * as React from 'react';
import { IAttachmentsTable, ITableColumn } from '../../../types';
import { Input } from '../../../ui-atoms';

type Props = {
  rows: IAttachmentsTable[];
  setRows: (rows: IAttachmentsTable[]) => void;
  updateValue: (id: string, row: IAttachmentsTable) => void;
  fontSize: number;
};

const AttachmentsColumn: ITableColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'format',
    headerName: 'Format',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'author',
    headerName: 'Author',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'comment',
    headerName: 'Comment',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
];
export const Attachments = ({
  rows,
  setRows,
  updateValue,
  fontSize,
}: Props) => {
  const handleChangeValue = (field: string, value: string, row) => {
    if (field === 'name') {
      return updateValue(row.id, {
        ...row,
        name: value,
      });
    }
    if (field === 'format') {
      return updateValue(row.id, {
        ...row,
        format: value,
      });
    }
    if (field === 'author') {
      return updateValue(row.id, {
        ...row,
        author: value,
      });
    }
    if (field === 'comment') {
      return updateValue(row.id, {
        ...row,
        comment: value,
      });
    }
  };

  const onAdd = () => {
    let rowData = [];
    rowData.push(...rows, {
      id: rows.length,
      name: '',
      format: '',
      author: '',
      comment: '',
    });
    setRows(rowData);
  };

  const renderRow = (row) => {
    return (
      <tr key={row.id} className="border border-t-0 hover:bg-gray-300">
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-left h-[30px]"
              onChange={(e) => handleChangeValue('name', e, row)}
              value={row.name}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-left h-[30px]"
              onChange={(e) => handleChangeValue('format', e, row)}
              value={row.format}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-left h-[30px]"
              onChange={(e) => handleChangeValue('author', e, row)}
              value={row.author}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-left h-[30px]"
              onChange={(e) => handleChangeValue('comment', e, row)}
              value={row.comment}
            />
          </div>
        </td>
      </tr>
    );
  };
  return (
    <>
      <table className={'table-auto w-full h-full z-0'}>
        <thead>
          <tr>
            {AttachmentsColumn.map((column) => {
              return (
                !column?.hideCol && (
                  <th
                    key={column.field}
                    className="p-table text-center border font-[600] whitespace-nowrap  min-w-fit"
                    style={column?.headerStyle}
                  >
                    <p style={{ fontSize: `${fontSize}px` }}>
                      {column.headerName}
                    </p>
                  </th>
                )
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row) => {
            return renderRow(row);
          })}
          <tr
            className="border border-t-0 hover:cursor-pointer"
            onClick={onAdd}
          >
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
