import { Box } from 'ui-organisms';
import { Footer, Form, HeaderApp } from 'ui-molecules';
import EditableVerticalTable from 'ui-molecules/gridTable/EditableVerticalTable';
import { APP_ROLE, SET_LOADING_MODAL } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import { useApiCall } from 'hooks';
import DefaultLayout from 'layouts/DefaultLayout';
import { useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getListOrganizationsAPI, inviteUserAPI } from 'services';
import { IManagerInviteFormValues, ITableColumn } from 'types';
import { capitalizeName } from 'utils/text';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import { useManagerInviteSchema } from './manager-invite.schema';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { RouterPath } from '../../routes/router.constant';

const ManagerInvite: React.FC = () => {
  const { dispatch } = useContext(GlobalContext);
  const { CURRENT_ROLE } = useAuth();

  const navigate = useNavigate();
  const [inviteUser, loading] = useApiCall(inviteUserAPI);
  const [fetchOrganization, loadingOrganization, listOrganization] = useApiCall(
    getListOrganizationsAPI
  );
  const titleOpts = [{ label: 'Invite - Manager', value: 'title-1' }];
  const actionOpts = [
    {
      label: 'Action',
      value: 'edit',
      onClick: () => {},
    },
  ];

  const allowAccess = useMemo(() => {
    return (
      CURRENT_ROLE === APP_ROLE.OW ||
      CURRENT_ROLE === APP_ROLE.SA ||
      CURRENT_ROLE === APP_ROLE.MGR ||
      CURRENT_ROLE === APP_ROLE.DS
    );
  }, [CURRENT_ROLE]);

  useEffect(() => {
    if (!allowAccess) {
      navigate(RouterPath.MANAGERS);
    }
    fetchOrganization();
  }, [allowAccess, fetchOrganization, navigate]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading || loadingOrganization,
    });
  }, [dispatch, loading, loadingOrganization]);

  const form = useForm<IManagerInviteFormValues>({
    defaultValues: {
      organization: {
        label: '',
        value: '',
        short: '',
      },
      organizationName: '',
      organizationId: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      cellPhone: '',
      servicePhone: '',
      userRole: '',
      title: '',
      degree: '',
      specialty: '',
      locations: '',
    },
    resolver: FormResolver(useManagerInviteSchema()),
  });

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading || loadingOrganization,
    });
  }, [dispatch, loading, loadingOrganization]);

  const organizationOption = useMemo(() => {
    return listOrganization?.map((orgItem) => ({
      label: orgItem?.organizationName,
      value: orgItem?.organizationId,
      short: orgItem?.emailDomain,
    }));
  }, [listOrganization]);

  const organizationData = form.watch('organization');
  useEffect(() => {
    form.setValue('organizationName', organizationData.label);
    form.setValue('organizationId', organizationData.value);
  }, [form, organizationData]);

  const managerDetailColumn: ITableColumn[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
    },
    {
      field: 'middleName',
      headerName: 'Middle Name',
    },
    {
      field: 'organizationName',
      headerName: 'Organization Name',
      renderCell() {
        return (
          <Form.SelectInputV2
            name="organization"
            defaultValue={organizationOption?.find(
              (org) => org?.short === 'icqc.ai'
            )}
            options={organizationOption}
            text
            className="w-full"
          />
        );
      },
    },
  ];
  const managerContactColumn: ITableColumn[] =
    CURRENT_ROLE === APP_ROLE.OW
      ? [
          {
            field: 'email',
            headerName: 'Email',
          },
          {
            field: 'cellPhone',
            headerName: 'Cell phone',
          },
          {
            field: 'servicePhone',
            headerName: 'Service phone',
          },
          {
            field: 'userRole',
            headerName: 'Target',
            renderCell() {
              return (
                <Form.SelectInput
                  name="userRole"
                  options={[
                    {
                      label: 'System_Admin',
                      value: 'System_Admin',
                    },
                    {
                      label: 'Manager',
                      value: 'Manager',
                    },
                  ]}
                  text
                  className="w-full"
                />
              );
            },
          },
        ]
      : [
          {
            field: 'email',
            headerName: 'Email',
          },
          {
            field: 'cellPhone',
            headerName: 'Cell phone',
          },
          {
            field: 'servicePhone',
            headerName: 'Service phone',
          },
        ];

  const managerOtherColumn: ITableColumn[] = [
    {
      field: 'title',
      headerName: 'Staff title',
    },
    {
      field: 'degree',
      headerName: 'Degree',
    },
    {
      field: 'specialty',
      headerName: 'Specialty Experience',
    },
    {
      field: 'locations',
      headerName: 'Locations',
    },
  ];
  const handleSubmitFunc = async (data) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });

      await inviteUser({
        ...data,
        initials: capitalizeName(
          data?.firstName,
          data?.lastName,
          data?.middleName
        ),
        userRole: APP_ROLE.MNG,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
      navigate(`/`);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
    }
  };
  return (
    <DefaultLayout>
      <HeaderApp.Edit
        title={{ options: titleOpts }}
        actions={{ options: actionOpts }}
        onSave={form.handleSubmit(handleSubmitFunc)}
        viewName={'Invite Manager'}
      />
      <Box.Standard className="flex-1 my-2.5">
        <FormProvider {...form}>
          <form className="grid grid-cols-3 gap-[10px]">
            <EditableVerticalTable
              isEdit={true}
              columns={managerDetailColumn}
              row={false && form.getValues()}
            />
            <EditableVerticalTable
              isEdit={true}
              columns={managerContactColumn}
              row={false && form.getValues()}
            />
            <EditableVerticalTable
              isEdit={true}
              columns={managerOtherColumn}
              row={false && form.getValues()}
            />
          </form>
        </FormProvider>
      </Box.Standard>
      <Footer />
    </DefaultLayout>
  );
};
export default ManagerInvite;
