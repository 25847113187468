import * as React from 'react';
import cn from 'classnames';

interface avatarConfig {
  character: string;
  bgColor: string;
}
export interface IQnAMessageProps {
  message: React.ReactNode;
  avatar?: React.ReactNode | avatarConfig;
  time?: string;
}

export default function QnAMessage({
  message,
  avatar,
  time,
}: IQnAMessageProps) {
  return (
    <div className="flex gap-[5px] items-center">
      {!React.isValidElement(avatar) ? (
        <div
          className={cn(
            `text-white p-[5px] text-[10px]  font-bold rounded-full w-[24px] h-[24px] flex justify-center items-center`,
            avatar?.['bgColor']
          )}
        >
          {avatar?.['character']}
        </div>
      ) : (
        avatar
      )}

      <p className="flex-1 text-xs text-[#272A2F]">{message}</p>
      {time && (
        <div className="self-center justify-self-end text-xs text-dark-silver">
          {time}
        </div>
      )}
    </div>
  );
}
