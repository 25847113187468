import * as React from 'react';
import NextPrev from './NextPrev';
import PlayPause from './PlayPause';
import Range from './Range';
import Record from './Record';

export interface IPlayerControl {}

function PlayerControl({}: IPlayerControl) {
  return <React.Fragment />;
}
PlayerControl.PlayPause = PlayPause;
PlayerControl.Record = Record;
PlayerControl.NextPrev = NextPrev;
PlayerControl.Range = Range;

export default PlayerControl;
