/**
 * Capitalize first letters of name
 * @param value Ex: ryong ma
 * @returns Ex: RM
 */
export const capitalizeName = (
  firstName: string = '',
  lastName: string = '',
  middleName: string = ''
) => {
  return `${firstName?.[0]?.toUpperCase() || ''}${
    middleName?.[0]?.toUpperCase() || ''
  }${lastName?.[0]?.toUpperCase() || ''}`;
};
