import { useAuth } from 'hooks/useAuth';
import { FC, memo } from 'react';
import NoteCoder from './NoteCoder';
import NoteDocs from './NoteDocs';
import NoteManager from './NoteManger';
import { APP_ROLE } from 'constant';

interface NoteDetailProps {
  notesData;
}

const NoteDetail: FC<NoteDetailProps> = ({ notesData }) => {
  const { CURRENT_ROLE } = useAuth();

  const renderDetail = () => {
    switch (CURRENT_ROLE) {
      case APP_ROLE.SA:
        return <NoteManager notesData={notesData} />;
        break;
      case APP_ROLE.DS:
        return <NoteDocs notesData={notesData} />;
        break;
      case APP_ROLE.CO:
        return <NoteCoder notesData={notesData} />;
        break;

      default:
        break;
    }
  };

  return <div className="flex-col text-xs">{renderDetail()}</div>;
};

export default memo(NoteDetail);
