import * as React from 'react';

export interface INoteCommentProps {
  title?: string;
}

export default function NoteComment({
  title = 'Sort by Date (Most Recent 1st)',
}: INoteCommentProps) {
  return (
    <div className="flex flex-col">
      <div className="px-5 py-[5px] flex justify-between">
        <p className="flex-1 text-xs text-success">
          Sort by Date (Most Recent 1st)
        </p>
        <div className="text-xs flex-2 flex  justify-evenly font-bold">
          <p> {title}</p>
        </div>
        <div className="flex-1" />
      </div>

      <div className="grid grid-flow-col w-max p-2.5 text-xs">
        <div className="col-date">
          <div className="px-2.5 py-[5px]">03/18/2023</div>
          <div className="px-2.5 py-[5px]">02/06/2023</div>
          <div className="px-2.5 py-[5px]">01/03/2023</div>
        </div>
        <div className="col-date">
          <div className="px-2.5 py-[5px]">Betty MacDonald</div>
          <div className="px-2.5 py-[5px]">Julie Medley</div>
          <div className="px-2.5 py-[5px]">Name</div>
        </div>
        <div className="col-date">
          <div className="px-2.5 py-[5px]">MGR</div>
          <div className="px-2.5 py-[5px]">DS</div>
          <div className="px-2.5 py-[5px]">CO</div>
        </div>
        <div className="col-date">
          <div className="px-2.5 py-[5px]">Comment</div>
          <div className="px-2.5 py-[5px]">Comment</div>
          <div className="px-2.5 py-[5px]">Comment</div>
        </div>
      </div>
    </div>
  );
}
