import { GridTable } from 'ui-molecules';
import { ITableColumn } from 'types';
import { getFormatedDate } from '../../utils';

export interface Props {
  orgData: any;
}

export default function OrganizationDetailTable({ orgData }: Props) {
  const AttachmentsColumn: ITableColumn[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'format',
      headerName: 'Format',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'author',
      headerName: 'Author',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'comment',
      headerName: 'Comment',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
  ];

  const AttachmentsRow = [
    {
      name: 'name',
      format: 'format',
      author: 'author',
      comment: 'comment',
    },
  ];

  const CommentsColumn: ITableColumn[] = [
    {
      field: 'date',
      headerName: 'Date',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'full_name',
      headerName: 'Full Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'comment',
      headerName: 'Comment',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
  ];

  const CommentsRow = [
    {
      date: 'date',
      full_name: 'full_name',
      role: 'role',
      comment: 'comment',
    },
  ];

  const LocationsColumn: ITableColumn[] = [
    {
      field: 'id',
      headerName: '',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'abbr',
      headerName: 'Abbr.',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'func',
      headerName: '',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
  ];

  const LocationsRow = [
    {
      id: '+',
      abbr: 'SN',
      name: 'Main Campus',
      address: '361 South Birch Hill CourtLynn, MA 01902',
      func: '-',
    },
  ];

  const SiteNamesColumn: ITableColumn[] = [
    {
      field: 'id',
      headerName: '',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'abbr',
      headerName: 'Abbr.',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'site_name',
      headerName: 'Sitename',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'func',
      headerName: '',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
  ];

  const SiteNamesRow = [
    {
      id: '+',
      abbr: 'SN',
      site_name: 'Site name',
      address: '361 South Birch Hill CourtLynn, MA 01902',
      func: '-',
    },
  ];

  const DepartmentsColumn: ITableColumn[] = [
    {
      field: 'id',
      headerName: '',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'value',
      headerName: 'Value',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
    {
      field: 'func',
      headerName: '',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
      },
    },
  ];

  const DepartmentsRow = [
    {
      id: '+',
      value: 'PC',
      description: 'Primary Care',
      func: '-',
    },
  ];

  return (
    <div className="flex flex-col gap-4 items-center w-full">
      <div className={'w-full'}>
        <p className={'text-xs font-bold text-center py-1'}>Attachments</p>
        <div className="!overflow-auto  w-full max-h-full z-0">
          <GridTable
            column={AttachmentsColumn}
            row={AttachmentsRow}
            checkboxes={false}
          />
        </div>
      </div>
      <div className={'w-full'}>
        <p className={'text-xs font-bold text-center py-1'}>Comments</p>
        <div className="!overflow-auto  w-full max-h-full z-0">
          <GridTable
            column={CommentsColumn}
            row={CommentsRow}
            checkboxes={false}
          />
        </div>
      </div>
      <div className={'w-full'}>
        <p className={'text-xs font-bold text-center py-1'}>Locations</p>
        <div className="!overflow-auto  w-full max-h-full z-0">
          <GridTable
            column={LocationsColumn}
            row={LocationsRow}
            checkboxes={false}
          />
        </div>
      </div>
      <div className={'w-full'}>
        <p className={'text-xs font-bold text-center py-1'}>Site Names</p>
        <div className="!overflow-auto  w-full max-h-full z-0">
          <GridTable
            column={SiteNamesColumn}
            row={SiteNamesRow}
            checkboxes={false}
          />
        </div>
      </div>
      <div className={'w-full'}>
        <p className={'text-xs font-bold text-center py-1'}>Departments</p>
        <div className="!overflow-auto  w-full max-h-full z-0">
          <GridTable
            column={DepartmentsColumn}
            row={DepartmentsRow}
            checkboxes={false}
          />
        </div>
      </div>
    </div>
  );
}
