import cn from 'classnames';
import { FC, memo, ReactNode } from 'react';

type Props = {
  className?: string;
  underline?: boolean;
  label: ReactNode;
  onClick: () => void;
};

const ButtonText: FC<Props> = ({ className, label, onClick, underline }) => {
  return (
    <button
      type="button"
      className={cn(
        'w-full inline-block  bg-transparent font-inter text-sm  transition duration-150 ease-in-out',
        underline ? 'underline underline-offset-[3px]' : '',
        className
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

ButtonText.displayName = 'Button.Text';

export default memo(ButtonText);
