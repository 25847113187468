import { ErrorMessage } from '@hookform/error-message';
import { DetailedHTMLProps, FC, InputHTMLAttributes, memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ObjectUtils } from 'utils';
import { Input } from 'ui-atoms';

type Props = {
  className?: string;
  name: string;
  inputProps?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
};

const FormPhoneInput: FC<Props> = ({ name, className, inputProps }) => {
  const formContext = useFormContext();

  const { field, formState } = useController({
    name,
    control: formContext.control,
    defaultValue: '',
  });

  return (
    <div className={className}>
      <Input.Phone
        {...inputProps}
        error={!ObjectUtils.isEmpty(ObjectUtils.get(formState.errors, name))}
        value={field?.value}
        onChange={field?.onChange}
      />

      <ErrorMessage
        errors={formState.errors}
        name={name}
        render={({ message }) => (
          <p className="text-error text-xs font-inter my-1">{message}</p>
        )}
      />
    </div>
  );
};

FormPhoneInput.displayName = 'Form.PhoneInput';

export default memo(FormPhoneInput);
