import * as Yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const requiredText = 'This is required field';
export const useManagerInviteSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string().nullable(),
    middleName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    organizationName: Yup.string().nullable(),
    email: Yup.string()
      .nullable()
      .email('Email is invalid')
      .trim('Email is required')
      .required('Email is required'),
    cellPhone: Yup.string()
      .nullable()
      .optional()
      .matches(phoneRegExp, {
        message: 'Cell Phone is not valid',
        excludeEmptyString: true,
      })
      .required('Phone is required'),
    userRole: Yup.string().nullable(),
    servicePhone: Yup.string()
      .nullable()
      .optional()
      .matches(phoneRegExp, {
        message: 'Service Phone is not valid',
        excludeEmptyString: true,
      })
      .required('Service Phone is required'),
    title: Yup.string().nullable(),
    degree: Yup.string().nullable(),
    specialty: Yup.string().nullable(),
    locations: Yup.string().nullable(),
  });
};
