import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FC, memo, useCallback } from 'react';

type Props = {
  className?: string;
  error?: boolean;
  value: string;
  onChange: (value: string) => void;
};

const PhoneInputV2: FC<Props> = ({ className, error, onChange, ...rest }) => {
  const handleChange = useCallback(
    (number: string) => {
      onChange?.(number);
    },
    [onChange]
  );

  return (
    <PhoneInput
      country={'us'}
      containerClass="w-full h-10 border-none text-sm font-inter text-input placeholder:text-slate-400 bg-white"
      onlyCountries={['us', 'ca', 'de', 'fr', 'es', 'gb', 'in']}
      countryCodeEditable={false}
      inputClass={className}
      inputStyle={{ height: '40px' }}
      onChange={(phone) => handleChange(phone)}
      isValid={(value, country: any) => {
        if (value.match(/12345/)) {
          return 'Invalid value: ' + value + ', ' + country?.name;
        } else if (value.match(/1234/)) {
          return false;
        } else {
          return true;
        }
      }}
      {...rest}
    />
  );
};

PhoneInputV2.displayName = 'Input.PhoneV2';

export default memo(PhoneInputV2);
