import { FC, memo } from 'react';

type Props = {
  image: string;
  title: React.ReactNode;
  subtitle: string;
};

const AuthLeft: FC<Props> = ({ image, title, subtitle }) => {
  return (
    <div className="w-1/2 hidden md:flex bg-black xl:p-24 lg:p-12 justify-center items-center">
      <div className="text-center">
        <img
          src={image}
          className="max-w-[500px] w-full m-auto"
          alt="Side login image"
        />
        <div className="text-white text-4xl mt-8 font-bold font-inter">
          {title}
        </div>
        <div className="w-4/5 mx-auto">
          <p className="text-nav text-xl mt-4 font-inter">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default memo(AuthLeft);
