import { SVG } from 'assets/imagePath';
import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  memo,
  useCallback,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

export type SearchStandardProps = {
  className?: string;
  classNameInput?: string;
  error?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  clearInput?: () => void;
  inputProps?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
};

const SearchStandard: FC<SearchStandardProps> = ({
  className,
  classNameInput,
  value,
  onChange,
  clearInput,
  inputProps,
}) => {
  const inputRef = useRef(null);
  // const [valueInput, setValueInput] = useState('');

  const handleChangeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target?.value);
    },
    [onChange]
  );
  return (
    <div
      className={cn(
        'w-full p-2.5 rounded-[5px]  border border-grey bg-white flex items-center gap-x-[5px]',
        className
      )}
      onClick={() => inputRef.current.focus()}
    >
      <img
        src={SVG.search_black}
        className="icon-app ml-[3px]"
        alt="search-icon"
      />
      <input
        ref={inputRef}
        className={cn(
          'max-h-[15px] text-sm w-full flex-1 bg-transparent font-inter text-input placeholder:text-slate-40 bg-clip-padding transition ease-in-out m-0 focus:text-input focus:outline-none focus:border-none',
          classNameInput
        )}
        value={value}
        onChange={handleChangeSearch}
        {...inputProps}
      />
      {!!value ? (
        <img
          src={SVG.close}
          className="cursor-pointer icon-app"
          alt="search-icon"
          onClick={clearInput}
        />
      ) : (
        <div className="h-[15px] w-[15px]" />
      )}
    </div>
  );
};

SearchStandard.displayName = 'Search.Standard';

export default memo(SearchStandard);
