import { SVG } from 'assets/imagePath';
import { Button } from 'ui-atoms';
import { useCallback } from 'react';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  title: string;
  subtitle: string;
  canGoBack?: boolean;
};

const RegisterSetupHeader: FC<Props> = ({ title, subtitle, canGoBack }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="w-full">
      <div className="relative">
        <p className="max-w-xs md:max-w-lg text-label text-xl text-center font-inter font-bold my-5 mx-auto">
          {title}
        </p>
        {canGoBack && (
          <div className="absolute -top-9 -left-3 md:top-5 md:left-16 flex content-center">
            <Button.Icon icon={SVG.back_circle} onClick={handleClick} />
          </div>
        )}
      </div>
      <p className="max-w-xs md:max-w-lg text-input text-sm text-center font-inter mx-auto">
        {subtitle}
      </p>
    </div>
  );
};

export default memo(RegisterSetupHeader);
