import { FC, Fragment, memo } from 'react';
import { Menu, Transition } from '@headlessui/react';
import cn from 'classnames';
import { ISelectOption } from 'types';
import { SVG } from 'assets/imagePath';

type Props = {
  className?: string;
  label?: string;
  selectItem?: ISelectOption;
  error?: boolean;
  text?: boolean;
  classNameText?: string;
  options: ISelectOption[];
  onChangeValue: (value: ISelectOption) => void;
  disabled?: boolean;
};

const SelectInputV2: FC<Props> = ({
  className,
  label,
  selectItem,
  error,
  classNameText,
  options,
  onChangeValue,
  text = false,
  disabled = false,
}) => {
  return (
    <Menu as="div" className="relative block w-full text-left h-full">
      <div className="w-full h-full">
        {label && selectItem && (
          <p className="absolute text-[10px] leading-[10px] left-[10px] px-[5px] top-[-5px] bg-pearl">
            {label}
          </p>
        )}
        <Menu.Button
          className={cn(
            'flex w-full justify-end bg-clip-padding px-4 py-2 m-0 items-center',
            error ? 'ring-1 ring-red-500 focus:ring-1 focus:ring-red-500' : '',
            text
              ? '!p-0 h-full'
              : 'ring-1 ring-nav rounded-[5px]  bg-white hover:bg-gray-200',
            disabled ? 'bg-slate-200' : '',
            className
          )}
          disabled={disabled}
        >
          <p
            className={cn(
              'text-xs text-input font-inter',
              selectItem ? 'text-input' : 'text-slate-400',
              classNameText
            )}
          >
            {selectItem
              ? options?.find((item) => item.value === selectItem.value)?.label
              : label}
          </p>
          <img
            src={SVG.chevron_down}
            className="-mr-1 ml-2 my-auto h-5 w-5"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options?.map((item) => (
              <Menu.Item key={item.value}>
                {({ active }) => (
                  <a
                    className={cn(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm font-inter cursor-pointer text-center'
                    )}
                    onClick={() => onChangeValue(item)}
                  >
                    {item.label}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

SelectInputV2.displayName = 'Select.InputV2';

export default memo(SelectInputV2);
