export const RouterPath = {
  HOME: '/',
  // Authentication
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY_PASSWORD: '/verify-password',
  SUPPORT: '/support',
  SUPPORT_PAGE: '/support-page',
  CONTACT_US: '/contact',
  NEW_USER: '/users/new/:email',
  NEW_USER_CODE: '/users/code',
  // Dashboard
  NOTES: '/notes',
  NOTE: '/note/:userRecordId/:treatmentRecordId',
  BUILDER: '/note/edit/:userRecordId/:treatmentRecordId',
  ATTACHMENTS: '/attachments',

  ORGANIZATIONS: '/organizations',
  ORGANIZATION: '/organization/:organizationId',
  // ORGANIZATION_EDIT: '/organization/edit/:organizationId',
  ORGANIZATION_NEW: '/organization/edit/new',

  INQUIRIES: '/inquiries',
  STAFFS: '/staffs',
  STAFF_DETAIL: '/staffs/:staffId',

  DOC_ASSISTANTS: '/doc-assistants',
  DOC_ASSISTANT: '/doc-assistants/:dcId',
  DOC_ASSISTANT_EDIT: '/doc-assistant/edit/:dcId',
  DOC_ASSISTANT_INVITE: '/doc-assistant/invite',

  CODERS: '/coders',
  CODER: '/coders/:coderId',
  CODER_EDIT: '/coder/edit/:coderId',
  CODER_INVITE: '/coder/invite',

  MANAGERS: '/managers',
  MANAGER: '/managers/:managerId',
  MANAGER_EDIT: '/manager/edit/:managerId',
  MANAGER_INVITE: '/manager/invite',

  SYSTEM_ADMINS: '/system-admins',
  STANDARD_QNA: '/standard-qna',

  CLINICIANS: '/clinicians',
  CLINICIAN: '/clinicians/:clinicianId',
  // CLINICIAN_EDIT: '/clinician/edit/:clinicianId',
  // CLINICIAN_NEW: '/clinician/edit/new',

  CLINICIAN_INVITE: '/clinician/invite',

  SETTINGS: '/settings',
  SMART_TEXTS: '/smart-texts',

  //PUBLIC
  CLINICIAN_INVITATION: '/clinician-invitation',
  INVITATION_ACCEPT: '/users/new/:email',
  INVITATION_CONFIRM_DETAIL: '/users/new/confirm-detail',
  INVITATION_CHANGE_PASSWORD: '/users/new/change-password',

  REDEMPTION_CODE: '/redemption_code',
  //NOT MATCH
  NOT_MATCH: '/*',
};
