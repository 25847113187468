import { Text } from 'ui-molecules';
import * as React from 'react';

export interface SliderTextProps {
  title: string;
  desc: string;
}

export default function SliderText({ title, desc }: SliderTextProps) {
  return (
    <div className="flex flex-col gap-[5px] text-center">
      <Text responsive size="20" weight="700">
        {title}
      </Text>
      <Text responsive size="16">
        {desc}
      </Text>
    </div>
  );
}
