import { SVG } from 'assets/imagePath';
import { Button, Dropdown } from 'ui-atoms';

export interface ISelectedButtonProps {
  options: any[];
  propsButton?: object;
  onChangeValue?: (value: any) => void;
}

export default function SelectedButton({
  options,
  propsButton,
  onChangeValue,
}: ISelectedButtonProps) {
  return (
    <Dropdown.Standard
      options={options}
      onChangeValue={onChangeValue}
      CustomButton={(val) => (
        <Button.Common {...propsButton}>
          <div className="flex flex-start gap-[5px] items-center">
            <div>{val.label}</div>
            <img src={SVG.chevron_down} />
          </div>
        </Button.Common>
      )}
    />
  );
}
