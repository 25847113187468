import { IListSmartNotes } from './../../../types/builder.interface';
import { IListInsight } from 'types/builder.interface';

export const INSIGHT_LIST: IListInsight[] = [
  {
    id: 'chief_complaint',
    label: 'Chief complaint',
    defaultOpen: true,
    level: 0,
    html: /*html*/ `<p><strong>CHIEF COMPLAINT (CC):</strong> 1 have a sore throat, stuffy nose, and pressure across my forehead and face.</p>`,
    children: [
      {
        id: 'sore_thoart',
        parent_id: 'chief_complaint',
        label: 'Sore Throat',
        level: 0,
        children: [
          {
            id: 'sore_thoart',
            parent_id: 'chief_complaint',
            label: 'Sore Throat (Default)',
            level: 1,
          },
          {
            id: 'sore_thoart_2',
            parent_id: 'sore_thoart',
            label: 'Sore Throat 2',
            level: 1,
          },
          {
            id: 'sore_thoart_3',
            parent_id: 'sore_thoart',
            label: 'Sore Throat 3',
            level: 1,
          },
        ],
      },

      {
        id: 'urinary_discomfort',
        parent_id: 'chief_complaint',
        label: 'Urinary Discomfort',
        level: 0,
      },
    ],
  },
  {
    id: 'hpi',
    label: 'HPI Additions',
    level: 1,
    defaultOpen: true,
    children: [
      {
        id: 'sinusitis_viral',
        label: 'Sinusitis - Viral HPI',
        parent_id: 'hpi',
        level: 0,
      },
      {
        id: 'sinusitis_non_viral',
        label: 'Sinusitis - Non-Viral HPI',
        parent_id: 'hpi',

        level: 0,
      },
      {
        id: 'uti_viral',
        label: 'UTI - Viral',
        parent_id: 'hpi',

        level: 0,
      },
      {
        id: 'uti_non_viral',
        label: 'UTI - Non-Viral',
        parent_id: 'hpi',

        level: 0,
      },
    ],
  },
  {
    id: 'review_system',
    label: 'Review of Systems',
    defaultOpen: true,
    level: 0,
    children: [
      {
        id: 'default_ros',
        label: 'Default ROS',
        level: 0,
        parent_id: 'review_system',
      },
      {
        id: 'observational_ros',
        label: 'Observational ROS',
        level: 0,
        parent_id: 'review_system',
      },
      {
        id: 'comprehensive_ros',
        label: 'Comprehensive ROS',
        level: 0,
        parent_id: 'review_system',
      },
    ],
  },
  {
    id: 'ros_additions',
    label: 'ROS Additions',
    level: 1,
    defaultOpen: true,
    children: [
      {
        id: 'sinusitis_viral',
        label: 'Sinusitis - Viral HPI',
        parent_id: 'ros_additions',

        level: 0,
      },
      {
        id: 'sinusitis_non_viral',
        label: 'Sinusitis - Non-Viral HPI',
        parent_id: 'ros_additions',

        level: 0,
      },
      {
        id: 'uti_viral',
        label: 'UTI - Viral',
        parent_id: 'ros_additions',

        level: 0,
      },
      {
        id: 'uti_non_viral',
        label: 'UTI - Non-Viral',
        parent_id: 'ros_additions',

        level: 0,
      },
    ],
  },
  {
    id: 'exam',
    label: 'Exam',
    defaultOpen: true,
    level: 0,
    children: [
      {
        id: 'quick_exam',
        label: 'Quick Exam',
        level: 0,
        parent_id: 'exam',
      },
      {
        id: 'brief_exam',
        label: 'Brief Exam (Default)',
        level: 0,
        parent_id: 'exam',
      },
      {
        id: 'telemedicine_exam',
        label: 'Telemedicine Exam',
        level: 0,
        parent_id: 'exam',
      },
      {
        id: 'pre_op_exam',
        label: 'Pre - Op Exam',
        level: 0,
        parent_id: 'exam',
      },
    ],
  },
  {
    id: 'exam_add',
    label: 'Exam Additions',
    level: 1,
    defaultOpen: true,
    children: [
      {
        id: 'sinusitis_viral',
        label: 'Sinusitis - Viral HPI',
        level: 0,
        parent_id: 'exam_add',
      },
      {
        id: 'sinusitis_non_viral',
        label: 'Sinusitis - Non-Viral HPI',
        level: 0,
        parent_id: 'exam_add',
      },
      {
        id: 'uti_viral',
        label: 'UTI - Viral',
        level: 0,
        parent_id: 'exam_add',
      },
      {
        id: 'uti_non_viral',
        label: 'UTI - Non-Viral',
        level: 0,
        parent_id: 'exam_add',
      },
    ],
  },
  {
    id: 'impression_plan',
    label: 'IMPRESSION / PLAN',
    defaultOpen: true,
    level: 0,
    children: [
      {
        id: 'quick_exam',
        label: 'Quick Exam',
        level: 0,
        parent_id: 'impression_plan',
      },
      {
        id: 'brief_exam',
        label: 'Brief Exam (Default)',
        level: 0,
        parent_id: 'impression_plan',
      },
      {
        id: 'telemed_exam',
        label: 'Telemed Exam',
        level: 0,
        parent_id: 'impression_plan',
      },
    ],
  },
  {
    id: 'additions',
    label: 'Additions',
    level: 1,
    defaultOpen: true,
    children: [
      {
        id: 'sinusitis_viral',
        label: 'Sinusitis - Viral HPI',
        level: 0,
        parent_id: 'additions',
      },
      {
        id: 'sinusitis_non_viral',
        label: 'Sinusitis - Non-Viral HPI',

        level: 0,
        parent_id: 'additions',
      },
      {
        id: 'uti_viral',
        label: 'UTI - Viral',
        level: 0,
        parent_id: 'additions',
      },
      {
        id: 'uti_non_viral',
        label: 'UTI - Non-Viral',
        level: 0,
        parent_id: 'additions',
      },
    ],
  },
];

export const SMARTS_NOTES: IListSmartNotes[] = [
  {
    id: 'Respiratory',
    label: 'Respiratory',
    defaultOpen: true,
    level: 0,
    children: [
      {
        id: 'Sinusitis_viral',
        label: 'Sinusitis Viral',
        level: 0,
        note: '–J01.00',
      },
      {
        id: 'Sinusitis_non_viral',
        label: 'Sinusitis Non-Viral',
        level: 0,
        note: '–J01.00',
      },
    ],
  },
  {
    id: 'Abdominal',
    label: 'Abdominal',
    defaultOpen: true,
    level: 0,
    children: [
      {
        id: 'Abdominal Wall Pai',
        label: 'Abdominal Wall Pai',
        level: 0,
        note: '–848.60',
      },
      {
        id: 'Acid Dyspepsia',
        label: 'Acid Dyspepsia',
        level: 0,
        note: '–536.80',
      },
      {
        id: 'Appendicitis',
        label: 'Appendicitis',
        level: 0,
        note: '–540.90.80',
      },
      {
        id: 'Constipation',
        label: 'Constipation',
        level: 0,
        note: '–564.00',
      },
      { id: 'Crohn’s', label: 'Crohn’s', level: 0, note: '–555.90' },

      { id: 'Diarrhea', label: 'Diarrhea', level: 0, note: '–787.91' },
      {
        id: 'Food Poisoning',
        label: 'Food Poisoning',
        level: 0,
        note: '–005.90',
      },

      { id: 'Gastritis', label: 'Gastritis', level: 0, note: '–536.80' },

      {
        id: 'Gastroenteritis Viral',
        label: 'Gastroenteritis Viral',
        level: 0,
        note: '–008.80',
      },

      { id: 'GERD', label: 'GERD', level: 0, note: '–530.81' },

      {
        id: 'Hernia Inguinal',
        label: 'Hernia Inguinal',
        level: 0,
        note: '–550.90',
      },
      {
        id: 'Hernia Umbilical',
        label: 'Hernia Umbilical',
        level: 0,
        note: '–564.10',
      },
      { id: 'IBS', label: 'IBS', level: 0, note: '–005.90' },

      {
        id: 'Kidney Stone',
        label: 'Kidney Stone',
        level: 0,
        note: '–592.10',
      },

      {
        id: 'Nausea w Vomiting',
        label: 'Nausea w Vomiting',
        level: 0,
        note: '–787.01',
      },

      {
        id: 'Peptic Ulcer Disease',
        label: 'Peptic Ulcer Disease',
        level: 0,
        note: '-532.90',
      },

      {
        id: 'UTI Cystitis',
        label: 'UTI Cystitis',
        level: 0,
        note: '–595.00',
      },
      {
        id: 'UTI Pyelonephriti',
        label: 'UTI Pyelonephriti',
        level: 0,
        note: '–595.00',
      },
      {
        id: 'Viral Syndrome',
        label: 'Viral Syndrome',
        level: 0,
        note: '–079.99',
      },
    ],
  },
  {
    id: 'CLINICIAN CUSTOM PHRASES',
    label: 'CLINICIAN CUSTOM PHRASES',
    defaultOpen: true,
    disabled: true,
    level: 0,
    children: [
      {
        id: 'Return if more symptoms',
        label: 'Return if more symptoms',
        level: 0,
        note: '–',
      },
      {
        id: 'Return in _ Days',
        label: 'Return in _ Days',
        level: 0,
        note: '–',
      },
      {
        id: 'Return in _ Months',
        label: 'Return in _ Months',
        level: 0,
        note: '–',
      },
      {
        id: 'Return in _ Years',
        label: 'Return in _ Years',
        level: 0,
        note: '–',
      },
    ],
  },
];

export const CONTENT_LIST: any[] = [
  {
    id: 1,
    html: /*html*/ `<p><strong>CHIEF COMPLAINT (CC):</strong> 1 have a sore throat, stuffy nose, and pressure across my forehead and face.</p>`,
  },
  {
    id: 2,
    html: /*html*/ `<p><strong>HISTORY OF PRESENT ILLNESS (HPI):</strong> Robert Langley presents today complaining of a sore throat, nasal congestion, and sinus pressure. Says unable to breathe through his nose and feels pressure across his forehead, in between eyes, and bilaterally on each side of his nose that increases when he first gets out of bed in the morning or bends down. Reports a nonproductive cough. Says symptoms last throughout the entire day.</p>`,
  },
  {
    id: 3,
    html: /*html*/ `<p><strong>REVIEW OF SYSTEMS:</strong></p>
    <br/> 
    <p>
    General: Complains of fatigue but complains of intermittent chills. HEENT: Complains of sinus pressure and tenderness. Ears: Complains of ear fullness Nose: Complains of nasal congestion, decreased smell, post-nasal drip, and inability to breathe through nose. Complains of soreness, irritation, and mouth breathing which is causing dry mouth. Denies bleeding gums. Cardiovascular: Denies chest pain, palpitations, or swelling. Musculoskeletal: Complains of generalized body aches.
    </p>
    `,
  },
  {
    id: 4,
    html: /*html*/ `<p><strong>PHYSICAL EXAM:</strong></p>
    <br/> 
    <p>
   Nasal mucosa reddened and moist. Septum midline. Thick and purulent drainage noted. Frontal and maxillary sinus tendernes. Oral mucosa pink and moist. Pharynx reddened.
    </p>
    `,
  },
  {
    id: 5,
    html: /*html*/ `<p><strong>IMPRESSION/PLAN:</strong></p>
    <br/> 
    <p>
    Acute Sinusitis (J01): 14 days of symptoms without improvement, most likely bacterial.</p>
    `,
  },
  {
    id: 6,
    html: /*html*/ `<p><strong>Treatment Plan:</strong></p>
    <br/> 
    <p>
    Instructed to take antibiotic with food to avoid Gl upset. To take all of the medication even once you start to feel better. Stop the medication and notify the provider if nausea, vomiting, diarrhea, shortness of breath or rash develop.
    </p>
    
   
    `,
  },
  {
    id: 7,
    html: /*html*/ `<p><strong>Education:</strong> 
    Instructed to take antibiotic with food to avoid Gl upset. To take all of the medication even once you start to feel better. Stop the medication and notify the provider if nausea, vomiting, diarrhea, shortness of breath or rash develop.
    </p>
    `,
  },
];
