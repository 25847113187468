import { API } from 'aws-amplify';
import { config } from 'config';

export const inviteUserAPI = async (body) => {
  const response = await API.post(config.API_NAME, `/user/invite`, {
    body,
  });
  return response;
};

export const getNewUserDetailAPI = async ({ organizationId, userRecordId }) => {
  const response = await API.get(
    config.API_NAME,
    `/user/${organizationId}/${userRecordId}`,
    {}
  );
  return response;
};

export const updateNewUserDetailAPI = async ({
  body,
  organizationId,
  userRecordId,
}) => {
  const response = await API.put(
    config.API_NAME,
    `/user/${organizationId}/${userRecordId}`,
    {
      body,
    }
  );
  return body;
};
