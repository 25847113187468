import * as React from 'react';
import SettingBox from './SettingBox';

export interface ISettingsProps {}

export default function Settings({}: ISettingsProps) {
  return <React.Fragment />;
}

Settings.Box = SettingBox;
