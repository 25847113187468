import { SVG } from 'assets/imagePath';
import { Button } from 'ui-atoms';
import * as React from 'react';
import { Search, Text } from 'ui-molecules';
import { RouterPath } from 'routes/router.constant';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import classNames from 'classnames';
import { useAuth } from '../../hooks/useAuth';
import SelectedButton from './components/SelectedButton';

export interface IHeaderClinicianProps {
  className?: string;
  toggle: boolean;
  setToggle?: (prev: boolean) => void;
  setFontSize: (size: number) => void;
  viewName?: string;
  optionsList?: any[];
  onChangeOptions: (value: any) => void;
}

export default function HeaderClinician({
  toggle,
  setToggle,
  setFontSize,
  viewName,
  optionsList,
  onChangeOptions,
}: IHeaderClinicianProps) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [searchVal, setSearchValue] = useState<string>('');
  const [size, setSize] = useState(12);

  const handleChangeValue = (value: string) => {
    setSearchValue(value);
  };
  const handleOnChangleFontSize = (size: number) => {
    if (size < 12) {
      return setFontSize(12);
    }
    setFontSize(size);
  };
  const handleIncreaseSize = () => {
    setSize(size + 1);
    handleOnChangleFontSize(size + 1);
  };
  const handleDecreaseSize = () => {
    if (size > 12) {
      setSize(size - 1);
      handleOnChangleFontSize(size - 1);
    }
  };

  const renderListClinicianSelect = () => {
    return (
      <>
        <Menu as="div" className="relative  inline-block text-left">
          <div>
            <Menu.Button className="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-white px-[15px] py-[7.5px] text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Clinician List
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.85448 9.52145C7.65922 9.71671 7.34264 9.71671 7.14737 9.52145L4.60448 6.97855C4.2895 6.66357 4.51258 6.125 4.95803 6.125H10.0438C10.4893 6.125 10.7124 6.66357 10.3974 6.97855L7.85448 9.52145Z"
                  fill="black"
                />
              </svg>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm cursor-pointer'
                      )}
                    >
                      {viewName || ''}
                    </span>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </>
    );
  };
  const renderActionBtn = () => {
    return (
      <>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-white px-[15px] py-[7.5px] text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Actions
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.85448 9.52145C7.65922 9.71671 7.34264 9.71671 7.14737 9.52145L4.60448 6.97855C4.2895 6.66357 4.51258 6.125 4.95803 6.125H10.0438C10.4893 6.125 10.7124 6.66357 10.3974 6.97855L7.85448 9.52145Z"
                  fill="black"
                />
              </svg>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 w-[100px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      Print View
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </>
    );
  };
  return (
    <div className="flex items-center justify-between gap-x-[30px]">
      <ul className="flex flex-[0.5] w-full items-center list-none justify-between text-center gap-2.5">
        <div className="flex items-center gap-2.5">
          <li>
            <Button.Icon
              classNameIcon="max-w-[15px] "
              icon={toggle ? SVG.filter : SVG.un_filter}
              variant="outlined"
              className="bg-[#f3f3f3] p-[7.5px]"
              onClick={() => setToggle(!toggle)}
            />
          </li>
          <li>
            <SelectedButton
              propsButton={{
                variant: 'outlined',
                color: 'secondary',
                className: 'px-[15px]',
              }}
              onChangeValue={onChangeOptions}
              options={
                optionsList?.length
                  ? optionsList
                  : [
                      {
                        value: 'Clinician',
                        label: 'Clinician',
                      },
                    ]
              }
            />
          </li>
        </div>
      </ul>

      <ul className={'flex flex-1 justify-end items-center gap-x-2'}>
        <Text size="16" weight="700">
          {viewName || ''}
        </Text>
        <Button.White
          className="!p-default"
          color="white"
          label={`${currentUser?.['custom:initials'] || ''}`}
        />
      </ul>

      <ul className="flex flex-[1.25] items-center justify-end list-none text-center gap-2.5">
        <div className={'flex items-center justify-end gap-2.5'}>
          <li className="hidden lg:flex">
            <Button.Icon
              classNameIcon="!icon-app"
              icon={SVG.zoom_in}
              variant="outlined"
              className="bg-white"
              onClick={handleIncreaseSize}
            />
          </li>
          <li className="hidden lg:flex">
            <Button.Icon
              classNameIcon="!icon-app"
              icon={SVG.zoom_out}
              variant="outlined"
              className="bg-white"
              onClick={handleDecreaseSize}
            />
          </li>
          <li className="hidden lg:flex">
            <div className="mt-2.5 mb-[15px]">
              <Search.Standard
                value={searchVal}
                onChange={handleChangeValue}
                className="border-[1px] border-nav border-solid !rounded-full bg-transparent"
                inputProps={{
                  placeholder: 'Search',
                  style: { borderStyle: 'solid' },
                }}
              />
            </div>
          </li>
          <li>
            <Button.Primary
              label="Add Clinician"
              onClick={() => navigate('/clinician/invite')}
            />
          </li>
          <li>{renderActionBtn()}</li>
        </div>
      </ul>
    </div>
  );
}
