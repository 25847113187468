import { useLocation } from 'react-router-dom';
import HeaderBuilder from './HeaderBuilder';
import HeaderDetail from './HeaderDetail';
import HeaderList from './HeaderList';
import HeaderNote from './HeaderNote';
import HeaderNotes from './HeaderNotes';
import HeaderSettings from './HeaderSettings';
import HeaderOrganization from './HeaderOrganization';
import HeaderInquires from './HeaderInquires';
import HeaderEdit from './HeaderEdit';
import HeaderClinician from './HeaderClinician';
import HeaderSmartTexts from './HeaderSmartTexts';
import HeaderAssistant from './HeaderAssistant';

const Header = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();

  // const breadcrumbs: IBreadcrumb[] = BREADCRUMB.find((item) => {
  //   return item.url === location.pathname;
  // })?.breadcrumbs;

  return (
    <div className="w-full bg-white border border-nav p-2.5">
      {/* <Breadcrumb
          separator={<img src={SVG.arrow_right} className="object-contain h-[8px] w-[8px]" />}
          breadcrumbs={breadcrumbs}
        /> */}
      <Component {...props} />
    </div>
  );
};
Header.Notes = Header(HeaderNotes);
Header.Note = Header(HeaderNote);
Header.Detail = Header(HeaderDetail);
Header.Builder = Header(HeaderBuilder);
Header.List = Header(HeaderList);
Header.Settings = Header(HeaderSettings);
Header.Organization = Header(HeaderOrganization);
Header.Inquires = Header(HeaderInquires);
Header.Edit = Header(HeaderEdit);
Header.Clinician = Header(HeaderClinician);
Header.SmartTexts = Header(HeaderSmartTexts);
Header.Assistant = Header(HeaderAssistant);

//Header is HOC
export default Header;
