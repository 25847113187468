import { CLINICIAN_DETAILS } from 'ui-molecules/detail/data';
import { DetailBox } from 'ui-molecules';
import { memo, useContext, useEffect } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { useApiCall } from '../../hooks';
import { getOrganizationAPI } from '../../services';
import { SET_LOADING_MODAL } from '../../constant';
import { useNavigate } from 'react-router-dom';

const ClinicianSummary = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { userRecordId, primaryAccountId } = state;
  const navigate = useNavigate();

  const [fetchOrg, loading, orgData] = useApiCall(getOrganizationAPI);

  useEffect(() => {
    if (!userRecordId || !primaryAccountId) return navigate('/');
    // fetchOrg({
    //   userRecordId: userRecordId,
    //   organizationId: primaryAccountId,
    // });
  }, [fetchOrg, userRecordId, primaryAccountId]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading,
    });
  }, [loading]);
  return (
    <div className="grid grid-cols-2 w-full">
      <div className="col-span-1 flex flex-col ">
        <DetailBox detailRows={CLINICIAN_DETAILS.left_summary(orgData)} />
      </div>
      <div className="col-span-1 flex flex-col">
        <DetailBox detailRows={CLINICIAN_DETAILS.right_summary(orgData)} />
      </div>
    </div>
  );
};

export default memo(ClinicianSummary);
