import * as React from 'react';
import ClinicianDetail from './ClinicianDetail';
import ClinicianSummary from './ClinicianSummary';
import ClinicianInvitation from './ClinicianInvitation';
import ClinicianListFilter from './ClinicianListFilter';
import ClinicianFilter from './ClinicianFilter';
import ClinicianDetailTable from './ClinicianDetailTable';

export interface IClinicianProps {}

function Clinician(props: IClinicianProps) {
  return <React.Fragment />;
}

Clinician.Detail = ClinicianDetail;
Clinician.Summary = ClinicianSummary;
Clinician.Filter = ClinicianFilter;
Clinician.ClinicianListFilter = ClinicianListFilter;
Clinician.ClinicianInvitation = ClinicianInvitation;
Clinician.DetailTable = ClinicianDetailTable;
export default Clinician;
