import { useState, useEffect } from 'react';

export const useDebounce = (value, delay = 500) => {
  const [result, setResult] = useState(value);

  useEffect(() => {
    const id = setTimeout(() => {
      setResult(value);
    }, delay);

    return () => {
      clearTimeout(id);
    };
  }, [value]);

  return result;
};
