import cn from 'classnames';
import { FC, memo } from 'react';

type Props = {
  className?: string;
  label: string;
  icon?: string;
  color?: 'white' | 'default';
  onClick: () => void;
};

const ButtonBlue: FC<Props> = ({
  className,
  label,
  icon,
  onClick,
  color = 'default',
}) => {
  return (
    <button
      type="button"
      className={cn(
        'bg-gradient-to-b from-[#00A3F4] to-[#017AFE] flex px-6 py-2  text-white font-inter text-md leading-snug rounded-md shadow-inner inner-border inner-border-pearl hover:bg-grey-lightest-700 hover:shadow-lg focus:bg-grey-lightest-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-grey-lightest-800 active:shadow-lg transition duration-150 ease-in-out',
        className
      )}
      onClick={onClick}
    >
      {label}
      {icon && <img className="ml-2" src={icon} alt="button-icon" />}
    </button>
  );
};

ButtonBlue.displayName = 'Button.Blue';

export default memo(ButtonBlue);
