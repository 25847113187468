import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { SVG } from 'assets/imagePath';
import { Box, Organization } from 'ui-organisms';
import { Button } from 'ui-atoms';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import { DEFAULT_LIMIT } from 'constant/pagination';
import { Footer, HeaderApp } from 'ui-molecules';
import { useApiCall } from 'hooks';
import { GlobalContext } from 'context/GlobalContext';
import {
  APP_ROLE,
  SET_IS_HAVE_RECORDS_ORGANIZATION,
  SET_LOADING_MODAL,
  SET_PRIMARY_ACCOUNT_ID,
  SET_USER_RECORD_ID,
} from 'constant';
import { getRowsByPage } from 'utils';
import { ListOrganization } from './tables';
import { getListOrganizationsAPI } from '../../services';
import { RouterPath } from '../../routes/router.constant';
import { useAuth } from '../../hooks/useAuth';

export interface IOrganizationPageProps {}

export default function OrganizationPage({}: IOrganizationPageProps) {
  const { dispatch } = useContext(GlobalContext);
  const { CURRENT_ROLE } = useAuth();
  const navigate = useNavigate();
  const [fetchOrgList, loading, orgList] = useApiCall(getListOrganizationsAPI);
  const [toggle, setToggle] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(DEFAULT_LIMIT);
  const [rows, setRows] = useState(
    getRowsByPage(orgList, pageSize, currentPage)
  );

  const [fontSize, setFontSize] = useState(12);

  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }

  useEffect(() => {
    fetchOrgList();
  }, []);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading,
    });
  }, [dispatch, loading]);

  const handleNavigateOrganizationDetail = (row) => {
    dispatch({
      type: SET_USER_RECORD_ID,
      payload: row?.userRecordId,
    });
    dispatch({
      type: SET_PRIMARY_ACCOUNT_ID,
      payload: row?.organizationId,
    });
    navigate(`/organization/${row?.userRecordId}`);
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const newRows = getRowsByPage(orgList, pageSize, currentPage);
    setRows(newRows);
  }, [pageSize, currentPage, orgList]);

  useEffect(() => {
    dispatch({
      type: SET_IS_HAVE_RECORDS_ORGANIZATION,
      payload: !isEmpty(rows),
    });
  }, [dispatch, rows]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading,
    });
  }, [dispatch, loading]);

  return (
    <DefaultLayout>
      <HeaderApp.Organization
        toggle={toggle}
        setToggle={setToggle}
        setFontSize={setFontSize}
        viewName={'Organizations List'}
      />
      <div className="w-full h-full flex flex-col flex-1 items-center p-2.5">
        <div className="w-full flex justify-between items-center">
          <p className="font-normal">
            Records:
            {/* <span className="font-bold">{pageSize}</span> */}
          </p>
          <div className="px-2.5">
            <Button.White
              className="px-4"
              color="white"
              label="10 Records per page"
              icon={SVG.chevron_down}
              onClick={() => {}}
            />
          </div>
        </div>

        <div className="flex gap-2.5 items-stretch h-full w-full mt-2.5">
          <div
            className={`w-[180px] transition-all ${
              toggle ? '' : '-translate-x-[150%] fixed'
            }`}
          >
            <Box.Standard className="h-full">
              <Organization.Filter organizationalList={orgList} />
            </Box.Standard>
          </div>
          <div className="flex-1 h-full overflow-auto relative">
            <Box.Standard className="flex w-full h-full overflow-auto flex-col !p-0">
              <div className="!overflow-auto w-full max-h-full z-0">
                <ListOrganization
                  rows={rows}
                  fontSize={fontSize}
                  handleNavigateOrganizationDetail={
                    handleNavigateOrganizationDetail
                  }
                />
              </div>
            </Box.Standard>
          </div>
        </div>
      </div>
      <Footer
        pagination={{
          total: orgList?.length,
          pageSize: pageSize,
          onChangeCurrentPage: handleChangePage,
          onChangePageSize: handleChangePageSize,
        }}
      />
    </DefaultLayout>
  );
}
