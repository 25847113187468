import { ISettingBox } from '../../../types/setting.interface';
import { Settings } from '../../../ui-molecules';
import { RouterPath } from '../../../routes/router.constant';

export const SETTINGS_LIST_ROW_1: ISettingBox[] = [
  {
    id: 'general',
    title: 'General',
    settings: [
      { id: 'personal', label: 'Personal Settings', url: '' },
      { id: 'organization', label: 'Organization Details', url: '' },
      { id: 'service_phone', label: 'Service Phone', url: '' },
      { id: 'localisation', label: 'Localisation', url: '' },
      { id: 'support_tickets', label: 'Support tickets', url: '' },
      {
        id: 'redemption_code',
        label: 'iOS Redemption Code',
        url: '/redemption_code',
      },
    ],
  },
  {
    id: 'user_control',
    title: 'Users and Control',
    settings: [
      { id: 'user', label: 'Users', url: '' },
      { id: 'user_status', label: 'Users - User Status', url: '' },
      { id: 'security_control', label: 'Security Control', url: '' },
      { id: 'time_zones', label: 'Time Zones', url: '' },
      {
        id: 'prefix',
        label: 'Prefix',
        url: '',
      },
      { id: 'suffix', label: 'Suffix', url: '' },
      { id: 'org_service_level', label: 'Org Service Level', url: '' },
    ],
  },
  {
    id: 'clinicians',
    title: 'CLINICIANS',
    settings: [
      {
        id: 'clinician',
        label: 'Clinician',
        url: RouterPath.CLINICIANS,
      },
      // { id: 'CL_Stats', label: 'CL - Stats', url: '' },
      { id: 'degree', label: 'Degree', url: '' },
      { id: 'other_degrees', label: 'Other Degrees', url: '' },
      { id: 'specialty', label: 'Specialty', url: '' },
      { id: 'fellowships', label: 'Fellowships', url: '' },
      { id: 'ranking', label: 'Ranking', url: '' },
      {
        id: 'clinician_record_status',
        label: 'Clinician Record Status',
        url: '',
      },
      { id: 'clin_service_levels', label: 'Clin Service Levels', url: '' },
      { id: 'priority', label: 'Priority', url: '' },
      { id: 'predictability', label: 'Predictability', url: '' },
    ],
  },
  {
    id: 'note',
    title: 'Note',
    settings: [
      { id: 'patient_data', label: 'Patient - Data', url: '' },
      {
        id: 'clinician_recorded_notes',
        label: 'Clinician - Recorded Notes',
        url: RouterPath?.NOTES,
      },
      { id: 'sla_deadline', label: 'SLA - Deadlines', url: '' },
      { id: 'place_of_service', label: 'Place of Service', url: '' },
      { id: 'recording_stats', label: 'Recording Stats', url: '' },
      { id: 'notes_app_stats', label: 'Notes App Stats', url: '' },
      {
        id: 'worklist_status',
        label: 'Worklist Status - DS CO',
        url: '',
      },
      { id: 'requests', label: 'Requests', url: '' },
      {
        id: 'text_classification_settings',
        label: 'Text Classification Settings',
        url: '',
      },
    ],
  },
  {
    id: 'document_specialists',
    title: 'Document Specialists',
    settings: [
      {
        id: 'clarify_questions',
        label: 'Clarify Questions',
        url: '',
      },
    ],
  },
];

export const SETTINGS_LIST_ROW_2: ISettingBox[] = [
  {
    id: 'manager',
    title: 'Manager',
    settings: [
      {
        id: 'clarify_questions',
        label: 'Clarify Questions',
        url: '',
      },
    ],
  },
  {
    id: 'system_admin',
    title: 'System Admin',
    settings: [
      { id: 'roles_permissions', label: 'Roles & Permissions', url: '' },
      {
        id: 'data_administration',
        label: 'Data Administration',
        url: '',
      },
      {
        id: 'mobile_app_dictionary',
        label: 'Mobile App Dictionary',
        url: '',
      },
      {
        id: 'admin_panel_dictionary',
        label: 'Admin Panel Dictionary',
        url: '',
      },
      { id: 'clinician_database', label: 'Clinician Database', url: '' },
      { id: 'doc_spec_database', label: 'Doc Spec Database', url: '' },
      { id: 'coder_database', label: 'Coder Database', url: '' },
      { id: 'manager_database', label: 'Manager Database', url: '' },
    ],
  },
];

export const SettingMgr = () => {
  return (
    <div className="h-full p-2.5 flex flex-col gap-y-2.5">
      <div className="grid xs:grid-cols-1 grid-cols-5 gap-2.5 h-max">
        {SETTINGS_LIST_ROW_1.map((item: ISettingBox) => (
          <div className="row-span-1" key={item.id}>
            <Settings.Box itemSetting={item} />
          </div>
        ))}
      </div>
      <div className="xs:w-full w-2/5 gap-2.5 mx-auto grid xs:grid-cols-1 grid-cols-2">
        {SETTINGS_LIST_ROW_2.map((item: ISettingBox) => (
          <div key={item.id}>
            <Settings.Box itemSetting={item} />
          </div>
        ))}
      </div>
    </div>
  );
};
