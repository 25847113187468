import { API } from 'aws-amplify';
import { config } from 'config';
import { ISupportFormValues } from '../types';

export * from './invite';
export * from './AuthService';

export const createNewUser = async (user) => {
  return await API.post(config.API_NAME, '/users/add', {
    body: user,
  });
};

export const createNewCorp = async (corp) => {
  return await API.post(config.API_NAME, '/corporate-record', {
    body: corp,
  });
};

export const getOrganizationListAPI = async (primaryAccountId) => {
  return await API.get(
    config.API_NAME,
    `/corporate-records/${primaryAccountId}`,
    {}
  );
};

export const getOrganizationAPI = async ({ userRecordId, organizationId }) => {
  return await API.get(
    config.API_NAME,
    `/corporate-record/${userRecordId}/${organizationId}`,
    {}
  );
};

export const createOrganizationAPI = async (body, signature) => {
  return await API.post(config.API_NAME, `/corporate-record`, {
    body: body,
    headers: {
      'X-ECOM-REQUEST': signature,
    },
  });
};

export const updateOrganizationAPI = async ({ body }) => {
  return await API.put(config.API_NAME, `/corporate-record`, {
    body,
  });
};

export const getUserListAPI = async ({ primaryAccountId, userRole }) => {
  return await API.post(
    config.API_NAME,
    `/users/${primaryAccountId}/${userRole}`,
    {}
  );
};

export const getUserAPI = async ({ primaryAccountId, userRecordId }) => {
  return await API.get(
    config.API_NAME,
    `/user/${primaryAccountId}/${userRecordId}`,
    {}
  );
};

export const createUserAPI = async (body) => {
  return await API.post(config.API_NAME, `/users/add`, {
    body,
  });
};

export const updateUserAPI = async ({
  body,
  primaryAccountId,
  userRecordId,
}) => {
  return await API.put(
    config.API_NAME,
    `/user/${primaryAccountId}/${userRecordId}`,
    {
      body,
    }
  );
};

// Tread Record
// GET - UNIQUE RECORD
export const getTreatmentRecordsAPI = async ({
  userRecordId,
  treatmentRecordId,
}) => {
  return await API.get(
    config.API_NAME,
    `/get-treatment-record/${userRecordId}/${treatmentRecordId}`,
    {}
  );
};

export const getListNotesRecordsAPI = async (organizationId: string) => {
  return await API.get(
    config.API_NAME,
    `/list-treatment-records/${organizationId}`,
    {}
  );
};

export const getListNotesRecordsByClinicianAPI = async ({
  organizationId,
  userRecordId,
}) => {
  return await API.get(
    config.API_NAME,
    `/list-treatment-records/${organizationId}?userRecordId=${userRecordId}`,
    {}
  );
};

// export const getListTreatmentRecordsAPI = async (
//   organizationId,
//   treatmentRecordId
// ) => {
//   const ExclusiveStartKey = {
//     organizationId: organizationId,
//     treatmentRecordId: treatmentRecordId,
//   };
//
//   return await API.get(
//     config.API_NAME,
//     `/list-treatment-records/all?ExclusiveStartKey=`,
//     ExclusiveStartKey
//   );
// };

export const updateTreatmentRecordAPI = async ({
  organizationId,
  treatmentRecordId,
  body,
}) => {
  return await API.put(
    config.API_NAME,
    `/update-treatment-record/${organizationId}/${treatmentRecordId}`,
    {
      body,
    }
  );
};

export const deleteTreatmentRecordAPI = async (body) => {
  return await API.put(config.API_NAME, '/delete-treatment-record', { body });
};

export const getUserRecordAPI = async ({ organizationId, userRole }) => {
  return await API.post(
    config.API_NAME,
    `/users/${organizationId}/${userRole}`,
    {}
  );
};

export const getListOrganizationsAPI = async () => {
  return await API.get(config.API_NAME, `/corporate-records`, {});
};

export const getListClinicianByOrganizationIdAPI = async (
  organizationId: string
) => {
  const params = {
    organizationId: organizationId,
  };
  return await API.post(config.API_NAME, '/users/Clinician', {
    body: params,
  });
};

export const getListDocumentSpecialistByOrganizationIdAPI = async (
  organizationId: string
) => {
  const params = {
    organizationId: organizationId,
  };
  return await API.post(config.API_NAME, '/users/Document_Specialist', {
    body: params,
  });
};

export const getListMedicalCoderByOrganizationIdAPI = async (
  organizationId: string
) => {
  const params = {
    organizationId: organizationId,
  };
  return await API.post(config.API_NAME, '/users/Medical_Coder', {
    body: params,
  });
};

export const getListSystemAdminByOrganizationIdAPI = async (
  organizationId: string
) => {
  const params = {
    organizationId: organizationId,
  };
  return await API.post(config.API_NAME, '/users/System_Admin', {
    body: params,
  });
};

export const getListOwnerByOrganizationIdAPI = async (
  organizationId: string
) => {
  const params = {
    organizationId: organizationId,
  };
  return await API.post(config.API_NAME, '/users/Owner', {
    body: params,
  });
};

export const getListOrganizationAdminByOrganizationIdAPI = async (
  organizationId: string
) => {
  const params = {
    organizationId: organizationId,
  };
  return await API.post(config.API_NAME, '/users/Organization_Admin', {
    body: params,
  });
};

export const getUserListOfOrganizationForAdminAPI = async (
  organizationId: string
) => {
  return await API.get(
    config.API_NAME,
    `/corporate-record-info/${organizationId}`,
    {}
  );
};

export const submitSupportFormAPI = (body: ISupportFormValues) => {
  return API.post(config.API_NAME, '/user/support', { body });
};

export const getUserDetailAPI = async ({ organizationId, userRecordId }) => {
  return await API.get(
    config.API_NAME,
    `/user/${organizationId}/${userRecordId}`,
    {}
  );
};
export const getListUserApi = async ({ userRole, organizationId }) => {
  const params = {
    organizationId: organizationId,
  };
  return await API.post(config.API_NAME, `/users/${userRole}`, {
    body: params,
  });
};

export const updateClinicianAPI = async ({
  organizationId,
  userRecordId,
  body,
}) => {
  return await API.put(
    config.API_NAME,
    `/user/${organizationId}/${userRecordId}`,
    {
      body,
    }
  );
};
