import { Box } from 'ui-organisms';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ISettingBox } from 'types/setting.interface';

export interface ISettingBoxProps {
  itemSetting: ISettingBox;
}

export default function SettingBox({ itemSetting }: ISettingBoxProps) {
  return (
    <Box.Standard className="p-5 h-full" key={itemSetting.id}>
      <h3 className="py-[5px] px-2.5 font-bold text-xs">{itemSetting.title}</h3>
      <div className="flex flex-col px-2.5">
        {itemSetting?.settings.map((setting, cIdx) => (
          <Link
            to={setting?.url}
            className="text-xs py-[2.5px]"
            key={setting.id}
          >
            {setting?.label}
          </Link>
        ))}
      </div>
    </Box.Standard>
  );
}
