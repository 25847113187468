import * as Yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const requiredText = 'This is required field';

export const useCoderEditSchema = () => {
  return Yup.object().shape({
    staffName: Yup.string().nullable().required(requiredText),
    role: Yup.string().nullable().required(requiredText),
    organizationName: Yup.string().nullable().required(requiredText),
    cellPhone: Yup.string()
      .nullable()
      .optional()
      .matches(phoneRegExp, {
        message: 'Cell Phone is not valid',
        excludeEmptyString: true,
      })
      .required('Phone is required'),
    email: Yup.string()
      .email('Email is invalid')
      .nullable()
      .required('Email is required'),
    timeZone: Yup.string().nullable(),
    specialty: Yup.string().nullable().required(requiredText),
    ranking: Yup.string().nullable(),

    firstName: Yup.string().nullable().required(requiredText),
    middleName: Yup.string().nullable().required(requiredText),
    lastName: Yup.string().nullable().required(requiredText),
    initials: Yup.string().nullable().required(requiredText),
    sortName: Yup.string().nullable().required(requiredText),
    givenName: Yup.string().nullable(),
    prefix: Yup.string().nullable(),
    suffix: Yup.string().nullable(),

    degree: Yup.string().nullable(),
    staffRecordStatus: Yup.string().nullable(),

    organization: Yup.object().required(requiredText),
    orgShort: Yup.string().nullable().required(requiredText),
    orgInitials: Yup.string().nullable().required(requiredText),
    locations: Yup.string().nullable(),
    site: Yup.string().nullable(),

    important: Yup.string().nullable(),
    staffTimeZone: Yup.string().nullable(),
    startDate: Yup.string().nullable().required(requiredText),
    organizationId: Yup.string().nullable().required(requiredText),
    serviceLevel: Yup.string().nullable(),
    priority: Yup.string().nullable(),
  });
};
