import { ChangeEvent, FC, memo, useCallback, useId } from 'react';
import { ObjectUtils } from 'utils';
import cn from 'classnames';

export type CheckboxesProps = {
  className?: string;
  classNameBox?: string;
  classNameText?: string;
  label?: string;
  name?: string;
  onChange: (e: any) => void;
};

const Checkboxes: FC<CheckboxesProps> = ({
  className,
  label,
  classNameBox,
  classNameText,
  name,
  onChange,
  ...rest
}) => {
  const checkboxId = useId();
  return (
    <div className={cn('flex justify-center items-center', classNameBox)}>
      <input
        type="checkbox"
        className={cn(
          'h-[13px] w-[13px] border-2 border-text rounded-sm transition duration-200 mt-1 align-top mr-2 cursor-pointer my-auto checked:bg-primary checked:focus:bg-primary checked:hover:bg-primary  focus:ring-transparent',
          className
        )}
        name={name}
        onChange={onChange}
        {...rest}
        id={checkboxId}
      />
      {!ObjectUtils.isEmpty(label) && (
        <label
          className={cn(
            'form-check-label text-sm font-inter inline-block mt-0.5 text-label w-full',
            classNameText
          )}
          htmlFor={checkboxId}
        >
          {label}
        </label>
      )}
    </div>
  );
};

Checkboxes.displayName = 'Checkbox.Checkboxes';

export default memo(Checkboxes);
