import { Text } from 'ui-molecules';
import * as React from 'react';

export interface BenefitBoxProps {
  percent: number;
  desc: string;
}

export default function BenefitBox({ percent, desc }: BenefitBoxProps) {
  const heightPercent = `h-[${percent}%]`;
  return (
    <div className="w-full h-full min-h-[300px] rounded-[30px] bg-primary bg-opacity-50 flex flex-col overflow-hidden justify-end">
      <div
        style={{ height: `${percent}%` }}
        className={`${heightPercent} bg-primary p-[30px] flex flex-col justify-end gap-[5px]`}
      >
        <Text color="white" className="text-3xl md:text-4xl" weight="300">
          {percent}%
        </Text>
        <Text color="white" size="16" weight="400">
          {desc}
        </Text>
      </div>
    </div>
  );
}
