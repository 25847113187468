import OrganizationDetail from './OrganizationDetail';
import OrganizationSummary from './OrganizationSummary';
import OrganizationsFilter from './OrganizationsFilter';
import OrganizationSideBar from './OrganizationSideBar';
import OrganizationDetailTable from './OrganizationDetailTable';

const Organization = {
  Filter: OrganizationsFilter,
  Summary: OrganizationSummary,
  Detail: OrganizationDetail,
  DetailTable: OrganizationDetailTable,
  SideBar: OrganizationSideBar,
};

export default Organization;
