import { SVG } from 'assets/imagePath';
import { Button } from 'ui-atoms';
import { APP_ROLE } from 'constant';
import { useViewport } from 'context/ViewportContext';
import { useAuth } from 'hooks/useAuth';
import { FC, memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SelectedButton from './components/SelectedButton';
import { Search, Text } from '../index';
import { RouterPath } from '../../routes/router.constant';
import * as React from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';

type Props = {
  className?: string;
  toggle: boolean;
  setToggle?: (prev: boolean) => void;
  setFontSize: (size: number) => void;
  viewName?: string;
};

const HeaderOrganization: FC<Props> = ({
  className,
  toggle,
  setToggle,
  setFontSize,
  viewName,
}) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { width } = useViewport();
  const [searchVal, setSearchValue] = useState<string>('');
  const [size, setSize] = useState(12);

  const handleOnChangleFontSize = (size: number) => {
    if (size < 12) {
      return setFontSize(12);
    }
    setFontSize(size);
  };
  const handleIncreaseSize = () => {
    setSize(size + 1);
    handleOnChangleFontSize(size + 1);
  };
  const handleDecreaseSize = () => {
    if (size > 12) {
      setSize(size - 1);
      handleOnChangleFontSize(size - 1);
    }
  };

  useEffect(() => {
    // if (width < BREAKPOINT.lg) {
    //   setToggle(false);
    // } else {
    //   setToggle(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);
  const handleChangeValue = (value: string) => {
    setSearchValue(value);
  };

  const renderActionBtn = () => {
    return (
      <>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-white px-[15px] py-[7.5px] text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Actions
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.85448 9.52145C7.65922 9.71671 7.34264 9.71671 7.14737 9.52145L4.60448 6.97855C4.2895 6.66357 4.51258 6.125 4.95803 6.125H10.0438C10.4893 6.125 10.7124 6.66357 10.3974 6.97855L7.85448 9.52145Z"
                  fill="black"
                />
              </svg>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 w-[100px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      Print View
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </>
    );
  };

  return (
    <div className="flex justify-between items-center gap-x-[30px]">
      <ul className="flex items-center  list-none text-center gap-2.5">
        <li>
          <Button.Icon
            classNameIcon="!icon-app"
            icon={toggle ? SVG.filter : SVG.un_filter}
            variant="outlined"
            className="bg-[#f3f3f3]"
            onClick={() => setToggle(!toggle)}
          />
        </li>
        <li className="flex">
          <div>
            <SelectedButton
              propsButton={{
                variant: 'outlined',
                color: 'secondary',
                className: 'px-[15px]',
              }}
              options={[
                { value: 'my_orgs', label: 'My ORGs' },
                { value: 'all_orgs', label: 'All ORGs' },
              ]}
            />
          </div>
        </li>
      </ul>
      <ul className={'flex flex-1 items-center justify-end gap-x-2'}>
        <Text size="16" weight="700">
          {viewName || ''}
        </Text>
        <Button.White
          className="!p-default"
          color="white"
          label={`${currentUser?.['custom:initials'] || ''}`}
        />
      </ul>
      <ul className="flex flex-[1.25] items-center justify-end list-none text-center gap-2.5">
        <li className="hidden lg:flex">
          <Button.Icon
            classNameIcon="!icon-app"
            icon={SVG.zoom_in}
            variant="outlined"
            className="bg-white"
            onClick={handleIncreaseSize}
          />
        </li>
        <li className="hidden lg:flex">
          <Button.Icon
            classNameIcon="!icon-app"
            icon={SVG.zoom_out}
            variant="outlined"
            className="bg-white"
            onClick={handleDecreaseSize}
          />
        </li>
        <li className="hidden lg:flex">
          <div className="mt-2.5 mb-[15px]">
            <Search.Standard
              value={searchVal}
              onChange={handleChangeValue}
              className="border-[1px] border-nav border-solid !rounded-full bg-transparent"
              inputProps={{
                placeholder: 'Search',
                style: { borderStyle: 'solid' },
              }}
            />
          </div>
        </li>
        <li>
          <Button.Primary
            className="!p-default"
            label="Add ORG"
            onClick={() => navigate(RouterPath.ORGANIZATION_NEW)}
          />
        </li>
        <li>{renderActionBtn()}</li>
      </ul>
    </div>
  );
};
HeaderOrganization.displayName = 'Header.Organization';
export default memo(HeaderOrganization);
