import { SVG } from 'assets/imagePath';
import { Button } from 'ui-atoms';
import { FC, memo, useContext, useState } from 'react';
import { GlobalContext } from 'context/GlobalContext';
import { IDropdown } from 'types';
import SelectedButton from './components/SelectedButton';
import { Text } from '../index';
import * as React from 'react';
import cn from 'classnames';

type Props = {
  className?: string;
  detailProps: {
    detailName: string;
    addLabel: string;
    actionLabel?: string;
    labelDetail: string;
  };
  onEdit?: () => {};
  onAdd?: () => {};
  onMore?: () => {};
  toggle?: boolean;
  setToggle?: (prev: boolean) => void;
  title?: {
    options?: IDropdown[];
  };
  actions?: {
    options?: IDropdown[];
  };
  viewName?: string;
  setFontSize?: (size: number) => void;
};

const HeaderDetail: FC<Props> = ({
  className,
  detailProps,
  onEdit,
  onAdd,
  onMore,
  toggle,
  setToggle,
  title,
  viewName,
  actions,
  setFontSize,
}) => {
  const { state } = useContext(GlobalContext);
  const { userInfo } = state;
  const [size, setSize] = useState(12);
  const handleOnChangleFontSize = (size: number) => {
    if (size < 12) {
      return setFontSize?.(12);
    }
    setFontSize?.(size);
  };
  const handleIncreaseSize = () => {
    setSize(size + 1);
    handleOnChangleFontSize(size + 1);
  };
  const handleDecreaseSize = () => {
    if (size > 12) {
      setSize(size - 1);
      handleOnChangleFontSize(size - 1);
    }
  };
  return (
    <div className="flex justify-between items-center">
      <ul className="flex items-center  list-none text-center gap-2.5">
        <li>
          <Button.Icon
            classNameIcon="!icon-app"
            icon={SVG.filter_panel}
            variant="outlined"
            className={cn(
              'bg-[#f3f3f3]',
              toggle && 'border-2 border-solid border-[#BC9A3B]'
            )}
            onClick={() => setToggle(!toggle)}
          />
        </li>
        {title?.options && (
          <li className="flex">
            <div>
              <SelectedButton
                propsButton={{
                  variant: 'outlined',
                  color: 'secondary',
                  className: 'px-[15px]',
                }}
                options={title?.options}
              />
            </div>
          </li>
        )}
      </ul>
      {/* <Button.White
        className="!p-default mr-[35px]"
        color="white"
        label={detailProps.labelDetail}
        icon={SVG.chevron_down}
        onClick={() => {}}
      /> */}
      <ul className="flex flex-1 justify-end items-center">
        <Text size="16" weight="700">
          {viewName || ''}
        </Text>

        <Button.Text
          className="border border-silver rounded-md w-fit p-1 mx-2"
          label={`${userInfo?.['custom:initials'] || ''}`}
          onClick={() => {}}
        />
      </ul>
      <ul className="flex flex-1 items-center justify-end list-none gap-2.5">
        <li>
          <Button.Icon
            classNameIcon="!icon-app"
            icon={SVG.zoom_in}
            variant="outlined"
            className="bg-[#f3f3f3]"
            onClick={handleIncreaseSize}
          />
        </li>
        <li>
          <Button.Icon
            classNameIcon="!icon-app"
            icon={SVG.zoom_out}
            variant="outlined"
            className="bg-[#f3f3f3]"
            onClick={handleDecreaseSize}
          />
        </li>
        {!!detailProps?.addLabel?.length && (
          <li>
            <Button.Primary
              className="!p-default"
              label={detailProps.addLabel}
              onClick={onAdd}
            />
          </li>
        )}
        {detailProps?.actionLabel && (
          <li>
            <Button.White
              className="!p-default"
              color="white"
              label={detailProps?.actionLabel}
              onClick={onEdit}
            />
          </li>
        )}
        {actions?.options && (
          <SelectedButton
            propsButton={{
              variant: 'contained',
              color: 'whiteLight',
              className: 'px-[15px]',
              textClass: '!text-black',
            }}
            options={actions?.options}
          />
        )}
        <li>
          <Button.Icon
            icon={SVG.more_horizontal}
            variant="outlined"
            onClick={onMore}
          />
        </li>
      </ul>
    </div>
  );
};
HeaderDetail.displayName = 'Header.Detail';
export default memo(HeaderDetail);
