import * as Yup from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const useAcceptSchema = () => {
  return Yup.object().shape({
    password: Yup.string()
      .nullable()
      .trim('Password is required')
      .required('Password is required'),
    email: Yup.string()
      .nullable()
      .email('Email is invalid')
      .trim('Email is required')
      .required('Email is required'),
    code: Yup.string()
      .nullable()
      .trim('Confirmation Code is required')
      .required('Confirmation Code is required'),
  });
};

export const useConfirmDetailSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .nullable()
      .email('Email is invalid')
      .trim('Email is required')
      .required('Email is required'),
    firstName: Yup.string()
      .nullable()
      .trim('First Name is required')
      .required('First Name is required'),
    lastName: Yup.string()
      .nullable()
      .trim('Last Name is required')
      .required('Last Name is required'),
    cellPhone: Yup.string()
      .nullable()
      .trim('Cell Phone is required')
      .required('Cell Phone is required')
      .matches(phoneRegExp, 'Cell Phone is not valid'),
    servicePhone: Yup.string()
      .nullable()
      .trim('Service Phone is required')
      .required('Service Phone is required')
      .matches(phoneRegExp, 'Service Phone is not valid'),
    timeZone: Yup.string()
      .nullable()
      .trim('Time zone is required')
      .required('Time zone is required'),
  });
};

export const useChangePassword = () => {
  return Yup.object().shape({
    oldPassword: Yup.string()
      .nullable()
      .trim('Old Password is required')
      .required('Old Password is required'),
    newPassword: Yup.string()
      .nullable()
      .trim('New Password is required')
      .required('New Password is required')
      .min(8, 'New Password must be over 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    confirmPassword: Yup.string()
      .nullable()
      .trim('Confirm Password is required')
      .required('Confirm Password is required')
      .oneOf([Yup.ref('newPassword'), ''], 'Confirm Password does not match'),
  });
};
