import * as React from 'react';
import classnames from 'classnames';
import classes from './pagination-item.module.scss';
import { SVG } from '../../assets/imagePath';
import { PAGE_NEXT, PAGE_PREV } from '../../hooks/useRenderPaginationItem';

type Props = {
  type: 'prev' | 'next';
  isFirstPage: boolean;
  isLastPage: boolean;
};

export const COLOR_GRAY_HEX = '#666666';
export const COLOR_WHITE_HEX = '#E0E0E0';

const arrowMap = {
  prev: <img src={SVG.arrow_prev} color={COLOR_GRAY_HEX} alt={'left-arrow'} />,
  next: <img src={SVG.arrow_next} color={COLOR_GRAY_HEX} alt={'right-arrow'} />,
};

const arrowDisablePreMap = {
  prev: <img src={SVG.arrow_prev} color={COLOR_WHITE_HEX} alt={'left-arrow'} />,
  next: (
    <img src={SVG.arrow_next} color={COLOR_WHITE_HEX} alt={'right-arrow'} />
  ),
};

const arrowDisableNextMap = {
  prev: <img src={SVG.arrow_prev} color={COLOR_GRAY_HEX} alt={'left-arrow'} />,
  next: <img src={SVG.arrow_next} color={COLOR_GRAY_HEX} alt={'right-arrow'} />,
};

const arrowDisableNextAndPreMap = {
  prev: <img src={SVG.arrow_prev} color={COLOR_WHITE_HEX} alt={'left-arrow'} />,
  next: (
    <img src={SVG.arrow_next} color={COLOR_WHITE_HEX} alt={'right-arrow'} />
  ),
};

export function PaginationItemArrow({
  type,
  isFirstPage,
  isLastPage,
}: Props): React.ReactElement {
  function renderId(arrowType: string): string {
    if (arrowType === PAGE_PREV) return PAGE_PREV;
    if (arrowType === PAGE_NEXT) return PAGE_NEXT;
    return '';
  }

  if (isFirstPage && isLastPage)
    return (
      <div className={classes['arrow']}>{arrowDisableNextAndPreMap[type]}</div>
    );
  if (isFirstPage)
    return (
      <div
        className={classnames(
          classes['arrow'],
          type === PAGE_PREV ? 'mr-3' : 'ml-3'
        )}
        id={renderId(type)}
      >
        {arrowDisablePreMap[type]}
      </div>
    );
  if (isLastPage)
    return (
      <div
        className={classnames(
          classes['arrow'],
          type === PAGE_PREV ? 'mr-3' : 'ml-3'
        )}
        id={renderId(type)}
      >
        {arrowDisableNextMap[type]}
      </div>
    );
  return (
    <div
      className={classnames(
        classes['arrow'],
        type === PAGE_PREV ? 'mr-3' : 'ml-3'
      )}
      id={renderId(type)}
    >
      {arrowMap[type]}
    </div>
  );
}
