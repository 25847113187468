import * as Yup from 'yup';

export const useActiveStep1Schema = () => {
  return Yup.object().shape({
    password: Yup.string()
      .trim('Password is required')
      .required('Password is required')
      .min(8, 'Password must be over 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
  });
};

export const useActiveStep2Schema = () => {
  return Yup.object().shape({
    confirmCode: Yup.string().required('Email is required'),
  });
};

export const useActiveStep3Schema = () => {
  return Yup.object().shape({
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    email: Yup.string().required('This field is required'),
    cellPhone: Yup.string().required('This field is required'),
    servicePhone: Yup.string().required('This field is required'),
    timeZone: Yup.string().required('This field is required'),
    degree: Yup.array().required('This field is required'),
    specialty: Yup.array().required('This field is required'),
  });
};

export const useActiveStep4Schema = () => {
  return Yup.object().shape({
    // oldPassword: Yup.string()
    //   .trim('Old Password is required')
    //   .required('Old Password is required')
    //   .min(8, 'Password must be over 8 characters')
    //   .matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //     'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    //   ),
    newPassword: Yup.string()
      .trim('Password is required')
      .required('Password is required')
      .min(8, 'Password must be over 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    confirmPassword: Yup.string()
      .trim('Confirm Password is required')
      .required('Confirm Password is required')
      .oneOf([Yup.ref('newPassword'), ''], 'Confirm Password does not match'),
  });
};
