import { Footer, Header } from '../../../ui-organisms/landing';
import Contact from '../../../ui-organisms/landing/Contact';

export default function ContactPage() {
  return (
    <section className="flex flex-col">
      <Header />
      <body className="flex-1 h-full max-w-[1300px] w-full px-2.5 mx-auto">
        <Contact />
      </body>
      <Footer />
    </section>
  );
}
