import { FC, Fragment, memo } from 'react';

type Props = {
  src: string;
  onClick?: () => void;
  title?: string;
};

const NavBarIcon: FC<Props> = ({ src, onClick, title }) => {
  return (
    <Fragment>
      <li className="h-12">
        <div
          className="h-full cursor-pointer px-4 flex items-center hover:bg-gray-active"
          onClick={onClick}
        >
          <img
            src={src}
            className="m-auto object-contain h-[15px] w-[15px]"
            alt="icon"
          />
          {title && <span>{title}</span>}
        </div>
      </li>
    </Fragment>
  );
};

NavBarIcon.displayName = 'Nav.BarIcon';

export default memo(NavBarIcon);
