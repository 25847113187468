import { Button } from 'ui-atoms';
import { useNavigateSearch } from 'hooks';
import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import RegisterSetupHeader from './RegisterSetupHeader';

const RegisterSetupHPI: FC = () => {
  const navigate = useNavigate();
  const navigateSearch = useNavigateSearch();

  const onConfirm = () => {
    navigateSearch('/register', { step: '4', setup: 'ros' });
  };

  const onSkip = () => {
    navigate('/login', { replace: true });
  };

  const data = [
    {
      id: '1',
      command: 'Item 1',
      note: 'Item Note 1',
      sentiment: 'Positive',
      addition: 'Yes',
    },
    {
      id: '2',
      command: 'Item 2',
      note: 'Item Note 2',
      sentiment: 'Positive',
      addition: 'No',
    },
    {
      id: '3',
      command: 'Item 3',
      note: 'Item Note 3',
      sentiment: 'Positive',
      addition: 'Yes',
    },
    {
      id: '4',
      command: 'Item 4',
      note: 'Item Note 4',
      sentiment: 'Negative',
      addition: 'Yes',
    },
    {
      id: '5',
      command: 'Item 5',
      note: 'Item Note 5',
      sentiment: 'Positive',
      addition: 'No',
    },
    {
      id: '6',
      command: 'Item 6',
      note: 'Item Note 6',
      sentiment: 'Positive',
      addition: 'Yes',
    },
    {
      id: '7',
      command: 'Item 7',
      note: 'Item Note 7',
      sentiment: 'Positive',
      addition: 'Yes',
    },
    {
      id: '8',
      command: 'Item 8',
      note: 'Item Note 8',
      sentiment: 'Positive',
      addition: 'Yes',
    },
    {
      id: '9',
      command: 'Item 9',
      note: 'Item Note 9',
      sentiment: 'Positive',
      addition: 'Yes',
    },
    {
      id: '10',
      command: 'Item 10',
      note: 'Item Note 10',
      sentiment: 'Positive',
      addition: 'Yes',
    },
  ];

  return (
    <div className="w-screen flex flex-col items-center p-8">
      <RegisterSetupHeader
        title="Setup History of present illness (HPI) smart texts!"
        subtitle=""
        canGoBack
      />

      <div className="min-w-full md:min-w-4/5 grow mt-5">
        <table className="min-w-full rounded-lg inner-border">
          <thead>
            <tr className="border-b-2">
              <th className="text-left text-label font-inter font-normal px-4 py-2 w-3">
                ID
              </th>
              <th className="border text-left text-label font-inter font-normal px-4 py-2">
                Command
              </th>
              <th className="border text-left text-label font-inter font-normal px-4 py-2">
                Note
              </th>
              <th className="border text-left text-label font-inter font-normal px-4 py-2">
                Sentiment
              </th>
              <th className="text-left text-label font-inter font-normal px-4 py-2">
                Addition
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr className="border-b" key={item.id}>
                <td className="text-label font-inter text-center px-4 py-2">
                  {item.id}
                </td>
                <td className="text-label font-inter px-4 py-2">
                  {item.command}
                </td>
                <td className="text-label font-inter px-4 py-2">{item.note}</td>
                <td className="text-label font-inter px-4 py-2">
                  {item.sentiment}
                </td>
                <td className="text-label font-inter px-4 py-2">
                  {item.addition}
                </td>
              </tr>
            ))}
            <tr>
              <td className="font-inter text-input cursor-pointer text-center px-4 py-2">
                +
              </td>
              <td className="font-inter text-input cursor-pointer px-4 py-2">
                + Add Command
              </td>
              <td className="font-inter text-input cursor-pointer px-4 py-2">
                + Add Command
              </td>
              <td className="font-inter text-input cursor-pointer px-4 py-2">
                Select Sentiment
              </td>
              <td className="font-inter text-input cursor-pointer px-4 py-2">
                Select Addition
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="w-72 mt-5 md:my-5 flex">
        <Button.White className="mr-2" label="Skip" onClick={onSkip} />

        <Button.Primary
          className="flex-1 ml-2"
          label="Confirm"
          onClick={onConfirm}
        />
      </div>
    </div>
  );
};

export default memo(RegisterSetupHPI);
