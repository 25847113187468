import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';

import 'react-quill/dist/quill.snow.css'; //Import React Quill  css
import 'react-tooltip/dist/react-tooltip.css'; //
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './index.css';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { GlobalContextProvider } from 'context/GlobalContext';
import ViewportProvider from 'context/ViewportContext';
import { Amplify } from 'aws-amplify';
import { config } from './config';
import App from 'App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        // NOTE: The API "name" is critical and used by aws-amplify
        //       when an amplify API.post() method where the first
        //       argument is the name of this API field!!!
        name: config.API_NAME,
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

root.render(
  <Fragment>
    <React.StrictMode>
      <BrowserRouter>
        <GlobalContextProvider>
          <ViewportProvider>
            <App />
          </ViewportProvider>
        </GlobalContextProvider>
      </BrowserRouter>
    </React.StrictMode>
  </Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
