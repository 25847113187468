import * as React from 'react';
import { Form, Text } from 'ui-molecules';
import { FormProvider, useForm } from 'react-hook-form';
import { IContactFormValues, ISupportFormValues } from '../../types';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import { useSupportSchema } from '../../pages/public/SupportPage/support.schema';
import cn from 'classnames';
import { Button } from '../../ui-atoms';
import { useContactSchema } from '../../pages/public/ContactPage/contact.schema';

export default function Contact() {
  const form = useForm<IContactFormValues>({
    defaultValues: {
      fullName: '',
      email: '',
      message: '',
    },
    resolver: FormResolver(useContactSchema()),
  });

  const onSubmit = async (values: IContactFormValues) => {};

  return (
    <div className="p-[30px] bg-white-light md:px-5 my-2.5 rounded-[30px]">
      <div className="flex flex-col gap-y-5">
        <Text size="20" weight="700" className="text-center">
          Contact
        </Text>
        <FormProvider {...form}>
          <div className="flex items-center gap-x-5">
            <Form.TextInput
              name="fullName"
              className="flex-1 bg-white"
              inputProps={{
                placeholder: 'Full Name',
                className: 'ring-1',
              }}
            />
            <Form.EmailInput
              name="email"
              className="flex-1"
              inputProps={{
                placeholder: 'E-mail',
              }}
            />
          </div>

          <Form.TextArea
            name="message"
            className="flex-1 bg-white"
            inputProps={{
              placeholder: 'Message',
              className: 'ring-1 h-[200px] flex-start items-start',
            }}
          />

          <div className="flex justify-center">
            <Button.Common
              className={cn(
                'w-[180px] h-[32px] xs:h-[40px] md:h-[48px] rounded-full px-5 md:px-[30px]'
              )}
              onClick={form.handleSubmit(onSubmit)}
            >
              <Text
                responsive
                className="whitespace-nowrap"
                weight="400"
                size="16"
                color="white"
              >
                Submit
              </Text>
            </Button.Common>
          </div>
        </FormProvider>
      </div>
    </div>
  );
}
