import * as React from 'react';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import { Form } from 'ui-molecules';
import { useActiveStep4Schema } from '../../pages/auth/activeNewUser/active.schema';
import { Button } from '../../ui-atoms';
import { GlobalContext } from '../../context/GlobalContext';
import { SET_ACTIVE_FORM, SET_LOADING_MODAL } from '../../constant';
import { ActiveNewUserStep4 } from '../../types';
import { SVG } from '../../assets/imagePath';
import { useNavigate } from 'react-router-dom';

type Props = {
  nextStep: () => void;
};

export const ActiveUserStep3 = ({ nextStep }: Props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { activeForm } = state;
  const navigate = useNavigate();

  const form = useForm<ActiveNewUserStep4>({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'onChange',
    resolver: FormResolver(useActiveStep4Schema()),
  });
  const onSubmit = async (values: ActiveNewUserStep4) => {
    try {
      dispatch({
        type: SET_ACTIVE_FORM,
        payload: values,
      });
      const currentUser = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(
        currentUser,
        activeForm?.password,
        values?.newPassword
      );
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
      navigate(`/users/code`);
    } catch (err) {
      toast(err.message, {
        hideProgressBar: true,
        autoClose: 3000,
        type: 'error',
        theme: 'colored',
      });
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col gap-y-5 items-center justify-center">
      <img src={SVG.avatar_circle} width={110} alt="logo" className="mb-10" />
      <h1 className="text-xl font-bold">Create New Password</h1>
      <p className="flex items-baseline text-center text-[#1D2533]">
        Please change your password.
      </p>

      <FormProvider {...form}>
        <form className="flex flex-col gap-y-5">
          <Form.PasswordInput
            className="w-full"
            name="newPassword"
            inputProps={{ id: 'newPassword', placeholder: 'New Password' }}
          />

          <Form.PasswordInput
            className="w-full"
            name="confirmPassword"
            inputProps={{
              id: 'confirmPassword',
              placeholder: 'Confirm Password',
            }}
          />

          <Button.Primary
            label="Create"
            className="h-10"
            onClick={form.handleSubmit(onSubmit)}
          />
        </form>
      </FormProvider>
    </div>
  );
};
