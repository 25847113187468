import * as React from 'react';
import { SVG } from 'assets/imagePath';
import PaginationLib from 'rc-pagination';
import cn from 'classnames';
import classes from './Pagination.module.scss';
import { usePaginationItemRender } from '../../hooks/useRenderPaginationItem';
import { Dropdown } from '../../ui-atoms';
import { PAGE_SIZE_OPTIONS } from '../../constant';

export const PAGE_SIZES_ARRAY = [10, 20, 50, 100];

// eslint-disable-next-line @typescript-eslint/ban-types
export type PaginationProps = {
  total: number;
  currentPage: number;
  pageSize: number;
  setCurrentPage?: (currentPage: number) => void;
  setPageSize: (pageSize: number) => void;
};

function NewPagination({
  total,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
}: PaginationProps) {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === Math.ceil(total / pageSize);
  const { itemRender } = usePaginationItemRender(isFirstPage, isLastPage);

  return (
    <div className={cn(classes['pagination-wrap'])}>
      <div className={classes['pagination-content']}>
        <PaginationLib
          itemRender={itemRender}
          current={currentPage}
          onChange={setCurrentPage}
          total={total}
          pageSize={pageSize}
          showTitle={false}
          className={cn(
            classes['rc-pagination'],
            total === 0 && classes['rc-pagination-disabled']
          )}
          showLessItems
        />

        {setPageSize && (
          <div className="flex items-center">
            <span className="mr-[5px]">Page size:</span>

            <Dropdown.Standard
              CustomButton={(item) => (
                <div className="flex gap-2.5 items-center hover:bg-transparent p-5-10 border border-nav rounded-[5px]">
                  <div className="">
                    <p className="text-xs">{item.label}</p>
                  </div>
                  <img
                    className="icon-app"
                    src={SVG.chevron_down}
                    alt="chevron_down"
                  />
                </div>
              )}
              options={PAGE_SIZE_OPTIONS}
              onChangeValue={(item: any) => {
                setPageSize(item?.value);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default NewPagination;
