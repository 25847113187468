import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

export interface IClinicianListFilterProps {
  orgList: any[];
  onClick: (id: string) => void;
}

function ClinicianListFilter({ orgList, onClick }: IClinicianListFilterProps) {
  return (
    <div className="flex flex-col gap-y-2.5 px-2.5">
      <h3 className={'font-bold text-xs py-1'}>Organizations</h3>
      <div className="flex flex-col text-xs gap-[5px]">
        {orgList?.map((org) => (
          <p
            key={org?.organizationId}
            className={
              'hover:cursor-pointer hover:text-pink-700 hover:underline hover:font-bold'
            }
            onClick={() => onClick(org?.organizationId)}
          >
            {org?.organizationName}
          </p>
        ))}
      </div>
    </div>
  );
}

ClinicianListFilter.displayName = 'Clinician.ClinicianListFilter';

export default memo(ClinicianListFilter);
