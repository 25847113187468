import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

export interface IOrganizationFilterProps {
  organizationalList: any[];
}

function OrganizationFilter({ organizationalList }: IOrganizationFilterProps) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-y-2.5">
      <h3 className="font-bold text-xs py-[5px] px-2.5">Organizational Info</h3>
      <div className={'px-2.5'}>
        <h3 className={'font-bold text-xs py-1'}>Organizations</h3>
        <div className="flex flex-col text-xs gap-[5px]">
          {organizationalList?.map((org) => (
            <p
              key={org?.organizationId}
              className={
                'hover:cursor-pointer hover:text-pink-700 hover:underline hover:font-bold'
              }
              onClick={() => {
                navigate(`/organization/${org?.organizationId}}`);
              }}
            >
              {org?.organizationName}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

OrganizationFilter.displayName = 'Organization.Filter';

export default memo(OrganizationFilter);
