import React from 'react';
import AuthHeader from './AuthHeader';
import AuthLeft from './AuthLeft';
import TitleCover from './TitleCover';

const Auth = () => {
  return <React.Fragment />;
};

Auth.Header = AuthHeader;

Auth.Left = AuthLeft;
Auth.TitleCover = TitleCover;

export default Auth;
