import { ErrorMessage } from '@hookform/error-message';
import {
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  memo,
  useEffect,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ISelectOption } from 'types';
import { ObjectUtils } from 'utils';
import { Input } from 'ui-atoms';

type Props = {
  className?: string;
  name: string;
  label?: string;
  options: ISelectOption[];
  disabled?: boolean;
  text?: boolean;
  note?: string;
  defaultValue?: ISelectOption;
  inputProps?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
};

const FormSelectInputV2: FC<Props> = ({
  name,
  className,
  note,
  defaultValue,
  inputProps,
  disabled = false,
  ...rest
}) => {
  const formContext = useFormContext();
  const { field, formState } = useController({
    name,
    control: formContext.control,
    defaultValue: defaultValue?.value || {},
  });

  useEffect(() => {
    if (defaultValue) {
      field?.onChange(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className={className}>
      <Input.SelectV2
        {...rest}
        {...inputProps}
        error={!ObjectUtils.isEmpty(ObjectUtils.get(formState.errors, name))}
        selectItem={field?.value}
        onChangeValue={field?.onChange}
        disabled={disabled}
      />

      <ErrorMessage
        errors={formState.errors}
        name={name}
        render={({ message }) => (
          <p className="text-error text-xs font-inter my-1">{message}</p>
        )}
      />

      {!ObjectUtils.isEmpty(note) && (
        <p className="w-full text-input text-xs font-inter my-1">{note}</p>
      )}
    </div>
  );
};

FormSelectInputV2.displayName = 'Form.SelectInputV2';

export default memo(FormSelectInputV2);
