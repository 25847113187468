import * as Yup from 'yup';

export const useLoginSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .nullable()
      .email('Email is invalid')
      .trim('Email is required')
      .required('Email is required'),
    password: Yup.string()
      .nullable()
      .trim('Password is required')
      .required('Password is required')
      .min(8, 'Password must be over 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
  });
};
