import * as React from 'react';
import AcceptStep from './AcceptStep';
import ConfirmDetails from './ConfirmDetails';
import ChangePassword from './ChangePassword';

export interface IClinicianProps {}

function Invitation(props: IClinicianProps) {
  return <React.Fragment />;
}

Invitation.AcceptStep = AcceptStep;
Invitation.ConfirmDetails = ConfirmDetails;
Invitation.ChangePassword = ChangePassword;

export default Invitation;
