import { GlobalContext } from 'context/GlobalContext';
import { useContext } from 'react';
import Loading from './Loading';

const LoadingModal = () => {
  const { state } = useContext(GlobalContext);
  const { loadingModalOpen } = state;

  return (
    <>
      {loadingModalOpen && (
        <div className="fixed top-0 right-0 left-0 bottom-0 flex justify-center items-center inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
          <Loading className="w-[100px] h-[100px]" />
        </div>
      )}
    </>
  );
};

export default LoadingModal;
