import * as React from 'react';
import Dropdown from './Dropdown';
import { SVG } from 'assets/imagePath';
import { IDropdown } from 'types';
import { Text } from 'ui-molecules';

export interface IDropdownTextProps {
  options: IDropdown[];
}

export default function DropdownText({ options, ...rest }: IDropdownTextProps) {
  return (
    <Dropdown.Standard
      CustomButton={(item) => (
        <div className="flex items-center">
          <div className="">
            <Text size="10">{item.label}</Text>
          </div>

          <img className="icon-app" src={SVG.chevron_down} alt="chevron_down" />
        </div>
      )}
      options={options}
      {...rest}
    />
  );
}
