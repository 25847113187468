import React, { Fragment } from 'react';
import NotesFilter from './NotesFilter';

export interface INotesProps {}

function Notes({}: INotesProps) {
  return <Fragment />;
}

Notes.Filter = NotesFilter;

export default Notes;
