import * as React from 'react';
import BuilderContent from './BuilderContent';
import BuilderSmartInsight from './BuilderSmartInsight';
import BuilderSmartNote from './BuilderSmartNote';
import BuilderPlayer from './BuilderPlayer';

export interface IBuilderProps {}

function Builder(props: IBuilderProps) {
  return <React.Fragment />;
}
Builder.Content = BuilderContent;
Builder.SmartInsight = BuilderSmartInsight;
Builder.SmartNote = BuilderSmartNote;
Builder.Player = BuilderPlayer;

export default Builder;
