import { SVG } from 'assets/imagePath';
import { Box, Clinician } from 'ui-organisms';
import { Dropdown } from 'ui-atoms';
import { IFilterCheckListProps } from 'ui-molecules/filterSide/FilterCheckList';
import { Footer, GridTable, HeaderApp, Text } from 'ui-molecules';
import {
  APP_ROLE,
  DEFAULT_PAGE,
  SET_LOADING_MODAL,
  SET_PRIMARY_ACCOUNT_ID,
  SET_USER_RECORD_ID,
} from 'constant';
import { DEFAULT_LIMIT, PAGE_SIZE_OPTIONS } from 'constant/pagination';
import { GlobalContext } from 'context/GlobalContext';
import { useApiCall } from 'hooks';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import { useContext, useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { getListOrganizationsAPI, getListUserApi } from 'services';
import { getRowsByPage } from 'utils';
import { RouterPath } from '../../routes/router.constant';
import { useAuth } from '../../hooks/useAuth';

export default function CliniciansListPage() {
  const { dispatch } = useContext(GlobalContext);
  const { CURRENT_ROLE } = useAuth();

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LIMIT);
  const [rows, setRows] = useState();
  const [toggle, setToggle] = useState(false);
  const [fontSize, setFontSize] = useState(12);
  const [role, setRole] = useState('Clinician');

  const [fetchClinician, loading, clinicianList] = useApiCall(getListUserApi);
  const [fetchOrganization, loadingOrganization, listOrganization] = useApiCall(
    getListOrganizationsAPI
  );

  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }

  useEffect(() => {
    fetchOrganization();
  }, []);

  useEffect(() => {
    fetchClinician({
      userRole: role,
    });
  }, [role]);

  const handleNavigateClinicianDetail = (row) => {
    dispatch({
      type: SET_USER_RECORD_ID,
      payload: row?.userRecordId,
    });
    dispatch({
      type: SET_PRIMARY_ACCOUNT_ID,
      payload: row?.organizationId,
    });
    navigate(`/clinicians/${row?.userRecordId}`);
  };

  const filters: IFilterCheckListProps[] = [
    {
      title: 'Time Zone',
      checkList: [{ value: 'tz_est', label: 'EST' }],
      isCollapse: true,
    },
    {
      title: 'Degree',
      checkList: [{ value: 'degree_md', label: 'MD' }],
      isCollapse: true,
    },
    {
      title: 'Specialty',
      checkList: [{ value: 'special_fm', label: 'FM' }],
      isCollapse: true,
    },
  ];

  const columns = [
    {
      field: 'clinician',
      headerName: 'Clinician',
      renderCell: (row) => {
        return `${row?.firstName} ${row?.lastName}`;
      },
    },
    {
      field: 'degree',
      headerName: 'Deg',
    },
    {
      field: 'organizationName',
      headerName: 'Org',
    },
    {
      field: 'specialty',
      headerName: 'Specialty',
    },
    {
      field: 'cellPhone',
      headerName: 'Cell Phone',
    },
    {
      field: 'timeZone',
      headerName: 'T Zone',
      renderCell: (row) => {
        return (
          <Text color="error" weight="700">
            {row?.timeZone}
          </Text>
        );
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      renderCell: (row) => {
        return (
          <Text color="blue" weight="700">
            ({row?.notes})
          </Text>
        );
      },
    },
    {
      field: 'ds',
      headerName: 'DS',
    },
    {
      field: 'co',
      headerName: 'CO',
    },
    {
      field: 'lead',
      headerName: 'Lead',
    },
    {
      field: 'dsCreateAppointment',
      headerName: 'APPT',
      renderCell: (row) => {
        return (
          <Text color="error" weight="700">
            ({row?.appt})
          </Text>
        );
      },
    },
    {
      field: 'dsQ',
      headerName: 'DS?',
      renderCell: (row) => {
        return (
          <Text color="success" weight="700">
            ({row?.dsQ})
          </Text>
        );
      },
    },
    {
      field: 'coQ',
      headerName: 'CO?',
      renderCell: (row) => {
        return (
          <Text color="error" weight="700">
            ({row?.coQ})
          </Text>
        );
      },
    },
    {
      field: 'important',
      headerName: 'Important',
      renderCell: (row) => {
        return (
          <Text className="" color="error">
            {row?.important}
          </Text>
        );
      },
    },
    {
      field: 'priority',
      headerName: 'Priority',
    },
    {
      field: 'ranking',
      headerName: 'Ranking',
    },
    {
      field: 'actions',
      headerName: ' ',
      headerStyle: {
        minWidth: '50px',
      },
      renderCell: (row) => {
        return (
          <FiPlus
            className="text-gray-active w-4 h-4 cursor-pointer"
            onClick={() => handleNavigateClinicianDetail(row)}
          />
        );
      },
    },
  ];

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const newRows = getRowsByPage(clinicianList, pageSize, currentPage);
    setRows(newRows);
  }, [pageSize, currentPage, clinicianList]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading,
    });
  }, [dispatch, loading]);

  const handleSelectRole = (data) => {
    setRole(data.value);
  };

  const handleClickFilter = (id) => {
    fetchClinician({
      userRole: role,
      organizationId: id,
    });
  };

  return (
    <DefaultLayout>
      <div className="flex flex-col flex-1">
        <div className="">
          <HeaderApp.Clinician
            toggle={toggle}
            setToggle={setToggle}
            setFontSize={setFontSize}
            viewName={'Clinicians List'}
            optionsList={[
              {
                value: 'Clinician',
                label: 'Clinician List',
              },
            ]}
            onChangeOptions={handleSelectRole}
          />
        </div>
        <div className="flex flex-col flex-1 p-2.5 gap-2.5 mb-[50px]">
          <div className="flex items-center justify-between">
            <Text>{clinicianList?.length || '0'} Records</Text>
            <Dropdown.Standard
              CustomButton={(item) => (
                <div className="flex gap-2.5 items-center bg-white hover:bg-white p-5-10 border border-nav rounded-[5px]">
                  <div className="">
                    <p className="text-xs">{item.label} Records per page</p>
                  </div>
                  <img
                    className="icon-app"
                    src={SVG.chevron_down}
                    alt="chevron_down"
                  />
                </div>
              )}
              options={PAGE_SIZE_OPTIONS}
              onChangeValue={(item: any) => {
                handleChangePageSize(item?.value);
              }}
            />
          </div>
          <div className="flex flex-1 gap-2.5">
            <Box.Standard
              className={`max-w-[180px] h-auto w-full transition-all ${
                toggle ? '' : '-translate-x-[150%] fixed'
              }`}
            >
              <Clinician.ClinicianListFilter
                orgList={listOrganization}
                onClick={handleClickFilter}
              />
            </Box.Standard>
            <Box.Standard className="flex-1 h-auto !p-0 overflow-hidden">
              <div className="w-full overflow-auto">
                <GridTable
                  checkboxes={false}
                  row={rows}
                  column={columns}
                  fontSize={fontSize}
                />
              </div>
            </Box.Standard>
          </div>
        </div>
        <Footer
          pagination={{
            total: clinicianList?.length,
            pageSize: pageSize,
            onChangeCurrentPage: handleChangePage,
            onChangePageSize: handleChangePageSize,
          }}
        />
      </div>
    </DefaultLayout>
  );
}
