import { Box } from 'ui-organisms';
import { Dropdown } from 'ui-atoms';
import DefaultLayout from 'layouts/DefaultLayout';
import { useContext, useEffect, useState } from 'react';
import { Footer, GridTable, HeaderApp, Text } from 'ui-molecules';
import { ITableColumn } from 'types';
import { SVG } from 'assets/imagePath';
import { useApiCall } from 'hooks';
import { getListOrganizationsAPI, getListUserApi } from 'services';
import { GlobalContext } from 'context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import {
  APP_ROLE,
  DEFAULT_LIMIT,
  PAGE_SIZE_OPTIONS,
  SET_LOADING_MODAL,
  SET_PRIMARY_ACCOUNT_ID,
  SET_USER_RECORD_ID,
} from 'constant';
import { getRowsByPage } from 'utils';
import { FiPlus } from 'react-icons/fi';
import CoderListFilter from './CoderListFilter';
import { useAuth } from '../../hooks/useAuth';
import { RouterPath } from '../../routes/router.constant';

const CoderList = () => {
  const { dispatch } = useContext(GlobalContext);
  const { CURRENT_ROLE } = useAuth();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_LIMIT);
  const [rows, setRows] = useState();
  const [toggle, setToggle] = useState(false);
  const [fontSize, setFontSize] = useState(12);
  const role = 'Medical_Coder';

  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }

  const [fetchCoder, loading, coderList] = useApiCall(getListUserApi);
  const [fetchOrganization, loadingOrganization, listOrganization] = useApiCall(
    getListOrganizationsAPI
  );

  useEffect(() => {
    fetchOrganization();
  }, []);
  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const newRows = getRowsByPage(coderList, pageSize, currentPage);
    setRows(newRows);
  }, [pageSize, currentPage, coderList]);

  useEffect(() => {
    fetchCoder({
      userRole: role,
    });
  }, [role]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading || loadingOrganization,
    });
  }, [loading, loadingOrganization]);

  const columns: ITableColumn[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerStyle: { textAlign: 'center' },
      rowStyle: {
        justifyContent: 'center',
        paddingLeft: '7px',
        paddingRight: '7px',
      },

      renderCell: (row) => {
        return <>{`${row?.firstName}, ${row?.lastName}`}</>;
      },
    },
    {
      field: 'userRole',
      headerName: 'Staff Role',
    },
    {
      field: 'degree',
      headerName: 'Staff Degree',
    },
    {
      field: 'email',
      headerName: 'Email',
    },
    {
      field: 'cellPhone',
      headerName: 'Cell',
    },
    {
      field: 'specialty',
      headerName: 'Specialty',
      rowStyle: {
        fontWeight: 600,
      },
    },
    {
      field: 'department',
      headerName: 'Department',
    },
    {
      field: 'locations',
      headerName: 'Locations',
      rowStyle: {
        fontWeight: 600,
      },
    },
    {
      field: 'organizationName',
      headerName: 'Organization',
    },
    {
      field: 'dur',
      headerName: 'Staffing Assignments',
      rowStyle: {
        fontWeight: 600,
      },
    },
    {
      field: 'comments',
      headerName: 'Comments',
      renderCell: (row) => {
        return <>{row?.comments?.[0]?.comment}</>;
      },
    },
    {
      field: 'attachments',
      headerName: 'Attachments',
      renderCell: (row) => {
        return <>{row?.attachments?.[0]?.comment}</>;
      },
    },
    {
      field: 'actions',
      headerName: ' ',
      renderCell: (row) => {
        return (
          <FiPlus
            className="text-gray-active w-4 h-4 cursor-pointer"
            onClick={() => {
              dispatch({
                type: SET_USER_RECORD_ID,
                payload: row?.userRecordId,
              });
              dispatch({
                type: SET_PRIMARY_ACCOUNT_ID,
                payload: row?.organizationId,
              });
              navigate(`/coders/${row?.userRecordId}`);
            }}
          />
        );
      },
    },
  ];

  const handleClickFilter = (id) => {
    fetchCoder({
      userRole: role,
      organizationId: id,
    });
  };

  return (
    <DefaultLayout>
      <div className="">
        <HeaderApp.Assistant
          toggle={toggle}
          setToggle={(value) => {
            setToggle(value);
          }}
          setFontSize={setFontSize}
          viewName={'Coders List'}
        />
      </div>
      <div className="flex flex-col flex-1 p-2.5 gap-2.5">
        <div className="flex items-center justify-between">
          <Text>{pageSize} Records</Text>
          <Dropdown.Standard
            CustomButton={(item) => (
              <div className="flex gap-2.5 items-center bg-white hover:bg-white p-5-10 border border-nav rounded-[5px]">
                <p className="text-xs">{item.label} Records per page</p>
                <img
                  className="icon-app"
                  src={SVG.chevron_down}
                  alt="chevron_down"
                />
              </div>
            )}
            options={PAGE_SIZE_OPTIONS}
            onChangeValue={(item: any) => {
              handleChangePageSize(item?.value);
            }}
          />
        </div>
        <div className="flex flex-1 gap-2.5 mb-[50px]">
          <Box.Standard
            className={`max-w-[180px] h-auto w-full transition-all ${
              toggle ? '' : '-translate-x-[150%] fixed'
            }`}
          >
            <div className="p-2.5 flex flex-col">
              <h4 className="font-bold text-sm flex justify-between items-center">
                <span>Filter Notes By</span>
                <img src={SVG.un_filter} alt={'un-filter-icon'} />
              </h4>
              <div className="mt-2.5 mb-[15px]">
                <CoderListFilter
                  coderList={listOrganization}
                  onClick={handleClickFilter}
                />
              </div>
            </div>
          </Box.Standard>
          <div className="flex-1 z-0 overflow-x-hidden">
            <Box.Standard className="flex w-full  flex-col gap-2 p-0 z-0">
              <div className="!overflow-x-auto w-full h-auto z-0">
                <GridTable
                  column={columns}
                  row={rows}
                  checkboxes={false}
                  fontSize={fontSize}
                />
              </div>
            </Box.Standard>
          </div>
        </div>
        <Footer
          pagination={{
            total: coderList?.length,
            pageSize: pageSize,
            onChangeCurrentPage: handleChangePage,
            onChangePageSize: handleChangePageSize,
          }}
        />
      </div>
    </DefaultLayout>
  );
};

export default CoderList;
