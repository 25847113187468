import * as React from 'react';

export interface SliderItemProps {
  img: string;
  isCenter?: boolean;
}

export default function SliderItem({ img, isCenter = false }: SliderItemProps) {
  return (
    <div className={``}>
      <img
        className="w-full item__slick max-w-[210px] mx-auto img__slider"
        src={img}
      />
    </div>
  );
}
