import { SVG } from 'assets/imagePath';
import { Button } from 'ui-atoms';
import { BREAKPOINT } from 'constant/breakpoints';
import { useViewport } from 'context/ViewportContext';
import { useAuth } from 'hooks/useAuth';
import { FC, memo, useEffect } from 'react';
import SelectedButton from './components/SelectedButton';

type Props = {
  className?: string;
  toggle: boolean;
  setToggle?: (prev: boolean) => void;
};

const HeaderInquires: FC<Props> = ({ className, toggle, setToggle }) => {
  const { CURRENT_ROLE, currentUser } = useAuth();
  const { width } = useViewport();

  useEffect(() => {
    if (width < BREAKPOINT.lg) {
      setToggle(false);
    } else {
      setToggle(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <div className="flex justify-between">
      <ul className="flex max-w-[400px] w-full items-center list-none justify-between text-center gap-2.5">
        <li>
          <Button.Icon
            classNameIcon="!icon-app"
            icon={toggle ? SVG.filter : SVG.un_filter}
            variant="outlined"
            className="bg-[#f3f3f3]"
            // onClick={() => {}}
            onClick={() => setToggle(!toggle)}
          />
        </li>
        <li className="flex ">
          <div>
            <SelectedButton
              propsButton={{
                variant: 'outlined',
                color: 'secondary',
                className: 'px-[15px]',
              }}
              options={[
                { value: 'inquires', label: 'Inquires - MRG' },
                {
                  value: 'inquires1',
                  label: 'Inquires - MRG1',
                },
              ]}
            />
          </div>
          <div className="invisible text-xs">Inquiries (3)</div>
        </li>
      </ul>
      <div className="items-center justify-center flex-1 hidden text-xs font-bold text-black lg:flex">
        <p>Betty MacDonald – MRG</p>
      </div>

      <ul className="flex  max-w-[400px] w-full items-center justify-end list-none text-center gap-2.5">
        <li>
          <SelectedButton
            propsButton={{
              variant: 'outlined',
              color: 'secondary',
              className: 'px-[15px]',
            }}
            options={[
              { value: 'actions', label: 'Actions' },
              { value: 'inquires1', label: 'Inquires - MRG1' },
            ]}
          />
        </li>

        <li>
          <Button.Icon
            className="!p-[7.5px]"
            icon={SVG.more_horizontal}
            variant="outlined"
            onClick={() => {}}
          />
        </li>
      </ul>
    </div>
  );
};
HeaderInquires.displayName = 'Header.Inquires';
export default memo(HeaderInquires);
