import React from 'react';
import DropdownInput from './DropdownInput';
import DropdownStandard from './DropdownStandard';
import DropdownText from './DropdownText';

const Dropdown = () => {
  return <React.Fragment />;
};

Dropdown.Input = DropdownInput;
Dropdown.Standard = DropdownStandard;
Dropdown.DropdownText = DropdownText;

export default Dropdown;
