import { Text } from 'ui-molecules';
import * as React from 'react';
import ButtonRequest from './components/ButtonRequest';
import { RouterPath } from '../../routes/router.constant';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../ui-atoms';
import cn from 'classnames';
import ButtonText from '../../ui-atoms/button/ButtonText';

export default function Note() {
  const navigate = useNavigate();

  return (
    <div className="p-[30px] md:py-[60px] bg-white-light md:px-5 my-2.5 rounded-[30px]">
      <div className="max-w-[640px] w-full mx-auto text-center">
        <Text className="text-center text-xl sm:text-3xl md:text-[32px]">
          Try{' '}
          <strong className="block md:inline-block">
            Coded<span className="text-primary">Note</span>®
          </strong>
        </Text>
        <Text
          responsive
          className="text-center my-[15px] md:mt-[20px] md:mb-[30px]"
          size="18"
        >
          Say goodbye to tedious documentation tasks and hello to more time with
          your patients. Sign up for a FREE Demo and see how our software can
          streamline your medical documentation process, save you time, and
          improve the accuracy of your patient records.
        </Text>

        <Button.Text
          label="CONTACT US"
          className={cn('w-fit bg-white text-primary font-bold')}
          onClick={() => {
            navigate(RouterPath.CONTACT_US);
          }}
        />
      </div>
    </div>
  );
}
