import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

export const useApiCall = (service: any, flag: boolean = true) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const fetchData = useCallback(async (param: any, errorFunc: any = null) => {
    setLoading(true);
    try {
      const result = await service(param);
      setLoading(false);
      const resData = result?.items || result?.data || result;

      let newData;
      if (flag) {
        const str = JSON.stringify(resData)
          .replaceAll('empty_flag', '')
          .replaceAll('no_data_yet', '');
        newData = JSON.parse(str);
      } else {
        newData = resData;
      }

      setData(newData);
      return newData;
    } catch (error) {
      setLoading(false);
      setError(error?.message);
      toast.error(error?.message);
      return;
    }
  }, []);

  return [fetchData, loading, data, error];
};
