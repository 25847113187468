import cn from 'classnames';
import { ReactNode, FC, HTMLProps, memo } from 'react';

export type ButtonWhiteType = {
  className?: string;
  label: ReactNode | string;
  icon?: string;
  color?: 'white' | 'default' | 'silver';
  type?: 'button' | 'reset' | 'submit';
  onClick?: () => void;
};

const ButtonWhite: FC<any> = ({
  className,
  label,
  icon,
  onClick,
  type = 'button',
  color = 'default',
  ...rest
}) => {
  return (
    <button
      type={type}
      className={cn(
        color === 'white'
          ? 'bg-white inner-border inner-border-nav'
          : color === 'silver'
          ? 'bg-[#d0d0d0]'
          : 'bg-gradient-to-b from-[#FBFCFF] to-[#EBEFF6]',
        'flex justify-center items-center px-6 h-10 text-input font-inter text-xs leading-snug rounded-md shadow-inner inner-border inner-border-pearl hover:bg-grey-lightest-700 hover:shadow-lg focus:bg-grey-lightest-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-grey-lightest-800 active:shadow-lg transition duration-150 ease-in-out',
        className,
        rest.disabled ? 'opacity-50' : ''
      )}
      onClick={onClick}
      {...rest}
    >
      {label}
      {icon && <img className="ml-2 icon-app" src={icon} alt="button-icon" />}
    </button>
  );
};

ButtonWhite.displayName = 'Button.White';

export default memo(ButtonWhite);
