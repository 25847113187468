import { ICurrentUser } from 'types/auth.interface';

export const useAuth = () => {
  const currentUser: ICurrentUser =
    localStorage.getItem('CODED_NOTE_userData') !== 'undefined'
      ? JSON.parse(localStorage.getItem('CODED_NOTE_userData'))
      : '';
  const CURRENT_ROLE = currentUser?.['custom:userRole'];

  return { currentUser, CURRENT_ROLE };
};
