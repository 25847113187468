import * as React from 'react';
import AcceptStep from './AcceptStep';
import ConfirmDetails from './ConfirmDetails';
import CreatePassword from './CreatePassword';
import VerifyAccount from './VerifyAccount';
import GetStarted from './GetStarted';

export interface IClinicianProps {}

function ClinicianInvitation(props: IClinicianProps) {
  return <React.Fragment />;
}

ClinicianInvitation.AcceptStep = AcceptStep;
ClinicianInvitation.ConfirmDetails = ConfirmDetails;
ClinicianInvitation.CreatePassword = CreatePassword;
ClinicianInvitation.VerifyAccount = VerifyAccount;
ClinicianInvitation.GetStarted = GetStarted;

export default ClinicianInvitation;
