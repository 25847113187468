import {
  Benefit,
  Carousel,
  Footer,
  Header,
  Hero,
  Introduce,
  Note,
  Question,
} from 'ui-organisms/landing';

export interface LandingPageProps {}

export default function LandingPage(props: LandingPageProps) {
  return (
    <section className="flex flex-col">
      <Header />
      <body className="flex-1 h-full max-w-[1300px] w-full px-2.5 mx-auto">
        <Hero />
        <Carousel />
        <Introduce />
        <Benefit />
        <Question />
        <Note />
      </body>
      <Footer />
    </section>
  );
}
