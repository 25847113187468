import React from 'react';
import NavBar from './NavBar';

const Nav = () => {
  return <React.Fragment />;
};

Nav.Bar = NavBar;

export default Nav;
