import { SVG } from 'assets/imagePath';
import { Button } from 'ui-atoms';
import { Form, Search } from 'ui-molecules';
import { CONTENT_LIST } from 'pages/notes/data';
import React, { memo } from 'react';
import Checkbox from '../../ui-atoms/checkbox';
import isEmpty from 'lodash/isEmpty';

export interface INotesFilterProps {
  onSubmitFilter?: () => void;
  onClearFilter?: () => void;
  onClearSearchBox?: () => void;
  orgList: any;
  selectOrgId?: (organizationId: string) => void;
  clinicianList: any;
  dsList: any;
  mcList: any;
  saList: any;
  ownerList: any;
  oaList: any;
  selectRoleId?: (organizationId: string, userRecordId: string) => void;
  selectStatus?: (status: string) => void;
  searchValue: string;
  onChangeSearch: (value: string) => void;
}

const statusOptions = [
  { label: 'New', value: 'NEW - Ready to Transcribe' },
  { label: 'DS question', value: 'DS question' },
  { label: 'Schedule', value: 'Schedule' },
  { label: 'Transcribe', value: 'Transcribe' },
  { label: 'DS review QI', value: 'DS review QI' },
  { label: 'Transfer', value: 'Transfer' },
  { label: 'To code', value: 'To code' },
  { label: 'Coding', value: 'Coding' },
  { label: 'CO question', value: 'CO question' },
  { label: 'CO review QI', value: 'CO review QI' },
  { label: 'Tasked EMR', value: 'Tasked EMR' },
  { label: 'Submitted', value: 'Submitted' },
];

function NotesFilter({
  onClearFilter,
  onSubmitFilter,
  onClearSearchBox,
  orgList,
  selectOrgId,
  clinicianList,
  dsList,
  saList,
  mcList,
  ownerList,
  oaList,
  selectRoleId,
  selectStatus,
  searchValue,
  onChangeSearch,
}: INotesFilterProps) {
  const [selectedStatus, setSelectedStatus] = React.useState('All');
  const [selectedOrg, setSelectedOrg] = React.useState('All');
  const [selectedClinician, setSelectedClinician] = React.useState('');
  const [selectedDS, setSelectedDS] = React.useState('');
  const [selectedMC, setSelectedMC] = React.useState('');
  const [selectedSA, setSelectedSA] = React.useState('');
  const [selectedOwner, setSelectedOwner] = React.useState('');
  const [selectedOA, setSelectedOA] = React.useState('');
  return (
    <>
      <div className="p-2.5 flex flex-col">
        <h4 className="flex items-center justify-between text-sm font-bold">
          <span>Filter Notes By</span>
          <img src={SVG.un_filter} alt="filter-icon" />
        </h4>
        <div className="my-2.5 -mx-2.5">
          <Search.Standard
            inputProps={{
              placeholder: 'Search by Patient Initials',
              style: { borderStyle: 'solid' },
            }}
            className="w-ful rounded-full border border-nav border-solid  bg-transparent px-2.5 py-[5px]"
            clearInput={onClearSearchBox}
            value={searchValue}
            onChange={(e) => {
              onChangeSearch(e);
            }}
          />
        </div>
        <div>
          <h4 className="text-xs font-bold">Status</h4>
          <div
            className="form-check-label text-xs font-inter inline-block mt-0.5 text-label w-full hover:cursor-pointer"
            onClick={() => selectStatus?.('All')}
          >
            <Checkbox.Single
              classNameText="text-xs cursor-pointer"
              label={'All'}
              checked={selectedStatus === 'All'}
              onChange={() => setSelectedStatus('All')}
            />
          </div>
          {statusOptions?.map((status) => {
            return (
              <div
                className="form-check-label text-xs font-inter inline-block mt-0.5 text-label w-full hover:cursor-pointer"
                key={status.value}
                onClick={() => selectStatus?.(status.value)}
              >
                <Checkbox.Single
                  classNameText="text-xs cursor-pointer"
                  label={status.label}
                  checked={selectedStatus === status.value}
                  onChange={() => setSelectedStatus(status.value)}
                />
              </div>
            );
          })}
        </div>
        {orgList?.length && (
          <div>
            <h4 className="text-xs font-bold">Organizations</h4>
            <div
              className="form-check-label text-xs font-inter inline-block mt-0.5 text-label w-full hover:cursor-pointer"
              onClick={() => selectStatus?.('All')}
            >
              <Checkbox.Single
                classNameText="text-xs cursor-pointer"
                label={'All'}
                checked={selectedOrg === 'All'}
                onChange={() => setSelectedOrg('All')}
              />
            </div>
            {orgList?.map((org) => {
              if (
                !isEmpty(org?.organizationName) &&
                !isEmpty(org?.organizationId)
              ) {
                return (
                  <div
                    className="form-check-label text-xs font-inter inline-block mt-0.5 text-label w-full hover:cursor-pointer"
                    key={org?.organizationId}
                    onClick={() => selectOrgId?.(org?.organizationId)}
                  >
                    <Checkbox.Single
                      classNameText="text-xs cursor-pointer"
                      label={org?.organizationName}
                      checked={selectedOrg === org?.organizationId}
                      onChange={() => {
                        setSelectedOrg(org?.organizationId);
                        setSelectedStatus('All');
                        setSelectedClinician('');
                      }}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
        <div>
          <h4 className="text-xs font-bold">Clinician</h4>
          {clinicianList?.map((cli) => {
            return (
              <div
                className="form-check-label text-xs font-inter inline-block mt-0.5 text-label w-full hover:cursor-pointer"
                key={`${cli?.userRecordId} - ${cli?.organizationId}`}
                onClick={() =>
                  selectRoleId?.(cli?.organizationId, cli?.userRecordId)
                }
              >
                <Checkbox.Single
                  classNameText="text-xs cursor-pointer"
                  label={`${cli.lastName}, ${cli.firstName}`}
                  checked={selectedClinician === cli?.userRecordId}
                  onChange={() => {
                    setSelectedClinician(cli?.userRecordId);
                    setSelectedStatus('All');
                    setSelectedOrg('All');
                  }}
                />
              </div>
            );
          })}
        </div>
        <div>
          <h4 className="text-xs font-bold">Document_Specialist</h4>
          {dsList?.map((ds) => {
            return (
              <div
                className="form-check-label text-xs font-inter inline-block mt-0.5 text-label w-full hover:cursor-pointer"
                key={`${ds?.userRecordId} - ${ds?.organizationId}`}
                onClick={() =>
                  selectRoleId?.(ds?.organizationId, ds?.userRecordId)
                }
              >
                <Checkbox.Single
                  classNameText="text-xs cursor-pointer"
                  label={`${ds.lastName}, ${ds.firstName}`}
                  checked={selectedDS === ds?.userRecordId}
                  onChange={() => {
                    setSelectedDS(ds?.userRecordId);
                    setSelectedClinician('');
                    setSelectedStatus('All');
                    setSelectedOrg('All');
                  }}
                />
              </div>
            );
          })}
        </div>
        <div>
          <h4 className="text-xs font-bold">Medical_Coder</h4>
          {mcList?.map((mc) => {
            return (
              <div
                className="form-check-label text-xs font-inter inline-block mt-0.5 text-label w-full hover:cursor-pointer"
                key={`${mc?.userRecordId} - ${mc?.organizationId}`}
                onClick={() =>
                  selectRoleId?.(mc?.organizationId, mc?.userRecordId)
                }
              >
                <Checkbox.Single
                  classNameText="text-xs cursor-pointer"
                  label={`${mc.lastName}, ${mc.firstName}`}
                  checked={selectedMC === mc?.userRecordId}
                  onChange={() => {
                    setSelectedDS(mc?.userRecordId);
                    setSelectedStatus('All');
                    setSelectedOrg('All');
                    setSelectedClinician('');
                    setSelectedDS('');
                  }}
                />
              </div>
            );
          })}
        </div>
        <div>
          <h4 className="text-xs font-bold">System_Admin</h4>
          {saList?.map((sa) => {
            return (
              <div
                className="form-check-label text-xs font-inter inline-block mt-0.5 text-label w-full hover:cursor-pointer"
                key={`${sa?.userRecordId} - ${sa?.organizationId}`}
                onClick={() =>
                  selectRoleId?.(sa?.organizationId, sa?.userRecordId)
                }
              >
                <Checkbox.Single
                  classNameText="text-xs cursor-pointer"
                  label={`${sa.lastName}, ${sa.firstName}`}
                  checked={selectedSA === sa?.userRecordId}
                  onChange={() => {
                    setSelectedSA(sa?.userRecordId);
                    setSelectedStatus('All');
                    setSelectedOrg('All');
                    setSelectedClinician('');
                    setSelectedDS('');
                    setSelectedMC('');
                  }}
                />
              </div>
            );
          })}
        </div>
        <div>
          <h4 className="text-xs font-bold">Owner</h4>
          {ownerList?.map((owner, index) => {
            return (
              <div
                className="form-check-label text-xs font-inter inline-block mt-0.5 text-label w-full hover:cursor-pointer"
                key={`${owner?.userRecordId} - ${owner?.organizationId} - ${index}`}
                onClick={() =>
                  selectRoleId?.(owner?.organizationId, owner?.userRecordId)
                }
              >
                <Checkbox.Single
                  classNameText="text-xs cursor-pointer"
                  label={`${owner.lastName}, ${owner.firstName}`}
                  checked={selectedOwner === owner?.userRecordId}
                  onChange={() => {
                    setSelectedOwner(owner?.userRecordId);
                    setSelectedStatus('All');
                    setSelectedOrg('All');
                    setSelectedClinician('');
                    setSelectedDS('');
                    setSelectedMC('');
                    setSelectedSA('');
                  }}
                />
              </div>
            );
          })}
        </div>
        <div>
          <h4 className="text-xs font-bold">Organization_Admin</h4>
          {oaList?.map((oa) => {
            return (
              <div
                className="form-check-label text-xs font-inter inline-block mt-0.5 text-label w-full hover:cursor-pointer"
                key={`${oa?.userRecordId} - ${oa?.organizationId}`}
                onClick={() =>
                  selectRoleId?.(oa?.organizationId, oa?.userRecordId)
                }
              >
                <Checkbox.Single
                  classNameText="text-xs cursor-pointer"
                  label={`${oa.lastName}, ${oa.firstName}`}
                  checked={selectedOA === oa?.userRecordId}
                  onChange={() => {
                    setSelectedOA(oa?.userRecordId);
                    setSelectedStatus('All');
                    setSelectedOrg('All');
                    setSelectedClinician('');
                    setSelectedDS('');
                    setSelectedMC('');
                    setSelectedSA('');
                    setSelectedOwner('');
                  }}
                />
              </div>
            );
          })}
        </div>
        {CONTENT_LIST.map((content, idx) => {
          return (
            !content.isHide && (
              <div className="mt-2.5" key={idx}>
                <h4 className="text-xs font-bold">{content?.title}</h4>
                <div className="mt-2.5">
                  {content.check_list.map((item, idx) => {
                    return (
                      !item.isHide && (
                        <div
                          key={idx}
                          className="flex justify-between w-full items-center mb-[5px]"
                        >
                          <div className="w-fit">
                            <Form.CheckboxSingle
                              classNameText="text-xs"
                              name={item?.name || ''}
                              label={item?.label}
                              // onChange={() => {}}
                            />
                          </div>
                          {!!item?.collapse && (
                            <img
                              src={SVG.chevron_down}
                              alt="plus-icon"
                              className="icon-app"
                            />
                          )}
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            )
          );
        })}
      </div>
      <div className="w-full mt-10">
        <div className="flex w-full flex-row gap-2.5">
          <Button.Primary
            className="flex-1 !p-default"
            label="Apply"
            onClick={onSubmitFilter}
          />
          <Button.White
            className="flex-1 !p-default"
            color="white"
            label="Clear"
            onClick={onClearFilter}
          />
        </div>
      </div>
    </>
  );
}

NotesFilter.displayName = 'Notes.Filter';

export default memo(NotesFilter);
