import { DetailBox } from 'ui-molecules';
import { FC, memo } from 'react';
import { DC_DETAILS } from './constant';

interface IDC {
  dcData: any;
}

const DocAssistantDetail: FC<IDC> = ({ dcData }) => {
  return (
    <div className="grid h-full grid-cols-2 gap-x-2.5">
      <div className="col-span-1 h-full flex flex-col justify-between">
        <DetailBox title="Details" detailRows={DC_DETAILS.detailInfo(dcData)} />
      </div>
      <div className="col-span-1 h-full flex flex-col justify-between">
        {/*<DetailBox title="Assignments" detailRows={DC_DETAILS.detail(dcData)} />*/}
      </div>
    </div>
  );
};

export default memo(DocAssistantDetail);
