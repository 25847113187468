import React from 'react';
import FilterForm from './FilterForm';
import FilterSearch from './FilterSearch';
import FilterSystem from './FilterSystem';
import FilterDepartment from './FilterDepartment';
import FilterOtherDepartment from './FilterOtherDepartment';
import FilterLocation from './FilterLocation';
import FilterCheckList from './FilterCheckList';

const Filter = () => {
  return <React.Fragment />;
};

Filter.FilterForm = FilterForm;
Filter.FilterSearch = FilterSearch;
Filter.FilterSystem = FilterSystem;
Filter.FilterDepartment = FilterDepartment;
Filter.FilterOtherDepartment = FilterOtherDepartment;
Filter.FilterLocation = FilterLocation;
Filter.FilterCheckList = FilterCheckList;

export default Filter;
