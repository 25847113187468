import React from 'react';
import ButtonBlue from './ButtonBlue';
import ButtonIcon from './ButtonIcon';
import ButtonPrimary from './ButtonPrimary';
import ButtonText from './ButtonText';
import ButtonWhite from './ButtonWhite';
import ButtonCommon from './ButtonCommon';

const Button = () => {
  return <React.Fragment />;
};

Button.Icon = ButtonIcon;
Button.Common = ButtonCommon;

Button.Primary = ButtonPrimary;

Button.White = ButtonWhite;
Button.Blue = ButtonBlue;
Button.Text = ButtonText;

export default Button;
