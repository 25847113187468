import cn from 'classnames';
import { ChangeEvent, FC, memo, useCallback } from 'react';

type Props = {
  className?: string;
  error?: boolean;
  value: string;
  onChange: (value: string) => void;
  label?: string;
  disabled?: boolean;
};

const TextArea: FC<Props> = ({
  className,
  error,
  onChange,
  label,
  disabled,
  ...rest
}) => {
  const handleChange = useCallback(
    (value: string) => {
      onChange?.(value);
    },
    [onChange]
  );
  return (
    <div className="w-full relative bg-inherit">
      {label && (
        <p className="absolute text-[10px] leading-[10px] left-[10px] px-[5px] top-[-5px] bg-pearl">
          {label}
        </p>
      )}
      <textarea
        className={cn(
          'w-full h-full z-0 border-none px-2.5 py-3 text-sm font-inter text-input placeholder:text-slate-400 bg-inherit bg-clip-padding transition ease-in-out m-0 focus:text-input focus:bg-inherit  ring-nav rounded-[5px]',
          error ? 'ring-1 ring-red-500 focus:ring-1 focus:ring-red-500' : '',
          disabled ? 'bg-slate-200' : '',
          className
        )}
        disabled={disabled}
        onChange={(event) => handleChange(event.target.value)}
        {...rest}
      />
    </div>
  );
};

TextArea.displayName = 'Input.TextArea';

export default memo(TextArea);
