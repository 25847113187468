import { ErrorMessage } from '@hookform/error-message';
import { DetailedHTMLProps, FC, InputHTMLAttributes, memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ObjectUtils } from 'utils';
import { Input } from 'ui-atoms';

type Props = {
  className?: string;
  name: string;
  label?: string;
  inputProps?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  disable?: boolean;
};

const FormTextInput: FC<Props> = ({
  name,
  className,
  label,
  inputProps,
  disable = false,
}) => {
  const formContext = useFormContext();

  const { field, formState } = useController({
    name,
    control: formContext.control,
    defaultValue: inputProps.defaultValue,
  });

  return (
    <div className={className}>
      <Input.Text
        label={label}
        {...inputProps}
        error={!ObjectUtils.isEmpty(ObjectUtils.get(formState.errors, name))}
        value={field?.value}
        onChange={field?.onChange}
        disabled={disable}
      />

      <ErrorMessage
        errors={formState.errors}
        name={name}
        render={({ message }) => (
          <p className="text-error text-xs font-inter my-1">{message}</p>
        )}
      />
    </div>
  );
};

FormTextInput.displayName = 'Form.TextInput';

export default memo(FormTextInput);
