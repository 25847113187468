import * as React from 'react';
import { Form } from 'ui-molecules';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { IOrganizationEditFormValues } from '../../types';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import { useOrganizationEditSchema } from '../../pages/organization/organization-edit.schema';
import { GeoSuggest } from '../../ui-atoms';
import { useState } from 'react';
import { Suggest } from 'react-geosuggest';

export interface IOrganizationDetailProps {}

export default function OrganizationDetail({}: IOrganizationDetailProps) {
  const form = useForm<IOrganizationEditFormValues>({
    resolver: FormResolver(useOrganizationEditSchema()),
    reValidateMode: 'onChange',
  });
  const [customInputValue, setCustomInputValue] = useState('');

  const renderPhone = (phone: string) => {
    return isEmpty(phone)
      ? '-'
      : `(${phone?.slice(0, 3)})-${phone?.slice(3, 6)}-${phone?.slice(
          6,
          phone?.length
        )}`;
  };

  const renderPart = (title: string, element: any, isMl?: boolean) => {
    return (
      <div className="w-full flex items-start gap-x-10">
        {title && (
          <div
            className={cn('text-xs font-bold py-4 w-1/4', isMl ? 'ml-9' : '')}
          >
            {title}
          </div>
        )}
        <div className="w-full mt-6">{element}</div>
      </div>
    );
  };

  const renderVerticalRow = (headerName: string, cell: any) => {
    return (
      <div className=" w-full flex flex-col gap-y-4 py-4">
        <div className="flex items-center gap-x-5">
          <div className="w-1/2 px-2.5 justify-end">
            <p className="text-xs font-bold text-right">{headerName}</p>
          </div>
          <div className="w-full flex items-center px-2.5 justify-end">
            {cell}
          </div>
        </div>
      </div>
    );
  };

  const formatStreet = (suggestion: Suggest) => {
    form.setValue('street', suggestion);
    if (suggestion && suggestion.gmaps) {
      const postCode = suggestion.gmaps.address_components.find((component) =>
        component.types.includes('postal_code')
      );

      const city = suggestion.gmaps.address_components.find((component) =>
        component.types.includes('locality')
      );

      form.setValue('postCode', postCode ? postCode?.long_name : '');
      form.setValue('city', city ? city.long_name : '');
    }
    if (suggestion) {
      const [inputValue] = suggestion?.label.split(',');

      setCustomInputValue(inputValue.trim());
      // form.setValue('apartment', apartment.length ? apartment.join(',') : '');
    }
  };

  return (
    <FormProvider {...form}>
      <form className="w-full grid grid-cols-2 gap-5">
        <div className="flex w-full flex-col gap-y-[20px]">
          {renderPart(
            'Organization',
            <>
              {renderVerticalRow(
                '*Organization',
                <Form.TextInput
                  name="organization"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('organization'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                />
              )}
              {renderVerticalRow(
                '*Org Short',
                <Form.TextInput
                  name="orgShort"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('orgShort'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                />
              )}
              {renderVerticalRow(
                '*Org. Initials',
                <Form.TextInput
                  name="orgInitials"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('orgInitials'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                />
              )}
              {renderVerticalRow(
                'Site Names',
                <Form.TextInput
                  name="siteName"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('siteName'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                />
              )}
              {renderVerticalRow('', <></>)}
              {renderVerticalRow('', <></>)}
              {renderVerticalRow('', <></>)}
              {renderVerticalRow('', <></>)}
            </>,
            true
          )}
          {renderPart(
            'Key Info',
            <>
              {renderVerticalRow(
                'Important',
                <Form.TextInput
                  name="important"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('servicePhone'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold !text-error',
                  }}
                />
              )}
              {renderVerticalRow(
                'Organization Time Zone',
                <Form.TextInput
                  name="organizationTimeZone"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('servicePhone'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !text-error',
                  }}
                />
              )}
              {renderVerticalRow('', <></>)}
              {renderVerticalRow('', <></>)}
              {renderVerticalRow('', <></>)}
              {renderVerticalRow('', <></>)}
              {renderVerticalRow('', <></>)}
            </>,
            true
          )}
          {renderPart(
            'Identification',
            <>
              {renderVerticalRow(
                '*Start Date',
                <Form.DatePicker
                  name="startDate"
                  inputProps={{ id: 'startDate', placeholder: '' }}
                  date={form?.watch('startDate')}
                  onSelect={(value: Date) => {
                    form.setValue('startDate', value);
                  }}
                  className="text-right"
                />
              )}
              {renderVerticalRow(
                '*Organization ID',
                <Form.TextInput
                  name="orgId"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('orgId'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] ',
                  }}
                />
              )}
            </>,
            true
          )}
        </div>
        <div className="flex w-full flex-col">
          {renderPart(
            'Contact Information',
            <>
              {renderVerticalRow(
                '*First Name (Goes by)',
                <Form.TextInput
                  name="firstName"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('firstName'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold ',
                  }}
                />
              )}
              {renderVerticalRow(
                '*Last Name',
                <Form.TextInput
                  name="lastName"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('lastName'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold ',
                  }}
                />
              )}
              {renderVerticalRow(
                '*Middle Name',
                <Form.TextInput
                  name="middleName"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('middleName'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold ',
                  }}
                />
              )}
              {renderVerticalRow(
                '*Initials',
                <Form.TextInput
                  name="initials"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('initials'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold ',
                  }}
                />
              )}
              {renderVerticalRow(
                '*Sort Name - Clin',
                <Form.TextInput
                  name="sortName"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('sortName'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold ',
                  }}
                />
              )}
              {renderVerticalRow(
                'Given Name (First)',
                <Form.TextInput
                  name="givenName"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('givenName'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold ',
                  }}
                />
              )}
              {renderVerticalRow(
                'Prefix',
                <Form.TextInput
                  name="prefixName"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('prefixName'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold ',
                  }}
                />
              )}
              {renderVerticalRow(
                'Suffix',
                <Form.TextInput
                  name="suffixName"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('suffixName'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold ',
                  }}
                />
              )}
              {renderVerticalRow(
                '*Service Phone #',
                <Form.TextInput
                  name="servicePhone"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: renderPhone(form.getValues('servicePhone')),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold ',
                  }}
                />
              )}
            </>
          )}
          {renderPart(
            'Address',
            <>
              {renderVerticalRow(
                'Street',
                <GeoSuggest
                  placeholder=""
                  inputClassName={cn(
                    'w-full flex-1 ring-none !shadow-none px-4 py-2 text-sm font-inter text-input placeholder:text-slate-400 bg-white bg-clip-padding transition ease-in-out m-0 focus:text-input focus:bg-white  !focus:border-primary rounded-[5px]'
                  )}
                  onSuggestSelect={(suggestion) => formatStreet(suggestion)}
                  initialValue={customInputValue}
                  // location={new google.maps.LatLng(53.558572, 9.9278215)}
                  // radius="20"
                  name="streetAddress"
                />
              )}
              {renderVerticalRow(
                'Unit',
                <Form.TextInput
                  name="unit"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('unit'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold ',
                  }}
                />
              )}
              {renderVerticalRow(
                'City',
                <Form.TextInput
                  name="city"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('city'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold ',
                  }}
                />
              )}
              {renderVerticalRow(
                'State',
                <Form.TextInput
                  name="state"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: form.getValues('state'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold ',
                  }}
                />
              )}
              {renderVerticalRow(
                'Post Code',
                <Form.TextInput
                  name="postCode"
                  className="flex-1 bg-white"
                  inputProps={{
                    placeholder: '',
                    value: isEmpty(form.getValues('postCode'))
                      ? '-'
                      : form.getValues('postCode'),
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold ',
                  }}
                />
              )}
            </>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
