import { SVG } from 'assets/imagePath';
import { Box } from 'ui-organisms';
import * as React from 'react';
import QnABox from './QnABox';
import QnAMessage from './QnAMessage';

export interface IQnAProps {}

function QnA(props: IQnAProps) {
  return <React.Fragment />;
}

QnA.Box = QnABox;
QnA.Message = QnAMessage;

export default QnA;
