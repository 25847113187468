import { FC, Fragment, memo } from 'react';
import { Menu, Transition } from '@headlessui/react';
import cn from 'classnames';
import { IDropdown } from 'types';
import { SVG } from 'assets/imagePath';

type Props = {
  className?: string;
  classNameText?: string;
  classNameIcon?: string;
  classNameMenu?: string;

  icon?: string;
  label: string;
  value?: string | number;
  options: IDropdown[];
  onChangeValue?: (value: string | number) => void;

  disabled?: boolean;
};

const DropdownInput: FC<Props> = ({
  className,
  classNameText,
  classNameIcon,
  classNameMenu,
  label,
  value,
  options,
  onChangeValue,
  icon,
  disabled = false,
}) => {
  return (
    <Menu as="div" className="relative inline-block w-full text-left">
      <Menu.Button
        className={cn(
          'flex justify-between w-full items-center rounded-md bg-white bg-clip-padding m-0 hover:bg-gray-200',
          className
        )}
        disabled={disabled}
      >
        <p className={cn('text-sm text-input font-inter', classNameText)}>
          {value ? options?.find((item) => item.value === value)?.label : label}
        </p>
        <img
          src={icon || SVG.chevron_down}
          className={cn('my-auto icon-app', classNameIcon)}
          aria-hidden="true"
          alt="icon"
        />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cn(
            'min-w-[150px] z-50 fixed mt-2 w-max rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            classNameMenu
          )}
        >
          <div className="py-1">
            {options?.map((item) => (
              <Menu.Item key={item.value}>
                {({ active }) => (
                  <a
                    // href="#"
                    className={cn(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm font-inter cursor-pointer'
                    )}
                    onClick={() => onChangeValue(item.value)}
                  >
                    {item.label}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

DropdownInput.displayName = 'Dropdown.Input';

export default memo(DropdownInput);
