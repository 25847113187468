import { SVG } from 'assets/imagePath';
import DetailBox, { IDetailBoxProps } from 'ui-molecules/detail/DetailBox';
import { Dropdown } from 'ui-atoms';
import { useAuth } from 'hooks/useAuth';
import { FC, memo } from 'react';

export type NoteSummary = {
  notesData; //type note
};

const NoteSummary: FC<NoteSummary> = ({ notesData }) => {
  const { CURRENT_ROLE } = useAuth();

  const DETAIL_HEADER: IDetailBoxProps[] = [
    {
      detailRows: [
        {
          subTitle: 'Clinician',
          className: {
            classNameContent: 'font-bold mr-[24px] md:text-left',
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex justify-end">
              <div className="flex gap-[5px] items-center">
                <p>Welby, Marcus Jr.</p>
                <img className="icon-app" src={SVG.phone} alt="phone" />
              </div>
            </div>
          ),
        },
        {
          subTitle: 'Status',
          className: {
            classNameContent: 'font-bold mr-[24px] md:text-left',
          },
          content: (
            <div className="w-full text-left ml-auto z-0 flex justify-end">
              <Dropdown.Input
                label="New - To Transcribe"
                icon={SVG.chevron_down}
                options={[
                  {
                    label: 'New - To Transcribe',
                    value: 'New',
                  },
                ]}
                className={`p-0 hover:bg-transparent text-2.5`}
                classNameText="px-[10px] py-[0px] uppercase text-xs"
                classNameIcon="!m-0"
                classNameMenu="absolute"
              />
            </div>
          ),
        },
      ],
    },
    {
      detailRows: [
        {
          subTitle: 'Patiant',
          importantRow: true,
          content: notesData?.patiant,
          className: {
            classNameContent: 'font-bold',
          },
        },
        {
          subTitle: 'Date of Service',
          importantRow: true,

          content: 'Mon 7/25/2022',
          className: {
            classNameContent: 'font-bold',
          },
        },
      ],
    },
  ];

  return (
    <div className="flex justify-between items-center p-0 gap-x-[95px]">
      <div className="flex-1 grid grid-cols-1 md:grid-cols-2">
        {DETAIL_HEADER.map((item) => (
          <div className="col-span-1">
            <DetailBox title={item.title} detailRows={item.detailRows} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(NoteSummary);
