import { IMAGES } from 'assets/imagePath';
import { Box, Clinician } from 'ui-organisms';
import React, { useState } from 'react';

interface ClinicianInvitationPageProps {}

function ClinicianInvitationPage({}: ClinicianInvitationPageProps) {
  const [step, setStep] = useState<number>(1);
  return (
    <div className="max-h-screen h-full w-full flex flex-col relative">
      <div className="h-full w-full flex-1 flex justify-center items-center p-2.5 overflow-auto">
        {step === 1 && (
          <Clinician.ClinicianInvitation.AcceptStep
            step={1}
            onClick={{
              onDecline: () => {},
              onAccept: (step) => setStep(step),
            }}
          />
        )}
        {step === 2 && (
          <Clinician.ClinicianInvitation.ConfirmDetails
            step={2}
            onClick={{
              onDecline: () => {},
              onAccept: (step) => setStep(step),
            }}
          />
        )}
        {step === 3 && (
          <Clinician.ClinicianInvitation.CreatePassword
            step={3}
            onClick={{
              onDecline: () => {},
              onAccept: (step) => setStep(step),
            }}
          />
        )}
        {step === 4 && (
          <Clinician.ClinicianInvitation.VerifyAccount
            step={4}
            onClick={{
              onDecline: () => {},
              onAccept: (step) => setStep(step),
            }}
          />
        )}
        {step === 5 && (
          <Clinician.ClinicianInvitation.GetStarted
            step={5}
            onClick={{
              onDecline: () => {},
              onAccept: (step) => setStep(step),
            }}
          />
        )}
      </div>
      {step === 5 && (
        <Box.Standard className="h-fit rounded-none absolute bottom-0 w-full flex flex-col items-center gap-2.5 py-[5px]">
          <img
            className="max-w-[133px] h-auto object-contain"
            src={IMAGES.app_store}
          />
          <p className="text-[10px]">
            Apple logo® and App Store® are registered trademarks of Apple Inc.
          </p>
        </Box.Standard>
      )}
    </div>
  );
}

export default ClinicianInvitationPage;
