// @flow
import * as React from 'react';
import { ISiteNamesTable, ITableColumn } from '../../../types';
import { Dropdown, GeoSuggest, Input } from '../../../ui-atoms';
import { SVG } from '../../../assets/imagePath';
import { v4 as uuidv4 } from 'uuid';
import { Suggest } from 'react-geosuggest';

type Props = {
  rows: ISiteNamesTable[];
  setRows: (rows: ISiteNamesTable[]) => void;
  updateValue: (id: number, row: ISiteNamesTable) => void;
  fontSize: number;
};

const SiteNamesColumn: ITableColumn[] = [
  {
    field: 'id',
    headerName: '',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'abbr',
    headerName: 'Abbr.',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'site_name',
    headerName: 'Sitename',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'address',
    headerName: 'Address',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'func',
    headerName: '',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
];
export const SiteNames = ({ rows, setRows, updateValue, fontSize }: Props) => {
  const handleChangeValue = (field: string, value: string | Suggest, row) => {
    if (field === 'abbr') {
      return updateValue(row.id, {
        ...row,
        abbr: value,
      });
    }
    if (field === 'site_name') {
      return updateValue(row.id, {
        ...row,
        site_name: value,
      });
    }
    if (field === 'address') {
      return updateValue(row.id, {
        ...row,
        address: value,
      });
    }
  };

  const onAdd = () => {
    let rowData = [];
    rowData.push(...rows, {
      id: uuidv4(),
      abbr: '',
      site_name: '',
      address: null,
      func: '',
    });
    setRows(rowData);
  };

  const handleDuplicateRow = (row) => {
    let rowData = [];
    rowData.push(...rows, {
      id: uuidv4(),
      abbr: row.abbr,
      site_name: row.site_name,
      address: row.address,
      func: row.func,
    });
    setRows(rowData);
  };

  const handleDeleteRow = (row) => {
    const filterRows = rows.filter((item) => item.id !== row.id);
    setRows(filterRows);
  };

  const renderRow = (row) => {
    const addressData = row?.address?.label?.trim();
    return (
      <tr key={row.id} className="border border-t-0 hover:bg-gray-300">
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          />
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-right h-[30px]"
              onChange={(e) => handleChangeValue('abbr', e, row)}
              value={row.abbr}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Input.Text
              className="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-right h-[30px]"
              onChange={(e) => handleChangeValue('site_name', e, row)}
              value={row.site_name}
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <GeoSuggest
              placeholder=""
              inputClassName="outlined-none !p-1 border-none w-full rounded-[0px] !px-2 text-left h-[30px]"
              onSuggestSelect={(suggestion) =>
                handleChangeValue('address', suggestion, row)
              }
              initialValue={addressData ?? ''}
              name="address"
            />
          </div>
        </td>
        <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
          <div
            className={'flex-1 bg-white'}
            style={{ fontSize: `${fontSize}px` }}
          >
            <Dropdown.Standard
              CustomButton={(item) => (
                <div className="flex gap-2.5 items-center bg-white">
                  <img
                    src={SVG.more_horizontal}
                    className="m-auto"
                    alt="more"
                  />
                </div>
              )}
              options={[
                {
                  label: 'Duplicate',
                  value: 'Duplicate',
                  onClick: () => handleDuplicateRow(row),
                },
                {
                  label: 'Delete',
                  value: 'Delete',
                  onClick: () => handleDeleteRow(row),
                },
              ]}
            />
          </div>
        </td>
      </tr>
    );
  };
  return (
    <>
      <table className={'table-auto w-full h-full z-0'}>
        <thead>
          <tr>
            {SiteNamesColumn.map((column) => {
              return (
                !column?.hideCol && (
                  <th
                    key={column.field}
                    className="p-table text-center border font-[600] whitespace-nowrap  min-w-fit"
                    style={column?.headerStyle}
                  >
                    <p style={{ fontSize: `${fontSize}px` }}>
                      {column.headerName}
                    </p>
                  </th>
                )
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row) => {
            return renderRow(row);
          })}
          <tr
            className="border border-t-0 hover:cursor-pointer"
            onClick={onAdd}
          >
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
            <td className="h-full text-center p-table whitespace-nowrap min-w-fit">
              <div
                className={'flex-1 bg-white'}
                style={{ fontSize: `${fontSize}px` }}
              >
                +
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
