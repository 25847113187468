import { FC, Fragment, memo } from 'react';

type Props = {
  src: string;
  onClick?: () => void;
  title?: string;
};

const NavBarIconMenu: FC<Props> = ({ src, onClick, title }) => {
  return (
    <Fragment>
      <div className="h-full px-4 gap-2 flex items-center" onClick={onClick}>
        <img src={src} className="icon-app" alt="icon" />
        {title && <span>{title}</span>}
      </div>
    </Fragment>
  );
};

NavBarIconMenu.displayName = 'Nav.BarIconMenu';

export default memo(NavBarIconMenu);
