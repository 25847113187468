import * as React from 'react';

export interface IOrganizationSideBarProps {}

const detail = [
  {
    title: 'Views',
    elements: [{ content: 'Contact Info' }, { content: 'Details' }],
  },
  {
    title: 'Lists',
    elements: [],
  },
  {
    title: 'Comments (1)',
    elements: [
      { content: 'Sites', count: '1' },
      { content: 'Attachments', count: '1' },
      { content: 'Open Notes', count: '1' },
      { content: 'Done Notes', count: '1' },
      { content: 'Staff List', count: '1' },
    ],
  },
  {
    title: 'Settings',
    elements: [
      { content: 'Device Settings' },
      { content: 'App Settings' },
      { content: 'Audio Settings' },
    ],
  },
  {
    title: 'Analytics',
    elements: [
      { content: 'Dashboard Stats' },
      { content: 'Pattern of Practice' },
      { content: 'Productivity Stats' },
      { content: 'Quality Issues' },
    ],
  },
  {
    title: 'Links',
    elements: [
      { content: 'SC - EMR' },
      { content: 'PA - EMR' },
      { content: 'HHS - EMR' },
      { content: 'My Email' },
      { content: 'Coding Asst' },
      { content: 'Transcribes' },
    ],
  },
];

export default function OrganizationSideBar(props: IOrganizationSideBarProps) {
  return (
    <div className="flex flex-col gap-y-2.5">
      <h3 className="font-bold text-xs py-[5px] px-2.5">Organizational Info</h3>
      {detail.map((item) => (
        <div className="px-2.5" key={item.title}>
          <h3 className="font-bold text-xs py-[5px]">{item.title}</h3>
          <div className="flex flex-col text-xs gap-[5px]">
            {item.elements.map((element) => (
              <p key={element.content}>
                {element.content}{' '}
                <strong>{element?.count ? `(${element?.count})` : ''}</strong>
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
