import { SVG } from 'assets/imagePath';
import { PaginationProps } from 'ui-molecules/pagination/Pagination';
import { RouterPath } from '../../routes/router.constant';
import { useNavigate } from 'react-router-dom';
import NewPagination from '../new-pagination';

interface FooterProps {
  pagination?: PaginationProps;
}

function Footer({ pagination }: FooterProps) {
  const navigate = useNavigate();
  return (
    <div className="fixed bottom-0 left-0 right-0 w-full bg-white border border-grey p-2.5 grid grid-cols-3">
      <div />
      <div
        className="self-center justify-self-center"
        onClick={() => navigate(RouterPath.SUPPORT_PAGE)}
      >
        <img
          src={SVG.light_bulb}
          className="object-contain w-5 h-5 cursor-pointer"
          alt="light-icon"
        />
      </div>
      {pagination && (
        <div className="justify-self-end">
          <NewPagination
            total={pagination?.total}
            currentPage={pagination?.currentPage}
            pageSize={pagination?.pageSize}
            setPageSize={pagination?.onChangePageSize}
            setCurrentPage={pagination?.onChangeCurrentPage}
          />
        </div>
      )}
    </div>
  );
}

export default Footer;
