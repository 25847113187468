import { ITableColumn } from '../../types';

export const fakeColumn1: ITableColumn[] = [
  {
    field: 'Day',
    headerName: 'Day',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '6AM-8AM',
    headerName: '6AM-8AM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '8AM-12PM',
    headerName: '8AM-12PM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '12PM-1PM',
    headerName: '12PM-1PM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '1PM-2PM',
    headerName: '1PM-2PM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '2PM-5PM',
    headerName: '2PM-5PM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '5PM-6PM',
    headerName: '5PM-6PM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '6PM-6AM',
    headerName: '6PM-6AM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'Total',
    headerName: 'Total',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
];

export const fakeColumn2: ITableColumn[] = [
  {
    field: 'Day',
    headerName: 'Day',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '5AM-8AM',
    headerName: '5AM-8AM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '8AM-12PM',
    headerName: '8AM-12PM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '12PM-5PM',
    headerName: '12PM-5PM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },

  {
    field: '5PM-8PM',
    headerName: '5PM-6PM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '8PM-5AM',
    headerName: '6PM-6AM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'Total',
    headerName: 'Total',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: 'Backlog',
    headerName: 'Backlog',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
];

export const fakeColumn3: ITableColumn[] = [
  {
    field: 'Day',
    headerName: 'Day',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '5AM-8AM',
    headerName: '5AM-8AM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '8AM-12PM',
    headerName: '8AM-12PM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '12PM-5PM',
    headerName: '12PM-5PM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },

  {
    field: '5PM-8PM',
    headerName: '5PM-6PM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
  {
    field: '8PM-5AM',
    headerName: '6PM-6AM',
    headerStyle: { textAlign: 'center' },
    rowStyle: {
      justifyContent: 'center',
    },
  },
];

export const fakeRow1 = [
  {
    Day: 'Mon - Pat',
    '6AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-1PM': '0',
    '1PM-2PM': '',
    '2PM-5PM': '7',
    '5PM-6PM': '0',
    '6PM-6AM': '0',
    Total: '13',
  },
  {
    Day: 'Tue - Pat',
    '6AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-1PM': '0',
    '1PM-2PM': '',
    '2PM-5PM': '7',
    '5PM-6PM': '0',
    '6PM-6AM': '0',
    Total: '13',
  },
  {
    Day: 'Wed - Pat',
    '6AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-1PM': '0',
    '1PM-2PM': '',
    '2PM-5PM': '7',
    '5PM-6PM': '0',
    '6PM-6AM': '0',
    Total: '13',
  },
  {
    Day: 'Thu - Pat',
    '6AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-1PM': '0',
    '1PM-2PM': '',
    '2PM-5PM': '7',
    '5PM-6PM': '0',
    '6PM-6AM': '0',
    Total: '13',
  },
  {
    Day: 'Fri - Pat',
    '6AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-1PM': '0',
    '1PM-2PM': '',
    '2PM-5PM': '7',
    '5PM-6PM': '0',
    '6PM-6AM': '0',
    Total: '13',
  },
  {
    Day: 'Sat - Pat',
    '6AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-1PM': '0',
    '1PM-2PM': '',
    '2PM-5PM': '7',
    '5PM-6PM': '0',
    '6PM-6AM': '0',
    Total: '13',
  },
  {
    Day: 'Sun - Pat',
    '6AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-1PM': '0',
    '1PM-2PM': '',
    '2PM-5PM': '7',
    '5PM-6PM': '0',
    '6PM-6AM': '0',
    Total: '13',
  },
  {
    Day: 'Grand Total',
    Total: '13',
  },
];

export const fakeRow2 = [
  {
    Day: 'Mon - Pat',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
    Total: '13',
    Backlog: '5',
  },
  {
    Day: 'Tue - Pat',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
    Total: '13',
    Backlog: '5',
  },
  {
    Day: 'Wed - Pat',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
    Total: '13',
    Backlog: '5',
  },
  {
    Day: 'Thu - Pat',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
    Total: '13',
    Backlog: '5',
  },
  {
    Day: 'Fri - Pat',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
    Total: '13',
    Backlog: '5',
  },
  {
    Day: 'Sat - Pat',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
    Total: '13',
    Backlog: '5',
  },
  {
    Day: 'Sun - Pat',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
    Total: '13',
    Backlog: '5',
  },
];

export const fakeRow3 = [
  {
    Day: 'Mon',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
  },
  {
    Day: 'Tue',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
  },
  {
    Day: 'Wed',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
  },
  {
    Day: 'Thu',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
  },
  {
    Day: 'Fri',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
  },
  {
    Day: 'Sat',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
  },
  {
    Day: 'Sun',
    '5AM-8AM': '0',
    '8AM-12PM': '6',
    '12PM-5PM': '0',
    '5PM-8PM': '',
    '8PM-5AM': '7',
  },
];
