import { SVG } from 'assets/imagePath';
import { Button } from 'ui-atoms';
import { APP_ROLE } from 'constant';
import { useViewport } from 'context/ViewportContext';
import { useAuth } from 'hooks/useAuth';
import { FC, memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search } from 'ui-molecules';
import SelectedButton from './components/SelectedButton';
import { IDropdown } from 'types';

type Props = {
  className?: string;
  toggle: boolean;
  setToggle?: (prev: boolean) => void;
  setFontSize: (size: number) => void;
  viewName?: string;
  title?: {
    options: IDropdown[];
  };
};

const HeaderList: FC<Props> = ({
  className,
  toggle,
  setToggle,
  setFontSize,
  viewName,
  title,
}) => {
  const navigate = useNavigate();
  const { CURRENT_ROLE, currentUser } = useAuth();
  const { width } = useViewport();
  const [searchVal, setSearchValue] = useState<string>('');
  const [size, setSize] = useState(12);

  useEffect(() => {
    // if (width < BREAKPOINT.lg) {
    //   setToggle(false);
    // } else {
    //   setToggle(true);
    // }
  }, [width]);

  const handleChangeValue = (value: string) => {
    setSearchValue(value);
  };

  const handleOnChangleFontSize = (size: number) => {
    if (size < 12) {
      return setFontSize(12);
    }
    setFontSize(size);
  };
  const handleIncreaseSize = () => {
    setSize(size + 1);
    handleOnChangleFontSize(size + 1);
  };
  const handleDecreaseSize = () => {
    if (size > 12) {
      setSize(size - 1);
      handleOnChangleFontSize(size - 1);
    }
  };

  return (
    <div className="flex justify-between">
      <ul className="flex items-center list-none justify-between text-center gap-2.5">
        <li className="flex gap-[10px] items-center">
          <Button.Icon
            classNameIcon="!icon-app"
            icon={toggle ? SVG.filter : SVG.un_filter}
            variant="outlined"
            className="bg-[#f3f3f3]"
            // onClick={() => {}}
            onClick={() => setToggle(!toggle)}
          />
          <div>
            <div>
              <SelectedButton
                propsButton={{
                  variant: 'outlined',
                  color: 'secondary',
                  className: 'px-[15px]',
                }}
                options={title.options}
              />
            </div>
          </div>
          {CURRENT_ROLE === APP_ROLE.SA && (
            <div>
              <Button.White
                className="!p-default"
                color="white"
                label={
                  <p>
                    Inquires <span className="font-bold">(3)</span>
                  </p>
                }
                onClick={() => {}}
              />
            </div>
          )}
        </li>
      </ul>
      <ul className="hidden xl:flex justify-center items-center text-xs text-black font-bold">
        <li className="flex items-center">
          <p>{viewName || ''}</p>
          <Button.Text
            className="border border-silver rounded-md w-fit p-1 mx-2"
            label={`${currentUser?.['custom:initials'] || ''}`}
            onClick={() => {}}
          />
        </li>
      </ul>

      <ul className="flex items-center justify-end list-none text-center gap-2.5">
        <li className="hidden lg:flex">
          <Button.Icon
            classNameIcon="!icon-app"
            icon={SVG.zoom_in}
            variant="outlined"
            className="bg-[#f3f3f3]"
            onClick={handleIncreaseSize}
          />
        </li>
        <li className="hidden lg:flex">
          <Button.Icon
            classNameIcon="!icon-app"
            icon={SVG.zoom_out}
            variant="outlined"
            className="bg-[#f3f3f3]"
            onClick={handleDecreaseSize}
          />
        </li>
        <li className="hidden lg:flex">
          <div className="mt-2.5 mb-[15px]">
            <Search.Standard
              value={searchVal}
              onChange={handleChangeValue}
              className="border-[1px] border-nav border-solid !rounded-full bg-transparent"
              inputProps={{
                placeholder: 'Search',
                style: { borderStyle: 'solid' },
              }}
            />
          </div>
        </li>
        <li>
          <Button.Primary
            className="!p-default"
            label="Add Note"
            onClick={() => navigate('#')}
          />
          {/* onClick={() => navigate(`/note/new`)} */}
        </li>
        <li>
          <Button.White
            className="!p-default"
            color="white"
            label="Actions"
            icon={SVG.chevron_down}
            onClick={() => {}}
          />
        </li>

        <li>
          <Button.Icon
            icon={SVG.more_horizontal}
            variant="outlined"
            onClick={() => {}}
          />
        </li>
      </ul>
    </div>
  );
};
HeaderList.displayName = 'Header.List';
export default memo(HeaderList);
