import { IDropdown } from 'types';

export const PAGE_SIZE_OPTIONS: IDropdown[] = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];
export const DEFAULT_PAGE = 1;

export const DEFAULT_LIMIT = 10;
