import { SVG } from 'assets/imagePath';
import { Button, Dropdown } from 'ui-atoms';
import { useAuth } from 'hooks/useAuth';
import { FC, Fragment, memo } from 'react';
import * as React from 'react';
import cn from 'classnames';

type Props = {
  toggle?: boolean;
  setToggle?: (prev: boolean) => void;
  selectPage: string;
  onChange;
};

const HeaderSettings: FC<Props> = ({
  toggle,
  setToggle,
  selectPage,
  onChange,
}) => {
  const { currentUser } = useAuth();
  return (
    <Fragment>
      <ul className="flex justify-between items-center list-none text-center gap-2.5">
        {selectPage !== 'Settings - MGR' && (
          <li>
            <Button.Icon
              classNameIcon="max-w-[15px] "
              icon={SVG.filter_panel}
              variant="outlined"
              className={cn(
                'bg-[#F7F8FA] p-[7.5px]',
                toggle ? 'border-2 border-[#BC9A3B]' : 'border'
              )}
              onClick={() => setToggle(!toggle)}
            />
          </li>
        )}
        <li className="flex-1 flex">
          <Dropdown.Standard
            CustomButton={(item) => (
              <div className="flex gap-[5px] items-center hover:bg-transparent px-[15px] py-[7.5px] border border-nav rounded-[5px]">
                <p className="text-sm">{item.label}</p>
                <img
                  className="icon-app"
                  src={SVG.chevron_down}
                  alt="chevron_down"
                />
              </div>
            )}
            onChangeValue={onChange}
            options={[
              {
                label: 'Settings',
                value: 'settings',
              },
              {
                label: 'General',
                value: 'general',
              },
              {
                label: 'Users and Control',
                value: 'user_and_control',
              },
              {
                label: 'Clinicians',
                value: 'clinicians',
              },
              {
                label: 'Note',
                value: 'note',
              },
              {
                label: 'Document Specialists',
                value: 'document_specialists',
              },
              {
                label: 'Manager',
                value: 'manager',
              },
              {
                label: 'System Admin',
                value: 'system_admin',
              },
            ]}
          />
        </li>
        <li className="py-[7.5px] px-2.5">
          <p className="text-xs font-bold">{currentUser?.username}</p>
        </li>
        <li className="flex-1 flex justify-end">
          <Button.Icon
            className="!p-[7.5px]"
            icon={SVG.more_horizontal}
            variant="outlined"
            onClick={() => {}}
          />
        </li>
      </ul>
    </Fragment>
  );
};
HeaderSettings.displayName = 'Header.Settings';
export default memo(HeaderSettings);
