import React, { Fragment, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { APP_ROLE } from '../../constant';
import { RouterPath } from '../../routes/router.constant';

interface IDefaultLayout {
  children: ReactNode;
}

const DefaultLayout: React.FC<IDefaultLayout> = ({ children }) => {
  return (
    <Fragment>
      <div className="w-full h-auto min-h-screen flex flex-col bg-pearl pt-12">
        {children}
      </div>
    </Fragment>
  );
};

export default DefaultLayout;
