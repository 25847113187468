import { ActiveUserStep4 } from 'ui-organisms/activeNewUser';

export const ActiveNewCodePage = () => {
  return (
    <div className="flex min-h-screen">
      <div className="w-full flex-1 h-screen p-[15px]">
        <ActiveUserStep4 />
      </div>
    </div>
  );
};
