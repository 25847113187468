import { SVG } from 'assets/imagePath';
import { Collapse, Form } from 'ui-molecules';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

export interface IFilterCheckListProps {
  title: React.ReactNode;
  checkList: any[];
  classNameText?: string;
  isCollapse?: boolean;
}

export default function FilterCheckList({
  isCollapse = false,
  title,
  checkList,
  classNameText,
  ...rest
}: IFilterCheckListProps) {
  const form = useFormContext();
  return (
    <div className="flex flex-col">
      <Collapse.Text
        defaultOpen={true}
        disabled={!isCollapse}
        button={(open) => (
          <div className={cn('flex gap-[5px] items-center', classNameText)}>
            <h4 className={cn('font-bold text-xs py-[2px] text-left')}>
              {title}
            </h4>
            {isCollapse && <img src={SVG.chevron_down} alt="chevron" />}
          </div>
        )}
        collapseContent={
          <ul className="flex flex-col">
            {checkList?.map((item, index) => (
              <li className="py-[1px]" key={index}>
                <Form.CheckboxSingle
                  value={item?.value}
                  name={item?.label}
                  label={item?.label}
                />
              </li>
            ))}
          </ul>
        }
        {...rest}
      />
    </div>
  );
}
