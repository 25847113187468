import React from 'react';
import EmailInput from './EmailInput';
import PasswordInput from './PasswordInput';
import PhoneInput from './PhoneInput';
import SelectInput from './SelectInput';
import SelectMulti from './SelectMulti';
import TextInput from './TextInput';
import TextArea from './TextArea';
import UploadFile from './UploadFile';
import PhoneInputV2 from './PhoneInputV2';
import SelectInputV2 from './SelectInputV2';

const Input = () => {
  return <React.Fragment />;
};

Input.Text = TextInput;

Input.Email = EmailInput;

Input.Phone = PhoneInput;

Input.PhoneV2 = PhoneInputV2;

Input.Password = PasswordInput;

Input.Select = SelectInput;

Input.SelectV2 = SelectInputV2;

Input.SelectMulti = SelectMulti;

Input.TextArea = TextArea;

Input.UploadFile = UploadFile;

export default Input;
