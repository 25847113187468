import * as React from 'react';
import { Form } from 'ui-molecules';

const EditableVerticalTable = ({ columns, row, isEdit = true }) => {
  return (
    <div className="border border-white-light border-b-0 flex flex-col gap-y-2">
      {columns.map((col, idx) => {
        return (
          <div
            key={`${col?.field}-${idx}`}
            className="flex items-start border-b h-[50px] border-white-light"
          >
            <div
              className="title flex-1 px-2.5 justify-end border-r border-white-light"
              style={col?.headerStyle}
            >
              <p className="text-xs font-bold text-right">{col?.headerName}</p>
            </div>
            <div
              className="title flex-1 flex items-center px-2.5 justify-end"
              style={col?.rowStyle}
            >
              {col?.renderCell ? (
                col?.renderCell(row)
              ) : (
                <Form.TextInput
                  inputProps={{
                    defaultValue: row?.defaultValue,
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                    placeholder: col?.placeholder || '',
                    disabled: !!col?.disabled,
                  }}
                  className="w-full"
                  name={col?.field}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EditableVerticalTable;
