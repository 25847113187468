import * as Yup from 'yup';

export const useContactSchema = () => {
  return Yup.object().shape({
    fullName: Yup.string().nullable(),
    email: Yup.string()
      .nullable()
      .email('Email is invalid')
      .trim('Email is required')
      .required('Email is required'),
    message: Yup.string().nullable(),
  });
};
