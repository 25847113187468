import * as React from 'react';

export interface IDrawerProps {
  open: boolean;
  position: 'right' | 'left' | 'top' | 'bottom';
  DrawerContent?: React.ReactNode;
  handleClose: () => void;
}

export default function Drawer({
  open,
  position,
  handleClose,
  DrawerContent,
}: IDrawerProps) {
  //Style
  let positionClass;
  switch (position) {
    case 'left':
      positionClass = !open
        ? '-translate-x-full top-0 left-0  w-auto h-full'
        : 'top-0 left-0 w-auto h-full';
      break;
    case 'right':
      positionClass = !open
        ? 'translate-x-full top-0 right-0  w-auto h-full'
        : 'top-0 right-0 w-auto h-full';

      break;
    case 'bottom':
      positionClass = !open
        ? 'translate-y-full bottom-0 w-full h-auto'
        : 'bottom-0 w-full h-auto';
      break;
    case 'top':
      positionClass = !open
        ? '-translate-y-full top-0 w-full h-auto'
        : 'top-0 w-full h-auto';
      break;
    default:
      break;
  }
  React.useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [open]);

  return (
    <>
      <div
        className={`fixed bg-[#0000006e] w-full top-0  h-full ${
          open ? '' : '-translate-x-full'
        }`}
        onClick={handleClose}
      ></div>
      <div
        className={`z-40 fixed overflow-auto max-h-full max-w-full transition-transform  bg-white ${positionClass} `}
      >
        <div className="relative p-2.5">
          <button
            type="button"
            data-drawer-hide="drawer-navigation"
            aria-controls="drawer-navigation"
            onClick={handleClose}
            className="text-white bg-transparent rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center"
          >
            <svg
              aria-hidden="true"
              fill="initial"
              className="w-5 h-5"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close menu</span>
          </button>
          {DrawerContent}
        </div>
      </div>
    </>
  );
}
