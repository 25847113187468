import * as React from 'react';
import PlayerControl from './components/controls/PlayerControl';

export interface IPlayerProps {}

function Player({}: IPlayerProps) {
  return <React.Fragment />;
}

Player.Controls = PlayerControl;

export default Player;
