import { ChangeEvent, FC, memo, useCallback, useId } from 'react';
import { ObjectUtils } from 'utils';
import cn from 'classnames';

export type CheckboxSingleProps = {
  className?: string;
  classNameBox?: string;
  classNameText?: string;
  label?: string;
  value?: any;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
};

const CheckboxSingle: FC<CheckboxSingleProps> = ({
  className,
  label,
  onChange,
  classNameBox,
  classNameText,
  checked,
  ...rest
}) => {
  const checkboxId = useId();
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
    },
    [onChange]
  );
  return (
    <div className={cn('flex justify-center items-center', classNameBox)}>
      <input
        {...rest}
        type="checkbox"
        className={cn(
          'h-[13px] w-[13px] border-2 border-text rounded-sm transition duration-200 mt-1 align-top mr-2 cursor-pointer my-auto checked:bg-primary checked:focus:bg-primary checked:hover:bg-primary  focus:ring-transparent',
          className
        )}
        id={checkboxId}
        onChange={handleChange}
        checked={checked}
      />
      {!ObjectUtils.isEmpty(label) && (
        <label
          className={cn(
            'form-check-label text-xs font-inter inline-block mt-0.5 text-label w-full',
            classNameText
          )}
          htmlFor={checkboxId}
        >
          {label}
        </label>
      )}
    </div>
  );
};

CheckboxSingle.displayName = 'Checkbox.Single';

export default memo(CheckboxSingle);
