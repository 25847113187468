import * as React from 'react';
import { Box } from 'ui-organisms';
import { Footer, HeaderApp, Form } from 'ui-molecules';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useMemo } from 'react';
import { GeoSuggest } from 'ui-atoms';
import cn from 'classnames';
import { useApiCall, useSelectBillToSuggestion } from 'hooks';
import { GlobalContext } from 'context/GlobalContext';
import { APP_ROLE, SET_LOADING_MODAL, TIMEZONE_OPTIONS } from 'constant';
import {
  checkCorporateRecordAPI,
  postCorporateAPI,
  postSignatureAPI,
} from 'services';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import { useOrganizationNewSchema } from './organization-new.schema';
import { ErrorMessage } from '@hookform/error-message';
import isEmpty from 'lodash/isEmpty';
import { RouterPath } from '../../routes/router.constant';
import { useAuth } from '../../hooks/useAuth';

export interface IOrganizationEditProps {}

export default function OrganizationNew({}: IOrganizationEditProps) {
  const { dispatch } = useContext(GlobalContext);
  const { CURRENT_ROLE } = useAuth();

  const [checkCorporateRecord] = useApiCall(checkCorporateRecordAPI);
  const [getSignature] = useApiCall(postSignatureAPI);
  const [postCorporate] = useApiCall(postCorporateAPI);
  const navigate = useNavigate();

  const allowAccess = useMemo(() => {
    return (
      CURRENT_ROLE === APP_ROLE.OW ||
      CURRENT_ROLE === APP_ROLE.SA ||
      CURRENT_ROLE === APP_ROLE.MGR ||
      CURRENT_ROLE === APP_ROLE.DS
    );
  }, [CURRENT_ROLE]);

  useEffect(() => {
    if (!allowAccess) {
      return navigate(RouterPath.ORGANIZATIONS);
    }
  }, [allowAccess, navigate]);

  const form = useForm({
    resolver: FormResolver(useOrganizationNewSchema()),
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });
  const userBillingAddress = useSelectBillToSuggestion(
    form.watch('billing_address')?.label
  );

  useEffect(() => {
    form.setValue('streetAddress', userBillingAddress?.streetAddress);
    form.setValue('addressLine2', userBillingAddress?.streetAddressLine2);
    form.setValue('stateOf', userBillingAddress?.stateOf);
    form.setValue('city', userBillingAddress?.city);
    form.setValue('zipCode', userBillingAddress?.zipCode);
    form.setValue('country', userBillingAddress?.country);
  }, [form, userBillingAddress]);

  const viewName = `New Organization Details`;
  const titleOpts = [{ label: `New Details`, value: 'title-1' }];
  const actionOpts = [
    { label: 'Actions', value: 'actions' },
    // { label: 'Edit', value: 'edit', onClick: () => setIsEdit(true) },
    // { label: 'Delete', value: 'delete', onClick: () => setIsEdit(true) }
  ];

  const handleSubmitFunc = async (data) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });
      const res = await checkCorporateRecord({
        adminEmail: data.adminEmail.toLowerCase(),
      });
      const security = await getSignature('UNASSIGNED');

      const payload = {
        organizationId: res.organizationId,
        userRecordId: 'UNASSIGNED', // in add org this is static
        organizationName: data.orgFullName,
        orgShort: data.orgShortName,
        orgInitials: data.orgInitials,
        locations: [
          {
            zipCode: data.zipCode,
            country: data.country,
            locationAdmin: {
              firstName: data.adminFirstName,
              lastName: data.adminLastName,
              phone: data.adminPhone,
              email: data.adminEmail,
            },
            locationName: data?.primaryLocation ?? 'PRIMARY', // Change Label from Primary Location and make it input field not dropdown
            stateOf: data.stateOf,
            streetAddress: data.streetAddress,
            city: data.city,
            addressLine2: data.streetAddressLine2,
          },
        ],
        department: data.department,
        orgLocation: data.orgLocation,
        primaryLocation: data.primaryLocation,
        adminEmail: data.adminEmail,
        adminFirstName: data.adminFirstName,
        adminLastName: data.adminLastName,
        adminPhone: data.adminPhone,
        emrSystem: data.emrSystem,
        servicePhone: data.servicePhone,
        timeZone: data.timeZone,
        SlaTiming: data.SlaTiming,
      };
      const corpResult = await postCorporate({
        body: payload,
        signature: security,
      });
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
      if (corpResult) navigate(`/organizations`);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
    }
  };

  const renderVerticalRow = (headerName: string, cell: any) => {
    return (
      <div className=" w-full flex flex-col gap-y-4 my-4 border border-white-light border-b-0">
        <div className="flex items-center gap-x-2 border-b border-white-light h-[60px]">
          <div className="w-2/5 justify-end border-r border-white-light">
            <p className="text-xs font-bold text-center">{headerName}</p>
          </div>
          <div className="w-full flex-1 items-center">{cell}</div>
        </div>
      </div>
    );
  };

  const renderPhone = (phone: string) => {
    return isEmpty(phone)
      ? '-'
      : `(${phone?.slice(0, 3)})-${phone?.slice(3, 6)}-${phone?.slice(
          6,
          phone?.length
        )}`;
  };

  return (
    <DefaultLayout>
      <HeaderApp.Edit
        title={{ options: titleOpts }}
        viewName={viewName}
        actions={{ options: actionOpts }}
        onSave={form.handleSubmit(handleSubmitFunc)}
      />
      <Box.Standard className="my-2.5">
        <FormProvider {...form}>
          <form className="grid grid-cols-3 gap-[10px]">
            <div>
              {renderVerticalRow(
                '*Org Full Name',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                  className="w-full"
                  name="orgFullName"
                />
              )}
              {renderVerticalRow(
                '*Org Short Name',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                  className="w-full"
                  name="orgShortName"
                />
              )}
              {renderVerticalRow(
                '*Org Initials',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                  className="w-full"
                  name="orgInitials"
                />
              )}
              {renderVerticalRow(
                'Department',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                  className="w-full"
                  name="department"
                />
              )}
              {renderVerticalRow(
                'SLA Timing',
                <Form.SelectInput
                  defaultValue={{ label: '', value: '' }}
                  options={[
                    { label: 'Immediate Service', value: 'immediate' },
                    { label: '1 hour', value: '1hour' },
                    { label: '2 hour', value: '2hour' },
                    { label: '3 hour', value: '3hour' },
                    { label: '4 hour', value: '4hour' },
                    { label: 'Next Day', value: 'nextDay' },
                  ]}
                  name="SlaTiming"
                  text
                  className="w-full"
                />
              )}
            </div>
            <div>
              {renderVerticalRow(
                'Org Location',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                  className="w-full"
                  name="orgLocation"
                />
              )}
              {renderVerticalRow(
                'Primary Location Name',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                  className="w-full"
                  name="primaryLocation"
                />
              )}
              {renderVerticalRow(
                'Billing address',
                <div className="!m-0">
                  <GeoSuggest
                    placeholder="Street Address"
                    inputClassName={cn(
                      'z-0 text-sm font-inter text-input placeholder:text-slate-400 bg-inherit bg-clip-padding transition ease-in-out m-0 focus:text-input focus:bg-inherit ring-nav outlined-none !p-1 border-none ring-0 !w-full h-full rounded-[0px] !shadow-none text-right'
                    )}
                    onSuggestSelect={(suggestion) => {
                      form.setValue('billing_address', suggestion);
                    }}
                    name="billing_address"
                    id="billing_address"
                  />
                  <ErrorMessage
                    errors={form.formState.errors}
                    name="billing_address"
                    render={({ message }) => (
                      <p className="text-error text-xs font-inter my-1">
                        {message}
                      </p>
                    )}
                  />
                </div>
              )}
              {renderVerticalRow(
                'Admin First Name',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                  className="w-full"
                  name="adminFirstName"
                />
              )}
              {renderVerticalRow(
                'Admin Last Name',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                  className="w-full"
                  name="adminLastName"
                />
              )}
              {renderVerticalRow(
                'Admin phone',
                <Form.PhoneInputV2
                  className="flex-1"
                  name="adminPhone"
                  inputProps={{
                    id: 'adminPhone',
                    placeholder: 'Phone Number',
                    value: renderPhone(form.getValues('adminPhone')),
                  }}
                />
              )}
              {renderVerticalRow(
                '*Admin email',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                  className="w-full"
                  name="adminEmail"
                />
              )}
            </div>
            <div>
              {renderVerticalRow(
                'Other Organization Names',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                  className="w-full"
                  name="otherOrgName"
                />
              )}
              {renderVerticalRow(
                'Other Department',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                  className="w-full"
                  name="otherDepartment"
                />
              )}
              {renderVerticalRow(
                '*Service Phone Number',
                <Form.PhoneInputV2
                  className="flex-1"
                  name="servicePhone"
                  inputProps={{
                    id: 'servicePhone',
                    placeholder: 'Service Phone Number',
                    value: renderPhone(form.getValues('servicePhone')),
                  }}
                />
              )}
              {renderVerticalRow(
                'EMR System',
                <Form.SelectInput
                  defaultValue={{ label: '', value: '' }}
                  options={[{ label: 'EMR System', value: 'sc' }]}
                  name="emrSystem"
                  text
                  className="w-full"
                />
              )}
              {renderVerticalRow(
                '*Time Zone UTC',
                <Form.SelectInput
                  options={TIMEZONE_OPTIONS}
                  name="timeZone"
                  className="w-full"
                  text
                />
              )}
              {renderVerticalRow(
                'Attach function',
                <Form.TextInput
                  inputProps={{
                    className:
                      'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                  }}
                  className="w-full"
                  name="attach_func"
                />
              )}
            </div>
          </form>
        </FormProvider>
      </Box.Standard>

      <Footer />
    </DefaultLayout>
  );
}
