import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ICoderListFilterProps {
  coderList: any[];
  onClick: (id: string) => void;
}

function CoderListFilter({ coderList, onClick }: ICoderListFilterProps) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col">
      <h3 className={'font-bold text-xs py-2.5'}>Manager</h3>
      <div className="flex flex-col text-xs gap-y-2.5">
        {coderList?.map((org) => (
          <p
            key={org?.organizationId}
            className={
              'hover:cursor-pointer hover:text-pink-700 hover:underline hover:font-bold'
            }
            onClick={() => onClick(org?.organizationId)}
          >
            {org?.organizationName}
          </p>
        ))}
      </div>
    </div>
  );
}

export default memo(CoderListFilter);
