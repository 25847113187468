import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { yupResolver as FormResolver } from '@hookform/resolvers/yup/dist/yup';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Box } from 'ui-organisms';
import { Suggest } from 'react-geosuggest';
import { SVG } from 'assets/imagePath';
import { Footer, Form, HeaderApp } from 'ui-molecules';
import { APP_ROLE, SET_LOADING_MODAL, TIMEZONE_OPTIONS } from 'constant';
import { GlobalContext } from 'context/GlobalContext';
import { useApiCall, useSelectBillToSuggestion } from 'hooks';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import { useNavigate } from 'react-router-dom';
import { RouterPath } from 'routes/router.constant';
import { getOrganizationAPI, updateOrganizationAPI } from 'services';
import { FormProvider, useForm } from 'react-hook-form';
import {
  IAttachmentsTable,
  ICommentsTable,
  IDepartmentsTable,
  ILocationsTable,
  IOrganizationEditFormValues,
  ISelectOption,
  ISiteNamesTable,
} from '../../types';
import { useOrganizationEditSchema } from './organization-edit.schema';
import { GeoSuggest } from '../../ui-atoms';
import {
  Attachments,
  Comments,
  Departments,
  Locations,
  SiteNames,
} from './tables';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth';

export interface IOrganizationDetailProps {}

export default function OrganizationDetail({}: IOrganizationDetailProps) {
  const { state, dispatch } = useContext(GlobalContext);

  const { userRecordId: clinicianId, primaryAccountId: organizationId } = state;
  const { CURRENT_ROLE } = useAuth();
  const navigate = useNavigate();
  const [fetchOrg, loading, orgData] = useApiCall(getOrganizationAPI);
  const [updateOrganization] = useApiCall(updateOrganizationAPI);

  const [fontSize, setFontSize] = useState(12);
  const [customInputValue, setCustomInputValue] = useState('');

  const [attachmentRows, setAttachmentRows] = React.useState<
    IAttachmentsTable[]
  >([]);

  const [locationRows, setLocationRows] = React.useState<ILocationsTable[]>([
    {
      id: uuidv4(),
      locationName: '',
      address: null,
      email: '',
      phone: '',
      administrator: '',
      func: '',
    },
  ]);

  const [commentRows, setCommentRows] = React.useState<ICommentsTable[]>([
    {
      id: uuidv4(),
      date: '',
      full_name: '',
      role: '',
      comment: '',
    },
  ]);

  const [siteNameRows, setSiteNameRows] = React.useState<ISiteNamesTable[]>([
    {
      id: uuidv4(),
      abbr: '',
      site_name: '',
      address: null,
      func: '',
      primary: false,
    },
  ]);

  const [departmentRows, setDepartmentRows] = React.useState<
    IDepartmentsTable[]
  >([
    {
      id: uuidv4(),
      abbr: '',
      departmentName: '',
      description: '',
      func: '',
    },
  ]);

  if (CURRENT_ROLE === APP_ROLE.CL) {
    navigate(RouterPath.REDEMPTION_CODE);
    dispatch({
      type: SET_LOADING_MODAL,
      payload: false,
    });
  }

  const allowAccess = useMemo(() => {
    return (
      CURRENT_ROLE === APP_ROLE.OW ||
      CURRENT_ROLE === APP_ROLE.SA ||
      CURRENT_ROLE === APP_ROLE.MGR ||
      CURRENT_ROLE === APP_ROLE.DS
    );
  }, [CURRENT_ROLE]);

  useEffect(() => {
    if (!allowAccess) {
      return navigate(RouterPath.ORGANIZATIONS);
    }
  }, [allowAccess, navigate]);

  function convertToSiteNameData(
    items: ISiteNamesTable[] | undefined
  ): ISelectOption[] {
    return (
      items?.map((items) => ({
        label: items.site_name,
        value: items.site_name,
      })) ?? []
    );
  }

  function convertSelectSiteName(items: ISiteNamesTable[] | undefined, value) {
    return items?.map((item) => ({
      id: item.id,
      abbr: item.abbr,
      site_name: item.site_name,
      address: item.address,
      func: '',
      primary: item.site_name === value,
    }));
  }

  const SITES_NAME_OPTIONS = React.useMemo(() => {
    return convertToSiteNameData(siteNameRows);
  }, [siteNameRows]);

  useEffect(() => {
    if (!clinicianId && !organizationId) return navigate('/');
    fetchOrg({
      userRecordId: clinicianId,
      organizationId: organizationId,
    });
  }, [clinicianId, organizationId]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: loading,
    });
  }, [loading]);

  const actionOpts = [
    {
      label: 'Action',
      value: 'Action',
    },
  ];

  const form = useForm<IOrganizationEditFormValues>({
    defaultValues: {
      organization: '',
      important: '',
      organizationTimeZone: '',
      orgShort: '',
      orgInitials: '',
      siteName: '',
      orgId: '',
      firstName: '',
      lastName: '',
      middleName: '',
      initials: '',
      sortName: '',
      givenName: '',
      prefixName: '',
      suffixName: '',
      servicePhone: '',
      unit: '',
      city: '',
      state: '',
      postCode: '',
    },
    resolver: FormResolver(useOrganizationEditSchema()),
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });
  useEffect(() => {
    orgData?.organizationName &&
      form.setValue('organization', orgData?.organizationName);
    orgData?.important && form.setValue('important', orgData?.important);
    orgData?.organizationTimeZone &&
      form.setValue('organizationTimeZone', orgData?.organizationTimeZone);
    orgData?.orgShort && form.setValue('orgShort', orgData?.orgShort);
    orgData?.orgInitials && form.setValue('orgInitials', orgData?.orgInitials);
    orgData?.organizationId && form.setValue('orgId', orgData?.organizationId);
    orgData?.adminFirstName &&
      form.setValue('firstName', orgData?.adminFirstName);
    orgData?.adminLastName && form.setValue('lastName', orgData?.adminLastName);
    orgData?.adminMiddleName &&
      form.setValue('middleName', orgData?.adminMiddleName);
    orgData?.adminInitials && form.setValue('initials', orgData?.adminInitials);
    orgData?.adminSortName && form.setValue('sortName', orgData?.adminSortName);
    orgData?.adminGivenName &&
      form.setValue('givenName', orgData?.adminGivenName);
    orgData?.adminPrefix && form.setValue('prefixName', orgData?.adminPrefix);
    orgData?.adminSuffix && form.setValue('suffixName', orgData?.adminSuffix);
    orgData?.servicePhone &&
      form.setValue('servicePhone', orgData?.servicePhone);
    orgData?.attachments && setAttachmentRows(orgData?.attachments);
    orgData?.comments && setCommentRows(orgData?.comments);
    orgData?.siteNames && setSiteNameRows(orgData?.siteNames);
    orgData?.departments && setDepartmentRows(orgData?.departments);
    orgData?.locations && setLocationRows(orgData?.locations);
    orgData?.startDate &&
      form.setValue('startDate', new Date(orgData?.startDate));
    orgData?.siteNames &&
      form.setValue(
        'siteName',
        orgData?.siteNames?.filter((item) => item.primary)?.[0]?.site_name
      );
  }, [orgData]);

  const userBillingAddress = useSelectBillToSuggestion(
    form.watch('street')?.label
  );

  const addressFieldData = React.useMemo(() => {
    return locationRows.find(
      (location) => location.locationName.toUpperCase() === 'PRIMARY'
    )?.address;
  }, [locationRows]);

  const userBillingAddressLocation = useSelectBillToSuggestion(
    addressFieldData?.label
  );

  React.useEffect(() => {
    form.setValue('city', userBillingAddressLocation.city);
    form.setValue('state', userBillingAddressLocation.stateOf);
    form.setValue('postCode', userBillingAddressLocation.zipCode);
    setCustomInputValue(userBillingAddressLocation?.streetAddress);
  }, [userBillingAddressLocation]);

  React.useEffect(() => {
    form.setValue('city', userBillingAddress.city);
    form.setValue('state', userBillingAddress.stateOf);
    form.setValue('postCode', userBillingAddress.zipCode);
  }, [form, userBillingAddress]);

  const renderPhone = (phone: string) => {
    return isEmpty(phone)
      ? '-'
      : `(${phone?.slice(0, 3)})-${phone?.slice(3, 6)}-${phone?.slice(
          6,
          phone?.length
        )}`;
  };

  const updateAttachmentRows = (id: string, row: IAttachmentsTable) => {
    const newRow = attachmentRows?.map((it) => {
      if (it.id === id) {
        return {
          ...it,
          ...row,
        };
      }
      return it;
    });

    setAttachmentRows(newRow);
  };

  const updateLocationRows = (id: number, row: ILocationsTable) => {
    const newRow = locationRows?.map((it) => {
      if (it.id === id) {
        return {
          ...it,
          ...row,
        };
      }
      return it;
    });

    setLocationRows(newRow);
  };

  const updateCommentRows = (id: number, row: ICommentsTable) => {
    const newRow = commentRows?.map((it) => {
      if (it.id === id) {
        return {
          ...it,
          ...row,
        };
      }
      return it;
    });

    setCommentRows(newRow);
  };

  const updateSiteNameRows = (id: number, row: ISiteNamesTable) => {
    const newRow = siteNameRows?.map((it) => {
      if (it.id === id) {
        return {
          ...it,
          ...row,
        };
      }
      return it;
    });

    setSiteNameRows(newRow);
  };

  const updateDepartmentRows = (id: number, row: IDepartmentsTable) => {
    const newRow = departmentRows?.map((it) => {
      if (it.id === id) {
        return {
          ...it,
          ...row,
        };
      }
      return it;
    });

    setDepartmentRows(newRow);
  };

  const renderPart = (title: string, element: any, isMl?: boolean) => {
    return (
      <div className="w-full flex items-start gap-x-10">
        {title && (
          <div
            className={cn('text-xs font-bold py-4 w-1/4', isMl ? 'ml-9' : '')}
            style={{ fontSize: `${fontSize}px` }}
          >
            {title}
          </div>
        )}
        <div className="w-full mt-6">{element}</div>
      </div>
    );
  };

  const renderVerticalRow = (headerName: string, cell: any) => {
    return (
      <div className=" w-full flex flex-col gap-y-4 py-4">
        <div className="flex items-center gap-x-2">
          <div className="w-2/5 justify-end">
            <p
              className="text-xs font-bold text-right"
              style={{ fontSize: `${fontSize}px` }}
            >
              {headerName}
            </p>
          </div>
          <div
            className="w-full flex flex-1 items-center justify-end"
            style={{ fontSize: `${fontSize}px` }}
          >
            {cell}
          </div>
        </div>
      </div>
    );
  };

  const renderTablePart = (title: string, element: any) => {
    return (
      <div
        className="flex flex-col gap-4 items-center w-full"
        style={{ fontSize: `${fontSize}px` }}
      >
        <p className={'font-bold text-center py-1'}>{title}</p>
        <div className="!overflow-auto  w-full max-h-full z-0">{element}</div>
      </div>
    );
  };

  const handleSubmitFunc = async (data: IOrganizationEditFormValues) => {
    const ind = orgData?.adminEmail.indexOf('@');
    const demain = orgData?.adminEmail.slice(
      ind + 1,
      orgData?.adminEmail.length
    );

    const siteNameData = convertSelectSiteName(siteNameRows, data.siteName);

    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: true,
      });
      await updateOrganization({
        body: {
          locations: locationRows,
          adminFirstName: data.firstName,
          adminLastName: data.lastName,
          adminMiddleName: data.middleName,
          adminInitials: data.initials,
          adminGivenName: data.givenName,
          adminSortName: data.sortName,
          adminPrefix: data.prefixName,
          adminSuffix: data.suffixName,
          adminEmail: orgData?.adminEmail,
          createdAt: orgData?.createdAt,
          servicePhone: data.servicePhone,
          startDate: data.startDate,
          emailDomain: demain,
          organizationId: orgData.organizationId,
          userRecordId: orgData.userRecordId,
          organization: data.organization,
          orgShort: data.orgShort,
          orgInitials: data.orgInitials,
          organizationTimeZone: data.organizationTimeZone,
          important: data.important,
          attachments: attachmentRows,
          comments: commentRows,
          siteNames: siteNameData,
          departments: departmentRows,
        },
      });
    } catch (err) {
      console.log('error', err);
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: false,
        });
      }, 100);
    }
    setTimeout(() => {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: false,
      });
    }, 100);
  };

  const formatStreet = (suggestion: Suggest) => {
    form.setValue('street', suggestion);
    if (suggestion && suggestion.gmaps) {
      const postCode = suggestion.gmaps.address_components.find((component) =>
        component.types.includes('postal_code')
      );

      const city = suggestion.gmaps.address_components.find((component) =>
        component.types.includes('locality')
      );

      form.setValue('postCode', postCode ? postCode?.long_name : '');
      form.setValue('city', city ? city.long_name : '');
    }
    if (suggestion) {
      const [inputValue] = suggestion?.label?.split(',');

      setCustomInputValue(inputValue?.trim());
    }
  };

  useEffect(() => {
    const mapData = orgData?.locations?.find(
      (location) => location.locationName.toUpperCase() === 'PRIMARY'
    );
    if (orgData?.locations && mapData) {
      form.setValue('city', mapData?.city);
      form.setValue('state', mapData?.stateOf);
      form.setValue('postCode', mapData?.zipCode);
      setCustomInputValue(mapData?.streetAddress);
    }
  }, [orgData?.locations]);

  useEffect(() => {
    if (!isEmpty(form?.formState?.errors)) {
      toast.error('Missing some fields. Please check them', {
        hideProgressBar: true,
        autoClose: 3000,
        type: 'error',
        theme: 'colored',
      });
    }
  }, [form?.formState?.errors]);

  return (
    <DefaultLayout>
      <HeaderApp.Detail
        detailProps={{
          detailName: `${orgData?.orgFullName || ''} - All Org Details`,
          labelDetail: 'Org Details - MGR',
          addLabel: 'Save',
        }}
        onAdd={form.handleSubmit(handleSubmitFunc)}
        viewName="Organization Detail"
        actions={{ options: actionOpts }}
        setFontSize={setFontSize}
      />

      <div className="w-full flex flex-col flex-1 mb-[50px] p-2.5">
        <div className="w-full flex justify-between">
          <div
            className="flex items-center text-xs text-black font-bold px-2.5 gap-x-[5px] cursor-pointer"
            onClick={() =>
              navigate(RouterPath.ORGANIZATIONS, {
                replace: true,
              })
            }
          >
            <img src={SVG.chevron_left} className="icon-app" alt="icon-back" />
            <p>{orgData?.orgFullName || ''}</p>
          </div>
          <p className="text-right self-end text-xs">
            Last Update: 23 hrs – 40 Min Ago
          </p>
        </div>
        <FormProvider {...form}>
          <form className="flex gap-2.5 items-stretch h-full w-full mt-2.5 flex-1">
            <div className="flex-1 flex flex-col overflow-auto relative gap-y-3">
              <Box.Standard>
                <div className="grid grid-cols-2 gap-[10px] mt-4">
                  {renderVerticalRow(
                    'Organization',
                    <Form.TextInput
                      name="organization"
                      className="flex-1 bg-white"
                      inputProps={{
                        placeholder: '',
                        value: form.getValues('organization'),
                        className:
                          'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                      }}
                      disable={true}
                    />
                  )}
                  <div className="flex flex-col">
                    {renderVerticalRow(
                      'Important',
                      <Form.TextInput
                        name="important"
                        className="flex-1 bg-white"
                        inputProps={{
                          placeholder: '',
                          value: form.getValues('important'),
                          className:
                            'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold !text-error',
                        }}
                        disable={true}
                      />
                    )}
                    {renderVerticalRow(
                      'Organization Time Zone',
                      <Form.TextInput
                        name="organizationTimeZone"
                        className="flex-1 bg-white"
                        inputProps={{
                          placeholder: '',
                          value: form.getValues('organizationTimeZone'),
                          className:
                            'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !text-error',
                        }}
                        disable={true}
                      />
                    )}
                  </div>
                </div>
              </Box.Standard>
              <Box.Standard>
                <div className="w-full grid grid-cols-2 gap-5">
                  <div className="flex w-full flex-col gap-y-[20px]">
                    {renderPart(
                      'Organization',
                      <>
                        {renderVerticalRow(
                          '*Organization',
                          <Form.TextInput
                            name="organization"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('organization'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          '*Org Short',
                          <Form.TextInput
                            name="orgShort"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('orgShort'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          '*Org. Initials',
                          <Form.TextInput
                            name="orgInitials"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('orgInitials'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Site Names',
                          <Form.SelectInput
                            name="siteName"
                            className="flex-1 bg-white"
                            options={SITES_NAME_OPTIONS}
                          />
                        )}
                        {renderVerticalRow('', <></>)}
                        {renderVerticalRow('', <></>)}
                        {renderVerticalRow('', <></>)}
                        {renderVerticalRow('', <></>)}
                      </>,
                      true
                    )}
                    {renderPart(
                      'Key Info',
                      <>
                        {renderVerticalRow(
                          'Important',
                          <Form.TextInput
                            name="important"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('important'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px] !font-semibold !text-error',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Organization Time Zone',
                          <Form.SelectInput
                            name="organizationTimeZone"
                            className="flex-1 bg-white"
                            options={TIMEZONE_OPTIONS}
                          />
                        )}
                        {renderVerticalRow('', <></>)}
                        {renderVerticalRow('', <></>)}
                        {renderVerticalRow('', <></>)}
                        {renderVerticalRow('', <></>)}
                        {renderVerticalRow('', <></>)}
                      </>,
                      true
                    )}
                    {renderPart(
                      'Identification',
                      <>
                        {renderVerticalRow(
                          '*Start Date',
                          <Form.DatePicker
                            name="startDate"
                            inputProps={{ id: 'startDate', placeholder: '' }}
                            date={form?.watch('startDate')}
                            onSelect={(value: Date) => {
                              form.setValue('startDate', value);
                            }}
                            className="text-right"
                          />
                        )}
                        {renderVerticalRow(
                          '*Organization ID',
                          <Form.TextInput
                            name="orgId"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('orgId'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                      </>,
                      true
                    )}
                  </div>
                  <div className="flex w-full flex-col">
                    {renderPart(
                      'Contact Information',
                      <>
                        {renderVerticalRow(
                          '*First Name (Goes by)',
                          <Form.TextInput
                            name="firstName"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('firstName'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          '*Last Name',
                          <Form.TextInput
                            name="lastName"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('lastName'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          '*Middle Name',
                          <Form.TextInput
                            name="middleName"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('middleName'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          '*Initials',
                          <Form.TextInput
                            name="initials"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('initials'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          '*Sort Name - Clin',
                          <Form.TextInput
                            name="sortName"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('sortName'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Given Name (First)',
                          <Form.TextInput
                            name="givenName"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('givenName'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Prefix',
                          <Form.TextInput
                            name="prefixName"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('prefixName'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Suffix',
                          <Form.TextInput
                            name="suffixName"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('suffixName'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          '*Service Phone #',
                          <Form.PhoneInputV2
                            className=""
                            name="servicePhone"
                            inputProps={{
                              id: 'servicePhone',
                              placeholder: 'Phone Number',
                              value: renderPhone(
                                form.getValues('servicePhone')
                              ),
                            }}
                          />
                        )}
                      </>,
                      false
                    )}
                    {renderPart(
                      'Address',
                      <>
                        {renderVerticalRow(
                          'Street',
                          <GeoSuggest
                            placeholder=""
                            inputClassName={cn(
                              '!w-full !text-right flex-1 ring-none !shadow-none px-4 py-2 text-sm font-inter text-input placeholder:text-slate-400 bg-white bg-clip-padding transition ease-in-out m-0 focus:text-input focus:bg-white !focus:border-primary outlined-none border-none rounded-[5px]'
                            )}
                            onSuggestSelect={(suggestion) =>
                              formatStreet(suggestion)
                            }
                            initialValue={customInputValue}
                            name="street"
                          />
                        )}
                        {renderVerticalRow(
                          'Unit',
                          <Form.TextInput
                            name="unit"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('unit'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]  ',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'City',
                          <Form.TextInput
                            name="city"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('city'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'State',
                          <Form.TextInput
                            name="state"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: form.getValues('state'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                        {renderVerticalRow(
                          'Post Code',
                          <Form.TextInput
                            name="postCode"
                            className="flex-1 bg-white"
                            inputProps={{
                              placeholder: '',
                              value: isEmpty(form.getValues('postCode'))
                                ? '-'
                                : form.getValues('postCode'),
                              className:
                                'outlined-none !p-1 border-none w-full h-full rounded-[0px] !px-2 text-right h-[30px]',
                            }}
                          />
                        )}
                      </>,
                      false
                    )}
                  </div>
                </div>
              </Box.Standard>
              <Box.Standard>
                {renderTablePart(
                  'Attachments',
                  <Attachments
                    fontSize={fontSize}
                    rows={attachmentRows}
                    setRows={setAttachmentRows}
                    updateValue={updateAttachmentRows}
                  />
                )}
              </Box.Standard>
              <Box.Standard>
                {renderTablePart(
                  'Comments',
                  <Comments
                    fontSize={fontSize}
                    rows={commentRows}
                    setRows={setCommentRows}
                    updateValue={updateCommentRows}
                  />
                )}
              </Box.Standard>
              <Box.Standard>
                {renderTablePart(
                  'Locations',
                  <Locations
                    fontSize={fontSize}
                    rows={locationRows}
                    setRows={setLocationRows}
                    updateValue={updateLocationRows}
                  />
                )}
              </Box.Standard>
              <Box.Standard>
                {renderTablePart(
                  'Site Names',
                  <SiteNames
                    fontSize={fontSize}
                    rows={siteNameRows}
                    setRows={setSiteNameRows}
                    updateValue={updateSiteNameRows}
                  />
                )}
              </Box.Standard>
              <Box.Standard>
                {renderTablePart(
                  'Departments',
                  <Departments
                    fontSize={fontSize}
                    rows={departmentRows}
                    setRows={setDepartmentRows}
                    updateValue={updateDepartmentRows}
                  />
                )}
              </Box.Standard>
            </div>
          </form>
        </FormProvider>
      </div>
      <Footer />
    </DefaultLayout>
  );
}
