import { SVG } from 'assets/imagePath';
import { Collapse, Form } from 'ui-molecules';
import { useDebounce } from 'hooks/useDebounce';
import { INSIGHT_LIST } from 'pages/builder/data';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import BuilderInsightCheckbox from './BuilderInsightCheckbox';

export interface IBuilderSmartInsightProps {
  onGetData: (dataInsight: any, dataSmart: any, dataCheckbox: any) => void;
}

function BuilderSmartInsight({ onGetData }: IBuilderSmartInsightProps) {
  const form = useForm({ mode: 'onChange' });

  const searchVal = form.watch('search_box');

  const debounceVal = useDebounce(searchVal);

  const handleSubmitData = (dataCheckbox = null) => {
    form.handleSubmit((data) => {
      onGetData(data, null, dataCheckbox);
    });
  };

  React.useEffect(() => {
    handleSubmitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceVal]);

  return (
    <FormProvider {...form}>
      <form className="flex flex-col gap-y-2.5">
        <h3 className="font-bold text-sm mx-2.5 py-2.5 text-center uppercase">
          Smart Insights
        </h3>
        <div className="">
          <Form.SearchBox
            name="search_box"
            className="w-full border-[1px] border-nav border-solid  bg-transparent"
            clearInput={() =>
              form.reset((currentValues) => ({
                ...currentValues,
                search_box: '',
              }))
            }
            inputProps={{
              placeholder: 'Search',
              style: { borderStyle: 'solid' },
            }}
          />
        </div>
        {INSIGHT_LIST.map((insight_it, idx) => {
          const collapseList = insight_it.children;
          return (
            <ul key={insight_it.id} className="px-2.5">
              <li className="w-full">
                <Collapse.Text
                  defaultOpen={insight_it.defaultOpen}
                  value={insight_it?.id as string}
                  button={(open) => (
                    <Collapse.Button
                      open={open}
                      className={
                        insight_it.level === 0
                          ? 'text-black uppercase !justify-start py-[5px]'
                          : '!justify-center text-center py-[4.5px]  text-black'
                      }
                      label={insight_it.label}
                      icon={SVG.arrow_down}
                      classNameIcon="h-[15px] w-[15px ml-[5px]"
                    />
                  )}
                  collapseContent={
                    <ul className="flex flex-col">
                      {collapseList?.map((collapse_it) => {
                        const collapseChild = collapse_it?.children;
                        return (
                          <BuilderInsightCheckbox
                            propsCheckBox={{
                              name: `${collapse_it.id}_${collapse_it?.parent_id}`,
                              label: collapse_it?.label,
                              onChangeCustom: handleSubmitData,
                            }}
                            isCollapse={!!collapseChild}
                            childItem={collapse_it}
                          />
                        );
                      })}
                    </ul>
                  }
                />
              </li>
            </ul>
          );
        })}
      </form>
    </FormProvider>
  );
}
export default React.memo(BuilderSmartInsight);
