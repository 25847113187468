import React from 'react';
import CheckboxSingle from './CheckboxSingle';
import Checkboxes from './Checkboxes';

const Checkbox = () => {
  return <React.Fragment />;
};

Checkbox.Single = CheckboxSingle;
Checkbox.Checkboxes = Checkboxes;

export default Checkbox;
