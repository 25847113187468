import { Disclosure, Transition } from '@headlessui/react';
import * as React from 'react';

export interface ICollapseTextProps {
  button?: (
    open: boolean,
    close: (
      focusableElement?:
        | HTMLElement
        | React.MutableRefObject<HTMLElement | null>
    ) => void
  ) => React.ReactNode;
  disabled?: boolean;
  collapseContent?: React.ReactNode;
  defaultOpen?: boolean;
  value?: string;
  onClick?: () => void;
}

export default function CollapseText({
  button,
  collapseContent,
  defaultOpen,
  disabled = false,
  value,
}: ICollapseTextProps) {
  return (
    /* Render a `div` for the root `Disclosure` component */
    <Disclosure as="div" defaultOpen={defaultOpen}>
      {({ open, close }) => (
        <>
          <Disclosure.Button
            as={'button'}
            disabled={disabled}
            className="w-full cursor-pointer"
            onClick={() => {}}
          >
            {button(open, close)}
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel as={'div'}>
              {collapseContent || ''}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
CollapseText.displayName = 'Collapse.Text';
